var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wscn-http404-container" }, [
    _c("div", { staticClass: "wscn-http404" }, [
      _c(
        "div",
        { staticClass: "svg_content" },
        _vm._l(_vm.icons, function (item) {
          return _c(
            "div",
            {
              key: item.default.id,
              staticClass: "svg_item",
              on: {
                click: function ($event) {
                  return _vm.handleClick(item)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("svg-icon", {
                    attrs: { "icon-class": _vm.translateIconClass(item) },
                  }),
                ],
                1
              ),
              _c("p", [_vm._v(_vm._s(_vm.translateIconClass(item)))]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }