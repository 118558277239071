"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ['discussLoading', 'discussNoMore', 'list', 'cData', 'title'],
  data: function data() {
    return {};
  },
  computed: {
    disabled: function disabled() {
      return this.discussLoading || this.discussNoMore;
    }
  },
  mounted: function mounted() {},
  methods: {
    discussLoad: function discussLoad() {
      this.$emit('getComment');
    }
  }
};
exports.default = _default;