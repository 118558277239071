"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _echarts = _interopRequireDefault(require("echarts"));
var _AnalyzeLine = _interopRequireDefault(require("@/components/Charts/AnalyzeLine"));
var _MorePie = _interopRequireDefault(require("@/components/Charts/Pie/MorePie"));
var _index = _interopRequireDefault(require("@/components/Charts/Pie/index"));
var _WordCloud = _interopRequireDefault(require("@/components/Charts/WordCloud"));
var _moment = _interopRequireDefault(require("moment"));
var _Progress = _interopRequireDefault(require("@/components/Item/Progress"));
var _raf = _interopRequireDefault(require("raf"));
require("flv.js/dist/flv.min.js");
var _flv = _interopRequireDefault(require("flv.js"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)("live_analyze"),
  mapState = _createNamespacedHelp.mapState,
  mapMutations = _createNamespacedHelp.mapMutations,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  components: {
    AnalyzeLine: _AnalyzeLine.default,
    MorePie: _MorePie.default,
    PieChart: _index.default,
    WordCloud: _WordCloud.default,
    Progress: _Progress.default
  },
  data: function data() {
    return {
      hls: "",
      flvPlayer: null,
      dialogVisible: false,
      nowDate: "",
      valueDate: "",
      commentWord: "",
      active: 0,
      scrollTips: false,
      activeCar: "",
      showLiveRecord: false,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var times = Date.now();
          return time.getTime() > times;
        }
      },
      scrollTime: 500
    };
  },
  watch: {
    liveComment: function liveComment(val) {
      console.log(val);
      this.initChart(val);
    }
  },
  computed: _objectSpread({}, mapState({
    liveRecord: function liveRecord(state) {
      return state.liveRecord;
    },
    liveComment: function liveComment(state) {
      return state.liveComment;
    },
    commentList: function commentList(state) {
      return state.commentList;
    },
    hotCarType: function hotCarType(state) {
      return state.hotCarType;
    },
    analysisLine: function analysisLine(state) {
      return state.analysisLine;
    },
    // commentTotal: state => state.commentTotal,
    current: function current(state) {
      return state.current;
    },
    loading: function loading(state) {
      return state.loading;
    },
    disabledLoad: function disabledLoad(state) {
      return state.loading || state.noMore;
    },
    noMore: function noMore(state) {
      return state.noMore;
    },
    hotWord: function hotWord(state) {
      return state.hotWord;
    },
    fansStat: function fansStat(state) {
      return state.fansStat;
    },
    audienceAdress: function audienceAdress(state) {
      return state.audienceAdress;
    },
    live_count: function live_count(state) {
      return state.live_count;
    }
  })),
  created: function created() {
    this.changeState({
      name: "room_id",
      data: this.$route.query.room_id
    });
    this.changeState({
      name: "author_id",
      data: this.$route.query.author_id
    });
  },
  mounted: function mounted() {
    this.getInitialData();
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll, false);
    this.nowDate = (0, _moment.default)(new Date()).add("year", 0).format("YYYY-MM-DD HH:mm");
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["getLiveRecordAnaly", "getCommentAnalysis", "getCommentHot", "getCommentList", "getAudience", "getAnalysisChart", "getHotWord", "getAudienceAdress", "getLiveCalender"])), mapMutations(["changeState"])), {}, {
    format: function format(percentage) {
      return percentage;
    },
    getInitialData: function getInitialData() {
      this.getLiveRecordAnaly();
      this.getCommentAnalysis();
      this.getCommentHot();
      this.getCommentList();
      this.getAudience();
      this.getAnalysisChart();
      this.getHotWord();
      this.getAudienceAdress();
    },
    selectDate: function selectDate(date) {
      this.showLiveRecord = true;
      var d = new Date();
      var beginTime = (0, _moment.default)(date).format("YYYY-MM-DD HH:mm:ss");
      this.changeState({
        name: "begin_time",
        data: beginTime
      });
      var time1 = (0, _moment.default)(d).valueOf() - 1000 * 24 * 60 * 60;
      var time2 = (0, _moment.default)(date).valueOf();
      var value = "";
      if (time1 > time2) {
        value = "".concat((0, _moment.default)(date).format("YYYY-MM-DD"), " 23:59:59");
        this.changeState({
          name: "end_time",
          data: value
        });
      } else {
        value = (0, _moment.default)(d).format("YYYY-MM-DD HH:mm:ss");
      }
      this.changeState({
        name: "end_time",
        data: value
      });
      this.getLiveCalender();
    },
    // 切换直播场次
    selectLive: function selectLive(index) {
      this.showLiveRecord = false, this.clearComment();
      this.changeState({
        data: index,
        name: "room_id"
      });
      this.getInitialData();
      this.nowDate = (0, _moment.default)(new Date()).add("year", 0).format("YYYY-MM-DD HH:mm");
    },
    selectComment: function selectComment(count, word) {
      console.log("我的count", count);
      if (count == 1) {
        this.commentWord = word;
        this.activeCar = "";
        this.changeState({
          name: "jubgeComment",
          data: true
        });
      } else if (count == 2) {
        this.commentWord = word;
        this.changeState({
          name: "jubgeComment",
          data: false
        });
      } else {
        this.activeCar = "";
        this.commentWord = "";
        this.changeState({
          name: "jubgeComment",
          data: true
        });
      }
      this.clearComment();
      this.changeState({
        name: "word",
        data: this.commentWord
      });
      this.getCommentList();
    },
    selectCar: function selectCar(index, count, word) {
      this.clearComment();
      this.activeCar = index;
      this.selectComment(count, word);
    },
    changeInput: function changeInput(value) {
      this.clearComment();
      this.selectComment(1, value);
    },
    clearComment: function clearComment() {
      this.changeState({
        name: "current",
        data: 1
      });
      this.changeState({
        name: "noMore",
        data: false
      });
      this.changeState({
        name: "loading",
        data: false
      });
      this.changeState({
        name: "commentList",
        data: {
          list: [],
          pagination: {}
        }
      });
    },
    onScroll: function onScroll() {
      var _this = this;
      // 获取所有锚点元素
      var navContents = document.querySelectorAll(".content_scroll");
      // 所有锚点元素的 offsetTop
      var offsetTopArr = [];
      var container = window;
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      navContents.forEach(function (item) {
        var eleOffsetTop = _this.getOffsetTop(item, container);
        var targetScrollTop = scrollTop + eleOffsetTop;
        offsetTopArr.push(targetScrollTop);
      });
      var navIndex = 0;
      if (scrollTop > 360) {
        this.scrollTips = true;
      } else {
        this.scrollTips = false;
      }
      // let slTop = 0;
      for (var n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        // slTop += offsetTopArr[n];
        // console.log('走过的距离',slTop)
        if (scrollTop > offsetTopArr[n]) {
          navIndex = n + 1;
        }
      }
      this.active = navIndex;
    },
    scrollTo: function scrollTo(item) {
      var _this2 = this;
      // this.active = item;
      var dom = document.querySelectorAll(".content_scroll")[item];
      var container = window;
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      var eleOffsetTop = this.getOffsetTop(dom, container);
      var targetScrollTop = scrollTop + eleOffsetTop;
      var startTime = Date.now();
      var frameFunc = function frameFunc() {
        var timestamp = Date.now();
        var time = timestamp - startTime;
        var nextScrollTop = _this2.easeInOutCubic(time, scrollTop, targetScrollTop, _this2.scrollTime);
        if (container === window) {
          window.scrollTo(window.pageXOffset, nextScrollTop);
        } else {
          container.scrollTop = nextScrollTop;
        }
        if (time < _this2.scrollTime) {
          (0, _raf.default)(frameFunc);
        } else {
          _this2.isLockScroll = false;
        }
      };
      this.isLockScroll = true;
      (0, _raf.default)(frameFunc);
    },
    easeInOutCubic: function easeInOutCubic(t, b, c) {
      var d = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 500;
      var cc = c - b;
      t /= d / 2;
      if (t < 1) {
        return cc / 2 * t * t * t + b;
      }
      return cc / 2 * ((t -= 2) * t * t + 2) + b;
    },
    /**
     * @desc: 获取目标和容器之间的高度差，该方法会漏算容器的border-top
     * @param {type}
     * @return: {type}
     * @example:
     */
    getOffsetTop: function getOffsetTop(element, container) {
      if (!element) {
        return 0;
      }
      if (!element.getClientRects().length) {
        return 0;
      }
      var rect = element.getBoundingClientRect();
      if (rect.width || rect.height) {
        if (container === window) {
          container = element || document;
          return rect.top - container.clientTop;
        }
        return rect.top - container.getBoundingClientRect().top;
      }
      return rect.top;
    },
    // 滚动加载评论
    load: function load() {
      if (this.current <= this.commentList.pagination.pages) {
        this.getCommentList();
      }
    },
    initChart: function initChart(val) {
      this.chart = _echarts.default.init(document.getElementById("commentChart"));
      var clue = val.types.filter(function (e) {
        return e.type == "CLUE";
      });
      var clue_count = clue.length > 0 ? clue[0].count : 0;
      var lineargroup = [{
        value: val.total,
        name: "所有评论",
        oriname: "所有评论",
        number: val.total
      }, {
        value: val.validate_comment_count,
        name: "有效评论",
        oriname: "有效评论",
        number: val.validate_comment_count
      }, {
        value: clue_count,
        name: "线索",
        oriname: "线索",
        number: clue_count
      }];
      var data = [];
      for (var i = 0; i < lineargroup.length; i++) {
        var obj = {
          value: lineargroup[i].value,
          num: lineargroup[i].number,
          name: lineargroup[i].oriname
        };
        data.push(obj);
      }
      this.chart.setOption({
        backgroundColor: "",
        color: ["#63cf8f", "#6c8ff7", "#f7c866"],
        grid: {
          top: "-5",
          left: "2%",
          right: 20,
          height: "20",
          bottom: "0"
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          show: false,
          boundaryGap: false,
          type: "category"
        }],
        series: [{
          top: 0,
          type: "funnel",
          height: "300",
          gap: 15,
          minSize: 100,
          left: "5%",
          width: "80%",
          borderWidth: 0,
          borderType: "dashed",
          label: {
            show: true,
            position: "inside",
            fontSize: "13",
            formatter: function formatter(d) {
              var ins = d.name + "  " + d.data.num;
              return ins;
            },
            rich: {
              aa: {
                padding: [8, 0, 6, 0]
              }
            }
          },
          data: data
        }]
      });
    },
    // 点击视频回放按钮
    openVideo: function openVideo() {
      if (this.liveRecord.play_url) {
        this.dialogVisible = true;
        var url = process.env.VUE_APP_OSS_LIVE_HOST + "/" + this.liveRecord.play_url;
        console.log(url);
        this.getStream(url);
      } else {
        this.$message.warning("暂无该直播回放，请检查开启该门店直播监测");
      }
    },
    // 初始化flv
    getStream: function getStream(source) {
      var _this3 = this;
      this.$nextTick(function () {
        if (_flv.default.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          console.log(videoElement);
          _this3.flvPlayer = _flv.default.createPlayer({
            type: "flv",
            url: source
          });
          _this3.flvPlayer.attachMediaElement(videoElement);
          _this3.flvPlayer.load();
          _this3.flvPlayer.play();
          _this3.flvPlayer.on("error", function (err) {
            console.log("err", err);
            _this3.handleClose();
            _this3.$message.warning("该直播视频已锁定,请联系管理员");
          });
        }
      });
    },
    // 关闭 flv 视频弹窗事件
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.flvPlayer.destroy();
      // 清除视频
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.flvPlayer.destroy();
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.onScroll);
    this.changeState({
      name: "jubgeComment",
      data: true
    });
    this.clearComment();
  }
};
exports.default = _default;