"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 回访统计列表
  getVisitList: function getVisitList(_ref) {
    var visit_begin_time = _ref.visit_begin_time,
      visit_end_time = _ref.visit_end_time,
      author_id = _ref.author_id;
    return request({
      url: "/revisit/customer-revisit-list",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        visit_begin_time: visit_begin_time,
        visit_end_time: visit_end_time,
        author_id: author_id
      }
    });
  },
  // 主播个人信息
  getAuthorInfo: function getAuthorInfo(_ref2) {
    var user_id = _ref2.user_id;
    return request({
      url: "/account/author-list",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        user_id: user_id
      }
    });
  }
};
exports.default = _default;