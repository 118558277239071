var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm.isOpenedRoutine
        ? _c("a-alert", {
            attrs: {
              message:
                "已开启「套路对话」，本功能暂时失效。若需要重新启用本功能，请关闭【套路对话】功能。",
              banner: "",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tips-box" }, [
        _c(
          "div",
          { staticClass: "title-header" },
          [
            _vm._v(" 私信接待 "),
            _c("a-switch", {
              attrs: { loading: _vm.isOpening },
              on: { change: _vm.switchCheck },
              model: {
                value: _vm.isOpened,
                callback: function ($$v) {
                  _vm.isOpened = $$v
                },
                expression: "isOpened",
              },
            }),
          ],
          1
        ),
        _c("p", [
          _vm._v("用户进入私信对话页，针对用户意向，自动发送欢迎语进行接待"),
        ]),
        _c("p", [_vm._v("1、每个用户每个策略只触发一次回复")]),
        _c("p", [
          _vm._v(
            "2、一个用户命中多个策略时，只触发优先级最高的。其中个人自定义＞品牌推荐"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-content" },
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.defaultClassifyTabId,
                callback: function ($$v) {
                  _vm.defaultClassifyTabId = $$v
                },
                expression: "defaultClassifyTabId",
              },
            },
            [
              _c("a-tab-pane", {
                key: "通用欢迎语",
                attrs: { tab: "通用欢迎语" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-10" },
            [
              _vm.listTypeList.length > 0
                ? _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.listType,
                        callback: function ($$v) {
                          _vm.listType = $$v
                        },
                        expression: "listType",
                      },
                    },
                    _vm._l(_vm.listTypeList, function (item, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { class: { "mask-list": !_vm.isOpened } },
            [
              _vm.defaultClassifyTabId === "通用欢迎语" && _vm.listType === 2
                ? _c("CommonAuthorList")
                : _vm._e(),
              _vm.defaultClassifyTabId === "通用欢迎语" && _vm.listType === 1
                ? _c("CommonPrincipalList")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("OpenCheckModal", { ref: "OpenCheckModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }