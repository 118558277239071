var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "param-table-container" }, [
    _c(
      "div",
      { staticClass: "container-left" },
      [
        _c("div", { staticClass: "list-item" }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.goAnchor("#id0")
                },
              },
            },
            [_vm._v("回到顶部")]
          ),
        ]),
        _vm._l(_vm.leftList, function (item, index) {
          return _c("div", { key: item.id, staticClass: "list-item" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.goAnchor("#id" + (index + 1))
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            ),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "container-right" },
      [
        _vm.titleName
          ? _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
                attrs: { id: "id0" },
              },
              [_vm._v(" 车型名称: " + _vm._s(_vm.titleName) + " ")]
            )
          : _vm._e(),
        _vm.tableData && _vm.tableData.length > 0
          ? _c(
              "div",
              { staticClass: "top-div" },
              _vm._l(_vm.topData, function (item, index) {
                return _c("div", { key: index, staticClass: "top-item" }, [
                  _c("div", { staticClass: "item-left" }, [
                    _vm._v(_vm._s("【" + item.name + "】:")),
                  ]),
                  _c("div", { staticClass: "item-right" }, [
                    _vm._v(_vm._s(item.value)),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "main-item" },
            [
              _c(
                "div",
                {
                  ref: "id" + (index + 1),
                  refInFor: true,
                  staticClass: "title",
                  attrs: { id: "id" + (index + 1) },
                },
                [
                  _vm._v(
                    _vm._s(index >= 9 ? item.name.slice(2) : item.name.slice(1))
                  ),
                ]
              ),
              _vm._l(item.value, function (item2) {
                return _c("div", { key: item2.name, staticClass: "content" }, [
                  _c("div", { staticClass: "left" }, [
                    _vm._v(_vm._s(item2.name)),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _vm._v(_vm._s(item2.value)),
                  ]),
                ])
              }),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }