var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        visible: _vm.visible,
        title: "编辑回复",
        loading: _vm.saving,
        width: "620",
        maskClosable: false,
        submitText: "保存",
      },
      on: {
        close: _vm.closeDrawer,
        submit: function ($event) {
          return _vm.save()
        },
      },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _vm.form.author_name
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "主播昵称", prop: "author_name" } },
                    [_vm._v(" " + _vm._s(_vm.form.author_name) + " ")]
                  )
                : _vm._e(),
              _vm.form.strategy_name
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "策略名称", prop: "strategy_name" } },
                    [_vm._v(" " + _vm._s(_vm.form.strategy_name) + " ")]
                  )
                : _vm._e(),
              _vm.form.key_word_group
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "关键词组名", prop: "key_word_group" } },
                    [_vm._v(" " + _vm._s(_vm.form.key_word_group) + " ")]
                  )
                : _vm._e(),
              _vm.form.key_word_group
                ? _c("a-form-model-item", { attrs: { label: "关键词" } }, [
                    _c(
                      "div",
                      { staticClass: "keyword-list" },
                      [
                        _vm._l(
                          _vm.form.key_word_details_vos,
                          function (word, index) {
                            return [
                              _c(
                                "div",
                                { key: index, staticClass: "keyword-item" },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(word.details_name)),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("a-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.conditionMap[word.match_type]
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.form.key_word_details_vos.length > 1 &&
                              index !== _vm.form.key_word_details_vos.length - 1
                                ? _c("div", { key: "or" + index }, [
                                    _vm._v(" 或 "),
                                  ])
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.form.listTypeName
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "类型", prop: "strategy_name" } },
                    [_vm._v(" " + _vm._s(_vm.form.listTypeName) + " ")]
                  )
                : _vm._e(),
              _vm._l(_vm.form.wordResponseList, function (word, index) {
                return _c(
                  "a-form-model-item",
                  {
                    key: word.responseId,
                    ref: "keyword" + index,
                    refInFor: true,
                    staticClass: "keyword-model-item",
                    attrs: {
                      label: index === 0 ? "回复内容" : " ",
                      colon: index === 0,
                      prop: "wordResponseList." + index + ".message",
                      rules: {
                        index: index,
                        required: true,
                        validator: _vm.validateMessage,
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    index === 0
                      ? _c("div", { staticClass: "normal-tip" }, [
                          _vm._v("最多可添加10条，用户命中策略时随机发送1条"),
                        ])
                      : _vm._e(),
                    _vm.principalCheckInfo.isPrincipal && word.showSpecial
                      ? _c(
                          "a-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              getPopupContainer: function (triggerNode) {
                                return triggerNode.parentNode
                              },
                              placeholder: "请选择变量",
                            },
                            on: {
                              change: function (value) {
                                return _vm.selectSpecialValue(value, word)
                              },
                            },
                          },
                          _vm._l(_vm.shopList, function (item, index) {
                            return _c(
                              "a-select-option",
                              { key: index, attrs: { value: item } },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "word-item" },
                      [
                        _c("a-textarea", {
                          staticClass: "keyword-input",
                          attrs: {
                            placeholder:
                              _vm.principalCheckInfo.scriptPlaceHolder,
                            rows: "5",
                            "max-length": 300,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.specialCheck(word)
                            },
                            blur: function ($event) {
                              _vm.$refs["keyword" + index][0].onFieldBlur()
                            },
                          },
                          model: {
                            value: word.message,
                            callback: function ($$v) {
                              _vm.$set(
                                word,
                                "message",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "word.message",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "right-operation" },
                          [
                            _c("a-icon", {
                              staticClass: "icon delete",
                              attrs: { type: "minus-circle" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeMessage(index)
                                },
                              },
                            }),
                            _vm.form.wordResponseList.length < 10
                              ? _c("a-icon", {
                                  staticClass: "icon add",
                                  attrs: { type: "plus-circle" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addMessage(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "tips_count",
                                staticStyle: {
                                  position: "absolute",
                                  left: "0",
                                  bottom: "0",
                                  "pointer-events": "none",
                                },
                              },
                              [_vm._v(_vm._s(word.message.length) + "/300")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.deprecatedTipList[index]
                      ? _c("div", { staticClass: "warning-tip" }, [
                          _vm._v(
                            "不建议包含" +
                              _vm._s(_vm.deprecatedTipList[index]) +
                              "敏感词"
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.form.wordResponseList.length === 0
                ? _c("a-form-model-item", { attrs: { label: "回复内容" } }, [
                    _c("div", { staticClass: "normal-tip" }, [
                      _vm._v("最多可添加10条，用户命中策略时随机发送1条"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "word-item" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "normal-tip",
                            staticStyle: { "margin-right": "10px" },
                          },
                          [_vm._v("暂无回复内容")]
                        ),
                        _c("a-icon", {
                          staticClass: "icon add",
                          attrs: { type: "plus-circle" },
                          on: {
                            click: function ($event) {
                              return _vm.addMessage(0)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }