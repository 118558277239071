"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var _getQuickReplyList$ge;
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}

// 缓存上一个请求的cancel，用于取消上一个尚未结束的请求
var cancel = null; // 一个请求对应一个cancel
var _module = '/live-crm';
var CancelToken = buildCancelToken();
var _default = (_getQuickReplyList$ge = {
  //   获取一键输入回复内容列表
  getQuickReplyList: function getQuickReplyList(params) {
    return request({
      url: "/mkms/videoAutoReplyModel/commentOneClickRepliesContent",
      method: 'get',
      params: params
    });
  },
  //  获取所有市
  getAllCity: function getAllCity() {
    return request({
      url: "/area/list",
      method: 'get'
    });
  },
  // 获取主播配置
  getAuthorSetting: function getAuthorSetting(params) {
    return request({
      url: "/mkms/authorSetting/configInfo",
      method: 'get',
      params: params
    });
  },
  // 更新主播设置
  updataAuthor: function updataAuthor(data) {
    return request({
      url: "/mkms/authorSetting/update",
      method: 'post',
      data: data
    });
  },
  // 查询触达策略列表
  getAttentionList: function getAttentionList() {
    return request({
      url: "/mkms/chatAutoReplyModel/list",
      method: 'get'
    });
  },
  // 开启关闭触达策略
  autoReplySwitch: function autoReplySwitch(data) {
    return request({
      url: "/mkms/chatAutoReplyModel/switch",
      method: 'post',
      data: data
    });
  },
  //   查询回复策略配置
  getIntellReplySetting: function getIntellReplySetting(sceneId) {
    return request({
      url: "/mkms/chatAutoReplyModel/".concat(sceneId),
      method: 'get'
    });
  },
  // 编辑触达策略配置
  editIntellectReply: function editIntellectReply(data) {
    return request({
      url: "/mkms/chatAutoReplyModel/".concat(data.sceneId),
      method: 'post',
      data: data
    });
  }
}, (0, _defineProperty2.default)(_getQuickReplyList$ge, "getQuickReplyList", function getQuickReplyList(params) {
  return request({
    url: "/mkms/chatAutoReplyModel/replyContent/list",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getCarAndChatTag", function getCarAndChatTag(params) {
  return request({
    url: "/mkms/chat/listForSearch",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getChatUserList", function getChatUserList(params) {
  return request({
    url: "/mkms/chat/user/list",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getNoReadCount", function getNoReadCount(params) {
  return request({
    url: "/mkms/chat/unReadCount",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getUserMessage", function getUserMessage(params) {
  return request({
    url: "/mkms/chat/userOverViewInfo",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getFansPolling", function getFansPolling(params) {
  return request({
    url: "/douyin-live-polling/get-fans-polling",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getFansInfo", function getFansInfo(params) {
  return request({
    url: "/douyin-live-polling/get-fans-info",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getVideoComment", function getVideoComment(params) {
  return request({
    url: "/mkms/videoComment/user",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getAwemeLikeList", function getAwemeLikeList(params) {
  return request({
    url: "/mkms/interact/awemeLikeList",
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "signRead", function signRead(data) {
  return request({
    url: "/customer-chat/read",
    method: 'post',
    data: data
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getMqttConfigUser", function getMqttConfigUser(params) {
  return request({
    url: "/customer-chat/mqttClientSetting",
    method: 'GET',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getChatList", function getChatList(params) {
  return request({
    url: '/mkms/chat/msg/list',
    method: 'GET',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "replyMessage", function replyMessage(data) {
  return request({
    url: '/customer-chat/reply/v2',
    method: 'POST',
    data: data
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getFansId", function getFansId(params) {
  return request({
    url: '/douyin-live-polling/getUserLabelInfoByOpenId',
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getReplyStatus", function getReplyStatus(params) {
  return request({
    url: '/labor/reply/get/transfer/status',
    method: 'get',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "changeReplyStatus", function changeReplyStatus(params) {
  return request({
    url: "/labor/reply/toggle/transfer/status",
    method: 'GET',
    params: params
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "getManualTimeSetting", function getManualTimeSetting() {
  return request({
    url: "/labor/reply/web/time/list",
    method: 'GET'
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "addReplyTimeConfig", function addReplyTimeConfig(data) {
  return request({
    url: "/labor/reply/web/time/add",
    method: 'POST',
    data: data
  });
}), (0, _defineProperty2.default)(_getQuickReplyList$ge, "editReplyTimeConfig", function editReplyTimeConfig(data) {
  return request({
    url: "/labor/reply/web/time/edit",
    method: 'POST',
    data: data
  });
}), _getQuickReplyList$ge);
exports.default = _default;