var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tips_box" }, [
        _c(
          "div",
          { staticClass: "title_header" },
          [
            _vm._v(" 关键词回复规则 "),
            _c("a-switch", {
              attrs: { loading: _vm.isOpening },
              on: { change: _vm.updateKeyWordReplyStatus },
              model: {
                value: _vm.isOpened,
                callback: function ($$v) {
                  _vm.isOpened = $$v
                },
                expression: "isOpened",
              },
            }),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "当用户私信中包含回复规则的关键词时，自动发送设置好的回复内容"
          ),
        ]),
        _c("p", [_vm._v("1、每个用户每个关键词只触发一次回复")]),
        _c("p", [_vm._v("2、命中多个关键词规则，只触发优先级最高的规则")]),
        _c("p", [
          _vm._v(
            " 3、自定义规则内的关键词不允许重复，但可以与通用规则等的关键词重复，当存在相同关键词时，优先触发自定义规则的话术 "
          ),
        ]),
        _c("p", [
          _vm._v(
            "4、同时命中私信关键词回复规则和私信智回策略，则优先触发关键词的话术"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "type-box" },
        [
          _c(
            "a-button",
            {
              staticClass: "mr-1",
              attrs: {
                type: _vm.keyWordType === 3 ? "primary" : "default",
                ghost: _vm.keyWordType === 3,
                disabled: !_vm.isOpened,
              },
              on: {
                click: function ($event) {
                  _vm.keyWordType = 3
                },
              },
            },
            [_vm._v(" 自定义规则 ")]
          ),
          _vm.listData[2]
            ? _c(
                "a-button",
                {
                  staticClass: "mr-1",
                  attrs: {
                    type: _vm.keyWordType === 2 ? "primary" : "default",
                    ghost: _vm.keyWordType === 2,
                    disabled: !_vm.isOpened,
                  },
                  on: {
                    click: function ($event) {
                      _vm.keyWordType = 2
                    },
                  },
                },
                [_vm._v(" 品牌规则 ")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              attrs: {
                type: _vm.keyWordType === 1 ? "primary" : "default",
                ghost: _vm.keyWordType === 1,
                disabled: !_vm.isOpened,
              },
              on: {
                click: function ($event) {
                  _vm.keyWordType = 1
                },
              },
            },
            [_vm._v(" 通用规则 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "operation-box" }, [
        _vm.keyWordType === 1
          ? _c(
              "span",
              [
                _c("a-icon", { attrs: { type: "exclamation-circle" } }),
                _vm._v(" 系统精选的通用关键词及回复，不定时更新 "),
              ],
              1
            )
          : _vm._e(),
        _vm.keyWordType === 3
          ? _c(
              "span",
              [
                _c("a-icon", { attrs: { type: "exclamation-circle" } }),
                _vm._v(" 个人自定义的关键词及回复，最多100个规则 "),
              ],
              1
            )
          : _vm._e(),
        _vm.keyWordType === 2
          ? _c(
              "span",
              [
                _c("a-icon", { attrs: { type: "exclamation-circle" } }),
                _vm._v(" 系统精选的品牌专属关键词及回复，不定时更新 "),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.keyWordType === 3
              ? _c(
                  "a-button",
                  {
                    staticClass: "mr-1",
                    attrs: { type: "primary", disabled: !_vm.isOpened },
                    on: {
                      click: function ($event) {
                        return _vm.openReplyRuleEditionDrawer()
                      },
                    },
                  },
                  [_vm._v(" 新增回复规则 ")]
                )
              : _vm._e(),
            _c(
              "a-button",
              {
                attrs: { type: "primary", disabled: !_vm.isOpened },
                on: { click: _vm.openRuleSortDrawer },
              },
              [_vm._v(" 调整优先级 ")]
            ),
          ],
          1
        ),
      ]),
      _c("a-table", {
        attrs: {
          columns: _vm.configPayload.columns,
          "data-source": _vm.list,
          rowKey: function (record, index) {
            return record.id
          },
          pagination: false,
          loading: _vm.listLoading,
          scroll: { x: 1200 },
          bordered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "priority",
            fn: function (text, record, index) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.list.length - index) + " "),
              ])
            },
          },
          {
            key: "replyMessage",
            fn: function (text) {
              return _c(
                "div",
                {},
                [_c("text-ellipsis", { attrs: { value: text, limit: 2 } })],
                1
              )
            },
          },
          {
            key: "isOpened",
            fn: function (text, record, index) {
              return _c(
                "div",
                {},
                [
                  _c("a-switch", {
                    attrs: { checked: !!text, disabled: !_vm.isOpened },
                    on: {
                      change: function (value) {
                        return _vm.onChangeSwitch(value, index)
                      },
                    },
                  }),
                ],
                1
              )
            },
          },
          {
            key: "action",
            fn: function (text) {
              return _c(
                "div",
                {},
                [
                  _vm.keyWordType === 3
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "padding-left": "0" },
                          attrs: { type: "link", disabled: !_vm.isOpened },
                          on: {
                            click: function ($event) {
                              return _vm.openReplyRuleEditionDrawer(text)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      )
                    : _vm._e(),
                  _vm.keyWordType === 3
                    ? _c(
                        "a-popconfirm",
                        {
                          attrs: { title: "确认删除该回复吗？" },
                          on: {
                            confirm: function ($event) {
                              return _vm.deleteReplyRule(text.id)
                            },
                          },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "padding-left": "0" },
                              attrs: { type: "link", disabled: !_vm.isOpened },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.keyWordType !== 3
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "padding-left": "0" },
                          attrs: { type: "link", disabled: !_vm.isOpened },
                          on: {
                            click: function ($event) {
                              return _vm.openReplyRuleEditionDrawer(text, false)
                            },
                          },
                        },
                        [_vm._v(" 查看 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("reply-rule-edition-drawer", {
        ref: "ReplyRuleEditionDrawer",
        on: { resetTable: _vm.resetTable },
      }),
      _c("rule-sort-drawer", {
        ref: "RuleSortDrawer",
        on: { success: _vm.resetTable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }