var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.item.show_time && _vm.item.transformMessage,
            expression: "item.show_time && item.transformMessage",
          },
        ],
        staticClass: "send_time",
      },
      [
        _vm._v(" " + _vm._s(_vm.getNewTime(_vm.item.publishTime)) + " "),
        _vm.item.transformMessage
          ? _c("span", [
              _vm._v(_vm._s(_vm.transformTypeMap[_vm.item.transformMessage])),
            ])
          : _vm._e(),
      ]
    ),
    !_vm.item.transformMessage
      ? _c(
          "div",
          {
            staticClass: "talk-item",
            style: {
              "flex-direction": _vm.position == "left" ? "row" : "row-reverse",
            },
            attrs: { id: "talk-item" },
          },
          [
            _c(
              "div",
              {
                staticClass: "message_box",
                style: {
                  "flex-direction":
                    _vm.position == "left" ? "row" : "row-reverse",
                },
              },
              [
                _c("div", { staticClass: "img_box" }, [
                  _vm.item.replyType == 2 && _vm.item.is_author_send == 1
                    ? _c("img", { attrs: { src: _vm.robotLogo } })
                    : _c("img", {
                        attrs: {
                          src:
                            _vm.position == "left"
                              ? _vm.author.avatar || _vm.avatar
                              : _vm.item.author_avatar || _vm.avatar,
                          alt: "",
                        },
                      }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "user_box",
                style: {
                  "align-items":
                    _vm.position == "left" ? "flex-start" : "flex-end",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c("span", { staticClass: "publish-time" }, [
                      _vm._v(_vm._s(_vm.item.publishTime)),
                    ]),
                    _vm.item.is_author_send == 1
                      ? [
                          _vm.item.replyType != 2
                            ? _c("span", { staticClass: "other-user" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.item.is_author_send == 1
                                        ? _vm.author.author_nickname
                                        : _vm.author.nickname ||
                                            _vm.getSimpleName(
                                              _vm.author.open_id
                                            )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class:
                                _vm.item.replyType == 2
                                  ? "other-user other-color"
                                  : "other-user",
                            },
                            [
                              _vm.item.replyType != 2 &&
                              _vm.author.author_nickname
                                ? _c("span", [
                                    _vm.item.replyType
                                      ? _c("span", [_vm._v("-")])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                _vm._s(
                                  _vm.disposeUse(
                                    _vm.item.replyType,
                                    _vm.item.user_name
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "message_content",
                    style: {
                      background:
                        _vm.position == "left" ? "#eaeaea" : "#b4d4f4",
                    },
                  },
                  [
                    _c("div", {
                      class:
                        _vm.position == "left"
                          ? "border-triangle border-left"
                          : "border-triangle border-right",
                    }),
                    !_vm.item.isDouYinSend &&
                    _vm.item.is_author_send &&
                    _vm.item.message_type === 2
                      ? _c("el-image", {
                          staticClass: "img_wrap",
                          attrs: { fit: "contain", src: _vm.item.content },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(_vm.item.content)
                            },
                          },
                        })
                      : _c("pre", { staticClass: "text_wrap" }, [
                          _vm._v(_vm._s(_vm.item.content)),
                        ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }