var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-row",
                { staticClass: "el-row-bottom" },
                [
                  _c(
                    "el-card",
                    [
                      _c("div", { staticClass: "el-title el-row-bottom" }, [
                        _vm._v("门店组织评价"),
                      ]),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("AreaChat", {
                                attrs: {
                                  dealerArea: _vm.dashboardState.dealerArea,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  textAlign: "center",
                                  marginBottom: "20px",
                                  lineHeight: "2",
                                },
                              },
                              [
                                _c("div", [_vm._v("全国门店蓝V认证")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dashboardState.dealerArea
                                          .dealer_count
                                      ) + "家"
                                    ),
                                  ]
                                ),
                                _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dashboardState.dealerArea
                                        .evaluate_desc
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c("div", [_vm._v("已认证省份分布 TOP10")]),
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.provinceTop10,
                                      stripe: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        type: "index",
                                        width: "50",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "name",
                                        label: "省份",
                                        width: "80",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: { prop: "count", label: "抖音" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "el-row-bottom", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-card",
                        [
                          _c("div", { staticClass: "el-title" }, [
                            _vm._v("门店运营 TOP 10"),
                          ]),
                          _vm.dashboardState.dealerTop
                            ? _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.dashboardState.dealerTop.data,
                                    stripe: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      width: "50",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "dealer_name",
                                      label: "门店名",
                                      width: "180",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "fans_count",
                                      label: "粉丝",
                                      width: "180",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "aweme_count",
                                      label: "作品",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "digg_per_aweme",
                                      label: "平均点赞",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "comment_per_aweme",
                                      label: "平均评论",
                                      align: "center",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _vm._v("作品更新:"),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-button-group",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type:
                                              _vm.dashboardState.dealerAweme
                                                .interval === "week"
                                                ? "primary"
                                                : "",
                                            "data-type": "week",
                                          },
                                          on: { click: _vm.handleDealerAweme },
                                        },
                                        [_vm._v("周更")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type:
                                              _vm.dashboardState.dealerAweme
                                                .interval === "day"
                                                ? "primary"
                                                : "",
                                            "data-type": "day",
                                          },
                                          on: { click: _vm.handleDealerAweme },
                                        },
                                        [_vm._v("日更")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticStyle: { margin: "20px 0" } },
                            [
                              _vm.dashboardState.dealerAweme.ratio
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12, offset: 6 } },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          type: "circle",
                                          percentage:
                                            _vm.dashboardState.dealerAweme
                                              .ratio * 100,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", [_vm._v("门店")]),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.dashboardState.dealerAweme.data,
                                stripe: "",
                                "show-header": false,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "dealer_name", width: "180" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "aweme_avg" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }