"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initChatOpenMouthOptions = void 0;
require("core-js/modules/es6.function.name");
var chatOpenMouthTypeMap = {
  chatOpenMouthCount: '私信会话开口人数',
  chatOpenMouthRate: '私信会话开口率',
  enterConversationCount: '进入私信会话人数'
};
var initChatOpenMouthOptions = function initChatOpenMouthOptions(list, chatOpenMouthMaxMap) {
  var option = {
    color: ['#FFCC55', '#6E9FF4', '#F75C5C'],
    grid: {
      x: 50,
      y: 60,
      x2: 50,
      y2: 35
    },
    tooltip: {
      trigger: 'axis',
      formatter: function formatter(params) {
        var showHtm = '';
        // x轴名称
        var xname = params[0].name;
        showHtm += xname + '<br>';
        for (var i = 0; i < params.length; i++) {
          // 标点
          var icon = "<span style=\"display:inline-block;margin-right:5px;width:10px;height:10px;border-radius:10px;background:".concat(params[i].color, "\"></span>");
          // y轴名称
          var yname = chatOpenMouthTypeMap[params[i].seriesName];
          // 数据
          var data = params[i].data[params[i].seriesName] + "".concat(yname === '私信会话开口率' ? '%' : '');
          showHtm += icon + yname + '：' + data + '<br>';
        }
        return showHtm;
      }
    },
    legend: {
      data: ['enterConversationCount', 'chatOpenMouthCount', 'chatOpenMouthRate'],
      formatter: function formatter(seriesName) {
        return chatOpenMouthTypeMap[seriesName];
      }
    },
    dataset: {
      dimensions: ['day', 'enterConversationCount', 'chatOpenMouthCount', 'chatOpenMouthRate'],
      source: list
    },
    xAxis: {
      type: 'category'
    },
    yAxis: [{
      max: chatOpenMouthMaxMap.leftMax,
      splitNumber: 5,
      interval: chatOpenMouthMaxMap.leftMax / 5
    }, {
      max: chatOpenMouthMaxMap.rightMax,
      splitNumber: 5,
      interval: chatOpenMouthMaxMap.rightMax / 5,
      axisLabel: {
        formatter: function formatter(value) {
          return value + '%';
        }
      }
    }],
    series: [{
      type: 'line',
      smooth: true,
      symbol: 'circle'
    }, {
      type: 'line',
      smooth: true,
      symbol: 'circle'
    }, {
      type: 'line',
      symbol: 'circle',
      smooth: true,
      yAxisIndex: 1
    }]
  };
  return option;
};
exports.initChatOpenMouthOptions = initChatOpenMouthOptions;