var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "monitoring-video-item" }, [
    _c("div", { staticClass: "monitoring-video-item-top" }, [
      _c("div", { staticClass: "head-box" }, [
        _c("img", {
          attrs: { src: _vm.itemData.awemeCover || _vm.defultCover, alt: "" },
        }),
        _c("div", { staticClass: "c-b" }, [
          _c(
            "h4",
            { staticClass: "f16", staticStyle: { margin: "4px 0 0 0" } },
            [_vm._v(_vm._s(_vm.itemData.awemeTitle))]
          ),
          _c("div", { staticStyle: { margin: "8px 0 4px" } }, [
            _c("span", { staticClass: "c-g f14" }, [_vm._v("监控类型:")]),
            _c("span", { staticClass: "c-b f14" }, [
              _vm._v(" " + _vm._s(_vm.itemData.monitType)),
            ]),
          ]),
          _c("div", [
            _c("span", { staticClass: "c-g f14" }, [_vm._v("监控时长:")]),
            _c("span", { staticClass: "c-b f14" }, [
              _vm._v(" " + _vm._s(_vm.itemData.monitDuration)),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("div", [
          _c("img", {
            attrs: { src: _vm.itemData.avatar || _vm.defultImg, alt: "" },
          }),
          _c("span", { staticClass: "f12" }, [
            _vm._v(_vm._s(_vm.itemData.nickname)),
          ]),
        ]),
        _c("span", { staticClass: "f12 c-g" }, [
          _vm._v("发布于 " + _vm._s(_vm.itemData.publishTime)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "monitoring-video-item-bottom" }, [
      _vm.itemData.monitStatus && _vm.itemData.monitStatu != 3
        ? _c("div", { staticClass: "monitoring-video-item-bottom-main" }, [
            _c(
              "div",
              { staticClass: "head" },
              [
                _c("span", { staticClass: "f12 c-g" }, [
                  _vm._v("人气峰值趋势"),
                ]),
                _vm.itemData.monitStatus == 1
                  ? _c(
                      "el-button",
                      {
                        staticStyle: {
                          background:
                            "linear-gradient(180deg, #27c77d 0%, #27b388 100%)",
                          color: "#fff",
                        },
                        attrs: { size: "mini" },
                      },
                      [_vm._v("监控中")]
                    )
                  : _vm._e(),
                _vm.itemData.monitStatus == 2
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { background: "#d8d8d8", color: "#fff" },
                        attrs: { size: "mini" },
                      },
                      [_vm._v("监控完成")]
                    )
                  : _vm._e(),
                _vm.itemData.monitStatus == 4
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { background: "#e74f3e", color: "#fff" },
                        attrs: { size: "mini" },
                      },
                      [_vm._v("作品已删除")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "chart", attrs: { id: _vm.idName } }),
            _c("div", { staticClass: "time" }, [
              _c("span", [
                _vm._v("监控时间: " + _vm._s(_vm.itemData.monitStartTime)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.itemData.monitStatus && _vm.itemData.monitStatu == 3
        ? _c("div", { staticClass: "no-live" }, [
            _vm._m(0),
            _c("div", [_vm._v("监控时间:")]),
            _c("div", [_vm._v(_vm._s(_vm.itemData.monitStartTime))]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "mask" },
      [
        _vm.itemData.monitStatus && _vm.itemData.monitStatu != 3
          ? _c(
              "el-button",
              {
                staticStyle: {
                  width: "30%",
                  background:
                    "linear-gradient(180deg, #64A9F3 0%, #516EF3 100%)",
                  border: "#52c41a",
                  color: "#fff",
                  "margin-top": "20px",
                },
                on: {
                  click: function ($event) {
                    return _vm.openDetail(_vm.itemData)
                  },
                },
              },
              [_vm._v("查看详情")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#df3a41" } }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _c("span", [_vm._v("监控失败，预约时间内没有发现视频")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }