var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_box" },
    [
      _c("a-card", { staticClass: "card_h" }, [
        _c("header", [
          _c("div", { staticClass: "flex_box" }, [
            _c("h3", [_vm._v("配置目标城市")]),
          ]),
          _c("p", [_vm._v("配置特别关注的城市为目标城市，作用如下：")]),
          _c("div", { staticClass: "pdb-12" }, [
            _vm._v(
              "【私信管理-私信会话】功能将识别用户是否目标城市，是则展示目标城市标签"
            ),
          ]),
        ]),
        _c("div", { staticClass: "city_box" }, [
          _c("p", [_vm._v("城市选择")]),
          _c(
            "p",
            [
              _c("span", {}, [_vm._v("请选择省（最多可选一个）：")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    "show-search": "",
                    "default-active-first-option": false,
                    "filter-option": _vm.filterOption,
                    placeholder: "请选择省份",
                    "allow-clear": "",
                  },
                  model: {
                    value: _vm.capital,
                    callback: function ($$v) {
                      _vm.capital = $$v
                    },
                    expression: "capital",
                  },
                },
                _vm._l(_vm.provinceList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.region_code,
                      attrs: { value: item.region_code },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("请选择市（最多可选三个）：")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    "show-search": "",
                    "default-active-first-option": false,
                    "filter-option": _vm.filterOption,
                    placeholder: "请选择城市",
                    "allow-clear": "",
                    mode: "multiple",
                  },
                  on: { change: _vm.countTag },
                  model: {
                    value: _vm.city_code,
                    callback: function ($$v) {
                      _vm.city_code = $$v
                    },
                    expression: "city_code",
                  },
                },
                _vm._l(_vm.buyCarList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.city_code, attrs: { value: item.city_code } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("p", [_vm._v("最多支持选择1个省份 加 3个城市，可只选择省/市")]),
        ]),
        _c(
          "footer",
          { staticStyle: { "margin-top": "48px" } },
          [
            _c(
              "a-button",
              {
                attrs: { type: "primary", loading: _vm.confirm_loading },
                on: { click: _vm.updata },
              },
              [_vm._v("提交 ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }