var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        "background-color": "#000710",
      },
    },
    [
      _vm.showLoading ? _c("Loading") : _vm._e(),
      _c("div", { attrs: { id: "box" } }, [
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "logo" }),
            _c("div", { staticClass: "live_name" }, [
              _c(
                "div",
                { staticClass: "live_message" },
                [
                  _c("el-avatar", {
                    attrs: { src: _vm.liveRecord.avatar, size: 44 },
                  }),
                  _c("div", { staticClass: "comment_content" }, [
                    _c("p", [_vm._v(_vm._s(_vm.liveRecord.nickname))]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.liveRecord.title)),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "watch_link" }),
            _c("div", { staticClass: "tips_left" }, [
              _c("div", [_vm._v("直播开始时间")]),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.liveRecord.publish_time
                      ? _vm.liveRecord.publish_time.slice(0, 16)
                      : ""
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "tips_right" }, [
              _vm._m(0),
              _c("div", [
                _vm._v("直播时长：" + _vm._s(_vm.liveRecord.liveLong)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "box_left" },
              _vm._l(_vm.changeCount, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "chart" },
                  [
                    _c("div", { staticClass: "chart-title" }, [
                      _vm._m(1, true),
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _vm._m(2, true),
                    _c("LiveLine", {
                      attrs: {
                        id: item.id,
                        "box-style": { width: "100", height: "80" },
                        "chart-data": item.chartDate,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "center_box" }, [
              _c("div", { staticClass: "fans" }, [
                _c(
                  "ul",
                  { staticClass: "data_box" },
                  _vm._l(_vm.fansList, function (item, index) {
                    return _c("li", { key: index }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("span", [_vm._v(_vm._s(item.value))]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "live" }, [
                _c("div", { staticClass: "live-box" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "live-box-content" }, [
                    _vm.hotCarType.length > 0
                      ? _c(
                          "ul",
                          { staticClass: "hot_car" },
                          _vm._l(_vm.hotCarType, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "content" }, [
                                _c("div", { staticClass: "img_box" }, [
                                  _c("img", {
                                    attrs: { src: item.image, alt: "" },
                                  }),
                                ]),
                                _c("p", [_vm._v(_vm._s(item.series))]),
                              ]),
                              _c("div", { staticClass: "progress" }, [
                                _c("div", { staticClass: "progress_box" }, [
                                  _c("div", {
                                    staticClass: "process_rate",
                                    style: {
                                      "background-image":
                                        index > 0
                                          ? index > 2
                                            ? "linear-gradient(270deg, #005B8D 0%, rgba(0,89,136,0.00) 100%)"
                                            : "linear-gradient(270deg, #007E8F 0%, rgba(0,126,139,0.00) 100%)"
                                          : "linear-gradient(270deg, #66131F 0%, rgba(227,73,84,0.00) 100%)",
                                      width: item.rate + "%",
                                    },
                                  }),
                                  index == 0
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../assets/live_images/rateA.png"),
                                        },
                                      })
                                    : _vm._e(),
                                  index >= 1 && index <= 2
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../assets/live_images/rateB.png"),
                                        },
                                      })
                                    : _vm._e(),
                                  index > 2
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../assets/live_images/rateC.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "outside_tips" }, [
                                  _vm._v(_vm._s(item.count)),
                                ]),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-top": "100px",
                              "text-align": "center",
                              color: "#ccc",
                            },
                          },
                          [_vm._v("暂无数据")]
                        ),
                  ]),
                ]),
                _c("div", { staticClass: "video_box" }, [
                  this.common.isDemo()
                    ? _c("video", {
                        staticStyle: {
                          width: "calc(100% - 2px)",
                          height: "calc(100% - 12px)",
                          "border-radius": "10px",
                        },
                        attrs: { id: "videoElement", autoplay: "", muted: "" },
                        domProps: { muted: true },
                      })
                    : _c(
                        "video",
                        {
                          ref: "liveVideo",
                          staticClass: "video-js vjs-default-skin",
                          staticStyle: {
                            width: "calc(100% - 2px)",
                            height: "calc(100% - 12px)",
                            "border-radius": "10px",
                          },
                          attrs: {
                            id: "live-video",
                            autoplay: "",
                            muted: "",
                            controls: "",
                            preload: "auto",
                          },
                          domProps: { muted: true },
                        },
                        [
                          _c("source", {
                            attrs: {
                              src: _vm.videoUrl,
                              type: "application/x-mpegURL",
                            },
                          }),
                        ]
                      ),
                ]),
                _c("div", { staticClass: "live-box" }, [
                  _c("div", { staticClass: "live-box-right" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "live-box-content" }, [
                      _c(
                        "ul",
                        { staticClass: "comment_lists" },
                        [
                          _c(
                            "transition-group",
                            { attrs: { name: "list-complete", tag: "p" } },
                            _vm._l(_vm.commentList, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item.id,
                                  staticClass: "list-complete-item",
                                },
                                [
                                  _c("el-avatar", {
                                    attrs: { src: item.avatar },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "comment_content" },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(item.name)),
                                        item.isBlack
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  color: "#cb6162",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [_vm._v("黑粉")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "comment" }, [
                                        _vm._v(_vm._s(item.content)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "live-box-right" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "live-box-content" }, [
                      _c(
                        "ul",
                        { staticClass: "comment_lists" },
                        [
                          _c(
                            "transition-group",
                            { attrs: { name: "list-complete", tag: "p" } },
                            _vm._l(_vm.forbiddenData, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass: "list-complete-item",
                                },
                                [
                                  _c("el-avatar", {
                                    attrs: {
                                      src:
                                        item.avatar ||
                                        require("@/assets/dev/defult-avatar.png"),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "comment_content" },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(item.nickname)),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "20px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.ctime.slice(11, 16))
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "comment" }, [
                                        _vm._v(_vm._s(item.content)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box_right" }, [
              _c(
                "div",
                { staticClass: "chart" },
                [
                  _vm._m(6),
                  !_vm.fansStat
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#999",
                            "text-align": "center",
                            "padding-top": "40px",
                          },
                        },
                        [_vm._v("暂无数据")]
                      )
                    : _c("PieChart", {
                        attrs: {
                          id: "fansGenderChart",
                          "box-style": { width: "100", height: "90" },
                          "pie-data": _vm.fansStat,
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart" },
                [
                  _vm._m(7),
                  _c("MapChart", {
                    attrs: {
                      id: "MapChart",
                      "box-style": { width: "100", height: "90" },
                      "chart-data": _vm.audienceAdress,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "chart" }, [
                _vm._m(8),
                _c(
                  "table",
                  { staticClass: "city_table_rate" },
                  [
                    _vm._m(9),
                    _vm._l(_vm.adressList, function (item, index) {
                      return _c(
                        "tr",
                        { key: index, staticClass: "tr_overflow" },
                        [
                          _c("td", [_vm._v(_vm._s(item.province))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                item.count ? (item.count * 100).toFixed(2) : ""
                              ) + "%"
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "chart" },
                [
                  _vm._m(10),
                  _c("WordCould", {
                    attrs: {
                      id: "WordCouldNOW",
                      "box-style": { width: "100", height: "90" },
                      "chart-data": _vm.hotWord,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "foot" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", {
        staticClass: "el-icon-s-data",
        staticStyle: { color: "#CB6162" },
      }),
      _vm._v("直播中 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img_box" }, [
      _c("img", {
        attrs: { src: require("../../assets/live_images/arrows.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _c("span", { staticStyle: { color: "#61C2C6", "font-size": "10px" } }, [
        _vm._v("增量"),
      ]),
      _c("span", { staticStyle: { color: "#2A90F4", "font-size": "10px" } }, [
        _vm._v("总量"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "topCar" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("观众关注车型 TOP5 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "comment_header" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("评论 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "topCar" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("禁言列表 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-title" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("性别分布"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-title" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("地域分布"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-title" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("城市分布"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_vm._v("城市")]), _c("th", [_vm._v("占比")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-title" }, [
      _c("div", { staticClass: "img_box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/live_images/arrows.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v("评论词云"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }