var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-box" }, [
    _c("div", { staticClass: "send_time" }, [
      _vm._v(" " + _vm._s(_vm.item.publishTime) + " "),
    ]),
    _c(
      "div",
      {
        staticClass: "talk-item",
        style: {
          "flex-direction": _vm.position == "left" ? "row" : "row-reverse",
        },
        attrs: { id: "talk-item" },
      },
      [
        _c("div", [
          _c(
            "div",
            {
              class:
                _vm.item.replyType == 2 && _vm.item.is_author_send == 1
                  ? "userName color2"
                  : "userName",
              style: {
                "text-align": _vm.position == "left" ? "left" : "right",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.item.replyType == 2 && _vm.item.is_author_send == 1
                      ? "皮皮拉客"
                      : _vm.item.userName
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "user_box",
              style: {
                "flex-direction":
                  _vm.position == "left" ? "row" : "row-reverse",
              },
            },
            [
              _c("div", { staticClass: "img_box" }, [
                _vm.item.replyType == 2 && _vm.item.is_author_send == 1
                  ? _c("img", { attrs: { src: _vm.robotLogo } })
                  : _c("img", {
                      attrs: {
                        src: _vm.item.author_avatar || _vm.avatar,
                        alt: "",
                      },
                    }),
              ]),
              _c(
                "div",
                {
                  staticClass: "message_content",
                  style: {
                    background: _vm.position == "left" ? "#eaeaea" : "#b4d4f4",
                  },
                },
                [
                  _c("div", {
                    class:
                      _vm.position == "left"
                        ? "border-triangle border-left"
                        : "border-triangle border-right",
                  }),
                  !_vm.item.isDouYinSend &&
                  _vm.item.is_author_send &&
                  _vm.item.messageType === 2
                    ? _c("el-image", {
                        staticClass: "img_wrap",
                        attrs: { fit: "contain", src: _vm.item.content },
                        on: {
                          click: function ($event) {
                            return _vm.handlePreview(_vm.item.content)
                          },
                        },
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.item.content))]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }