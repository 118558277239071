"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recallWayMap = exports.principalSecondRecallConfigPayload = exports.principalOnlineRecallConfigPayload = exports.principalFirstRecallConfigPayload = exports.listTypeList = exports.classifyTabList = exports.authorSecondRecallConfigPayload = exports.authorOnlineRecallConfigPayload = exports.authorFirstRecallConfigPayload = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _pplk_recall_manage = _interopRequireDefault(require("@/api/pplk_recall_manage"));
var _moment = _interopRequireDefault(require("moment"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var classifyTabList = [{
  label: '一次召回',
  value: '一次召回'
}, {
  label: '二次召回',
  value: '二次召回'
}, {
  label: '在线召回',
  value: '在线召回'
}];
exports.classifyTabList = classifyTabList;
var listTypeList = [{
  label: '个人自定义',
  value: 2
}, {
  label: '品牌推荐',
  value: 1
}];
exports.listTypeList = listTypeList;
var recallWayMap = {
  1: '按时间间隔',
  2: '固定时间',
  3: '在线召回'
};
exports.recallWayMap = recallWayMap;
var recallColumns = [{
  title: '策略名称',
  width: 150,
  dataIndex: 'strategy_name'
}, {
  title: '说明',
  width: 340,
  customRender: function customRender(_, record) {
    if (record.recall_extend && record.recall_extend.recallWay) {
      if (record.recall_extend.recallWay === 1 && record.recall_extend.triggerTime) {
        var duration = _moment.default.duration(record.recall_extend.triggerTime * 1, 'seconds');
        var hours = Math.floor(duration.asHours());
        var minutes = Math.floor(duration.minutes());
        return "\u672A\u7559\u8D44\u7528\u6237".concat(hours > 0 ? hours + '小时' : '').concat(minutes > 0 ? minutes + '分钟' : '', "\u672A\u56DE\u590D\u4E3B\u64AD\u6D88\u606F\u65F6\u89E6\u53D1\u56DE\u590D");
      } else if (record.recall_extend.recallWay === 2 && record.recall_extend.triggerTime) {
        return "\u7CFB\u7EDF\u76D1\u6D4B\u5230\u672A\u7559\u8D44\u7528\u6237\u672A\u56DE\u590D\u4E3B\u64AD\u6D88\u606F\uFF0C\u5219\u5728\u56FA\u5B9A".concat(record.recall_extend.triggerTime, "\u89E6\u53D1\u56DE\u590D");
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}, {
  title: '回复内容',
  dataIndex: 'reply_msg_vo_list',
  scopedSlots: {
    customRender: 'reply_msg'
  }
}, {
  title: '近7天回复私信数',
  dataIndex: 'chat_reply_num',
  width: 160,
  customRender: function customRender(text) {
    return text || '-';
  }
}, {
  title: '状态',
  dataIndex: 'status',
  width: 100,
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  width: 100,
  scopedSlots: {
    customRender: 'action'
  }
}];
var authorFirstRecallConfigPayload = {
  columns: recallColumns,
  http_api: _pplk_recall_manage.default.getFirstRecallList
};
exports.authorFirstRecallConfigPayload = authorFirstRecallConfigPayload;
var principalFirstRecallConfigPayload = {
  columns: recallColumns.filter(function (item) {
    return item.title !== '状态';
  }),
  http_api: _pplk_recall_manage.default.getFirstRecallList
};
exports.principalFirstRecallConfigPayload = principalFirstRecallConfigPayload;
var authorSecondRecallConfigPayload = {
  columns: recallColumns,
  http_api: _pplk_recall_manage.default.getSecondRecallList
};
exports.authorSecondRecallConfigPayload = authorSecondRecallConfigPayload;
var principalSecondRecallConfigPayload = {
  columns: recallColumns.filter(function (item) {
    return item.title !== '状态';
  }),
  http_api: _pplk_recall_manage.default.getSecondRecallList
};
exports.principalSecondRecallConfigPayload = principalSecondRecallConfigPayload;
var onlineRecallColumns = recallColumns.map(function (item) {
  return item.title === '说明' ? _objectSpread(_objectSpread({}, item), {}, {
    customRender: function customRender() {
      return '系统监测到未留资用户未回复主播消息，当用户在线时触发回复';
    }
  }) : _objectSpread({}, item);
});
var authorOnlineRecallConfigPayload = {
  columns: onlineRecallColumns,
  http_api: _pplk_recall_manage.default.getOnlineRecallList
};
exports.authorOnlineRecallConfigPayload = authorOnlineRecallConfigPayload;
var principalOnlineRecallConfigPayload = {
  columns: onlineRecallColumns.filter(function (item) {
    return item.title !== '状态';
  }),
  http_api: _pplk_recall_manage.default.getOnlineRecallList
};
exports.principalOnlineRecallConfigPayload = principalOnlineRecallConfigPayload;