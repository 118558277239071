"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['imgList'],
  data: function data() {
    return {};
  },
  methods: {
    getImg: function getImg(name) {
      return require("@/assets/function_des/".concat(name, ".png"));
    }
  },
  created: function created() {}
};
exports.default = _default;