"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _return_visit = _interopRequireDefault(require("@/api/return_visit"));
var _getVisitList = _return_visit.default.getVisitList,
  _getAuthorInfo = _return_visit.default.getAuthorInfo;
var _default = {
  namespaced: true,
  state: {
    visitList: [],
    authorInfoData: null
  },
  actions: {
    getVisitList: function getVisitList(_ref, payload) {
      var commit = _ref.commit;
      var visit_begin_time = payload.visit_begin_time,
        visit_end_time = payload.visit_end_time,
        author_id = payload.author_id;
      commit('changeState', {
        name: 'visitList',
        data: []
      });
      return _getVisitList({
        visit_begin_time: visit_begin_time,
        visit_end_time: visit_end_time,
        author_id: author_id
      }).then(function (res) {
        console.log(1, res);
        commit('changeState', {
          name: 'visitList',
          data: res.data.data || []
        });
      });
    },
    // 直播间粉丝跟主播的互动信息
    getAuthorInfo: function getAuthorInfo(_ref2, payload) {
      var commit = _ref2.commit;
      var user_id = payload.user_id;
      return _getAuthorInfo({
        user_id: user_id
      }).then(function (res) {
        commit('changeState', {
          name: 'authorInfoData',
          data: res.data
        });
      });
    }
  },
  mutations: {
    changeState: function changeState(state, payload) {
      var data = payload.data,
        name = payload.name;
      state[name] = data;
    }
  }
};
exports.default = _default;