"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var openHost = process.env.VUE_APP_OPENDATA_API;
var url = "".concat(openHost, "/mirror-data");
var openNr = new _NetworldRequest.default({
  baseURL: url
}).getAxios();

/*
 *直播信息
 */
var liveMessage = 'douyin/live/info';

/*
 *  直播间直播时评论分析，车型跟品牌
 */
var commentAnalysis = '/douyin/live-polling/comment/analysis-new';

/* 
 *评论中热度前5的车型
 */
var commentHotCar = '/douyin/live-polling/live/series/hot';

/* 
 *直播观众性别分析
 */
var audience = '/douyin/live-polling/live/audience/analysis/gender';

/* 
*评论中热词
*/
var hotWord = '/douyin/live-polling/live/comment/hotwords';

/* 
*直播观众地区分布
*/
var audienceAdress = '/douyin/live-polling/live/audience/analysis/area';
var liveCalender = '/douyin/live/show/calendar';
var _default = {
  getLiveMessage: function getLiveMessage(params) {
    var roomId = params.roomId;
    return openNr.get("".concat(liveMessage, "/").concat(roomId));
  },
  getCommentAnalysis: function getCommentAnalysis(param) {
    return openNr.get(commentAnalysis, {
      params: {
        room_id: param
      }
    });
  },
  getHotCarType: function getHotCarType(param) {
    return openNr.get(commentHotCar, {
      params: {
        room_id: param
      }
    });
  },
  // 直播间评论列表
  getCommentList: function getCommentList(param) {
    var room_id = param.room_id,
      current = param.current,
      page_size = param.page_size,
      word = param.word;
    return openNr.get("/douyin/live-polling/".concat(room_id, "/comment"), {
      params: {
        current: current,
        page_size: page_size,
        word: word
      }
    });
  },
  getAudience: function getAudience(param) {
    return openNr.get(audience, {
      params: {
        room_id: param
      }
    });
  },
  // 直播分析线条图
  getAnalysisChart: function getAnalysisChart(param) {
    return openNr.get("/douyin/live/statistical/chart/".concat(param));
  },
  getHotWord: function getHotWord(param) {
    return openNr.get(hotWord, {
      params: {
        room_id: param
      }
    });
  },
  getAudienceAdress: function getAudienceAdress(param) {
    return openNr.get(audienceAdress, {
      params: {
        room_id: param
      }
    });
  },
  getLiveTime: function getLiveTime(param) {
    var begin_time = param.begin_time,
      end_time = param.end_time,
      author_id = param.author_id;
    return openNr.get("".concat(liveCalender, "/").concat(author_id), {
      params: {
        begin_time: begin_time,
        end_time: end_time
      }
    });
  },
  getCarCommentList: function getCarCommentList(param) {
    var room_id = param.room_id,
      current = param.current,
      page_size = param.page_size,
      word = param.word;
    return openNr.get("/douyin/live-polling/".concat(room_id, "/series/comment"), {
      params: {
        current: current,
        page_size: page_size,
        series_name: word
      }
    });
  }
};
exports.default = _default;