"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _attention = _interopRequireDefault(require("@/api/attention"));
var _dyQrcodeBg = _interopRequireDefault(require("@/assets/live_images/dy-qrcode-bg.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isVisiable: false,
      isLoading: false,
      dyQrcodeBg: _dyQrcodeBg.default,
      data: {
        userNickname: "",
        userDyCode: ""
      },
      dyQrcodeUrl: ""
    };
  },
  methods: {
    /**
     * 打开模态框
     * @param {object} data 传递参数
     */
    openModal: function openModal(data) {
      var _this = this;
      Object.assign(this.$data, this.$options.data());
      this.isVisiable = true;
      this.isLoading = true;
      this.data = data;
      _attention.default.getDyUserQrcode({
        userId: data.userId
      }).then(function (res) {
        _this.isLoading = false;
        if (res.code === 200) {
          _this.dyQrcodeUrl = res.data;
        }
      }).catch(function (err) {
        if (err && err.message === "取消请求") return;else _this.isLoading = false;
      });
    },
    /**
     * 关闭模态框
     */
    closeModal: function closeModal() {
      this.isVisiable = false;
    }
  },
  created: function created() {}
};
exports.default = _default;