var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "回复评论",
        visible: _vm.show_modal,
        "confirm-loading": _vm.confirmLoading,
        okText: "回复评论",
        maskClosable: false,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c("p", [
        _c("span", { staticClass: "font_b" }, [
          _vm._v(_vm._s(_vm.video_message.platform) + "账号："),
        ]),
        _vm._v(
          _vm._s(_vm.video_message.nickname) +
            "-" +
            _vm._s(_vm.video_message.authorCode) +
            " "
        ),
      ]),
      _c("div", { staticClass: "flex_d" }, [
        _c("div", { staticClass: "img_box" }, [
          _c("img", { attrs: { src: _vm.video_message.videoCover } }),
        ]),
        _c("div", [
          _c("p", { staticClass: "publish_time" }, [
            _vm._v(
              " 发布时间：" +
                _vm._s(_vm.disposeTime(_vm.video_message.videoPublishTime)) +
                " "
            ),
          ]),
          _c("div", [_vm._v(_vm._s(_vm.video_message.videoTitle))]),
        ]),
      ]),
      _c("p", { staticClass: "comment_content" }, [
        _c("span", { staticClass: "font_b" }, [_vm._v(" 评论内容：")]),
        _c("a", [_vm._v(_vm._s(_vm.video_message.content))]),
      ]),
      _c(
        "div",
        { staticClass: "content_box" },
        [
          _c("p", [
            _c("span", { staticClass: "font_b" }, [_vm._v("回复内容：")]),
          ]),
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "message_content" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入回复内容",
                      rows: 5,
                      maxLength: 100,
                    },
                    model: {
                      value: _vm.form.message_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "message_content", $$v)
                      },
                      expression: "form.message_content",
                    },
                  }),
                  _c("div", { staticClass: "message_content" }, [
                    _vm._v(
                      " " + _vm._s(_vm.form.message_content.length) + "/100 "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "font_color" }, [
        _c("div", [_vm._v("· 评论回复成功将展示在抖音评论区，所有人可见")]),
        _c("div", [
          _vm._v(
            " · 回复内容请勿色情/暴力/政治等敏感词汇、抖音平台外部链接等内容，可能会触发抖音评论的风控规则，导致回复评论不可见等问题 "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }