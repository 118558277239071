var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-carousel",
        {
          staticClass: "img-carousel",
          attrs: { arrows: "", dots: false, autoplay: "" },
        },
        [
          _c(
            "div",
            {
              staticClass: "custom-slick-arrow",
              staticStyle: { left: "-12px", "z-index": "1" },
              attrs: { slot: "prevArrow" },
              slot: "prevArrow",
            },
            [_c("a-icon", { attrs: { type: "left-circle" } })],
            1
          ),
          _c(
            "div",
            {
              staticClass: "custom-slick-arrow",
              staticStyle: { right: "-12px" },
              attrs: { slot: "nextArrow" },
              slot: "nextArrow",
            },
            [_c("a-icon", { attrs: { type: "right-circle" } })],
            1
          ),
          _vm._l(_vm.imgList, function (item, index) {
            return _c("img", {
              key: index,
              staticClass: "img",
              attrs: { src: _vm.getImg(item) },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }