"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.textEmojiObject = exports.replaceEmoji = exports.emojiTextObject = exports.emojiList = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.function.name");
var emojiList = [{
  name: "[微笑]",
  unicode: "\uD83D\uDE42",
  emoji: "🙂"
}, {
  name: "[色]",
  unicode: "\uD83D\uDE0D",
  emoji: "😍"
}, {
  name: "[流泪]",
  unicode: "\uD83D\uDE22",
  emoji: "😢"
}, {
  name: "[捂脸]",
  unicode: "\uD83E\uDD26",
  emoji: "🤦"
}, {
  name: "[呲牙]",
  unicode: "\uD83D\uDE01",
  emoji: "😁"
}, {
  name: "[害羞]",
  unicode: "\uD83D\uDE0A",
  emoji: "😊"
}, {
  name: "[调皮]",
  unicode: "\uD83D\uDE1B",
  emoji: "😛"
}, {
  name: "[晕]",
  unicode: "\uD83D\uDE35",
  emoji: "😵"
}, {
  name: "[衰]",
  unicode: "\uD83D\uDE22",
  emoji: "😢"
}, {
  name: "[闭嘴]",
  unicode: "\uD83E\uDD10",
  emoji: "🤐"
}, {
  name: "[赞]",
  unicode: "\uD83D\uDC4D",
  emoji: "👍"
}, {
  name: "[感谢]",
  unicode: "\uD83D\uDE4F",
  emoji: "🙏"
}, {
  name: "[打脸]",
  unicode: "\uD83E\uDD15",
  emoji: "🤕"
}, {
  name: "[大笑]",
  unicode: "\uD83D\uDE04",
  emoji: "😄"
}, {
  name: "[哈欠]",
  unicode: "\uD83E\uDD71",
  emoji: "🥱"
}, {
  name: "[震惊]",
  unicode: "\uD83E\uDD2F",
  emoji: "🤯"
}, {
  name: "[送心]",
  unicode: "\uD83E\uDD70",
  emoji: "🥰"
}, {
  name: "[大金牙]",
  unicode: "\uD83E\uDDB7",
  emoji: "🦷"
}, {
  name: "[石化]",
  unicode: "\uD83D\uDE30",
  emoji: "😰"
}, {
  name: "[可怜]",
  unicode: "\uD83E\uDD7A",
  emoji: "🥺"
}, {
  name: "[笑哭]",
  unicode: "\uD83D\uDE02",
  emoji: "😂"
}, {
  name: "[奸笑]",
  unicode: "\uD83D\uDE0F",
  emoji: "😏"
}, {
  name: "[得意]",
  unicode: "\uD83D\uDE0E",
  emoji: "😎"
}, {
  name: "[憨笑]",
  unicode: "\uD83D\uDE00",
  emoji: "😀"
}, {
  name: "[坏笑]",
  unicode: "\uD83D\uDE2C",
  emoji: "😬"
}, {
  name: "[抓狂]",
  unicode: "\uD83D\uDE2B",
  emoji: "😫"
}, {
  name: "[钱]",
  unicode: "\uD83E\uDD11",
  emoji: "🤑"
}, {
  name: "[恐惧]",
  unicode: "\uD83D\uDE31",
  emoji: "😱"
}, {
  name: "[愉快]",
  unicode: "\uD83D\uDE04",
  emoji: "😄"
}, {
  name: "[委屈]",
  unicode: "\uD83D\uDE41",
  emoji: "🙁"
}, {
  name: "[飞吻]",
  unicode: "\uD83D\uDE18",
  emoji: "😘"
}, {
  name: "[再见]",
  unicode: "\uD83D\uDC4B",
  emoji: "👋"
}, {
  name: "[听歌]",
  unicode: "\uD83C\uDFA7",
  emoji: "🎧"
}, {
  name: "[不看]",
  unicode: "\uD83D\uDE48",
  emoji: "🙈"
}, {
  name: "[看]",
  unicode: "\uD83D\uDC36",
  emoji: "🐶"
}, {
  name: "[红脸]",
  unicode: "\uD83D\uDE33",
  emoji: "😳"
}, {
  name: "[惊喜]",
  unicode: "\uD83E\uDD29",
  emoji: "🤩"
}, {
  name: "[敲打]",
  unicode: "\uD83E\uDD15",
  emoji: "🤕"
}, {
  name: "[大哭]",
  unicode: "\uD83D\uDE2D",
  emoji: "😭"
}, {
  name: "[惊讶]",
  unicode: "\uD83D\uDE32",
  emoji: "😲"
}, {
  name: "[阴险]",
  unicode: "\uD83D\uDE0F",
  emoji: "😏"
}, {
  name: "[比心]",
  unicode: "\uD83E\uDD1E",
  emoji: "🤞"
}, {
  name: "[碰拳]",
  unicode: "\uD83E\uDD1B",
  emoji: "🤛"
}, {
  name: "[左上]",
  unicode: "\uD83D\uDC46",
  emoji: "👆"
}, {
  name: "[握手]",
  unicode: "\uD83E\uDD1D",
  emoji: "🤝"
}, {
  name: "[抱拳]",
  unicode: "\uD83D\uDE4F",
  emoji: "🙏"
}, {
  name: "[左边]",
  unicode: "\uD83D\uDC48",
  emoji: "👈"
}, {
  name: "[右边]",
  unicode: "\uD83D\uDC49",
  emoji: "👉"
}, {
  name: "[玫瑰]",
  unicode: "\uD83C\uDF39",
  emoji: "🌹"
}, {
  name: "[心碎]",
  unicode: "\uD83D\uDC94",
  emoji: "💔"
}, {
  name: "[凋谢]",
  unicode: "\uD83E\uDD40",
  emoji: "🥀"
}, {
  name: "[蛋糕]",
  unicode: "\uD83C\uDF82",
  emoji: "🎂"
}, {
  name: "[红包]",
  unicode: "\uD83E\uDDE7",
  emoji: "🧧"
}, {
  name: "[发]",
  unicode: "\uD83C\uDC05",
  emoji: "🀅"
}, {
  name: "[太阳]",
  unicode: "\uD83C\uDF1E",
  emoji: "🌞"
}, {
  name: "[月亮]",
  unicode: "\uD83C\uDF1C",
  emoji: "🌜"
}, {
  name: "[礼物]",
  unicode: "\uD83C\uDF81",
  emoji: "🎁"
}];
exports.emojiList = emojiList;
var emojiTextObject = emojiList.reduce(function (pre, cur) {
  return pre[cur.emoji] = cur.name, pre;
}, {});
exports.emojiTextObject = emojiTextObject;
var textEmojiObject = emojiList.reduce(function (pre, cur) {
  return pre[cur.name] = cur.emoji, pre;
}, {});

/**
 * @param {string} str 要转换的字符串
 * @param {string} type 默认 e2t emoji转text t2e 文字转文本
 * @return {string} 替换的字符串
 */
exports.textEmojiObject = textEmojiObject;
var replaceEmoji = function replaceEmoji(str) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "e2t";
  var data = emojiTextObject;
  if (type === "t2e") {
    data = textEmojiObject;
  }
  var regex = new RegExp("(" + Object.keys(data).map(function (key) {
    return key.replace("[", "\\[").replace("]", "\\]");
  }).join("|") + ")", "g");
  return str.replace(regex, function (match) {
    return data[match];
  });
};
exports.replaceEmoji = replaceEmoji;