var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "private-letter" },
    [
      _c(
        "nav",
        { staticClass: "search-header" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "searchFrom", attrs: { model: _vm.search_form } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "留资情况" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "240px" },
                              attrs: {
                                "show-search": "",
                                "default-active-first-option": false,
                                "filter-option": _vm.filterOption,
                                placeholder: "请选择",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.search_form.leaveInfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search_form, "leaveInfo", $$v)
                                },
                                expression: "search_form.leaveInfo",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: 1 } }, [
                                _vm._v(" 未留资 "),
                              ]),
                              _c("a-select-option", { attrs: { value: 2 } }, [
                                _vm._v(" 已留资 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "提及车系" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "240px" },
                              attrs: {
                                "show-search": "",
                                "default-active-first-option": false,
                                "filter-option": _vm.filterOption,
                                placeholder: "请选择车系",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.search_form.seriesId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search_form, "seriesId", $$v)
                                },
                                expression: "search_form.seriesId",
                              },
                            },
                            _vm._l(_vm.series_list, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.seriesId,
                                  attrs: { value: item.seriesId },
                                },
                                [_vm._v(" " + _vm._s(item.seriesName) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "消息" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入", "allow-clear": "" },
                            model: {
                              value: _vm.search_form.replyMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.search_form, "replyMsg", $$v)
                              },
                              expression: "search_form.replyMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "皮皮拉客触达" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "240px" },
                              attrs: {
                                placeholder: "请选择",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.search_form.isPPReach,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search_form, "isPPReach", $$v)
                                },
                                expression: "search_form.isPPReach",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: 1 } }, [
                                _vm._v(" 已触达 "),
                              ]),
                              _c("a-select-option", { attrs: { value: 0 } }, [
                                _vm._v(" 未触达 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showMoreFilter,
                          expression: "!showMoreFilter",
                        },
                      ],
                      attrs: { span: 16 },
                    },
                    [
                      _c("div", { staticClass: "tag-btn_box" }, [
                        _c("span"),
                        _c(
                          "div",
                          { staticClass: "nav-btn" },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showMoreFilter = true
                                  },
                                },
                              },
                              [
                                _vm._v("更多筛选项 "),
                                _c("a-icon", { attrs: { type: "down" } }),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-10p",
                                attrs: { icon: "redo", type: "dashed" },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-10p",
                                attrs: { type: "primary", icon: "search" },
                                on: { click: _vm.searchClick },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMoreFilter,
                  expression: "showMoreFilter",
                },
              ],
              staticClass: "tag-btn_box",
            },
            [
              _c(
                "div",
                { staticClass: "tag_box" },
                [
                  _c("span", [_vm._v("私信标签：")]),
                  _vm.platform !== "feishu"
                    ? _c("i", {
                        staticClass: "el-icon-question question_style",
                        on: { click: _vm.showTipsReply },
                      })
                    : _vm._e(),
                  _vm._l(_vm.attention_tag, function (item) {
                    return _c(
                      "a-tag",
                      {
                        key: item.sceneId,
                        attrs: {
                          color:
                            item.sceneId == _vm.search_form.sceneId
                              ? "#108ee9"
                              : "blue",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeSceneTag(item.sceneId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.sceneName) +
                            " " +
                            _vm._s(item.count || "") +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "nav-btn" },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showMoreFilter = false
                        },
                      },
                    },
                    [
                      _vm._v("收起筛选项 "),
                      _c("a-icon", { attrs: { type: "up" } }),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-10p",
                      attrs: { icon: "redo", type: "dashed" },
                      on: { click: _vm.resetSearch },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-10p",
                      attrs: { type: "primary", icon: "search" },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "private-letter-container" }, [
        _c(
          "div",
          { staticClass: "private-letter-container-left" },
          [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c(
                  "a-tabs",
                  {
                    on: { click: _vm.changeChatType },
                    model: {
                      value: _vm.user_list_tab,
                      callback: function ($$v) {
                        _vm.user_list_tab = $$v
                      },
                      expression: "user_list_tab",
                    },
                  },
                  [
                    _c("a-tab-pane", { key: 1, attrs: { tab: "全部" } }),
                    _c("a-tab-pane", {
                      key: 0,
                      attrs: {
                        tab: "未读" + _vm.no_read_count,
                        "force-render": "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-popover",
                  {
                    ref: "popover2",
                    attrs: {
                      placement: "right",
                      title: "新消息提醒设置",
                      width: "300",
                      trigger: "click",
                    },
                  },
                  [
                    _c("a-icon", {
                      staticClass: "setting-icon",
                      attrs: { slot: "reference", type: "setting" },
                      slot: "reference",
                    }),
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "开启后，企业号收到新会话消息，顶部浏览器模块将收到提醒"
                        ),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/live_images/web_title_tips.png"),
                        },
                      }),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        { staticStyle: { "padding-top": "24px", margin: "0" } },
                        [
                          _vm._v(" 新消息声音提醒 "),
                          _c("a-switch", {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              checked: !!_vm.author_setting.isNewsVoice,
                              loading: _vm.switch_loading_one == 1,
                            },
                            on: {
                              change: function (value) {
                                return _vm.authorUseSetting(value, 1)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticStyle: { padding: "16px 0" } },
                        [
                          _vm._v(" 标签栏闪烁提醒 "),
                          _c("a-switch", {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              checked: !!_vm.author_setting.isLabelFlashing,
                              loading: _vm.switch_loading_one == 2,
                            },
                            on: {
                              change: function (value) {
                                return _vm.authorUseSetting(value, 2)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-input", {
              staticStyle: { padding: "4px" },
              attrs: {
                placeholder: "搜索抖音昵称",
                size: "small",
                "prefix-icon": "el-icon-search",
                clearable: "",
              },
              model: {
                value: _vm.searchUser,
                callback: function ($$v) {
                  _vm.searchUser = $$v
                },
                expression: "searchUser",
              },
            }),
            _c(
              "div",
              { staticClass: "user-list" },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.show_message_tips && _vm.judge_search,
                        expression: "show_message_tips && judge_search",
                      },
                    ],
                    staticClass: "message-tips",
                    on: { click: _vm.updataNewMessage },
                  },
                  [_vm._v("有新消息啦")]
                ),
                _vm._l(_vm.searchChatList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "user-item",
                      style: {
                        background:
                          _vm.currentFansId == item.open_id &&
                          _vm.currentAuthorId == item.author_open_id
                            ? "#3b7af0"
                            : "none",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.userClick(item)
                        },
                      },
                    },
                    [
                      item.un_read_count
                        ? _c(
                            "el-badge",
                            {
                              staticClass: "item",
                              attrs: { value: item.un_read_count, max: 99 },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: item.avatar || _vm.avatar,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c("img", {
                            attrs: { src: item.avatar || _vm.avatar, alt: "" },
                          }),
                      _c("div", { staticClass: "user-item-main" }, [
                        _c("div", { staticClass: "message" }, [
                          _c(
                            "div",
                            {
                              staticClass: "f14 message-left",
                              style: {
                                color:
                                  _vm.currentFansId == item.open_id &&
                                  _vm.currentAuthorId == item.author_open_id
                                    ? "#fff"
                                    : "#17233d",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.nickname || "抖音用户") + " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "message-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.disposeLongTime(item.mtime)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "message" }, [
                          _c(
                            "div",
                            {
                              staticClass: "f12 message-left",
                              style: {
                                color:
                                  _vm.currentFansId == item.open_id &&
                                  _vm.currentAuthorId == item.author_open_id
                                    ? "#fff"
                                    : "#999",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.msg_sender == 1 ? "[主播]" : "[用户]"
                                  )
                                ),
                              ]),
                              _vm._v(" " + _vm._s(item.content) + " "),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.city,
                                  expression: "item.city",
                                },
                              ],
                              staticClass: "message-right",
                            },
                            [
                              _c("i", { staticClass: "el-icon-location" }),
                              _vm._v(" " + _vm._s(item.city)),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                _vm.user_list_tab === 1 && _vm.chat_list.length > 0
                  ? _c("div", { staticClass: "c-g f12 bottom-tip" }, [
                      _vm._v(" 「已拉取近15天内最多300个会话」 "),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "private-letter-container-main" }, [
          _c(
            "div",
            { staticClass: "main-header" },
            [
              _c("div", [
                _vm.currentAuthorData
                  ? _c("span", { staticStyle: { margin: "0 12px" } }, [
                      _vm._v(
                        _vm._s(_vm.currentAuthorData.nickname || "抖音用户")
                      ),
                    ])
                  : _vm._e(),
                _vm.currentFansId
                  ? _c(
                      "span",
                      {
                        staticClass: "c-g",
                        staticStyle: { "font-size": "14px" },
                      },
                      [
                        _vm._v(
                          "(to " +
                            _vm._s(
                              _vm.userInformation
                                ? _vm.userInformation.nickname
                                : ""
                            ) +
                            ")"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("svg-icon", {
                staticStyle: {
                  "font-size": "18px",
                  "margin-right": "8px",
                  cursor: "pointer",
                },
                attrs: {
                  "icon-class": _vm.show_left
                    ? "menu-unfold-fill"
                    : "menu-fold-fill",
                },
                on: { click: _vm.changeShowLeft },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "wrapper",
              staticClass: "main-talk",
              attrs: { id: "mainTalk" },
            },
            [
              _c(
                "div",
                { staticClass: "main-talk-container" },
                [
                  _vm.loading_chat_content
                    ? _c(
                        "div",
                        { staticClass: "spin-loading" },
                        [_c("a-spin")],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.historty_chat_list, function (item) {
                    return _c("talkItem", {
                      key: item.id,
                      attrs: {
                        position: item.is_author_send == 1 ? "right" : "left",
                        item: item,
                        author: _vm.currentAuthorData,
                      },
                    })
                  }),
                ],
                2
              ),
            ]
          ),
          _c("div", { staticClass: "main-reply" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentAuthorId && _vm.currentFansId,
                    expression: "currentAuthorId && currentFansId",
                  },
                ],
                staticClass: "main-reply-header",
              },
              [
                _c("div", [
                  _vm.currentAuthorData
                    ? _c("span", { staticClass: "c-g f12" }, [
                        _vm._v(
                          "【" +
                            _vm._s(
                              _vm.userInformation
                                ? _vm.userInformation.nickname
                                : ""
                            ) +
                            "】发送以下消息给 "
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentAuthorData
                    ? _c("span", { staticClass: "c-r f12" }, [
                        _vm._v(
                          " 【" +
                            _vm._s(
                              _vm.currentAuthorData.nickname || "抖音用户"
                            ) +
                            "】"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm.currentFansId
              ? _c(
                  "div",
                  { staticClass: "main-reply-bottom" },
                  [
                    [
                      _c("div", {
                        staticStyle: {
                          width: "100%",
                          height: "4px",
                          cursor: "n-resize",
                        },
                        on: { mousedown: _vm.mouseDown },
                      }),
                      _c("a-input", {
                        ref: "inputBox",
                        staticClass: "resizeNone",
                        class: { noSelect: _vm.isDrag },
                        attrs: {
                          type: "textarea",
                          placeholder: "输入回复内容",
                          maxLength: 300,
                          rows: 4,
                          autosize: false,
                          "show-word-limit": "",
                        },
                        on: { focus: _vm.focusInput, change: _vm.focusInput },
                        model: {
                          value: _vm.replyValue,
                          callback: function ($$v) {
                            _vm.replyValue =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "replyValue",
                        },
                      }),
                      _c("div", { staticClass: "button-div" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.replyValue.length >= 300
                                ? "最多输入300字"
                                : ""
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "button-right" },
                          [
                            _vm.showEmoji
                              ? _c("emoji-list", {
                                  staticClass: "emoji-box",
                                  on: { selectEmoji: _vm.selectEmoji },
                                })
                              : _vm._e(),
                            _c("svg-icon", {
                              staticClass: "emoji-style",
                              attrs: { "icon-class": "emoji" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showEmojiClick($event)
                                },
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  disabled: !this.replyValue,
                                },
                                on: { click: _vm.replyClick },
                              },
                              [_vm._v("回复")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]),
        _vm.show_left
          ? _c("div", { staticClass: "private-letter-container-right" }, [
              _c(
                "div",
                { staticClass: "histort-tab" },
                [
                  _c(
                    "a-tabs",
                    {
                      on: { change: _vm.interactionTabs },
                      model: {
                        value: _vm.active_btn,
                        callback: function ($$v) {
                          _vm.active_btn = $$v
                        },
                        expression: "active_btn",
                      },
                    },
                    [
                      _c("a-tab-pane", { key: 1, attrs: { tab: "概览信息" } }),
                      _c("a-tab-pane", {
                        key: 2,
                        attrs: { tab: "互动记录", "force-render": "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active_btn == 1,
                      expression: "active_btn == 1",
                    },
                  ],
                  staticClass: "history-main",
                },
                [
                  _c("div", { staticClass: "tips_lebals" }, [_vm._v("所在地")]),
                  _c(
                    "div",
                    { staticClass: "city_adds" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.client_message.location || "未知")
                      ),
                      _c(
                        "a-tag",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.client_message.targetCity,
                              expression: "client_message.targetCity",
                            },
                          ],
                          staticStyle: { "margin-left": "8px" },
                          attrs: { color: "#f59a23" },
                        },
                        [_vm._v("目标城市")]
                      ),
                      _vm.platform !== "feishu" && _vm.client_message.location
                        ? _c("i", {
                            staticClass: "el-icon-question add_question",
                            on: { click: _vm.gotoSetting },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tips_lebals" }, [
                    _vm._v("留资情况"),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.client_message.infoType == 1
                              ? "未留资"
                              : _vm.client_message.infoType == 2
                              ? "私信留资："
                              : "组件留资："
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.client_message.infoType != 1,
                              expression: "client_message.infoType != 1",
                            },
                          ],
                          staticClass: "leave_box",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.client_message.leaveInfo || "未留资") +
                              " "
                          ),
                        ]
                      ),
                      _vm.client_message.vxLeaveInfo
                        ? [
                            _c("span", [_vm._v("疑似微信：")]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.client_message.vxLeaveInfo)),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "tips_lebals" }, [
                    _vm._v("提及车系"),
                  ]),
                  _vm.client_message.seriesList.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.client_message.seriesList, function (item) {
                          return _c(
                            "a-tag",
                            {
                              key: item.seriesId,
                              staticStyle: { "margin-bottom": "4px" },
                              attrs: { color: "#108ee9" },
                            },
                            [_vm._v(" " + _vm._s(item.seriesName) + " ")]
                          )
                        }),
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("暂未提及任何车系"),
                          ]),
                        ],
                        1
                      ),
                  _c("div", { staticClass: "tips_lebals" }, [
                    _vm._v("私信标签"),
                  ]),
                  _vm.client_message.labels.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.client_message.labels, function (item) {
                          return _c(
                            "a-tag",
                            {
                              key: item.sceneId,
                              staticStyle: { "margin-bottom": "4px" },
                              attrs: { color: "#f59a23" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.sceneName) +
                                  " " +
                                  _vm._s(item.count) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("a-tag", { attrs: { color: "red" } }, [
                            _vm._v("暂未命中任何标签"),
                          ]),
                        ],
                        1
                      ),
                  _c("div", { staticClass: "tips_lebals" }, [
                    _vm._v("是否粉丝"),
                  ]),
                  _c(
                    "a-tag",
                    {
                      attrs: {
                        color: _vm.client_message.fans ? "#76ce46" : "#f50",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.client_message.fans ? "粉丝" : "非粉丝") +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.client_message.fans,
                          expression: "client_message.fans",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "最新关注时间：" +
                          _vm._s(_vm.client_message.fansTime || "")
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active_btn == 2,
                      expression: "active_btn == 2",
                    },
                  ],
                  staticClass: "history-main",
                  attrs: { id: "historyList" },
                },
                [
                  _c("div", { staticClass: "message_content_box" }, [
                    _c("div", { staticClass: "tips_title" }, [
                      _vm._v("直播互动"),
                    ]),
                    _c(
                      "div",
                      [
                        _vm.live_fans_msg.last_enter_date !== null
                          ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.live_fans_msg.last_enter_date > 0
                                      ? _vm.live_fans_msg.last_enter_date +
                                          "天前来过"
                                      : "今天来过"
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("a-tag", { attrs: { color: "#f50" } }, [
                              _vm._v(" 从未来过 "),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "live_top_box" },
                      [
                        _c(
                          "a-tabs",
                          {
                            on: { change: _vm.changeLiveTab },
                            model: {
                              value: _vm.active_live_tab,
                              callback: function ($$v) {
                                _vm.active_live_tab = $$v
                              },
                              expression: "active_live_tab",
                            },
                          },
                          [
                            _c("a-tab-pane", {
                              key: 1,
                              attrs: { tab: "价值互动" },
                            }),
                            _c("a-tab-pane", {
                              key: 0,
                              attrs: { tab: "全部互动" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.getFansPolling,
                                expression: "getFansPolling",
                              },
                            ],
                            staticClass: "live_list",
                            attrs: {
                              id: "live_list",
                              "infinite-scroll-distance": 30,
                              "infinite-scroll-disabled": "disable_live_list",
                            },
                          },
                          [
                            _vm._l(_vm.live_chat_message, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item.msg_id,
                                  staticClass: "live_comment_li",
                                },
                                [
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(_vm._s(item.publish_time)),
                                  ]),
                                  item.content
                                    ? _c("div", { staticClass: "content" }, [
                                        _vm._v(
                                          " " + _vm._s(item.content) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            _vm.loading_live_tab_top
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("加载中...")]
                                )
                              : _vm._e(),
                            _vm.no_more_live_top
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v("没有更多了")]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "message_content_box" },
                    [
                      _c("div", { staticClass: "tips_title" }, [
                        _vm._v("视频互动"),
                      ]),
                      _c(
                        "a-tabs",
                        {
                          model: {
                            value: _vm.active_video,
                            callback: function ($$v) {
                              _vm.active_video = $$v
                            },
                            expression: "active_video",
                          },
                        },
                        [
                          _c("a-tab-pane", { key: 1, attrs: { tab: "评论" } }),
                          _c("a-tab-pane", { key: 2, attrs: { tab: "点赞" } }),
                        ],
                        1
                      ),
                      _vm.active_video == 1
                        ? _c("div", { staticClass: "ul_box" }, [
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "infinite-scroll",
                                    rawName: "v-infinite-scroll",
                                    value: _vm.getVideoComment,
                                    expression: "getVideoComment",
                                  },
                                ],
                                staticClass: "video_list_box",
                                attrs: {
                                  id: "video_comment_list",
                                  "infinite-scroll-distance": 30,
                                  "infinite-scroll-disabled":
                                    "disable_video_comment",
                                },
                              },
                              [
                                _vm._l(_vm.video_comment_list, function (item) {
                                  return _c("li", { key: item.id }, [
                                    _c(
                                      "div",
                                      { staticClass: "comment_centent" },
                                      [_vm._v(_vm._s(item.content))]
                                    ),
                                    _c("p", { staticClass: "comment_time" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.disposeLongTime(
                                              item.commentTime
                                            )
                                          )
                                      ),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.openComment(item.id)
                                            },
                                          },
                                        },
                                        [_vm._v("回复")]
                                      ),
                                    ]),
                                  ])
                                }),
                                _vm.loading_video_com
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("加载中...")]
                                    )
                                  : _vm._e(),
                                _vm.no_more_video_com
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("没有更多了")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm.active_video == 2
                        ? _c("div", { staticClass: "ul_box" }, [
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "infinite-scroll",
                                    rawName: "v-infinite-scroll",
                                    value: _vm.getAwemeLikeList,
                                    expression: "getAwemeLikeList",
                                  },
                                ],
                                staticClass: "video_list_box",
                                attrs: {
                                  id: "video_comment_list",
                                  "infinite-scroll-distance": 30,
                                  "infinite-scroll-disabled":
                                    "disable_video_live",
                                },
                              },
                              [
                                _vm._l(_vm.video_like_list, function (item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.awemeId,
                                      staticClass: "like_li",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "live_content" },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.disposeLongTime(
                                                  item.actionTime
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("img", {
                                        staticClass: "img_box",
                                        attrs: { src: item.cover },
                                      }),
                                    ]
                                  )
                                }),
                                _vm.loading_video_com
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("加载中...")]
                                    )
                                  : _vm._e(),
                                _vm.no_more_video_com
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("没有更多了")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c("audio", {
        ref: "audio",
        attrs: {
          controls: "controls",
          hidden: "",
          src: require("@/assets/mp3/tips_voice_use.mp3"),
        },
      }),
      _c("reply-modal", {
        ref: "replyModal",
        attrs: { show_modal: _vm.show_modal },
        on: { closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }