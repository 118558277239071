"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _live_comment = _interopRequireDefault(require("@/api/live_comment"));
var _common = _interopRequireDefault(require("@/api/common"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    quick_modal: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      confirmLoading: false,
      old_reply_conent: {
        videoReplyMsgList: []
      },
      radio_value: null,
      radioStyle: {
        display: "block",
        lineHeight: "30px"
      }
    };
  },
  methods: (_methods = {
    disposeValue: function disposeValue(value) {
      if (!value) return;
      value = value.replace(/\n/gm, "<br>");
      var num = Math.round(Math.random() * 100000);
      return num + value;
    },
    disposeSlice: function disposeSlice(value) {
      return value.slice(5);
    },
    handleCancel: function handleCancel(e) {
      this.radio_value = null;
      this.$emit("closeModal");
    }
  }, (0, _defineProperty2.default)(_methods, "handleCancel", function handleCancel(e) {
    this.radio_value = null, this.$emit("closeModal");
  }), (0, _defineProperty2.default)(_methods, "getQuickReplyList", function getQuickReplyList(id) {
    var _this = this;
    var payload = {
      sceneId: id,
      scene: 3
    };
    _live_comment.default.getQuickReplyList(payload).then(function (res) {
      if (res.code = 200) {
        // res.data.liveReplyMsg = this.disposeValue(res.data.liveReplyMsg);
        // res.data.itemReplyMsg = this.disposeValue(res.data.itemReplyMsg);
        // res.data.directReplyMsg = this.disposeValue(res.data.directReplyMsg);
        if (res.data.videoReplyMsgList.length > 0) {
          res.data.videoReplyMsgList.forEach(function (item) {
            item.replyMsg = _this.disposeValue(item.replyMsg);
          });
        }
        if (res.data.liveReplyMsg.length > 0) {
          res.data.liveReplyMsg.forEach(function (item) {
            item.replyMsg = _this.disposeValue(item.replyMsg);
          });
        }
        if (res.data.itemReplyMsg.length > 0) {
          res.data.itemReplyMsg.forEach(function (item) {
            item.replyMsg = _this.disposeValue(item.replyMsg);
          });
        }
        if (res.data.directReplyMsg.length > 0) {
          res.data.directReplyMsg.forEach(function (item) {
            item.replyMsg = _this.disposeValue(item.replyMsg);
          });
        }
        _this.old_reply_conent = res.data;
      } else {
        _this.$message.error(res.message);
      }
    });
  }), (0, _defineProperty2.default)(_methods, "sendInput", function sendInput() {
    this.filtrate.setTracking({
      en: "zxmk_yx_yjsran_click",
      kv: {
        pageName: "关注监测"
      }
    });
    if (!this.radio_value) {
      this.$message.warning("请选择回复内容！");
      return;
    }
    this.$emit("sendOneConent", this.radio_value.slice(5));
  }), _methods)
};
exports.default = _default;