"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 查询官方主播旗下直播间信息(左侧列表)
  getList: function getList(_ref) {
    var nickname = _ref.nickname,
      page_size = _ref.page_size,
      current = _ref.current;
    return request({
      url: '/mirror-data/douyin/live/list',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        nickname: nickname,
        page_size: page_size,
        current: current
      }
    });
  },
  // 查询该主播id 所有直播记录 可选择 昨天，7 30
  getListOne: function getListOne(_ref2) {
    var current = _ref2.current,
      page_size = _ref2.page_size,
      author_id = _ref2.author_id,
      begin_time = _ref2.begin_time,
      end_time = _ref2.end_time;
    return request({
      url: '/mirror-data/douyin/live/list/one',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        current: current,
        page_size: page_size,
        author_id: author_id,
        begin_time: begin_time,
        end_time: end_time
      }
    });
  },
  // 渠道直播统计
  getListVotes: function getListVotes() {
    return request({
      url: '/mirror-data/douyin/live/list/count/votes',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 直播间直播时用户、礼物的变化趋势(详情趋势图)
  getLiveRoomTrend: function getLiveRoomTrend(_ref3) {
    var roomId = _ref3.roomId;
    return request({
      url: "mirror-data/douyin/live/live-room-trend/".concat(roomId),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  }
};
exports.default = _default;