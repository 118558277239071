"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/core-js/modules/es6.promise.js");
require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/core-js/modules/es6.object.assign.js");
require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _filters = _interopRequireDefault(require("./filters"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
require("video.js/dist/video-js.css");
var _vueResource = _interopRequireDefault(require("vue-resource"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("ant-design-vue/dist/antd.css");
var _antDesignVue = _interopRequireDefault(require("ant-design-vue"));
require("viewerjs/dist/viewer.css");
var _vViewer = _interopRequireDefault(require("v-viewer"));
var _BasePagination = _interopRequireDefault(require("@/components/BasePagination"));
var _BaseButton = _interopRequireDefault(require("@/components/BaseButton"));
var _CnTable = _interopRequireDefault(require("@/components/CnTable"));
require("@/icons");
require("@/permission");
var _lodash = _interopRequireDefault(require("lodash"));
var _common = _interopRequireDefault(require("./utils/common"));
var _errorLog = _interopRequireDefault(require("@/utils/errorLog"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _moment = _interopRequireDefault(require("moment"));
// A modern alternative to CSS resets

// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// global css

// import { createApp } from 'vue';

// const app = createApp(App);
_vue.default.use(_antDesignVue.default);
_vue.default.use(_vViewer.default);

// 全局注册Base组件

// icon
// permission control

_vue.default.use(_vueClipboard.default);

// import VConsole from 'vconsole'
// if (process.env.VUE_APP_VCONSOLE) {
//   const vConsole = new VConsole()
// }

// 时间格式化
_moment.default.locale('zh-cn');
_vue.default.prototype.moment = _moment.default;
_vue.default.mixin({
  components: {
    BasePagination: _BasePagination.default,
    BaseButton: _BaseButton.default,
    CnTable: _CnTable.default
  }
});
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default);
_vue.default.use(_vueResource.default);
_vue.default.config.productionTip = false;
_vue.default.prototype._get = function () {
  return _lodash.default.get.apply(_lodash.default, arguments);
};
_vue.default.prototype.common = _common.default;
// 注册全局过滤器
Object.keys(_filters.default).forEach(function (key) {
  _vue.default.filter(key, _filters.default[key]);
});
_vue.default.prototype.filtrate = _filters.default;
// 监听错误日志实例
// const err = new ErrorLog('http://localhost:6003/monitor/error?info=')
// window.onload = function(){
//   // 监听错误日志实例
//   const err = new ErrorLog('http://localhost:6003/monitor/error?info=')
//   err.listenWindow()
//   err.listenVue(Vue)
// }

// 开发环境不监听错误
if (process.env.NODE_ENV != 'development') {}
var _default = new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
exports.default = _default;