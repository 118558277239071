"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _getVideoList$getVide;
var request = (0, _request.default)();
var _default = (_getVideoList$getVide = {
  // 获取视频列表
  getVideoList: function getVideoList(params) {
    return request({
      url: "/cac/queryPphtController/list",
      method: 'GET',
      params: params
    });
  },
  // 获取视频类型列表
  getVideoTypeList: function getVideoTypeList() {
    return request({
      url: "/cac/queryPphtController/video/type",
      method: 'GET'
    });
  },
  // 获取抖音发布状态列表
  getDyPublishStatusList: function getDyPublishStatusList() {
    return request({
      url: "/cac/queryPphtController/public/status",
      method: 'GET'
    });
  },
  // 获取产品列表
  getProductList: function getProductList(params) {
    return request({
      url: "/cac/queryPphtController/product/option",
      method: 'GET',
      params: params
    });
  }
}, (0, _defineProperty2.default)(_getVideoList$getVide, "getDyPublishStatusList", function getDyPublishStatusList() {
  return request({
    url: "/cac/queryPphtController/public/status",
    method: 'GET'
  });
}), (0, _defineProperty2.default)(_getVideoList$getVide, "getDyNickNameList", function getDyNickNameList(params) {
  return request({
    url: "/cac/queryPphtController/author/option",
    method: 'GET',
    params: params
  });
}), (0, _defineProperty2.default)(_getVideoList$getVide, "getOssAuth", function getOssAuth() {
  return request({
    url: "/cac/queryPphtController/clip/get-sts-token?path_name='path_name'",
    method: 'POST'
  });
}), (0, _defineProperty2.default)(_getVideoList$getVide, "report", function report(data) {
  return request({
    url: "/cac/queryPphtController/report",
    method: 'POST',
    data: data
  });
}), _getVideoList$getVide);
exports.default = _default;