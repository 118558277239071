"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _attention = _interopRequireDefault(require("@/api/attention"));
var _common = _interopRequireDefault(require("@/api/common"));
var _columns = _interopRequireDefault(require("./columns.js"));
var _ReplyDawer = _interopRequireDefault(require("./components/ReplyDawer"));
var _CitySettingModal = _interopRequireDefault(require("./components/CitySettingModal"));
var _TipsModal = _interopRequireDefault(require("@/components/TipsModal.vue"));
var vuex = _interopRequireWildcard(require("vuex"));
var _alertMarning = _interopRequireDefault(require("@/components/alertMarning"));
var _TextEllipsis = _interopRequireDefault(require("@/components/TextEllipsis/TextEllipsis.vue"));
var _DyUserQrCodeModal = _interopRequireDefault(require("./components/DyUserQrCodeModal.vue"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers('intelligence_play'),
  mapState = _vuex$createNamespace.mapState,
  mapActions = _vuex$createNamespace.mapActions;
var configPayload = {
  columns: _columns.default.columns_all,
  http_api: _attention.default.attentionListAll
};
var _default = {
  components: {
    ReplyDawer: _ReplyDawer.default,
    CitySettingModal: _CitySettingModal.default,
    TipsModal: _TipsModal.default,
    alertMarning: _alertMarning.default,
    TextEllipsis: _TextEllipsis.default,
    DyUserQrCodeModal: _DyUserQrCodeModal.default
  },
  data: function data() {
    return {
      layout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      tab_active: 1,
      search_form: {
        trackStatus: undefined,
        cityCode: undefined,
        sceneId: undefined,
        userNickname: '',
        liveInteractContent: '',
        actionEndTime: '',
        actionStartTime: '',
        autoReplyStartTime: '',
        autoReplyEndTime: '',
        autoReplyStatusList: null,
        infoType: null,
        isScene: undefined,
        strategyTypeCode: undefined,
        failureCauseCode: undefined
      },
      time: [],
      attention_time: [],
      configPayload: configPayload,
      follow_up_list: [{
        name: '未跟进',
        id: 2
      }, {
        name: '已跟进',
        id: 3
      }, {
        name: '用户未回复',
        id: 1
      }],
      status_list: [{
        id: null,
        name: '全部'
      }, {
        id: 1,
        name: '未响应'
      }, {
        id: 2,
        name: '策略已回复'
      }, {
        id: 3,
        name: '响应失败'
      }, {
        id: 4,
        name: '人工响应'
      }],
      info_type_list: [{
        id: null,
        name: '全部'
      }, {
        id: 1,
        name: '未留资'
      }, {
        id: 2,
        name: '私信留资'
      }],
      crity_list: [{
        name: '未跟进',
        id: 1
      }, {
        name: '已跟进',
        id: 2
      }, {
        name: '用户未回复',
        id: 3
      }],
      attention_list: [],
      straegy_visible: false,
      city_setting: {},
      visible_modal: false,
      confirm_loading: false,
      route_loading: false,
      active_id: null,
      base_dictionaries: {},
      arrayMonent: [this.moment('00:00', 'HH:mm'), this.moment('23:59', 'HH:mm')],
      autoReply_time: [],
      infoData: {}
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.search_form.sceneId = this.$route.query.id;
    } else if (this.$route.query.form_path && this.$route.query.form_path == 'attention_monitor') {
      this.$set(this, 'attention_time', [this.moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00', this.moment().endOf('day').format('YYYY-MM-DD') + ' 23:59:59']);
      this.$set(this, 'time', []);
      this.onChangeDate(this.attention_time);
    } else {
      // console.log(this.moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00', this.moment().endOf('day').format('YYYY-MM-DD') + ' 23:59:59')
      this.$set(this, 'time', [this.moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00', this.moment().endOf('day').format('YYYY-MM-DD') + ' 23:59:59']);
      this.onChangeDate(this.time);
    }
    this.getBuyCarList();
    this.getTargetCity();
    this.filtrate.setTracking({
      en: 'zxmk_yx_xgzjcqb_view'
    });
    if (this.$route.query.tab) {
      this.tab_active = 2;
      this.changeTabs(2);
    } else {
      this.tab_active = 2;
      this.changeTabs(2);
      // this.$refs.cntable.getTableList(1, 10);
    }

    this.getAttentionList();
    this.getInitiativeMarketingAnnouncementInfo();
  },
  computed: _objectSpread({}, mapState({
    buyCarList: function buyCarList(state) {
      return state.buyCarList;
    } // 购车城市下拉列表
  })),

  methods: _objectSpread(_objectSpread({}, mapActions(['getBuyCarList'])), {}, {
    disabledDate: function disabledDate(current) {
      // Can not select days before today and today
      return current < this.moment().subtract(29, 'days').startOf('day') || current > this.moment().endOf('day');
    },
    disposeStatus: function disposeStatus(value) {
      var str = '';
      switch (value) {
        case 1:
          str = '未响应';
          break;
        case 2:
          str = '策略已回复';
          break;
        case 3:
          str = '响应失败';
          break;
        case 4:
          str = '人工响应';
          break;
        default:
          break;
      }
      return str;
    },
    disposeTrackStatus: function disposeTrackStatus(value) {
      var str = '';
      switch (value) {
        case 1:
          str = '用户未回复,去私信';
          break;
        case 2:
          str = '未跟进,去私信';
          break;
        case 3:
          str = '继续跟进,去私信';
          break;
        default:
          break;
      }
      return str;
    },
    disposeLiveInteractContents: function disposeLiveInteractContents(value, content) {
      // 1.关注、2.直播互动、3.定向、4.视频评论、5.视频点赞
      if (value === 2) {
        return "\u76F4\u64AD\uFF1A".concat(content);
      } else if (value === 3 || value === 4) {
        return "\u89C6\u9891\uFF1A".concat(content);
      } else {
        return '无';
      }
    },
    disposeStrategyType: function disposeStrategyType(value) {
      var str = '';
      switch (value) {
        case 1:
          str = '粉丝';
          break;
        case 2:
          str = '价值互动';
          break;
        case 3:
          str = '定向';
          break;
        default:
          break;
      }
      return str;
    },
    resetTable: function resetTable() {
      this.$refs.cntable.getTableList(1, 10);
    },
    searchClick: function searchClick() {
      var _this = this;
      var _this$search_form = this.search_form,
        trackStatus = _this$search_form.trackStatus,
        cityCode = _this$search_form.cityCode,
        sceneId = _this$search_form.sceneId,
        userNickname = _this$search_form.userNickname,
        liveInteractContent = _this$search_form.liveInteractContent,
        actionEndTime = _this$search_form.actionEndTime,
        actionStartTime = _this$search_form.actionStartTime;
      this.filtrate.setTracking({
        en: 'zxmk_yx_gzjccx_click',
        kv: {
          filterItem: {
            followUp: trackStatus,
            city: cityCode,
            strategy: sceneId,
            nickName: userNickname,
            liveComment: liveInteractContent,
            followTime: "".concat(actionStartTime, "-").concat(actionEndTime)
          }
        }
      });
      this.$nextTick(function () {
        _this.$refs.cntable.getTableList(1, 10);
      });
    },
    resetSearch: function resetSearch() {
      var _this2 = this;
      this.time = [this.moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00:00', this.moment().endOf('day').format('YYYY-MM-DD') + ' 23:59:59'];
      this.onChangeDate(this.time);
      this.$set(this, 'search_form', {
        trackStatus: undefined,
        cityCode: undefined,
        sceneId: undefined,
        userNickname: '',
        liveInteractContent: '',
        actionEndTime: this.search_form.actionEndTime,
        actionStartTime: this.search_form.actionStartTime,
        autoReplyStartTime: '',
        autoReplyEndTime: '',
        autoReplyStatusList: null,
        infoType: null,
        isScene: this.tab_active == 4 ? 1 : null
      });
      this.attention_time = [];
      this.$nextTick(function () {
        _this2.$refs.cntable.getTableList(1, 10);
      });
    },
    //日期选择框
    onChangeDate: function onChangeDate(dates) {
      var start_time = '';
      var end_time = '';
      if (dates.length) {
        start_time = this.moment(dates[0]._d || dates[0]).format('YYYY-MM-DD HH:mm');
        end_time = this.moment(dates[1]._d).format('YYYY-MM-DD HH:mm');
        this.search_form.actionStartTime = start_time;
        this.search_form.actionEndTime = end_time;
      } else {
        this.search_form.actionStartTime = '';
        this.search_form.actionEndTime = '';
      }
    },
    //策略主动私信时间
    onChangeAttenDate: function onChangeAttenDate(dates) {
      var start_time = '';
      var end_time = '';
      if (dates.length) {
        start_time = this.moment(dates[0]._d).format('YYYY-MM-DD HH:mm');
        end_time = this.moment(dates[1]._d).format('YYYY-MM-DD HH:mm');
        this.autoReply_time = [start_time, end_time];
        this.search_form.autoReplyStartTime = start_time;
        this.search_form.autoReplyEndTime = end_time;
      } else {
        this.search_form.autoReplyStartTime = '';
        this.search_form.autoReplyEndTime = '';
        this.autoReply_time = [];
      }
    },
    disposeLongTime: function disposeLongTime(value) {
      console.log(value);
      var result = Number(value);
      if (result) {
        var d = parseInt(result / (60 * 24));
        var h = parseInt(result % (60 * 24) / 60);
        var m = result % 60;
        if (d > 7) {
          return d + ' 天 ';
        } else if (d <= 7 && d > 1) {
          return d + ' 天 ' + h + ' 小时 ';
        } else {
          if (h > 0) {
            return h + ' 小时 ' + m + ' 分钟 ';
          } else {
            return m + ' 分钟 ';
          }
        }
      } else {
        return '——';
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 切换
    changeTabs: function changeTabs(value) {
      var _this3 = this;
      var et = {};
      switch (value) {
        case 1:
          this.configPayload = {
            columns: _columns.default.columns_all,
            http_api: _attention.default.attentionListAll
          };
          et = {
            en: 'zxmk_yx_xgzjcqb_view'
          };
          this.search_form.isScene = null;
          this.search_form.autoReplyStartTime = '';
          this.search_form.autoReplyEndTime = '';
          break;
        case 2:
          this.configPayload = {
            columns: _columns.default.columns_attention,
            http_api: _attention.default.attentionListRespose
          };
          et = {
            en: 'zxmk_yx_xgzjcclyxy_view'
          };
          this.search_form.isScene = null;
          this.search_form.autoReplyStartTime = this.autoReply_time[0];
          this.search_form.autoReplyEndTime = this.autoReply_time[1];
          break;
        case 3:
          this.configPayload = {
            columns: _columns.default.columns_no_dispose,
            http_api: _attention.default.attentionListNores
          };
          et = {
            en: 'zxmk_yx_xgzjcwcl_view'
          };
          this.search_form.isScene = null;
          this.search_form.autoReplyStartTime = '';
          this.search_form.autoReplyEndTime = '';
          break;
        case 4:
          this.configPayload = {
            columns: _columns.default.columns_hit,
            http_api: _attention.default.attentionListAll
          };
          this.search_form.isScene = 1;
          et = {
            en: 'zxmk_yx_xgzjcwcl_view'
          };
          this.search_form.autoReplyStartTime = this.autoReply_time[0];
          this.search_form.autoReplyEndTime = this.autoReply_time[1];
          break;
        default:
          break;
      }
      this.$nextTick(function () {
        _this3.$refs.cntable.getTableList(1, 10);
        _this3.filtrate.setTracking(et);
      });
    },
    /**
     * 打开联系ta模态框
     * @param {object} data 当行信息
     */
    openDyUserQrCodeModal: function openDyUserQrCodeModal(data) {
      this.$refs['DyUserQrCodeModal'].openModal(data);
    },
    openDrawerStrategy: function openDrawerStrategy(id, name) {
      this.filtrate.setTracking({
        en: 'zxmk_yx_qyzdhf_click',
        kv: {
          strategyName: name
        }
      });
      this.$refs.strategydawer.liveAutoReplyModel(id);
      this.straegy_visible = true;
    },
    startUseing: function startUseing(name) {
      this.filtrate.setTracking({
        en: 'zxmk_yx_qyzdhfqy_click',
        kv: {
          strategyName: name
        }
      });
    },
    closeDrawerStrategy: function closeDrawerStrategy() {
      this.straegy_visible = false;
    },
    // 查询目标城市配置
    getTargetCity: function getTargetCity() {
      var _this4 = this;
      _attention.default.getliveUserTargetCity().then(function (res) {
        console.log('目标城市配置', res);
        if (res.code == 200) {
          _this4.city_setting = res.data || {};
        }
      });
    },
    openModal: function openModal() {
      var _this5 = this;
      // this.visible_modal = true;
      this.$confirm('可以在【配置目标城市】页面详细了解!', '目标城市', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.$router.push({
          path: '/setting/setting_city'
        });
      }).catch(function () {
        console.log('cancel');
      });
    },
    closeModal: function closeModal() {
      this.visible_modal = false;
    },
    saveCity: function saveCity(value) {
      var _this6 = this;
      this.confirm_loading = true;
      _attention.default.getUserTargetCityUpdate(value).then(function (res) {
        if (res.code == 200) {
          _this6.getTargetCity();
          _this6.$message.success('配置开启成功');
          _this6.closeModal();
        }
      }).finally(function () {
        _this6.confirm_loading = false;
      });
    },
    closeCitySetting: function closeCitySetting() {
      var _this7 = this;
      this.confirm_loading = true;
      _attention.default.getUserTargetCitySwitch().then(function (res) {
        if (res.code == 200) {
          _this7.getTargetCity();
          _this7.$message.success('配置关闭成功');
          _this7.closeModal();
        } else {
          _this7.$message.error(res.message);
        }
      }).finally(function () {
        _this7.confirm_loading = false;
      });
    },
    goPrivatLetter: function goPrivatLetter(id, userId, displayPrompt) {
      var _this8 = this;
      this.filtrate.setTracking({
        en: 'zxmk_yx_xgzjcsx_click',
        kv: {
          openID: id,
          pageName: this.tab_active == 1 ? '全部' : this.tab_active == 2 ? '策略已回复' : '未处理' //媒体号ID
        }
      });

      this.route_loading = true;
      this.active_id = userId;
      _attention.default.chatFans(userId).then(function (res) {
        if (res.code == 200) {
          _this8.$router.push({
            path: '/private_letter_manage/conversation',
            query: {
              userOpenId: id,
              displayPrompt: displayPrompt
            }
          });
        }
      }).finally(function () {
        _this8.route_loading = false;
      });
    },
    getAttentionList: function getAttentionList() {
      var _this9 = this;
      _attention.default.getAttention().then(function (res) {
        if (res.code == 200) {
          _this9.base_dictionaries = res.data;
        }
      });
    },
    getInitiativeMarketingAnnouncementInfo: function getInitiativeMarketingAnnouncementInfo() {
      var _this10 = this;
      _attention.default.getInitiativeMarketingAnnouncementInfo({
        clientType: 1
      }).then(function (res) {
        if (res.code == 200) {
          _this10.infoData = res.data || {};
        } else {
          _this10.$message.error(res.message);
        }
      });
    },
    /**
     * 复制成功
     */
    handleCopySuccess: function handleCopySuccess() {
      this.$message.success('抖音号复制成功');
    },
    /**
     * 复制失败
     */
    handleCopyFail: function handleCopyFail() {
      this.$message.error('抖音号 复制失败');
    }
  })
};
exports.default = _default;