"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _attention = _interopRequireDefault(require("@/api/attention"));
var vuex = _interopRequireWildcard(require("vuex"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers('intelligence_play'),
  mapState = _vuex$createNamespace.mapState,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  //   watch: {
  //     city_setting(val) {
  //       this.getInitData(val);
  //     },
  //   },
  data: function data() {
    return {
      checked_status: false,
      checked_filter: true,
      city_code: [],
      provinceList: [],
      capital: undefined,
      confirm_loading: false
    };
  },
  computed: _objectSpread({}, mapState({
    areaList: function areaList(state) {
      return state.areaList;
    },
    buyCarList: function buyCarList(state) {
      return state.buyCarList;
    } // 城市下拉列表
  })),
  mounted: function mounted() {
    this.filtrate.setTracking({
      en: 'zxmk_yx_pzmbcs_click'
    });
    this.getTargetCity();
    this.getProvince();
    this.getBuyCarList();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['getBuyCarListProps', 'getAreaList', 'getBuyCarList'])), {}, {
    // 查询目标城市配置
    getTargetCity: function getTargetCity() {
      var _this = this;
      _attention.default.getliveUserTargetCity().then(function (res) {
        console.log('目标城市配置', res);
        if (res.code === 200) {
          _this.city_setting = res.data;
          _this.checked_status = !!_this.city_setting.isOpened;
          // this.checked_filter = !!val.isFilterUser;
          var city_list = [];
          console.log(_this.city_setting.address);
          if (_this.city_setting.address.length) {
            _this.city_setting.address.map(function (item) {
              if (item.level == 2) {
                _this.capital = Number(item.province_id);
              } else if (item.level == 3) {
                city_list.push(item.city_id);
              } else {
                _this.capital = undefined;
                _this.city_code = [];
              }
            });
          }
          _this.city_code = city_list;
        }
      });
    },
    // 获取省份列表
    getProvince: function getProvince() {
      var _this2 = this;
      this.getAreaList({
        parent_id: 0,
        level: 1
      }).then(function () {
        _this2.provinceList = _this2.areaList;
      });
    },
    // 开启关闭
    onChangeSwitch: function onChangeSwitch(value) {
      console.log('value关闭关闭', value);
      if (value) {
        this.checked_status = true;
      } else {
        this.checked_status = false;
      }
    },
    handleOk: function handleOk(e) {
      var _this3 = this;
      console.log(this.capital, this.city_code);
      if (this.checked_status) {
        this.updata();
      } else {
        this.confirm_loading = true;
        // this.$emit("closeCitySetting");
        _attention.default.getUserTargetCitySwitch().then(function (res) {
          if (res.code === 200) {
            _this3.getTargetCity();
            _this3.$message.success('配置关闭成功');
            _this3.closeModal();
          } else {
            _this3.$message.error(res.message);
          }
        }).finally(function () {
          _this3.confirm_loading = false;
        });
      }
    },
    updata: function updata() {
      var _this4 = this;
      console.log(this.city_code);
      console.log(this.capital);
      if (!this.city_code.length && !this.capital) {
        this.$message.error('请选择省/市');
        return;
      }
      var region = '';
      var liveUserTargetCityList = [];
      if (this.capital) {
        liveUserTargetCityList.push({
          cityLevel: 2,
          provinceId: this.capital
        });
        region += this.capital;
      }
      if (this.city_code.length > 0) {
        this.city_code.forEach(function (item) {
          region = region + ',' + item;
          liveUserTargetCityList.push({
            cityLevel: 3,
            cityId: item
          });
        });
      }
      console.log(region);
      this.filtrate.setTracking({
        en: 'zxmk_yx_jrhhtj_click',
        kv: {
          state: this.checked_status ? '开启' : '关闭',
          region: region
        }
      });
      console.log(liveUserTargetCityList);
      var address = liveUserTargetCityList.map(function (item) {
        return {
          city_id: item.cityId,
          level: item.cityLevel,
          province_id: item.provinceId || ''
        };
      });
      var payload = {
        // isFilterUser: 1,
        // isOpened: this.checked_status ? 1 : 0,
        // liveUserTargetCityList
        address: address
      };
      for (var key in payload) {
        if (payload[key] == '') {
          delete payload[key];
        }
      } // 删除空属性
      this.confirm_loading = true;
      _attention.default.getUserTargetCityUpdate(payload).then(function (res) {
        if (res.code === 200) {
          _this4.getTargetCity();
          _this4.$message.success('保存成功');
          _this4.closeModal();
        } else {
          _this4.$message.error(res.message);
        }
      }).finally(function () {
        _this4.confirm_loading = false;
      });
    },
    changeFilter: function changeFilter(e) {
      this.checked_filter = e.target.checked;
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    countTag: function countTag(value) {
      if (value && value.length > 3) {
        this.city_code.pop();
        this.$message.warning('最多选择3个城市');
      }
    }
  })
};
exports.default = _default;