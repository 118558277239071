var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empower" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "empower-container",
      },
      [
        _c("div", { staticClass: "second" }, [
          _c("img", {
            staticStyle: { margin: "74px 0 60px" },
            attrs: {
              src: require("@/assets/dev/success.jpg"),
              width: "110px",
              alt: "",
            },
          }),
          _c("div", { staticClass: "flex f16" }, [
            _c("img", {
              staticStyle: { "border-radius": "18px" },
              attrs: { src: _vm.info.avatar, width: "36px", alt: "" },
            }),
            _c("span", [_vm._v(" 抖音号 ")]),
            _c("span", { staticClass: "c-b" }, [
              _vm._v(" " + _vm._s(_vm.info.code || "---") + " "),
            ]),
            _c("span", [_vm._v(" 已绑定成功 ")]),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "sure-btn", on: { click: _vm.routerTo } }, [
            _vm._v("返回页面"),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex f14 c-g", staticStyle: { margin: "120px 0 36px" } },
      [
        _c("div", { staticClass: "line" }),
        _c("div", [_vm._v("更多新授权已可以体验")]),
        _c("div", { staticClass: "line" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }