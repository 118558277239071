"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  columns_hit: [{
    title: '用户信息',
    scopedSlots: {
      customRender: 'userInfo'
    }
  }, {
    slots: {
      title: 'recentInteractionTypesTitle'
    },
    scopedSlots: {
      customRender: 'recentInteractionTypes'
    }
  }, {
    title: '评论内容',
    dataIndex: 'liveInteractContents',
    scopedSlots: {
      customRender: 'liveInteractContents'
    }
  }, {
    title: '命中策略',
    dataIndex: 'sceneName',
    scopedSlots: {
      customRender: 'sceneName'
    }
  }, {
    title: '策略回复内容',
    dataIndex: 'replyMsg',
    scopedSlots: {
      customRender: 'replyMsg'
    }
  }, {
    title: '状态',
    dataIndex: 'autoReplyStatus',
    scopedSlots: {
      customRender: 'autoReplyStatus'
    }
  }, {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }],
  columns_attention: [{
    title: '用户信息',
    scopedSlots: {
      customRender: 'userInfo'
    }
  }, {
    slots: {
      title: 'recentInteractionTypesTitle'
    },
    scopedSlots: {
      customRender: 'recentInteractionTypes'
    }
  }, {
    title: '评论内容',
    dataIndex: 'liveInteractContents',
    scopedSlots: {
      customRender: 'liveInteractContents'
    }
  }, {
    title: '命中策略',
    dataIndex: 'sceneName',
    scopedSlots: {
      customRender: 'sceneName'
    }
  }, {
    title: '策略回复内容',
    dataIndex: 'replyMsg',
    scopedSlots: {
      customRender: 'replyMsg'
    }
  }, {
    title: '用户最新私信',
    dataIndex: 'userChatContents',
    scopedSlots: {
      customRender: 'userChatContents'
    }
  }, {
    title: '我的跟进',
    dataIndex: 'authorTrackContents',
    scopedSlots: {
      customRender: 'authorTrackContents'
    }
  }, {
    dataIndex: 'infoType',
    slots: {
      title: 'infoTypeTitle'
    },
    scopedSlots: {
      customRender: 'infoType'
    }
  }],
  columns_no_dispose: [{
    title: '用户信息',
    scopedSlots: {
      customRender: 'userInfo'
    }
  }, {
    slots: {
      title: 'recentInteractionTypesTitle'
    },
    scopedSlots: {
      customRender: 'recentInteractionTypes'
    }
  }, {
    title: '评论内容',
    dataIndex: 'liveInteractContents',
    scopedSlots: {
      customRender: 'liveInteractContents'
    }
  }, {
    title: '用户等待时间',
    dataIndex: 'userWaitTime',
    scopedSlots: {
      customRender: 'userWaitTime'
    }
  }, {
    title: '命中策略',
    dataIndex: 'sceneName',
    scopedSlots: {
      customRender: 'sceneName'
    }
  }, {
    title: '状态',
    dataIndex: 'autoReplyStatus',
    scopedSlots: {
      customRender: 'autoReplyStatus'
    }
  }, {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }]
};
exports.default = _default;