var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-button",
    {
      attrs: {
        icon: _vm.icon,
        size: _vm.size,
        type: _vm.type,
        loading: _vm.loading,
        shape: _vm.shape,
        disabled: _vm.disabled,
      },
      on: { click: _vm.onClickBtn },
    },
    [_vm._v(" " + _vm._s(_vm.title) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }