var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { class: ["clip", _vm.per > 50 ? "auto" : ""] }, [
      _c("div", {
        staticClass: "left",
        style: Object.assign({}, _vm.ringStyle, _vm.leftStyle),
      }),
      _c("div", {
        class: ["right", _vm.per > 50 ? "" : "width-none"],
        style: _vm.ringStyle,
      }),
    ]),
    _c("div", { staticClass: "num" }, [
      _c("div", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "22px",
            "font-weight": "500",
            "padding-top": "5px",
          },
        },
        [_vm._v(_vm._s(_vm.per) + "%")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }