"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.values");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _video_keyword_reply = _interopRequireDefault(require("@/api/video_keyword_reply"));
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      visible: false,
      isSaving: false,
      keyWordType: 3,
      listData: {}
    };
  },
  methods: {
    openDrawer: function openDrawer(listData, keyWordType) {
      this.visible = true;
      this.listData = _lodash.default.cloneDeep(listData);
      this.keyWordType = keyWordType;
      for (var key in this.listData) {
        if (Object.hasOwnProperty.call(this.listData, key)) {
          this.listData[key] = this.listData[key].map(function (item) {
            return {
              name: item.keyWordNames.join("，"),
              ruleId: item.id
            };
          }) || [];
        }
      }
      console.log(this.listData);
    },
    close: function close() {
      this.visible = false;
    },
    save: function save() {
      var _this = this;
      this.isSaving = true;
      var data = Object.values(this.listData).map(function (list) {
        return list.map(function (item, index) {
          return {
            priority: list.length - index,
            ruleId: item.ruleId
          };
        });
      }).flat();
      // const data = this.list.map((item, index) => ({
      //   priority: this.list.length - index,
      //   ruleId: item.ruleId,
      // }));
      _video_keyword_reply.default.sortKeyWordReplyRule(data).then(function (res) {
        if (res.code === 200) {
          _this.$message.success("保存成功");
          _this.close();
          _this.$emit("success");
        }
      }).finally(function () {
        return _this.isSaving = false;
      });
    }
  }
};
exports.default = _default;