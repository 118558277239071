var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        title: "通话详情",
        placement: "right",
        visible: _vm.visible,
        loading: _vm.isSaving,
        maskClosable: false,
        width: 580,
        hasPadding: false,
        cancelText: "取消",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-column",
          staticStyle: { height: "100%", overflow: "hidden" },
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _vm._v(" 联系方式： "),
              _c(
                "a-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    "show-search": "",
                    "filter-option": _vm.filterOption,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.getCallRecordDetail(true)
                    },
                  },
                  model: {
                    value: _vm.currentContact,
                    callback: function ($$v) {
                      _vm.currentContact = $$v
                    },
                    expression: "currentContact",
                  },
                },
                _vm._l(_vm.phoneNumberList, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item } },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-spin",
            { staticClass: "time-line-list", attrs: { spinning: _vm.loading } },
            [
              _c(
                "a-timeline",
                _vm._l(_vm.followRecordList, function (item, index) {
                  return _c(
                    "a-timeline-item",
                    {
                      key: index,
                      attrs: { color: _vm.getColor(item.callStatus) },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between",
                        },
                        [
                          _c("div", { staticClass: "call-name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (item.isFhlUser
                                    ? _vm.userName
                                    : item.dyNickName) || "[抖音用户]"
                                ) +
                                " 打给 " +
                                _vm._s(
                                  (item.isFhlUser
                                    ? item.dyNickName
                                    : _vm.userName) || "[抖音用户]"
                                ) +
                                " "
                            ),
                          ]),
                          item.callStatus === 1
                            ? _c("div", { staticClass: "success-text" }, [
                                _vm._v("接通并回应"),
                              ])
                            : _vm._e(),
                          item.callStatus === 2
                            ? _c("div", { staticClass: "fail-text" }, [
                                _vm._v("未接通"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "grey-text" }, [
                        _vm._v(
                          "联系方式：" +
                            _vm._s(
                              item.isFhlUser
                                ? item.calleePhone
                                : item.callerPhone
                            )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-items-center audio-card",
                        },
                        [
                          item.recordingUrl
                            ? [
                                _vm.playingIndex !== index ||
                                (_vm.playingIndex === index &&
                                  _vm.playStatus === "pause")
                                  ? _c("a-icon", {
                                      staticClass: "play-btn",
                                      attrs: {
                                        type: "play-circle",
                                        theme: "filled",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.start(index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.playingIndex === index &&
                                _vm.playStatus === "play"
                                  ? _c("a-icon", {
                                      staticClass: "play-btn",
                                      attrs: {
                                        type: "pause-circle",
                                        theme: "filled",
                                      },
                                      on: { click: _vm.pause },
                                    })
                                  : _vm._e(),
                                _vm.playingIndex !== index
                                  ? _c("span", { staticClass: "label-text" }, [
                                      _vm._v(
                                        "通话时长 " +
                                          _vm._s(
                                            _vm.convertToTime(item.callDuration)
                                          )
                                      ),
                                    ])
                                  : _c("span", { staticClass: "label-text" }, [
                                      _vm._v("通话录音"),
                                    ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-fill" },
                                  [
                                    _vm.isPlaying && _vm.playingIndex === index
                                      ? _c("AudioPlayer", {
                                          ref: "audio",
                                          refInFor: true,
                                          attrs: { fileUrl: item.recordingUrl },
                                          on: {
                                            play: function ($event) {
                                              _vm.playStatus = "play"
                                            },
                                            pause: function ($event) {
                                              _vm.playStatus = "pause"
                                            },
                                            ended: function ($event) {
                                              _vm.playingIndex = -1
                                              _vm.playStatus = "pause"
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _c("span", { staticClass: "label-text" }, [
                                _vm._v("暂无通话录音"),
                              ]),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "grey-text" }, [
                        _vm._v("通话日期：" + _vm._s(item.ctime)),
                      ]),
                      item.callStatus === 1 && !item.aiComment
                        ? _c(
                            "div",
                            {
                              staticClass: "grey-text",
                              staticStyle: { "padding-top": "0" },
                            },
                            [_vm._v(" AI点评生成中... ")]
                          )
                        : _vm._e(),
                      item.callStatus === 1 && item.aiComment
                        ? _c(
                            "a-collapse",
                            {
                              attrs: {
                                bordered: false,
                                expandIconPosition: "right",
                              },
                            },
                            [
                              _c(
                                "a-collapse-panel",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [_vm._v(" AI点评 ")]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "ai-content" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("AI点评"),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _vm._v(" 销售在通话中存在以下问题:"),
                                        _c("br"),
                                        _vm._l(
                                          item.aiComment.aiCommentList,
                                          function (aiItem) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(aiItem.level) +
                                                  "." +
                                                  _vm._s(aiItem.message)
                                              ),
                                              _c("br", { key: aiItem.id }),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("优秀案例"),
                                    ]),
                                    _c(
                                      "div",
                                      _vm._l(
                                        item.aiComment.caseList,
                                        function (caseItem, caseIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: caseIndex,
                                              staticClass:
                                                "d-flex flex-row align-items-center audio-card",
                                              staticStyle: {
                                                background: "#f0f0f0",
                                              },
                                            },
                                            [
                                              item.recordingUrl
                                                ? [
                                                    _vm.playingIndex !==
                                                      index + "." + caseIndex ||
                                                    (_vm.playingIndex ===
                                                      index + "." + caseIndex &&
                                                      _vm.playStatus ===
                                                        "pause")
                                                      ? _c("a-icon", {
                                                          staticClass:
                                                            "play-btn",
                                                          attrs: {
                                                            type: "play-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.start(
                                                                index +
                                                                  "." +
                                                                  caseIndex
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm.playingIndex ===
                                                      index + "." + caseIndex &&
                                                    _vm.playStatus === "play"
                                                      ? _c("a-icon", {
                                                          staticClass:
                                                            "play-btn",
                                                          attrs: {
                                                            type: "pause-circle",
                                                            theme: "filled",
                                                          },
                                                          on: {
                                                            click: _vm.pause,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm.playingIndex !==
                                                    index + "." + caseIndex
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "案例" +
                                                                _vm._s(
                                                                  caseIndex + 1
                                                                )
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label-text",
                                                          },
                                                          [_vm._v("通话录音")]
                                                        ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-fill",
                                                      },
                                                      [
                                                        _vm.isPlaying &&
                                                        _vm.playingIndex ===
                                                          index +
                                                            "." +
                                                            caseIndex
                                                          ? _c("AudioPlayer", {
                                                              ref: "audio",
                                                              refInFor: true,
                                                              attrs: {
                                                                fileUrl:
                                                                  caseItem.recordUrl,
                                                              },
                                                              on: {
                                                                play: function (
                                                                  $event
                                                                ) {
                                                                  _vm.playStatus =
                                                                    "play"
                                                                },
                                                                pause:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.playStatus =
                                                                      "pause"
                                                                  },
                                                                ended:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.playingIndex =
                                                                      -1
                                                                    _vm.playStatus =
                                                                      "pause"
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass: "label-text",
                                                    },
                                                    [_vm._v("暂无通话录音")]
                                                  ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("a-pagination", {
                attrs: {
                  "show-size-changer": "",
                  "page-size": _vm.form.size,
                  total: _vm.total,
                },
                on: {
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.form, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.form, "size", $event)
                  },
                  change: _vm.onPageChange,
                  showSizeChange: _vm.onPageSizeChange,
                },
                model: {
                  value: _vm.form.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "page", $$v)
                  },
                  expression: "form.page",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }