"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _live_comment = _interopRequireDefault(require("@/api/live_comment"));
var _common = _interopRequireDefault(require("@/api/common"));
var _emoji_config = require("@/components/EmojiPicker/emoji_config");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading_dw: false,
      btn_loading: false,
      form: {
        isHitPerform: true,
        sceneId: "",
        sceneName: "",
        sceneType: 2,
        wordResponseList: [],
        threeDayHitStrategyCommentNum: 0
      }
    };
  },
  computed: {
    responseContent: function responseContent() {
      return this.form.wordResponseList.map(function (item) {
        return item.message;
      }).join("\n");
    }
  },
  methods: {
    /**
     * 获取策略/规则对应话术内容
     * @param {string} sceneId
     */
    getWordResponseBySceneType: function getWordResponseBySceneType(sceneId) {
      var _this = this;
      this.loading_dw = true;
      return _live_comment.default.getWordResponseBySceneType({
        sceneId: sceneId,
        sceneType: 2
      }).then(function (res) {
        if (res.code === 200) {
          _this.form.wordResponseList = res.data.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              showMessage: (0, _emoji_config.replaceEmoji)(item.message, "t2e")
            });
          });

          // 如果不为空, 取第一个元素获取命中评论条数
          if (_this.form.wordResponseList.length > 0) {
            _this.form.threeDayHitStrategyCommentNum = _this.form.wordResponseList[0].threeDayHitStrategyCommentNum;
          }
        }
      }).finally(function () {
        return _this.loading_dw = false;
      });
    },
    saveEditMessage: function saveEditMessage() {
      var _this2 = this;
      this.$emit("startUseing", this.form.sceneName);
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          var data = _lodash.default.cloneDeep(_this2.form);
          data.isHitPerform = data.isHitPerform ? 1 : 0;
          _live_comment.default.useAutoReply(data).then(function (res) {
            if (res.code == 200) {
              _this2.$message.success("保存成功！");
              _this2.$emit("closeDrawer");
              _this2.$emit("resetTable");
            } else {
              _this2.$message.error(res.message);
            }
          }).finally(function () {
            _this2.btn_loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 获取策略详情
     * @param {string} id 规则id
     * @param {string} inamed 规则名称
     */
    liveAutoReplyModel: function liveAutoReplyModel(id, name) {
      this.form.sceneId = id;
      this.form.sceneName = name;
      this.getWordResponseBySceneType(id);
    },
    onClose: function onClose() {
      this.$emit("closeDrawer");
    },
    goRouter: function goRouter() {
      this.$router.push({
        path: "/live_new_comment/comment_smart_reply",
        query: {
          sceneType: 2,
          sceneId: this.form.sceneId
        }
      });
    }
  }
};
exports.default = _default;