"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    title: {
      type: String,
      default: ""
    },
    per: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "#6295f9"
    }
  },
  data: function data() {
    return {
      ringStyle: {
        border: "30px solid #6295f9"
      },
      leftStyle: {
        transform: "rotate(0deg)"
      }
    };
  },
  watch: {
    color: function color(val) {
      if (val) {
        this.ringStyle.border = "30px solid ".concat(val);
      }
    },
    per: function per(val) {
      if (val) {
        this.leftStyle.transform = "rotate(".concat(3.6 * this.per, "deg)");
      }
    }
  },
  created: function created() {
    if (this.color) {
      this.ringStyle.border = "30px solid ".concat(this.color);
    }
    if (this.per) {
      this.leftStyle.transform = "rotate(".concat(3.6 * this.per, "deg)");
    }
  },
  methods: {}
};
exports.default = _default;