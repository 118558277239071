var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intelligence-login" }, [
    _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: {
          src:
            _vm.hzOssHost +
            "/manufactor/images/" +
            _vm.userListData.manufactor_id +
            ".png",
          alt: "",
        },
      }),
      _c("div", [
        _c("h5", [
          _vm._v(
            "Hi，" + _vm._s(_vm.nickName) + "，点击【对应入口】进入AI直播助手吧"
          ),
        ]),
        _vm._m(0),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _vm.userListData && _vm.userListData.douyinlist.length > 0
        ? _c(
            "div",
            { staticStyle: { background: "#150b19" } },
            [
              _c("img", {
                attrs: { src: require("@/assets/dev/douyin.png"), alt: "" },
              }),
              _c("div", { staticClass: "info" }, [
                _c("img", {
                  attrs: {
                    src: _vm.userListData.douyinlist[0].avatar,
                    alt: "",
                  },
                }),
                _c("div", { staticStyle: { color: "#fff" } }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.userListData.douyinlist[0].nickname)),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.userListData.douyinlist[0].code)),
                  ]),
                ]),
              ]),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/intelligence/play",
                      query: {
                        type: "douyin",
                        author_id: _vm.userListData.douyinlist[0].author_id,
                        room_id: _vm.userListData.douyinlist[0].room_id,
                      },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "btn", attrs: { type: "primary" } },
                    [_vm._v("抖音AI助手")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.userListData && _vm.userListData.kuaishoulist.length > 0
        ? _c(
            "div",
            { staticStyle: { background: "#fff" } },
            [
              _c("img", {
                attrs: { src: require("@/assets/dev/kuaishou.png"), alt: "" },
              }),
              _c("div", { staticClass: "info" }, [
                _c("img", {
                  attrs: {
                    src: _vm.userListData.kuaishoulist[0].avatar,
                    alt: "",
                  },
                }),
                _c("div", [
                  _c("p", { staticStyle: {} }, [
                    _vm._v(_vm._s(_vm.userListData.kuaishoulist[0].nickname)),
                  ]),
                  _c("p", { staticStyle: {} }, [
                    _vm._v(_vm._s(_vm.userListData.kuaishoulist[0].code)),
                  ]),
                ]),
              ]),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/intelligence/play",
                      query: {
                        type: "kuaishou",
                        author_id: _vm.userListData.kuaishoulist[0].author_id,
                        room_id: _vm.userListData.kuaishoulist[0].room_id,
                      },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "btn", attrs: { type: "primary" } },
                    [_vm._v("快手AI助手")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 显示账号为绑定直播平台ID？—— 请联系系统管理人员在 "),
      _c("span", { staticStyle: { color: "#4b53fc" } }, [
        _vm._v("【系统配置】 ——【门店主播绑定】中完成绑定操作"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }