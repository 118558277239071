"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ["idName", "itemData"],
  data: function data() {
    return {
      isHaveLive: true,
      defultImg: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg',
      defultCover: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg'
    };
  },
  watch: {
    itemData: function itemData(val) {
      this.setChart();
    }
  },
  computed: {},
  mounted: function mounted() {
    this.setChart();
  },
  methods: {
    setChart: function setChart() {
      var xData = [];
      var yData = [];
      for (var key in this.itemData.user_counts) {
        xData.push(key);
        yData.push(this.itemData.user_counts[key]);
      }
      console.log('setChart', this.itemData);
      this.initChart(xData, yData);
    },
    initChart: function initChart(xData, yData) {
      var _this = this;
      this.$nextTick(function () {
        _this.chart = _echarts.default.init(document.getElementById(_this.idName));
        _this.chart.setOption({
          backgroundColor: '#FFF',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            top: '5%',
            left: '0%',
            right: '0%',
            bottom: '2%',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            show: false,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8c8c8c"
              }
            },
            data: xData
          }],
          yAxis: [(0, _defineProperty2.default)({
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: "#e5e5e5"
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: "#e5e5e5"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8c8c8c"
              }
            },
            axisTick: {
              show: false
            }
          }, "axisLabel", {
            show: false
          })],
          series: [{
            name: '会员',
            type: 'line',
            smooth: true,
            // 曲线
            showSymbol: false,
            // 鼠标移入才显示圆点
            color: ['#b4e4a2'],
            areaStyle: {
              normal: {
                // 线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(22, 205, 22, 0.5)'
                }, {
                  offset: 1,
                  color: 'rgba(249, 253, 250, 0)'
                }], false),
                shadowColor: 'rgba(118,168,248, 0.9)',
                // 阴影颜色
                shadowBlur: 20 // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },

            data: yData
          }]
        });
        window.onresize = _this.chart.resize;
      });
    },
    openDetail: function openDetail(detailData) {
      if (detailData.status == 1) {
        this.$message({
          message: "请在监控完成的2小时后查看数据",
          type: "warning"
        });
      } else {
        this.$emit('openDetail', detailData);
      }
    }
  }
};
exports.default = _default;