"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import _base_https from '@/api/base_api';
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    previewSrc: {
      type: String,
      default: ''
    },
    isAutoplay: {
      type: Boolean,
      default: false
    },
    isAuth: {
      type: Boolean,
      default: true
    },
    previewType: {
      type: String,
      default: ''
    }
  },
  watch: {
    previewSrc: {
      handler: function handler(res) {
        if (res) {
          // 之前的逻辑
          if (!this.previewType) {
            if ((0, _utils.fileSuffixTypeUtil)(res, this.video_suffix_list)) {
              this.is_video = true;
              if (this.isAuth) {
                this.getAuthUrl(res);
              } else {
                this.video_preview_src = res;
              }
            } else {
              this.is_video = false;
            }
          } else {
            if (this.previewType === 'video') {
              if (this.isAuth) {
                this.getAuthUrl(res);
              } else {
                this.video_preview_src = res;
              }
              this.is_video = true;
            } else {
              this.video_preview_src = '';
              this.is_video = false;
            }
          }
        }
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      is_video: false,
      video_suffix_list: ['mp4', 'mov', 'm4v', 'flv'],
      video_preview_src: ''
    };
  },
  methods: {
    handlePreviewCancel: function handlePreviewCancel() {
      this.video_preview_src = '';
      this.$emit('cancel');
    },
    getAuthUrl: function getAuthUrl(url) {
      this.video_preview_src = url;

      // _base_https.getAuthUrl({ resource_url: url }).then((res) => {
      //   if (res.code === 200) {
      //     this.video_preview_src = res.data;
      //   } else {
      //     this.video_preview_src = url;
      //   }
      // });
    }
  }
};
exports.default = _default;