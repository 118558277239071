var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video_player_content" }, [
      _c("h3", [_vm._v("拉流播放器测试")]),
      _c("hr"),
      _c("h4", [_vm._v("xgplayer")]),
      _c("div", { staticClass: "play_content" }, [
        _c("div", { attrs: { id: "live-player" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }