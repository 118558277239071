var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        visible: _vm.visible,
        title: "查看回复",
        width: "620",
        maskClosable: false,
      },
      on: { close: _vm.closeDrawer },
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _vm.form.author_name
            ? _c(
                "a-form-model-item",
                { attrs: { label: "主播昵称", prop: "author_name" } },
                [_vm._v(" " + _vm._s(_vm.form.author_name) + " ")]
              )
            : _vm._e(),
          _vm.form.strategy_name
            ? _c(
                "a-form-model-item",
                { attrs: { label: "策略名称", prop: "strategy_name" } },
                [_vm._v(" " + _vm._s(_vm.form.strategy_name) + " ")]
              )
            : _vm._e(),
          _vm.form.key_word_group
            ? _c(
                "a-form-model-item",
                { attrs: { label: "关键词组名", prop: "key_word_group" } },
                [_vm._v(" " + _vm._s(_vm.form.key_word_group) + " ")]
              )
            : _vm._e(),
          _vm.form.key_word_group
            ? _c("a-form-model-item", { attrs: { label: "关键词" } }, [
                _c(
                  "div",
                  { staticClass: "keyword-list" },
                  [
                    _vm._l(
                      _vm.form.key_word_details_vos,
                      function (word, index) {
                        return [
                          _c(
                            "div",
                            { key: index, staticClass: "keyword-item" },
                            [
                              _c("div", [_vm._v(_vm._s(word.details_name))]),
                              _c(
                                "div",
                                [
                                  _c("a-tag", [
                                    _vm._v(
                                      _vm._s(_vm.conditionMap[word.match_type])
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.form.key_word_details_vos.length > 1 &&
                          index !== _vm.form.key_word_details_vos.length - 1
                            ? _c("div", { key: "or" + index }, [_vm._v(" 或 ")])
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.form.listTypeName
            ? _c(
                "a-form-model-item",
                { attrs: { label: "类型", prop: "strategy_name" } },
                [_vm._v(" " + _vm._s(_vm.form.listTypeName) + " ")]
              )
            : _vm._e(),
          _vm._l(_vm.form.wordResponseList, function (word, index) {
            return _c(
              "a-form-model-item",
              {
                key: word.responseId,
                ref: "keyword" + index,
                refInFor: true,
                staticClass: "keyword-model-item",
                attrs: {
                  label: index === 0 ? "回复内容" : " ",
                  colon: index === 0,
                  prop: "wordResponseList." + index + ".message",
                  disabled: true,
                },
              },
              [
                index === 0
                  ? _c("div", { staticClass: "normal-tip" }, [
                      _vm._v(
                        "皮皮拉客精选的销冠话术，用户命中策略时随机发送1条"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "word-item" },
                  [
                    _c("a-textarea", {
                      staticClass: "keyword-input",
                      attrs: { rows: "5", "max-length": 300, disabled: true },
                      model: {
                        value: word.message,
                        callback: function ($$v) {
                          _vm.$set(
                            word,
                            "message",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "word.message",
                      },
                    }),
                    _c("div", { staticClass: "right-operation" }, [
                      _c(
                        "span",
                        {
                          staticClass: "tips_count",
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            "pointer-events": "none",
                          },
                        },
                        [_vm._v(_vm._s(word.message.length) + "/300")]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm.form.wordResponseList.length === 0
            ? _c("a-form-model-item", { attrs: { label: "回复内容" } }, [
                _c("div", { staticClass: "normal-tip" }, [
                  _vm._v("皮皮拉客精选的销冠话术，用户命中策略时随机发送1条"),
                ]),
                _c("div", { staticClass: "word-item" }, [
                  _c(
                    "span",
                    {
                      staticClass: "normal-tip",
                      staticStyle: { "margin-right": "10px" },
                    },
                    [_vm._v("暂无回复内容")]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }