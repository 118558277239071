var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "启用自动回复策略",
        placement: "right",
        visible: _vm.straegy_visible,
        maskClosable: false,
        width: 540,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-spin",
        {
          attrs: { spinning: _vm.loading_dw, tip: "加载中...", size: "large" },
        },
        [
          _c("p", [
            _vm._v(
              "启用针对新关注用户的触达策略，对之后命中的用户自动回复私信。"
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex_box" },
            [
              _c("p", { staticClass: "reply_tips" }, [
                _vm._v(
                  " 对命中" +
                    _vm._s(_vm.edit_data.sceneName) +
                    "策略的用户（已回复的除外），回复以下内容 "
                ),
              ]),
              _c(
                "a-tooltip",
                {
                  attrs: {
                    placement: "topRight",
                    title: "可点击一键输入，复用该策略已配置的回复内容",
                  },
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openQuickReply },
                    },
                    [_vm._v("一键输入")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content_box" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "message_content" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "请输入回复内容",
                          rows: 5,
                          maxLength: 300,
                        },
                        model: {
                          value: _vm.form.message_content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "message_content", $$v)
                          },
                          expression: "form.message_content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "message_content" }, [
                _vm._v(_vm._s(_vm.form.message_content.length) + "/300"),
              ]),
            ],
            1
          ),
          _c(
            "p",
            [
              _vm._v(" 若需要了解策略详细信息，请进入 "),
              _c(
                "a-button",
                {
                  staticStyle: { padding: "0" },
                  attrs: { type: "link" },
                  on: { click: _vm.goRouter },
                },
                [_vm._v("策略页面")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "tips_box" }, [
        _vm._v(
          " 注意：抖音限制最多给陌生人主动发送3条私信，超过后用户将无法收到消息；若用户主动回复，可在用户回复的48小时内，再发送6条私信 "
        ),
      ]),
      _c(
        "div",
        {
          style: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
          },
        },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "8px" },
              on: { click: _vm.onClose },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: { click: _vm.saveEditMessage },
            },
            [_vm._v(" 启用 ")]
          ),
        ],
        1
      ),
      _c("quick-reply", {
        ref: "quickReply",
        attrs: { quick_modal: _vm.quick_modal },
        on: { closeModal: _vm.closeModal, sendOneConent: _vm.sendOneConent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }