"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.match");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: function _default() {
        return {
          width: "874px",
          height: "300px"
        };
      }
    },
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    nameTips: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    styleObject: function styleObject() {
      var obj = this.boxStyle;
      for (var attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";
        obj[attr] = unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData: function chartData() {
      this.initChart();
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var colorList = ["#04EBEB", "#2A90F4"];
      var borderColor = ["rgba(4,235,235,0.1)", "rgba(42,144,244,0.1)"];
      this.chart = _echarts.default.init(document.getElementById(this.id));
      var arr = this.chartData.name;
      var series = [];
      for (var key in arr) {
        // let strings = key.toString()
        series.push({
          name: arr[key],
          type: "line",
          data: _lodash.default.get(this, "chartData.yAxis[".concat(key, "]"), []).slice(-7),
          // this.chartData.yAxis[key].slice(-7),
          symbolSize: 1,
          symbol: "circle",
          smooth: true,
          yAxisIndex: 1,
          // 单个图表两个Y轴默认为零
          showSymbol: false,
          lineStyle: {
            width: 1,
            color: colorList[key],
            shadowColor: borderColor[key],
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[key],
              borderColor: borderColor[key]
            }
          }
        });
      }
      this.chart.setOption({
        // backgroundColor: "#fff",
        legend: {
          show: false
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c"
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)"
        },
        grid: {
          top: 0,
          bottom: "20px",
          left: "14px",
          right: "44px",
          containLabel: false
        },
        xAxis: [{
          type: "category",
          data: _lodash.default.get(this, "chartData.xAxis", []).slice(-7),
          axisLine: {
            lineStyle: {
              color: "#5A5B64",
              opacity: 0.4
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#87898F",
              opacity: 0.6
            }
            //   margin: 15
          },

          axisPointer: {
            label: {
              padding: [11, 5, 7],
              backgroundColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: "#fff" // 0% 处的颜色
                }, {
                  offset: 0.9,
                  color: "#fff" // 0% 处的颜色
                }, {
                  offset: 0.9,
                  color: "#33c0cd" // 0% 处的颜色
                }, {
                  offset: 1,
                  color: "#33c0cd" // 100% 处的颜色
                }],

                global: false // 缺省为 false
              }
            }
          },

          boundaryGap: false
        }],
        yAxis: [{
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#5A5B64",
              opacity: 0.4
            }
          },
          axisLabel: {
            textStyle: {
              color: "#87898F",
              opacity: 0.6
            }
          },
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          position: "right",
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "#87898F",
              opacity: 0.6
            },
            formatter: "{value}"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#5A5B64",
              opacity: 0.4
            }
          },
          splitLine: {
            show: false
          }
        }],
        series: series
      });
    }
  }
};
exports.default = _default2;