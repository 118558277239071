"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    list: [],
    userInformation: {},
    dyMessage: {
      day_limit: 0,
      hour_limit: 0,
      used_day_limit: 0,
      used_hour_limit: 0,
      mutual_attention_limit: 0
    },
    mediaList: [],
    currentMedia: null,
    isOpenSmartReply: false
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_LIST: function SET_LIST(state, list) {
    state.list = list;
  },
  SET_USERINFORMATION: function SET_USERINFORMATION(state, res) {
    state.userInformation = res.data;
  },
  SET_MEDIA_LIST: function SET_MEDIA_LIST(state, res) {
    state.mediaList = res.data;
  },
  SET_CURRENT_MEDIA: function SET_CURRENT_MEDIA(state, res) {
    state.currentMedia = res.data;
  },
  SET_DYMESSAGE: function SET_DYMESSAGE(state, res) {
    state.dyMessage = res.data;
  },
  SET_IS_OPEN_SMART_REPLY: function SET_IS_OPEN_SMART_REPLY(state, res) {
    state.isOpenSmartReply = res;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password,
      fingerprint = userInfo.fingerprint;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password,
        eFingerprint: fingerprint
      }).then(function (response) {
        var data = response.data;
        console.log(data);
        if (data.code == 200) {
          if (data.data.token) {
            // getUserInfo(data.data.token_head + data.data.token).then((res) => {
            //   console.log(res);
            // });
            commit('SET_TOKEN', data.data.token_head + data.data.token);
            (0, _auth.setToken)(data.data.token_head + data.data.token);
            resolve(response);
          } else {
            var msg = 'modules/user.js error: token is ' + data.data.token;
            var errObj = {
              data: {
                errcode: -5001,
                errmsg: msg
              }
            };
            reject(errObj);
          }
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  loginWithToken: function loginWithToken(_ref2, token) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      var tokenStr = "Bearer ".concat(token);
      commit('SET_TOKEN', tokenStr);
      (0, _auth.setToken)(tokenStr);
      (0, _user.getUserInfo)().then(function (res) {
        resolve(res);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  checkToken: function checkToken(_ref3, manufactor_id) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.checkToken)({
        manufactor_id: manufactor_id
      }).then(function (response) {
        var data = response.data;
        console.log(data);
        if (data.token) {
          commit('SET_TOKEN', data.token);
          (0, _auth.setToken)(data.token);
          resolve();
        } else {
          var err = 'token is ' + data.token;
          reject(err);
          throw new Error(err);
        }
      }).catch(function (error) {
        // console.error(error)
        reject(error);
      });
    });
  },
  // get menu
  getInfo: function getInfo(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.Authorization).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }

        // 判断菜单是否拥有套路对话权限
        commit('SET_IS_OPEN_SMART_REPLY', false);
        data.data.forEach(function (item) {
          if (item.children) {
            item.children.forEach(function (child) {
              if (child.name === 'routine_conversation') {
                commit('SET_IS_OPEN_SMART_REPLY', true);
              }
            });
          }
        });

        // const { name, avatar } = data.data
        // commit('SET_NAME', name)
        // commit('SET_AVATAR', avatar)
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getUserInfo: function getUserInfo(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getUserInfo)().then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var _data$data = data.data,
          username = _data$data.username,
          avatarUrl = _data$data.avatarUrl,
          id = _data$data.id;
        commit('SET_NAME', username);
        commit('SET_AVATAR', avatarUrl);
        (0, _auth.setUserId)(id);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get manufactor list
  getManufactorList: function getManufactorList(_ref6) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getManufactorList)(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        commit('SET_LIST', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref7) {
    var _this = this;
    var commit = _ref7.commit,
      state = _ref7.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)().then(function (res) {
        if (res.data.code == '200') {
          (0, _auth.removeToken)(); // must remove  token  first
          (0, _router.resetRouter)();
          commit('RESET_STATE');
          resolve();
        } else {
          _this.$message.error('退出失败，请联系管理员');
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },
  getAccountMessage: function getAccountMessage(_ref9) {
    var commit = _ref9.commit;
    return (0, _user.getAccountMessage)().then(function (res) {
      window.localStorage.setItem('userInformation', JSON.stringify(res.data.data));
      commit('SET_USERINFORMATION', res.data);
    });
  },
  getDyLimit: function getDyLimit(_ref10) {
    var commit = _ref10.commit;
    return (0, _user.getDyLimit)().then(function (res) {
      commit('SET_DYMESSAGE', res.data);
    });
  },
  getMediaList: function getMediaList(_ref11) {
    var commit = _ref11.commit;
    return (0, _user.getMediaList)().then(function (res) {
      window.localStorage.setItem('mediaList', JSON.stringify(res.data.data));
      commit('SET_MEDIA_LIST', res.data);
    });
  },
  getCurrentMedia: function getCurrentMedia(_ref12) {
    var commit = _ref12.commit;
    return (0, _user.getCurrentMedia)().then(function (res) {
      commit('SET_CURRENT_MEDIA', res.data);
    });
  },
  toggleMedia: function toggleMedia(_ref13, params) {
    var commit = _ref13.commit;
    return (0, _user.toggleMedia)(params).then(function (res) {
      // commit('SET_CURRENT_MEDIA', res.data);
    });
  },
  phoneLogin: function phoneLogin(_ref14, userInfo) {
    var commit = _ref14.commit;
    var phone = userInfo.phone,
      authCode = userInfo.authCode;
    return new Promise(function (resolve, reject) {
      (0, _user.useAuthCodeLogin)({
        clientId: 'fhl',
        clientSecret: 123456,
        grantType: 'verify_code',
        mobile: phone,
        verifyCode: authCode,
        isMobile: true
      }).then(function (response) {
        var data = response.data;
        console.log(data);
        if (data.code == 200) {
          if (data.data.token) {
            (0, _user.getUserInfo)(data.data.token_head + data.data.token).then(function (res) {
              console.log(res);
            });
            commit('SET_TOKEN', data.data.token_head + data.data.token);
            (0, _auth.setToken)(data.data.token_head + data.data.token);
            resolve(response);
          } else {
            var msg = 'modules/user.js error: token is ' + data.data.token;
            var errObj = {
              data: {
                errcode: -5001,
                errmsg: msg
              }
            };
            reject(errObj);
          }
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  loginFeishuAction: function loginFeishuAction(_ref15, userInfo) {
    var commit = _ref15.commit;
    var username = userInfo.username,
      password = userInfo.password,
      grant_type = userInfo.grant_type,
      code = userInfo.code,
      oauth_config_id = userInfo.oauth_config_id,
      feishu_ticket = userInfo.feishu_ticket,
      phone = userInfo.phone,
      authCode = userInfo.authCode,
      is_mobile = userInfo.is_mobile;
    console.log({
      userInfo: userInfo
    });
    return new Promise(function (resolve, reject) {
      (0, _user.feishuLogin)({
        username: username && username.trim(),
        password: password,
        grant_type: grant_type,
        feishu_ticket: feishu_ticket,
        oauth_config_id: oauth_config_id,
        code: code,
        mobile: phone,
        verify_code: authCode,
        is_mobile: is_mobile
      }).then(function (response) {
        var data = response.data;
        console.log(data);
        if (data.code == 200) {
          if (data.data.token) {
            (0, _user.getUserInfo)(data.data.token_head + data.data.token).then(function (res) {
              console.log(res);
            });
            commit('SET_TOKEN', data.data.token_head + data.data.token);
            (0, _auth.setToken)(data.data.token_head + data.data.token);
            resolve(response);
          } else {
            var msg = 'modules/user.js error: token is ' + data.data.token;
            var errObj = {
              data: {
                errcode: -5001,
                errmsg: msg
              }
            };
            reject(errObj);
          }
        } else {
          resolve(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;