"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _live_stream = _interopRequireDefault(require("@/api/live_stream"));
var _common = _interopRequireDefault(require("@/utils/common"));
var _filters = _interopRequireDefault(require("@/filters"));
var _2 = _interopRequireDefault(require(".."));
var _lodash = _interopRequireDefault(require("lodash"));
var getLiveRecord = _live_stream.default.getLiveRecord,
  _getLiveCalender = _live_stream.default.getLiveCalender,
  _getAuthourMessage = _live_stream.default.getAuthourMessage;
var _default = {
  namespaced: true,
  state: {
    liveRecord: {},
    liveRecordList: [],
    tableLoading: false,
    liveCalender: {},
    begin_time: '',
    end_time: '',
    rotateActive: false,
    selectDate: 31,
    calenderArr: [],
    authorId: '',
    athourMessage: {}
  },
  mutations: {
    changeState: function changeState(state, payload) {
      var data = payload.data,
        name = payload.name;
      state[name] = data;
    },
    // 直播列表筛选
    CHANGE_LIVE_LIST: function CHANGE_LIVE_LIST(state, payload) {
      // filterDate 为0时默认获取所有直播列表数据，其他为筛选当天直播数据
      var obj = state.liveCalender;
      var arr = [];
      var timeInterval = _common.default.timeInterval;
      var changeNum = _filters.default.changeNum;
      var filterDate = payload.filterDate;
      if (filterDate) {
        for (var key in obj) {
          var date = key.slice(8, 9) != 0 ? key.slice(8) : key.slice(9);
          if (date == filterDate) {
            var dateArr = _lodash.default.cloneDeep(obj[key]);
            for (var i = 0; i < dateArr.length; i++) {
              dateArr[i].total_user = changeNum(dateArr[i].total_user);
              if (dateArr[i].statistics) {
                dateArr[i].user_count = changeNum(dateArr[i].statistics.max_user_count);
              } else {
                dateArr[i].user_count = changeNum(dateArr[i].user_count);
              }
              dateArr[i].follow_count = changeNum(dateArr[i].follow_count);
              dateArr[i].total_interactive_count = changeNum(dateArr[i].total_interactive_count);
              if (dateArr[i].room_status !== 2) {
                dateArr[i].finish_time = timeInterval(dateArr[i].publish_time, dateArr[i].finish_time);
              }
              arr.push(dateArr[i]);
            }
          }
        }
      } else {
        for (var _key in obj) {
          var _dateArr = _lodash.default.cloneDeep(obj[_key]);
          for (var _i = 0; _i < _dateArr.length; _i++) {
            _dateArr[_i].total_user = changeNum(_dateArr[_i].total_user);
            if (_dateArr[_i].statistics) {
              _dateArr[_i].user_count = changeNum(_dateArr[_i].statistics.max_user_count);
            } else {
              _dateArr[_i].user_count = changeNum(_dateArr[_i].user_count);
            }
            _dateArr[_i].follow_count = changeNum(_dateArr[_i].follow_count);
            _dateArr[_i].total_interactive_count = changeNum(_dateArr[_i].total_interactive_count);
            if (_dateArr[_i].room_status !== 2) {
              _dateArr[_i].finish_time = timeInterval(_dateArr[_i].publish_time, _dateArr[_i].finish_time);
            }
            arr.push(_dateArr[_i]);
          }
        }
      }
      state.liveRecordList = arr;
    },
    // 日历与接口返回数据匹配
    CHANGE_MONTH_DATE: function CHANGE_MONTH_DATE(state, payload) {
      var selectDate = state.selectDate,
        liveCalender = state.liveCalender;
      var month = selectDate.getMonth() + 1;
      var year = selectDate.getYear();
      var value = new Date(year, month, 0);
      var days = value.getDate();
      var calenderArr = [];
      for (var i = 0; i < days; i++) {
        var date = {
          haveLive: false,
          liveCout: 0,
          date: i + 1
        };
        calenderArr.push(date);
      }
      for (var key in liveCalender) {
        var _date = key.slice(8, 9) != 0 ? key.slice(8) : key.slice(9);
        calenderArr[_date - 1].haveLive = true;
        calenderArr[_date - 1].liveCout = liveCalender[key].length;
      }
      state.calenderArr = calenderArr;
    }
  },
  actions: {
    getAuthourMessage: function getAuthourMessage(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      var authorId = state.authorId;
      return _getAuthourMessage(authorId).then(function (res) {
        commit('changeState', {
          name: 'athourMessage',
          data: res.data
        });
      });
    },
    getLiveStreamRecord: function getLiveStreamRecord(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      var authorId = state.authorId;
      return getLiveRecord(authorId).then(function (res) {
        commit('changeState', {
          data: res.data,
          name: 'liveRecord'
        });
        var loading = setTimeout(function () {
          commit('changeState', {
            name: 'rotateActive',
            data: false
          });
          clearTimeout(loading);
        }, 1000);
      });
    },
    getLiveCalender: function getLiveCalender(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      var begin_time = state.begin_time,
        end_time = state.end_time,
        authorId = state.authorId;
      return _getLiveCalender({
        begin_time: begin_time,
        end_time: end_time,
        author_id: authorId
      }).then(function (res) {
        commit('changeState', {
          data: res.data,
          name: 'liveCalender'
        });
        commit('CHANGE_LIVE_LIST', {
          filterDate: 0
        });
        commit('CHANGE_MONTH_DATE');
      });
    }
  }
};
exports.default = _default;