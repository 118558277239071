var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            [
              _c("div", { staticClass: "header-logo" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCollapse,
                      expression: "!isCollapse",
                    },
                  ],
                  staticClass: "logo",
                  attrs: { src: require("@/assets/fhl-logo.png"), alt: "" },
                }),
                _c("span", [_vm._v("风火轮")]),
              ]),
              _vm._l(_vm.accessRoute, function (route) {
                return _c("sidebar-item", {
                  key: route.path,
                  attrs: { item: route, "base-path": route.path },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }