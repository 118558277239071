var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-switch",
    _vm._b(
      { attrs: { checked: _vm.newChecked }, on: { change: _vm.changeValue } },
      "a-switch",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }