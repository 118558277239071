"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _auth = require("@/utils/auth");
var vuex = _interopRequireWildcard(require("vuex"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("live_record"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  data: function data() {
    return {
      tableLoading: true,
      storeOptions: [],
      storeValue: '',
      pageSize: 25,
      currentPageNo: 1,
      currentId: null,
      dayRadio: "近30天",
      timeRadio: 'day',
      weekVal: this.getWeekRange(11),
      currentWeek: this.theWeek() || 1,
      monuthVal: this.getMonthRange(parseInt(this.moment()) || 1),
      munuthList: [],
      currentMonuth: parseInt(this.moment().format("MM")) || 1,
      date: new Date(new Date() - 24 * 60 * 60 * 1000),
      begin_time: '',
      end_time: ''
    };
  },
  components: {},
  props: [],
  computed: _objectSpread({}, mapState({
    tableData: function tableData(state) {
      return state.tableData;
    }
  })),
  created: function created() {
    window.moment = this.moment;
  },
  watch: {
    tableData: function tableData() {
      this.tableLoading = false;
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["getTableData", "getVoteData"])), mapMutations(["changeState"])), {}, {
    search: function search(isFirst) {
      if (isFirst) {
        this.currentPageNo = 1;
      }
      console.log('sadasdashjd');
      if (this.timeRadio == 'day') {
        if (this.moment(this.date).format('YYYY/MM/DD').indexOf('/') > 0) {
          this.begin_time = this.moment(this.date).format('YYYY-MM-DD');
          this.end_time = this.moment(this.date).format('YYYY-MM-DD');
        }
      } else if (this.timeRadio == 'week') {
        this.begin_time = this.weekVal.split(' - ')[0];
        this.end_time = this.weekVal.split(' - ')[1];
      } else {
        var year = this.monuthVal.slice(0, 4);
        var month = this.monuthVal.slice(0, -1).split('年')[1];
        this.begin_time = this.moment("".concat(year, "-").concat(month)).startOf('month').format("YYYY-MM-DD");
        this.end_time = this.moment("".concat(year, "-").concat(month)).endOf('month').format("YYYY-MM-DD");
      }
      var params = {
        current: isFirst ? 1 : this.currentPageNo,
        page_size: this.pageSize,
        begin_time: "".concat(this.begin_time, " 00:00:00"),
        end_time: "".concat(this.end_time, " 23:59:59")
      };
      if (this.currentId) {
        params.author_id = this.currentId;
      }
      this.getTableData(params);
    },
    // 查询数据
    getData: function getData(id) {
      this.tableLoading = true;
      if (id) {
        this.currentId = id;
        this.getTableData({
          current: 1,
          page_size: this.pageSize,
          author_id: id,
          begin_time: "".concat(this.begin_time, " 00:00:00"),
          end_time: "".concat(this.end_time, " 23:59:59")
        });
      } else {
        this.currentId = null;
        this.getTableData({
          current: 1,
          page_size: this.pageSize,
          begin_time: "".concat(this.begin_time, " 00:00:00"),
          end_time: "".concat(this.end_time, " 23:59:59")
        });
      }
    },
    radioChange: function radioChange(val) {
      console.log(val);
      this.tableLoading = true;
      this.search(true);
    },
    // 获取当前为第几周
    theWeek: function theWeek() {
      var totalDays = 0;
      var now = new Date();
      var years = now.getYear();
      if (years < 1000) years += 1900;
      var days = new Array(12);
      days[0] = 31;
      days[2] = 31;
      days[3] = 30;
      days[4] = 31;
      days[5] = 30;
      days[6] = 31;
      days[7] = 31;
      days[8] = 30;
      days[9] = 31;
      days[10] = 30;
      days[11] = 31;

      //判断是否为闰年，针对2月的天数进行计算
      if (Math.round(now.getYear() / 4) == now.getYear() / 4) {
        days[1] = 29;
      } else {
        days[1] = 28;
      }
      if (now.getMonth() == 0) {
        totalDays = totalDays + now.getDate();
      } else {
        var curMonth = now.getMonth();
        for (var count = 1; count <= curMonth; count++) {
          totalDays = totalDays + days[count - 1];
        }
        totalDays = totalDays + now.getDate();
      }
      //得到第几周
      var week = Math.round(totalDays / 7);
      return week + 1;
    },
    // 通过第几周判断日期范围
    getWeekRange: function getWeekRange(num) {
      var newNum = num - 11 + this.theWeek();
      var year = String(parseInt(this.moment().format('YYYY')) + 1);
      var days = this.moment(year).week(newNum - 1).format('YYYY-MM-DD');
      var firstDay = this.moment(days).add('days', -6).format('YYYY-MM-DD');
      var lastDay = this.moment(firstDay).add('days', 6).format('YYYY-MM-DD');
      return firstDay + ' - ' + lastDay;
    },
    // getMonthRange(num) {
    //   let year =  this.moment().format('YYYY')
    //   return year + '年' + num + '月'
    // },
    getMonthRange: function getMonthRange(num) {
      console.log(this.moment().month());
      var date = this.moment().month(this.moment().month() - num).format('YYYY年MM月');
      return date;
    },
    pageChange: function pageChange(num) {
      this.tableLoading = true;
      console.log(num);
      this.currentPageNo = num;
      this.search();
    },
    exportClick: function exportClick() {
      console.log(this);
      console.log(process.env.VUE_APP_OPENDATA_API);
      var httpObj = {
        method: 'get',
        // 请求方式
        url: process.env.VUE_APP_OPENDATA_API + '/mirror-data/douyin/live/list/export',
        // 请求地址
        headers: {
          token: (0, _auth.getToken)()
        },
        params: {
          // 请求参数
          begin_time: "".concat(this.begin_time, " 00:00:00"),
          end_time: "".concat(this.end_time, " 23:59:59")
        },
        responseType: 'blob' // 重点，请求的数据类型
      };

      if (this.currentId) {
        httpObj.params.author_id = this.currentId;
      }
      this.$http(httpObj).then(function (response) {
        /* 兼容ie内核，360浏览器的兼容模式 */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var _blob = new Blob([response.data]);
          window.navigator.msSaveOrOpenBlob(_blob, '数据导出.xlsx');
        } else {
          /* 火狐谷歌的文件下载方式 */
          var blob = new Blob([response.data]);
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '数据导出.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function (response) {
        console.log(response);
      });
    },
    openDetail: function openDetail(row) {
      var room_id = row.room_id,
        author_id = row.author_id;
      this.$router.push({
        path: '/live/stream/analyze',
        query: {
          room_id: room_id,
          author_id: author_id
        }
      });
      // this.$emit('openDetail', row)
    }
  }),
  mounted: function mounted() {
    this.getVoteData();
  }
};
exports.default = _default;