"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: []
    };
  },
  mounted: function mounted() {
    this.emojiInit();
  },
  methods: {
    emojiInit: function emojiInit() {
      // 表情信息可参考 http://unicode.org/emoji/charts/full-emoji-list.html
      var config = {
        size: 30,
        url: "//f2e.cn.ronghub.com/sdk/emoji-48.png",
        lang: "zh"
        // extension: {
        //   dataSource: {
        //     u1F914: {
        //       // 自定义 u1F914 对应的表情
        //       en: "thinking face", // 英文名称
        //       zh: "思考", // 中文名称
        //       tag: "🤔", // 原生 Emoji
        //       position: "0 0", // 所在背景图位置坐标
        //     },
        //   },
        //   url: "//cdn.ronghub.com/thinking-face.png", // 新增 Emoji 背景图 url
        // },
      };

      RongIMLib.RongIMEmoji.init(config);
      this.list = RongIMLib.RongIMEmoji.list;
    },
    clickEmoji: function clickEmoji(symbol) {
      var str = RongIMLib.RongIMEmoji.symbolToEmoji(symbol);
      //   console.log("str", str);
      this.$emit('selectEmoji', str);
    }
  }
};
exports.default = _default;