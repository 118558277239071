"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initLeaveAnalyzeOptions = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var chatOpenMouthTypeMap = {
  chatOpenMouthCount: '私信会话开口人数',
  ppLeaveCount: '皮皮拉客留资数',
  ppLeaveRate: '皮皮拉客留资占比',
  chatLeaveCount: '私信留资数',
  chatLeaveRate: '私信留资转化率'
};
var initLeaveAnalyzeOptions = function initLeaveAnalyzeOptions(list, chatOpenMouthMaxMap, legend) {
  var option = {
    color: ['#FFCC55', '#6E9FF4', '#F75C5C', '#856BF8', '#FF9026'],
    grid: {
      x: 50,
      y: 60,
      x2: 50,
      y2: 35
    },
    tooltip: {
      trigger: 'axis',
      formatter: function formatter(params) {
        var showHtm = '';
        // x轴名称
        var xname = params[0].name;
        showHtm += xname + '<br>';
        for (var i = 0; i < params.length; i++) {
          // 标点
          var icon = "<span style=\"display:inline-block;margin-right:5px;width:10px;height:10px;border-radius:10px;background:".concat(params[i].color, "\"></span>");
          // y轴名称
          var yname = chatOpenMouthTypeMap[params[i].seriesName];
          // 数据
          var data = params[i].data[params[i].seriesName] + "".concat(yname === '皮皮拉客留资占比' || yname === '私信留资转化率' ? '%' : '');
          showHtm += icon + yname + '：' + data + '<br>';
        }
        return showHtm;
      }
    },
    legend: _objectSpread(_objectSpread({}, legend), {}, {
      formatter: function formatter(seriesName) {
        return chatOpenMouthTypeMap[seriesName];
      }
    }),
    dataset: {
      dimensions: ['day'].concat((0, _toConsumableArray2.default)(legend.data)),
      source: list
    },
    xAxis: {
      type: 'category'
    },
    yAxis: [{
      max: chatOpenMouthMaxMap.leftMax,
      splitNumber: 5,
      interval: chatOpenMouthMaxMap.leftMax / 5
    }, {
      max: chatOpenMouthMaxMap.rightMax,
      splitNumber: 5,
      interval: chatOpenMouthMaxMap.rightMax / 5,
      axisLabel: {
        formatter: function formatter(value) {
          return value + '%';
        }
      }
    }],
    series: (0, _toConsumableArray2.default)(legend.data.map(function (name) {
      return {
        type: 'line',
        smooth: true,
        symbol: 'circle',
        yAxisIndex: name.includes('Rate') ? 1 : 0
      };
    }))
  };
  return option;
};
exports.initLeaveAnalyzeOptions = initLeaveAnalyzeOptions;