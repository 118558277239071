var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        placement: "right",
        visible: _vm.visible,
        loading: _vm.isSaving,
        maskClosable: false,
        width: 580,
        submitText: "保存",
      },
      on: { close: _vm.close, submit: _vm.save },
    },
    [
      _c(
        "div",
        {
          staticClass: "drawer-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h3", [_vm._v("调整优先级")]),
          _c("span", [_vm._v("上下拖拽调整优先级，排在前的优先级更高")]),
        ]
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "draggable",
            {
              attrs: { animation: "300" },
              model: {
                value: _vm.listData,
                callback: function ($$v) {
                  _vm.listData = $$v
                },
                expression: "listData",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.listData, function (item) {
                  return _c("div", { key: item.id, staticClass: "sort-item" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }