var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        placement: "right",
        visible: _vm.visible,
        maskClosable: false,
        width: 580,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "drawer-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h3", [_vm._v("调整优先级")]),
          _c("span", [_vm._v("上下拖拽调整优先级，排在前的优先级更高")]),
        ]
      ),
      _c(
        "a-tabs",
        {
          model: {
            value: _vm.keyWordType,
            callback: function ($$v) {
              _vm.keyWordType = $$v
            },
            expression: "keyWordType",
          },
        },
        [
          _c("a-tab-pane", { key: 3, attrs: { tab: "自定义" } }),
          _vm.listData[2]
            ? _c("a-tab-pane", { key: 2, attrs: { tab: "品牌" } })
            : _vm._e(),
          _c("a-tab-pane", { key: 1, attrs: { tab: "通用" } }),
        ],
        1
      ),
      _c(
        "draggable",
        {
          attrs: { animation: "300" },
          model: {
            value: _vm.listData[_vm.keyWordType],
            callback: function ($$v) {
              _vm.$set(_vm.listData, _vm.keyWordType, $$v)
            },
            expression: "listData[keyWordType]",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.listData[_vm.keyWordType], function (item) {
              return _c("div", { key: item.ruleId, staticClass: "sort-item" }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "50px" } }),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "8px" },
              on: { click: _vm.close },
            },
            [_vm._v(" 取消")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.isSaving },
              on: { click: _vm.save },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }