"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _mqttws31Min = _interopRequireDefault(require("@/utils/mqttws31.min.js"));
var _mqttRemoval = _interopRequireDefault(require("@/utils/mqttRemoval"));
var _talkItem = _interopRequireDefault(require("./components/talkItem.vue"));
var _historyItem = _interopRequireDefault(require("./components/historyItem.vue"));
var _ClientDetail = _interopRequireDefault(require("./components/ClientDetail.vue"));
var _intelligence_play = _interopRequireDefault(require("@/api/intelligence_play.js"));
var _private_letter_manage = _interopRequireDefault(require("@/api/private_letter_manage.js"));
var _common = _interopRequireDefault(require("@/api/common"));
var _ReplyModal = _interopRequireDefault(require("./components/ReplyModal.vue"));
var _Emoji = _interopRequireDefault(require("./components/Emoji"));
var _vuex = require("vuex");
var _auth = require("@/utils/auth");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var debounce = function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}();
var _default = {
  name: 'private_letter',
  data: function data() {
    return {
      mqtt_user_data: null,
      avatar: require('@/assets/dev/defult-avatar.png'),
      userId: (0, _auth.getUserId)('userId'),
      removal: null,
      removalUser: null,
      client: null,
      // 创建一个客户端实例
      clientUser: null,
      // 创建一个客户端实例
      currentFansId: null,
      currentAuthorId: null,
      currentAuthorData: {},
      // 当前聊天框信息
      replyValue: '',
      active_btn: 1,
      search_form: {
        provinces: undefined,
        cities: undefined,
        leaveInfo: undefined,
        // 1=未留资,2=已留资
        seriesId: undefined,
        isFans: undefined,
        // 1=是,0=否
        sceneId: undefined,
        // 策略ID
        isPPReach: undefined // 是否皮皮拉客触达
      },

      searchUser: undefined,
      showMoreFilter: false,
      province_list: [],
      city_list: [],
      layout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      attention_tag: [{
        sceneId: undefined,
        sceneName: '全部会话'
      }],
      series_list: [],
      author_setting: {},
      author_open_id: '',
      no_read_count: 0,
      isGetChatList: false,
      chat_list: [],
      client_open_id: '',
      client_message: {
        labels: [],
        seriesList: []
      },
      author_id: '',
      fans_id: '',
      live_fans_msg: {},
      switch_loading_one: '',
      active_live_tab: 1,
      live_list_current: 1,
      loading_live_tab_top: false,
      no_more_live_top: false,
      live_chat_message: [],
      active_video: 1,
      init_lock: true,
      // 初始化聊天框自锁
      show_message_tips: false,
      user_list_tab: 1,
      historty_chat_list: [],
      judge_search: false,
      // 判定是否通过筛选条件筛选
      show_left: true,
      loading_video_com: false,
      no_more_video_com: false,
      video_current: 1,
      video_comment_list: [],
      loading_video_like: false,
      no_more_video_live: false,
      video_current_like: 1,
      video_like_list: [],
      show_modal: false,
      loading_chat_content: false,
      chat_up: true,
      chat_current: 1,
      scroll: null,
      chat_list_total: 0,
      chat_total_judge: true,
      old_scroll_height: 0,
      showEmoji: false,
      // showNewsTip: false,
      nowFocusRead: null,
      fans_new_id: null,
      isDrag: false,
      // 拖拽输入框中

      // 1-人工回复，2-自动回复，3-人工回复时间段内
      replyStatus: 1
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['platform'])), (0, _vuex.mapState)({
    userInformation: function userInformation(state) {
      return state.user.userInformation;
    },
    isOpenSmartReply: function isOpenSmartReply(state) {
      return state.user.isOpenSmartReply;
    }
  })), {}, {
    disable_video_comment: function disable_video_comment() {
      return this.loading_video_com || this.no_more_video_com;
    },
    disable_video_live: function disable_video_live() {
      return this.no_more_video_live || this.loading_video_like;
    },
    disable_live_list: function disable_live_list() {
      return this.no_more_live_top || this.loading_live_tab_top;
    },
    searchChatList: function searchChatList() {
      var _this = this;
      return this.searchUser ? this.chat_list.filter(function (item) {
        return item.nickname.includes(_this.searchUser);
      }) : this.chat_list;
    }
  }),
  components: {
    talkItem: _talkItem.default,
    historyItem: _historyItem.default,
    ClientDetail: _ClientDetail.default,
    ReplyModal: _ReplyModal.default,
    EmojiList: _Emoji.default
  },
  watch: {
    mqtt_user_data: function mqtt_user_data(val) {
      console.log(val);
      this.removalUser = new _mqttRemoval.default();
      this.MQTTconnectUser();
    },
    historty_chat_list: function historty_chat_list(val) {
      var _this2 = this;
      // if (this.showNewsTip) {
      //   // this.readClick(this.currentFansId, this.currentAuthorId);
      //   return;
      // }
      this.$nextTick(function () {
        var ele = document.getElementById('mainTalk');
        var scroll_height = ele.scrollHeight;
        if (val.length > 0 && val.length <= 30) {
          ele.scrollTop = scroll_height;
        } else if (_this2.chat_total_judge && val.length > 30) {
          ele.scrollTop = scroll_height - _this2.old_scroll_height;
        }
      });
    },
    chat_list: function chat_list(val) {
      var _this3 = this;
      var ele = document.getElementById('mainTalk');
      if (val.length) {
        var index = 0;
        if (this.$route.query.userOpenId) {
          var id = this.$route.query.userOpenId;
          val.forEach(function (item, d) {
            if (item.open_id == id) {
              index = d;
            }
          });
          console.log('index', index);
        }
        if (this.init_lock) {
          this.userClick(val[index]);
          this.init_lock = false;
        }
        this.$nextTick(function () {
          var ele = document.getElementById('mainTalk');
          ele.addEventListener('scroll', _this3.listenerScroll, true);
        });
      } else {
        console.log('移除');
        ele.removeEventListener('scroll', this.listenerScroll, true);
      }
    }
  },
  created: function created() {
    if (this.$route.query.displayPrompt) {
      this.countDown();
    }
    // 获取省市
    this.getProvince();
    this.getCityList();
    // 清除缓存
    this.clearAllList(true);

    // 获取mqtt config设置(左侧用户列表)
    this.getMqttConfigUser();
    this.getCarAndChatTag();
    this.getLeftUserList(1, 300);
    this.getNoReadCount();
    this.getAuthorSetting();
  },
  mounted: function mounted() {
    this.listenKey();
    if (!!(window.h5sdk && window.tt) && this.$route.query.lark_id) {
      (0, _auth.setLarkId)(this.$route.query.lark_id);
    }
  },
  methods: {
    listenerScroll: function listenerScroll() {
      if (this.$refs.wrapper.scrollTop == 0 && !this.loading_chat_content && this.chat_total_judge) {
        this.old_scroll_height = this.$refs.wrapper.scrollHeight;
        this.loadChatList(this.chat_current);
      }
      var scroolBottom = this.$refs.wrapper.scrollHeight - this.$refs.wrapper.clientHeight - this.$refs.wrapper.scrollTop;
      console.log('scroolBottom', scroolBottom);
      // if (scroolBottom > 100) {
      //   this.showNewsTip = true;
      // } else {
      //   this.showNewsTip = false;
      //   this.nowFocusRead = 0;
      //   this.readClick(this.currentFansId, this.currentAuthorId);
      // }
    },
    // 获取mqtt config(左侧用户列表)
    getMqttConfigUser: function getMqttConfigUser() {
      var _this4 = this;
      var payload = {
        platform_type: 1
      };
      _private_letter_manage.default.getMqttConfigUser(payload).then(function (res) {
        console.log('实时更新是个什么鬼', res);
        if (res.code == 200) {
          _this4.mqtt_user_data = res.data;
        } else {
          _this4.$message.error('获取通信配置失败');
        }
      });
    },
    // 左侧列表点击
    userClick: function userClick(item) {
      var _this5 = this;
      if (this.currentFansId == item.open_id && this.currentAuthorId == item.author_open_id) {
        this.readClick(item.open_id, item.author_open_id);
        return false;
      }
      console.log('点击的item', item);
      // 清除记录
      this.clearAllList();
      // 清空之前的mqtt连接
      if (this.client) {
        this.client.disconnect(); // 断开连接
        this.client = null;
      }
      // 清空消息记录
      this.replyValue = '';
      this.replyStatus = false;
      // 赋值id
      this.currentFansId = item.open_id;
      this.currentAuthorId = item.author_open_id;
      this.currentAuthorData = item;
      // 获取聊天记录
      this.loadChatList(1);
      // 配置open_id
      this.client_open_id = item.open_id;
      this.author_id = item.author_id;
      this.fans_id = item.fans_id;
      // if (this.active_btn == 1) {
      //   this.getUserMessage();
      //   // this.$refs.clientDetail.handleCurrentChange(item);
      // } else {
      //   // 获取历史记录
      //   this.getFansInfo();
      // }

      this.readClick(item.open_id, item.author_open_id);
      this.$nextTick(function () {
        _this5.getFansInfo();
        _this5.getUserMessage();
        // this.getFansPolling();
        _this5.getVideoComment(true);
        _this5.getAwemeLikeList(true);
        _this5.getReplyStatus();
        if (!_this5.fans_id) {
          _this5.getFansId();
        }
      });
    },
    /**
     * 当前聊天框历史消息
     * @param {*} page
     * @return {*}
     */
    loadChatList: function loadChatList(page) {
      var _this6 = this;
      var payload = {
        channelUserOpenId: this.currentFansId,
        page: page,
        size: 30
      };
      this.loading_chat_content = true;
      _private_letter_manage.default.getChatList(payload).then(function (res) {
        if (res.list.length > 0) {
          var time_l = 60 * 1000 * 5; // 超分钟
          for (var i = 0; i < res.list.length; i++) {
            if (i < res.list.length - 1) {
              var time_one = _this6.moment(res.list[i].ctime).valueOf();
              var time_two = _this6.moment(res.list[i + 1].ctime).valueOf();
              var time_c = time_two - time_one;
              if (time_c > time_l) {
                res.list[i + 1].show_time = true;
              }
            }
            if (res.list[i].transformMessage) {
              res.list[i].show_time = true;
            }
          }
          res.list[0].show_time = true;
          // this.chat_list_total = res.total;
          if (page == 1) {
            _this6.historty_chat_list = res.list;
            _this6.$refs['wrapper'].scrollIntoView();
          } else {
            _this6.historty_chat_list = res.list.concat(_this6.historty_chat_list);
          }
          _this6.chat_current = res.page_num + 1;
          if (_this6.historty_chat_list.length >= res.total) {
            _this6.chat_total_judge = false;
          } else {
            _this6.chat_total_judge = true;
          }
        }
      }).finally(function () {
        _this6.loading_chat_content = false;
      });
    },
    // 回复点击
    replyClick: function replyClick() {
      var _this7 = this;
      if (this.replyValue == '') {
        this.$message.warning('请输入消息后点击！');
        return false;
      }
      this.filtrate.setTracking({
        en: 'zxmk_yx_sxhhhf_click'
      });
      var payload = {
        fans_open_id: this.currentFansId,
        author_open_id: this.currentAuthorId,
        content: this.replyValue,
        is_author_send: 1
      };
      _private_letter_manage.default.replyMessage(payload).then(function (res) {
        if (res.code == 200) {
          _this7.loadChatList(1);
          _this7.getLeftUserList(1, 300);
        } else {
          _this7.$message.error(res.message);
        }
      });
      // 清空消息记录
      this.replyValue = '';
      //重新调用中间聊天信息
    },
    // 创建mqtt实例，并连接
    MQTTconnectUser: function MQTTconnectUser() {
      var _this8 = this;
      this.clientUser = new _mqttws31Min.default.MQTT.Client(this.mqtt_user_data.end_point, 443, this.mqtt_user_data.client_id);
      this.clientUser.connect({
        userName: this.mqtt_user_data.user_name,
        //连接账号
        password: this.mqtt_user_data.password,
        //密码
        cleanSession: true,
        onSuccess: this.onConnectUser,
        useSSL: true,
        onFailure: function onFailure(message) {
          setTimeout(_this8.MQTTconnectUser, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4
      }); // 连接服务器并注册连接成功处理事件
      this.clientUser.onConnectionLost = this.onConnectionLostUser; // 注册连接断开处理事件
      this.clientUser.onMessageArrived = this.onMessageArrivedUser; // 注册消息接收处理事件
    },
    //当客户端连接
    onConnectUser: function onConnectUser() {
      //建立连接后，进行订阅并发送消息。
      console.log('onConnectUser');
      this.clientUser.subscribe(this.mqtt_user_data.client_subscribe.chat.subTopic, {
        qos: 0
      });
    },
    // 当客户端失去连接时调用
    onConnectionLostUser: function onConnectionLostUser(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log('onConnectionLostUser:' + responseObject.errorMessage);
      }
    },
    //当消息到达时
    onMessageArrivedUser: function onMessageArrivedUser(message) {
      console.log('onMessageArrivedUser:', JSON.parse(message.payloadString));
      var value = JSON.parse(message.payloadString);
      // 获取聊天记录
      this.chat_current = 1;
      // this.historty_chat_list = [];
      // this.chat_total_judge =true
      // console.log(
      //   "message.payloadString.author_open_id",
      //   value.chat,
      //   "this.currentAuthorId",
      //   this.currentFansId
      // );
      if (value.chat.fans_open_id !== this.currentFansId) {
        this.show_message_tips = true;
        if (!!this.author_setting.isNewsVoice) {
          this.$refs.audio.currentTime = 0; //从头开始播放提示音
          this.$refs.audio.play(); //播放
        }
      } else {
        this.loadChatList(this.chat_current);
      }
      this.getNoReadCount();
      this.getLeftUserList(1, 300);
    },
    getSimpleName: function getSimpleName(name) {
      if (name) {
        return name.slice(0, name.indexOf('-'));
      } else {
        return '--';
      }
    },
    clearAllList: function clearAllList(isAll) {
      this.client_message = {
        labels: [],
        seriesList: []
      };
      this.chat_current = 1;
      this.live_list_current = 1;
      this.video_current = 1;
      this.video_current_like = 1;
      this.historty_chat_list = [];
      this.currentAuthorData = {};
      this.video_like_list = [];
      this.video_comment_list = [];
      this.live_chat_message = [];
      this.currentFansId = undefined;
    },
    // 切换信息详情
    selectBtnType: function selectBtnType(value) {
      var _this9 = this;
      this.active_btn = value;
      if (this.active_btn == 2) {
        this.$nextTick(function () {
          _this9.loadHistoryData(true);
        });
      } else {
        this.$nextTick(function () {
          _this9.$refs.clientDetail.handleCurrentChange(_this9.currentAuthorData);
        });
      }
    },
    // 获取省份列表
    getProvince: function getProvince() {
      var _this10 = this;
      _intelligence_play.default.getAreaList({
        parent_id: 0,
        level: 1
      }).then(function (res) {
        _this10.province_list = res.data.data;
      });
      //   this.getAreaList({
      //     parent_id: 0,
      //     level: 1,
      //   })
    },
    getCityList: function getCityList() {
      var _this11 = this;
      _private_letter_manage.default.getAllCity().then(function (res) {
        if (res.code == 200) {
          _this11.city_list = res.data;
        }
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    showTipsReply: function showTipsReply() {
      var _this12 = this;
      this.filtrate.setTracking({
        en: 'zxmk_yx_sxbqts_click'
      });
      this.$confirm('可以在【智能回复】页面详细了解私信标签!', '私信标签', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this12.filtrate.setTracking({
          en: 'zxmk_yx_sxbqck_click'
        });
        _this12.$router.push({
          path: '/private_letter_manage/intellect_reply'
        });
      }).catch(function () {
        console.log('cancel');
      });
    },
    getCarAndChatTag: function getCarAndChatTag() {
      var _this13 = this;
      _private_letter_manage.default.getCarAndChatTag().then(function (res) {
        console.log(res);
        if (res.code == 200) {
          // this.attention_tag= res.data.labels;
          _this13.attention_tag = _this13.attention_tag.concat(res.data.labels);
          _this13.series_list = res.data.seriesList;
        }
      });
    },
    // 处理时间
    disposeLongTime: function disposeLongTime(value) {
      if (!value) {
        return;
      }
      var pub_time = this.moment(value).valueOf();
      var today = this.moment().startOf('day').valueOf();
      var yesterday_time = this.moment().subtract(1, 'days').startOf('day').valueOf();
      var year_time = this.moment().startOf('year').valueOf();
      var week_time = this.moment().startOf('week').valueOf();
      var date_arrA = value.split('-');
      var date_arrB = value.split(' ');
      var now_time = Date.parse(new Date()); // 获取当前时间戳
      var diff_time = now_time - pub_time;
      var minute = 60 * 1000;
      var hour = minute * 60;
      var hourC = diff_time / hour;
      var minC = diff_time / minute;
      var res = '';
      var time = value.slice(11, 16);
      if (pub_time < year_time) {
        res = date_arrA[0] + '.' + date_arrA[1] + '.' + date_arrA[2] + ' ' + date_arrB[1];
        res = res.slice(0, 16);
      } else if (pub_time < week_time) {
        res = date_arrA[1] + '.' + date_arrA[2] + ' ' + date_arrB[1];
        res = res.slice(0, 11);
      } else if (pub_time >= week_time && pub_time < yesterday_time) {
        var week = this.moment(pub_time).format('ddd');
        res = week + ' ' + date_arrB[1];
        res = res.slice(0, 8);
      } else if (pub_time < today && pub_time >= yesterday_time) {
        res = '昨天' + ' ' + time;
      } else if (pub_time >= today && hourC >= 1) {
        res = '今天' + ' ' + time;
      } else if (minC >= 1) {
        res = parseInt(minC) + '分钟前';
      } else {
        res = '刚刚';
      }
      return res;
    },
    changeSceneTag: function changeSceneTag(id) {
      this.judge_search = true;
      this.init_lock = true;
      this.search_form.sceneId = id;
      this.getLeftUserList(1, 300);
    },
    resetSearch: function resetSearch() {
      this.judge_search = false;
      this.search_form = {
        provinces: undefined,
        cities: undefined,
        leaveInfo: undefined,
        // 1=未留资,2=已留资
        seriesId: undefined,
        isFans: undefined,
        // 1=是,0=否
        sceneId: undefined // 策略ID
      };

      this.show_message_tips = false;
      this.init_lock = false;
      this.getLeftUserList(1, 300);
    },
    searchClick: function searchClick() {
      var _this$search_form = this.search_form,
        provinces = _this$search_form.provinces,
        cities = _this$search_form.cities,
        leaveInfo = _this$search_form.leaveInfo,
        seriesId = _this$search_form.seriesId,
        isFans = _this$search_form.isFans,
        sceneId = _this$search_form.sceneId;
      this.filtrate.setTracking({
        en: 'zxmk_yx_sxhhcx_click',
        kv: {
          filterItem: {
            province: provinces,
            city: cities,
            lead: leaveInfo,
            mentionCar: seriesId,
            isFan: isFans,
            messageTage: sceneId
          }
        }
      });
      this.judge_search = true;
      this.init_lock = true;
      this.show_message_tips = false;
      this.getLeftUserList(1, 300);
    },
    changeShowLeft: function changeShowLeft() {
      this.show_left = !this.show_left;
      var dom = document.getElementsByClassName('private-letter-container-main');
      if (this.show_left) {
        dom[0].style.width = 'calc(100% - 660px)';
      } else {
        dom[0].style.width = 'calc(100% - 300px)';
      }
    },
    /**
     * 获取左侧用户列表
     * @param {number} page 页数
     * @param {number} size 条数
     */
    getLeftUserList: function getLeftUserList(page, size) {
      var _this14 = this;
      if (!this.judge_search) {
        this.search_form = {
          provinces: undefined,
          cities: undefined,
          leaveInfo: undefined,
          // 1=未留资,2=已留资
          seriesId: undefined,
          isFans: undefined,
          // 1=是,0=否
          sceneId: undefined,
          // 策略ID
          isPPReach: undefined,
          // 是否皮皮拉客触达
          replyMsg: undefined
        };
      }
      this.searchUser = undefined;
      var _this$search_form2 = this.search_form,
        cities = _this$search_form2.cities,
        isFans = _this$search_form2.isFans,
        leaveInfo = _this$search_form2.leaveInfo,
        provinces = _this$search_form2.provinces,
        seriesId = _this$search_form2.seriesId,
        sceneId = _this$search_form2.sceneId,
        isPPReach = _this$search_form2.isPPReach,
        replyMsg = _this$search_form2.replyMsg;
      var payload = {
        cities: cities,
        isFans: isFans,
        leaveInfo: leaveInfo,
        provinces: provinces,
        seriesId: seriesId,
        sceneId: sceneId,
        isPPReach: isPPReach,
        replyMsg: replyMsg,
        page: page,
        size: size,
        isRead: this.user_list_tab ? true : false
      };
      if (this.$route.query.userOpenId) {
        payload.channelUserOpenId = this.$route.query.userOpenId || '';
      }
      _private_letter_manage.default.getChatUserList(payload).then(function (res) {
        if (_this14.user_list_tab === 1 && res.list.length == 0) {
          _this14.clearAllList();
          // return;
        }

        _this14.chat_list = res.list.map(function (item) {
          // 新接口数据做适配处理
          var newItem = _objectSpread(_objectSpread({}, item), {}, {
            open_id: item.openId,
            author_id: item.authorId,
            author_open_id: item.authorOpenId,
            fans_id: item.fansId,
            is_deleted: item.isDeleted,
            author_nickname: item.authorNickname,
            author_avatar: item.authorAvatar,
            addr_code: item.addrCode,
            city_code: item.cityCode,
            province_code: item.provinceCode,
            un_read_count: item.unReadCount,
            msg_sender: item.msgSender
          });
          if (newItem.open_id == _this14.currentFansId) {
            _this14.nowFocusRead = newItem.un_read_count;
            _this14.userClick(newItem);
          }
          return newItem;
        });
      }).finally(function () {
        _this14.isGetChatList = false;
      });
    },
    /**
     * 切换列表类型
     * 获取左侧未读用户列表
     */
    changeChatType: function changeChatType() {
      this.getLeftUserList(1, 300);
    },
    // 标记已读点击
    readClick: function readClick(fans_open_id, author_open_id) {
      var _this15 = this;
      var payload = {
        fans_open_id: fans_open_id,
        author_open_id: author_open_id
      };
      _private_letter_manage.default.signRead(payload).then(function (res) {
        if (res.code == 200) {
          _this15.nowFocusRead = 0;
          _this15.chat_list.map(function (item) {
            if (item.open_id == fans_open_id) {
              item.un_read_count = 0;
            }
          });
          _this15.getNoReadCount();
        }
      });
    },
    /**
     * 获取人工回复状态
     */
    getReplyStatus: function getReplyStatus() {
      var _this16 = this;
      var params = {
        author_open_id: this.currentAuthorId,
        user_open_id: this.client_open_id
      };
      _private_letter_manage.default.getReplyStatus(params).then(function (res) {
        if (res.code === 200) {
          _this16.replyStatus = res.data;
        }
      });
    },
    /**
     * 切换人工回复状态
     * @param {number} status 0人工 1自动
     */
    changeReplyStatus: function changeReplyStatus(status) {
      var _this17 = this;
      var params = {
        author_id: this.author_id,
        author_open_id: this.currentAuthorId,
        status: status,
        user_open_id: this.client_open_id
      };
      _private_letter_manage.default.changeReplyStatus(params).then(function (res) {
        if (res.code === 200) {
          _this17.$message.success(status === 0 ? '转人工回复成功' : '转自动回复成功');
          _this17.replyStatus = status === 0 ? 1 : 2;
          _this17.loadChatList(1);
        }
      });
    },
    //获取未读信息
    getNoReadCount: function getNoReadCount() {
      var _this18 = this;
      var _this$search_form3 = this.search_form,
        cities = _this$search_form3.cities,
        isFans = _this$search_form3.isFans,
        leaveInfo = _this$search_form3.leaveInfo,
        provinces = _this$search_form3.provinces,
        seriesId = _this$search_form3.seriesId,
        sceneId = _this$search_form3.sceneId;
      var payload = {
        cities: cities,
        isFans: isFans,
        leaveInfo: leaveInfo,
        provinces: provinces,
        seriesId: seriesId,
        sceneId: sceneId
      };
      _private_letter_manage.default.getNoReadCount(payload).then(function (res) {
        console.log('未读信息', res);
        if (res.code == 200) {
          _this18.no_read_count = res.data;
          if (!!_this18.author_setting.isLabelFlashing) {
            document.title = "[".concat(res.data, "\u6761\u672A\u8BFB\u6D88\u606F]");
          } else {
            document.title = "\u79C1\u4FE1\u4F1A\u8BDD";
          }
        }
      });
    },
    // 获取主播配置
    getAuthorSetting: function getAuthorSetting() {
      var _this19 = this;
      var payload = {
        authorOpenId: this.author_open_id
      };
      _private_letter_manage.default.getAuthorSetting(payload).then(function (res) {
        if (res.code == 200) {
          _this19.author_setting = res.data;
          if (!!_this19.author_setting.isLabelFlashing) {
            document.title = "[".concat(res.data, "\u6761\u672A\u8BFB\u6D88\u606F]");
          } else {
            document.title = "\u79C1\u4FE1\u4F1A\u8BDD";
          }
        } else {
          _this19.$message.error(res.message);
        }
      });
    },
    // 更改主播配置
    authorUseSetting: function authorUseSetting(value, type) {
      var _this20 = this;
      var status = value ? 1 : 0;
      var payload = {};
      if (type == 1) {
        payload.isNewsVoice = status;
      } else {
        payload.isLabelFlashing = status;
      }
      if (this.$route.query.userOpenId) {
        payload.authorOpenId = this.$route.query.userOpenId;
      }
      this.switch_loading_one = type;
      _private_letter_manage.default.updataAuthor(payload).then(function (res) {
        if (res.code == 200) {
          _this20.$message.success('修改设置成功');
          // this.getAuthorSetting();
          _this20.getAuthorSetting();
        } else {
          _this20.$message.error(res.message);
        }
      }).catch(function (res) {
        _this20.$message.error(res.message);
      }).finally(function () {
        _this20.switch_loading_one = 0;
      });
    },
    // 点击新消息
    updataNewMessage: function updataNewMessage() {
      this.filtrate.setTracking({
        en: 'zxmk_yx_sxxxxts_click'
      });
      this.show_message_tips = false;
      this.judge_search = false;
      this.getLeftUserList(1, 300);
    },
    // 获取用户概览信息
    getUserMessage: function getUserMessage() {
      var _this21 = this;
      var payload = {
        channelUserOpenId: this.client_open_id
      };
      _private_letter_manage.default.getUserMessage(payload).then(function (res) {
        if (res.code == 200) {
          _this21.client_message = res.data;
        } else {
          _this21.$message.error(res.message);
        }
      });
    },
    // 切换直播互动tab
    changeLiveTab: function changeLiveTab() {
      this.live_chat_message = [];
      this.getFansPolling(true);
    },
    // 直播间粉丝跟主播的互动信息
    getFansPolling: function getFansPolling(isTrue) {
      var _this22 = this;
      if (!this.fans_new_id && !this.fans_id) {
        this.no_more_live_top = true;
        return;
      }
      var payload = {
        fans_open_id: this.currentFansId,
        author_open_id: this.currentAuthorId,
        author_id: this.author_id,
        comment_validate: this.active_live_tab,
        // 是否有效评论，0否，1是
        current: isTrue ? 1 : this.live_list_current,
        fans_id: this.fans_id || this.fans_new_id,
        page_size: 10,
        type: 1
      };
      this.loading_live_tab_top = true;
      _private_letter_manage.default.getFansPolling(payload).then(function (res) {
        if (res.code == 500) {
          _this22.no_more_live_top = true;
        } else if (res.list.length > 0) {
          if (_this22.live_chat_message.length >= res.total) {
            _this22.no_more_live_top = true;
          } else {
            _this22.live_chat_message = _this22.live_chat_message.concat(res.list);
            _this22.live_list_current = res.page_num + 1;
          }
        } else {
          _this22.no_more_live_top = true;
        }
        // if(this.)
      }).finally(function () {
        _this22.loading_live_tab_top = false;
      });
    },
    // 粉丝直播间信息
    getFansInfo: function getFansInfo() {
      var _this23 = this;
      var payload = {
        author_open_id: this.currentAuthorId,
        fans_open_id: this.currentFansId,
        author_id: this.author_id,
        fans_id: this.fans_id || '',
        type: 1
      };
      _private_letter_manage.default.getFansInfo(payload).then(function (res) {
        if (res.code == 200) {
          _this23.live_fans_msg = res.data;
        }
      });
    },
    // 用户短视频评论记录
    getVideoComment: function getVideoComment(isTrue) {
      var _this24 = this;
      var payload = {
        pageNum: isTrue ? 1 : this.video_current,
        pageSize: 10,
        userOpenId: this.currentFansId,
        authorOpenId: this.currentAuthorId
      };
      this.loading_video_com = true;
      _private_letter_manage.default.getVideoComment(payload).then(function (res) {
        if (res.list.length > 0) {
          if (_this24.video_comment_list.length >= res.total) {
            _this24.no_more_video_com = true;
          } else {
            _this24.video_comment_list = _this24.video_comment_list.concat(res.list);
            _this24.video_current = res.page_num + 1;
          }
        } else {
          _this24.no_more_video_com = true;
        }
        // if(this.)
      }).finally(function () {
        _this24.loading_video_com = false;
      });
    },
    // 视频互动点赞列表
    getAwemeLikeList: function getAwemeLikeList(isTrue) {
      var _this25 = this;
      var payload = {
        authorId: this.author_id,
        authorOpenId: this.currentAuthorId,
        page: isTrue ? 1 : this.video_current_like,
        size: 10,
        userOpenId: this.currentFansId
      };
      this.loading_video_like = false;
      _private_letter_manage.default.getAwemeLikeList(payload).then(function (res) {
        if (res.list.length > 0) {
          if (_this25.video_like_list.length >= res.total) {
            _this25.no_more_video_live = true;
          } else {
            _this25.video_like_list = _this25.video_like_list.concat(res.list);
            _this25.video_current_like = res.page_num + 1;
          }
        } else {
          _this25.no_more_video_live = true;
        }
        // if(this.)
      }).finally(function () {
        _this25.loading_video_like = false;
      });
    },
    gotoSetting: function gotoSetting() {
      var _this26 = this;
      this.filtrate.setTracking({
        en: 'zxmk_yx_glxxmbcs_click'
      });
      this.$confirm('可以在【配置目标城市】页面详细了解!', '目标城市', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this26.filtrate.setTracking({
          en: 'zxmk_yx_glxxmbcsck_click'
        });
        _this26.$router.push({
          path: '/setting/setting_city'
        });
      }).catch(function () {
        console.log('cancel');
      });
    },
    openComment: function openComment(id) {
      // this.filtrate.setTracking({
      //   en: "zxmk_yx_pljchfpl_click",
      //   kv: {
      //     openID: id,
      //     pageName: this.tab_str,
      //   },
      // });
      this.show_modal = true;
      this.$refs.replyModal.getVideoComment(id);
    },
    closeModal: function closeModal() {
      this.show_modal = false;
    },
    interactionTabs: function interactionTabs(e) {
      if (e == 2) {
        this.video_like_list = [];
        this.video_comment_list = [];
        this.live_chat_message = [];
        this.getFansPolling(true);
        this.getVideoComment(true);
        this.getAwemeLikeList(true);
        this.filtrate.setTracking({
          en: 'zxmk_yx_sxhhhdjl_view'
        });
      }
    },
    /**
     * 转人工确认
     */
    switchPersonReply: function switchPersonReply() {
      var _this27 = this;
      var h = this.$createElement;
      this.$msgbox({
        title: '转人工回复',
        message: h('div', null, [h('div', {
          style: {
            marginBottom: '20px'
          }
        }, '仅针对该用户切换为人工回复，距用户最新一条消息10分钟内没有人工回复，或距离主播最新一条消息超过20分钟后，系统将切换为自动回复'), h('div', null, '人工回复状态下，也可点击「转自动回复」进行切换')]),
        autofocus: false,
        showCancelButton: true,
        confirmButtonText: '确认转人工回复',
        cancelButtonText: '取消'
      }).then(function () {
        _this27.changeReplyStatus(0);
      }).catch(function () {});
    },
    // 打开表情选择与关闭
    showEmojiClick: function showEmojiClick() {
      var that = this;
      this.showEmoji = true;
      function showEmojiClick() {
        //改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,
        that.showEmoji = false;
        document.body.removeEventListener('click', showEmojiClick); //消失后,为了性能,取消body这个事件就可以了
      }

      document.body.addEventListener('click', showEmojiClick);
    },
    // 选择表情
    selectEmoji: function selectEmoji(val) {
      this.replyValue = this.replyValue + val;
      this.showEmoji = false;
    },
    // 监听回车事件
    listenKey: function listenKey() {
      var that = this;
      document.onkeydown = function (e) {
        var ev = document.all ? window.event : e;
        if (ev.keyCode === 13 && that.replyValue) {
          that.replyClick();
        }
      };
    },
    /**
     * 输入框高度调节
     * @param {Event} e 事件
     */
    mouseDown: function mouseDown(e) {
      this.startHeight = this.$refs.inputBox.$el.clientHeight;
      this.startY = e.pageY;
      console.log('e.pageY:', e.pageY);
      this.isDrag = true;
      document.addEventListener('mousemove', this.mouseMove);
      document.addEventListener('mouseup', this.mouseUp);
    },
    mouseUp: function mouseUp() {
      this.isDrag = false;
      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('mouseup', this.mouseUp);
    },
    mouseMove: function mouseMove(e) {
      var diffY = e.pageY - this.startY;
      var minHeight = 80;
      var maxHeight = 200;
      var toHeght = this.startHeight - diffY;
      toHeght = toHeght < minHeight ? minHeight : toHeght;
      toHeght = toHeght > maxHeight ? maxHeight : toHeght;
      this.$refs.inputBox.$el.style.height = "".concat(toHeght, "px");
    },
    // // 查看新消息
    // readNewMessage() {
    //   let ele = document.getElementById("mainTalk");
    //   let scroll_height = ele.scrollHeight;
    //   // if (val.length > 0 && val.length <= 30) {
    //     ele.scrollTop = scroll_height;
    //   // } else if (this.chat_total_judge && val.length > 30) {
    //   //   ele.scrollTop = scroll_height - this.old_scroll_height;
    //   // }
    // },
    // 标记已读
    focusInput: function focusInput() {
      var _this28 = this;
      if (this.nowFocusRead > 0) {
        debounce(function () {
          _this28.readClick(_this28.currentFansId, _this28.currentAuthorId);
        }, 500);
      }
    },
    // 显示弹窗
    countDown: function countDown() {
      var secondsToGo = 5;
      var modal = this.$warning({
        title: '警告：给此用户发私信将消耗抖音私信额度（每天100）',
        content: "\u5F39\u7A97\u5C06\u5728 ".concat(secondsToGo, " \u79D2\u540E\u81EA\u52A8\u5173\u95ED")
      });
      var interval = setInterval(function () {
        secondsToGo -= 1;
        modal.update({
          content: "\u5F39\u7A97\u5C06\u5728 ".concat(secondsToGo, " \u79D2\u540E\u81EA\u52A8\u5173\u95ED")
        });
      }, 1000);
      setTimeout(function () {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
    },
    getFansId: function getFansId() {
      var _this29 = this;
      var payload = {
        userOpenId: this.currentFansId
      };
      _private_letter_manage.default.getFansId(payload).then(function (res) {
        console.log('res', res);
        if (res.code === 200) {
          _this29.fans_new_id = res.data.fans_id;
          _this29.getFansPolling(true);
        }
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    console.log('销毁');
    var ele = document.getElementById('mainTalk');
    ele.removeEventListener('scroll', this.listenerScroll);
    this.clearAllList(true);
    this.$notify.close();
    if (this.client) {
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
    if (this.clientUser) {
      this.clientUser.disconnect(); // 断开连接
      this.clientUser = null;
    }
  }
};
exports.default = _default;