"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var requestJJT_BFF = (0, _request.default)({
  baseURL: process.env.VUE_APP_JJT_BFF_API
});
var _default = {
  // 根据主体ID+经销商ID查询主体、成员信息
  getAddressInfo: function getAddressInfo(params) {
    return requestJJT_BFF({
      url: "/matrixMember/principalMemberInfo",
      method: 'GET',
      params: params
    });
  },
  // 私信接待开关查询
  getPrivateMessageReceptionStatus: function getPrivateMessageReceptionStatus(params) {
    return request({
      url: "/function/switch/query/private/Message/rec",
      method: 'GET',
      params: params
    });
  },
  // 私信接待开关
  switchPrivateMessageReceptionStatus: function switchPrivateMessageReceptionStatus(data) {
    return request({
      url: "/function/switch/update/private/Message/rec",
      method: 'POST',
      data: data
    });
  },
  // 私信智回开关查询
  getPrivateMessageReplyStatus: function getPrivateMessageReplyStatus(params) {
    return request({
      url: "/function/switch/query/private/Message/reply",
      method: 'GET',
      params: params
    });
  },
  // 私信智回开关
  switchPrivateMessageReplyStatus: function switchPrivateMessageReplyStatus(data) {
    return request({
      url: "/function/switch/update/private/Message/reply",
      method: 'POST',
      data: data
    });
  },
  // 潜客召回开关查询
  getPrivateMessageRecallStatus: function getPrivateMessageRecallStatus(params) {
    return request({
      url: "/function/switch/query/customer/recall",
      method: 'GET',
      params: params
    });
  },
  // 私信潜客召回开关
  switchPrivateMessageRecallStatus: function switchPrivateMessageRecallStatus(data) {
    return request({
      url: "/function/switch/update/customer/recall",
      method: 'POST',
      data: data
    });
  },
  // 视频评论开关查询
  getVideoCommentStatus: function getVideoCommentStatus(params) {
    return request({
      url: "/function/switch/query/video/comment",
      method: 'GET',
      params: params
    });
  },
  // 视频评论开关
  switchVideoCommentStatus: function switchVideoCommentStatus(data) {
    return request({
      url: "/function/switch/update/video/comment",
      method: 'POST',
      data: data
    });
  },
  //是否启用套路
  enableRoutine: function enableRoutine() {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/is/enable",
      method: 'GET'
    });
  },
  //启用/停用套路
  switchRoutine: function switchRoutine(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/switch",
      method: 'GET',
      params: params
    });
  },
  //启用/停用自动更新套路配置
  switchAuto: function switchAuto(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/switch/auto",
      method: 'GET',
      params: params
    });
  },
  //查询套路详情
  getRoutineDetail: function getRoutineDetail(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/detail",
      method: 'GET',
      params: params
    });
  },
  //关键词组名、关键词 - 维护
  updateData: function updateData(data) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/update",
      method: 'POST',
      data: data
    });
  },
  deleteData: function deleteData(group_id) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/delete/".concat(group_id),
      method: 'GET'
    });
  },
  // 定时召回维护
  updateRecall: function updateRecall(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/edit/timing/recall",
      method: 'GET',
      params: params
    });
  },
  //删除关键词组
  //话术 - 维护
  updateRoutineData: function updateRoutineData(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/edit/replyMsg?functionType=".concat(params.functionType, "&function_type=").concat(params.functionType),
      method: 'POST',
      data: params.data
    });
  },
  //新增关键词组
  insertKeywordGroup: function insertKeywordGroup(data) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/insert",
      method: 'POST',
      data: data
    });
  },
  //排序
  sortKeywordGroup: function sortKeywordGroup(data) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/sort",
      method: 'POST',
      data: data
    });
  },
  // 话术启用禁用
  changeScriptEnable: function changeScriptEnable(params) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/update/replyMsg/enable",
      method: 'POST',
      params: params
    });
  },
  // 新增回复图片
  addReplyImage: function addReplyImage(data) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/keywordGroupAppendImg",
      method: 'POST',
      data: data
    });
  },
  // 编辑回复图片
  editReplyImage: function editReplyImage(data) {
    return request({
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      url: "/routine/web/keywordGroupImg/edit",
      method: 'POST',
      data: data
    });
  }
};
exports.default = _default;