"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: function _default() {
        return {
          width: "874px",
          height: "300px"
        };
      }
    },
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    nameTips: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    styleObject: function styleObject() {
      var obj = this.boxStyle;
      for (var attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";
        obj[attr] = unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData: function chartData() {
      this.initChart();
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var colorList = ["#9E87FF", "#09b0e6", "#e43d24", "#f2a026"];
      var colorShadow = ["rgba(158,135,255, 0.3)", "rgba(115,221,255, 0.3)", "rgba(254,154,139, 0.3)"];
      this.chart = _echarts.default.init(document.getElementById(this.id));
      var arr = this.chartData;
      var series = [];
      this.chart.setOption({
        legend: {
          icon: "circle",
          bottom: '0',
          right: "auto",
          itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: "#556677"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c"
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)"
        },
        grid: {
          top: "15%"
        },
        xAxis: [{
          type: "category",
          data: this.chartData.time,
          axisLine: {
            lineStyle: {
              color: "#DCE2E8"
            }
          },
          axisTick: {
            show: false,
            interval: 'auto'
          },
          axisLabel: {
            // interval: 0,
            textStyle: {
              color: "#556677"
            },
            margin: 15
          },
          axisPointer: {
            label: {
              padding: [11, 5, 7],
              backgroundColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: "#fff" // 0% 处的颜色
                }, {
                  offset: 0.9,
                  color: "#fff" // 0% 处的颜色
                }, {
                  offset: 0.9,
                  color: "#33c0cd" // 0% 处的颜色
                }, {
                  offset: 1,
                  color: "#33c0cd" // 100% 处的颜色
                }],

                global: false // 缺省为 false
              }
            }
          },

          boundaryGap: false
        }],
        yAxis: [{
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DCE2E8"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#556677"
            }
          },
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          position: "right",
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "#556677"
            },
            formatter: "{value}"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DCE2E8"
            }
          },
          splitLine: {
            show: false
          }
        }],
        series: [{
          name: arr.nameTips[0],
          type: 'line',
          data: arr.lineData[0],
          symbolSize: 1,
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: false,
          lineStyle: {
            width: 5,
            color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: '#d7d3ee'
            }, {
              offset: 1,
              color: '#9E87FF'
            }]),
            shadowColor: 'rgba(161,148,232, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[0],
              borderColor: colorList[0]
            }
          }
        }, {
          name: arr.nameTips[1],
          type: 'line',
          data: arr.lineData[1],
          symbolSize: 1,
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: false,
          lineStyle: {
            width: 5,
            color: new _echarts.default.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#7bbcd1'
            }, {
              offset: 1,
              color: '#09b0e6'
            }]),
            shadowColor: 'rgba(38,153,191, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[1],
              borderColor: colorList[1]
            }
          }
        }, {
          name: arr.nameTips[2],
          type: 'line',
          data: arr.lineData[2],
          symbolSize: 1,
          yAxisIndex: 1,
          symbol: 'circle',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            width: 5,
            color: new _echarts.default.graphic.LinearGradient(0, 0, 1, 0, [{
              offset: 0,
              color: '#e8b1a9'
            }, {
              offset: 1,
              color: '#e43d24'
            }]),
            shadowColor: 'rgba(172,56,39, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[2],
              borderColor: colorList[2]
            }
          }
        }, {
          name: arr.nameTips[3],
          type: 'line',
          data: arr.lineData[3],
          symbolSize: 1,
          yAxisIndex: 1,
          symbol: 'circle',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            width: 5,
            color: new _echarts.default.graphic.LinearGradient(0, 0, 1, 0, [{
              offset: 0,
              color: '#fad59d'
            }, {
              offset: 1,
              color: '#f2a026'
            }]),
            shadowColor: 'rgba(242,160,38, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[3],
              borderColor: colorList[3]
            }
          }
        }]
      });
    }
  }
};
exports.default = _default2;