var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "intention_tracking" },
    [
      _c("div", { staticClass: "intention_tracking-container" }, [
        _c(
          "div",
          { staticClass: "excavate-left" },
          [
            _vm.trackingNumber
              ? _c("div", { staticClass: "excavate-left-info" }, [
                  _c(
                    "div",
                    [
                      _c("div", [_vm._v("意向统计：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "110px" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.numberTimeChange },
                          model: {
                            value: _vm.numberTime,
                            callback: function ($$v) {
                              _vm.numberTime = $$v
                            },
                            expression: "numberTime",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "近7天", value: "7" },
                          }),
                          _c("el-option", {
                            attrs: { label: "近15天", value: "15" },
                          }),
                          _c("el-option", {
                            attrs: { label: "近30天", value: "30" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "excavate-left-info-item" }, [
                    _c("div", { staticStyle: { color: "#ff001f" } }, [
                      _vm._v("H级"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.trackingNumber.hlevel))]),
                  ]),
                  _c("div", { staticClass: "excavate-left-info-item" }, [
                    _c("div", { staticStyle: { color: "#577ff4" } }, [
                      _vm._v("A级"),
                    ]),
                    _c("span", { staticStyle: { opacity: "0.8" } }, [
                      _vm._v(_vm._s(_vm.trackingNumber.alevel)),
                    ]),
                  ]),
                  _c("div", { staticClass: "excavate-left-info-item" }, [
                    _c("div", { staticStyle: { color: "#02a7f0" } }, [
                      _vm._v("B级"),
                    ]),
                    _c("span", { staticStyle: { opacity: "0.6" } }, [
                      _vm._v(_vm._s(_vm.trackingNumber.blevel)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "search-div" },
              [
                _c("span", [_vm._v("用户昵称：")]),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "请输入昵称" },
                  model: {
                    value: _vm.searchForm.nickname,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "nickname", $$v)
                    },
                    expression: "searchForm.nickname",
                  },
                }),
                _c("span", { staticClass: "margin-left" }, [
                  _vm._v("平台号："),
                ]),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "请输入平台ID" },
                  model: {
                    value: _vm.searchForm.code,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "code", $$v)
                    },
                    expression: "searchForm.code",
                  },
                }),
                _c("span", { staticClass: "margin-left" }, [
                  _vm._v("购车城市："),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择城市",
                    },
                    model: {
                      value: _vm.searchForm.city_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "city_code", $$v)
                      },
                      expression: "searchForm.city_code",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "全部", value: " " } }),
                    _vm._l(_vm.buyCarList, function (item) {
                      return _c("el-option", {
                        key: item.city_code,
                        attrs: { label: item.name, value: item.city_code },
                      })
                    }),
                  ],
                  2
                ),
                _c("br"),
                _c("br"),
                _c("span", [_vm._v("添加时间：")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "110px" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.searchForm.days,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "days", $$v)
                      },
                      expression: "searchForm.days",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "近7天", value: "7" } }),
                    _c("el-option", {
                      attrs: { label: "近15天", value: "15" },
                    }),
                    _c("el-option", {
                      attrs: { label: "近30天", value: "30" },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "margin-left" }, [_vm._v("车型：")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      multiple: "",
                      placeholder: "请选择",
                    },
                    model: {
                      value: _vm.searchForm.seriesArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "seriesArr", $$v)
                      },
                      expression: "searchForm.seriesArr",
                    },
                  },
                  _vm._l(_vm.seriesList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "margin-left",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.getTableList(true)
                      },
                    },
                  },
                  [_vm._v("搜索")]
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                ref: "singleTable",
                staticStyle: { width: "100%", margin: "30px 0 16px" },
                attrs: {
                  data: _vm.trackingList ? _vm.trackingList.list : [],
                  border: "",
                  "highlight-current-row": "",
                  height: "600",
                },
                on: { "current-change": _vm.handleCurrentChange },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "avatar_url", label: "头像", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("img", {
                            attrs: {
                              src:
                                scope.row.avatar_url ||
                                require("@/assets/dev/defult-avatar.png"),
                              width: "30",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "nickname",
                    label: "用户昵称",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "origin", label: "来源", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.origin == 1 ? "抖音" : "快手")
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "code", label: "用户平台号", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.origin == 1
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.code || "--")),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.code ||
                                      scope.row.customer_platform_id ||
                                      "--"
                                  )
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "favorite_level",
                    label: "意向",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "address",
                    label: "购车城市",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "series_name",
                    label: "意向车型",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "last_enter_time",
                    label: "最近发言时间",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "ctime", label: "添加时间", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "action", label: "操作", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "text-decoration": "underline",
                                color: "cornflowerblue",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openFindModal(scope)
                                },
                              },
                            },
                            [_vm._v("找他")]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "12px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteClick(scope)
                                },
                              },
                            },
                            [_vm._v("忽略")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "right" } },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.searchForm.currentPage,
                    "page-size": _vm.searchForm.pageSize,
                    layout: "prev, pager, next, jumper",
                    total: _vm.searchForm.total || 0,
                  },
                  on: {
                    "current-change": _vm.pageChange,
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.searchForm, "currentPage", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.searchForm, "currentPage", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.trackingList && _vm.trackingList.list.length > 0
          ? _c(
              "div",
              { staticClass: "excavate-right" },
              [
                _c("div", { staticClass: "excavate-right-title" }, [
                  _vm._v("客户详情："),
                ]),
                _vm.pollingInfoData && _vm.currentRow
                  ? _c("div", { staticClass: "excavate-right-info" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.pollingInfoData.avatar ||
                            require("@/assets/dev/defult-avatar.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                              width: "210px",
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pollingInfoData.nickname))]
                        ),
                        _c("div", { staticStyle: { "font-size": "16px" } }, [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "18px" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.pollingInfoData.gender == 0
                                    ? "未知"
                                    : _vm.pollingInfoData.gender == 1
                                    ? "男"
                                    : "女"
                                )
                              ),
                            ]
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.currentRow.area))]),
                        ]),
                      ]),
                      _vm.currentRow
                        ? _c("div", { staticClass: "pos-right" }, [
                            _vm.currentRow.favorite_level == "H"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#ff001f" } },
                                  [_vm._v("H")]
                                )
                              : _vm.currentRow.favorite_level == "A"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#577ff4" } },
                                  [_vm._v("A")]
                                )
                              : _vm.currentRow.favorite_level == "B"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#02a7f0" } },
                                  [_vm._v("B")]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.currentRow.favorite_level)),
                                ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.pollingInfoData
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "16px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            staticStyle: {
                              "background-image":
                                "linear-gradient(179deg, #64A9F3 0%, #516EF3 100%)",
                              margin: "0px 10px 5px 0",
                            },
                            attrs: { size: "mini" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.editTime(
                                  _vm.pollingInfoData.last_live_enter_time
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            staticStyle: {
                              "background-image":
                                "linear-gradient(180deg, #FBBC6C 0%, #FC9965 100%)",
                              margin: "0px 10px 5px 0",
                            },
                            attrs: { size: "mini" },
                          },
                          [
                            _vm._v(
                              "本月捧场 " +
                                _vm._s(_vm.pollingInfoData.enter_count30) +
                                "次"
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            staticStyle: {
                              "background-image":
                                "linear-gradient(180deg, #27C77D 0%, #27B388 100%)",
                              margin: "0px 10px 5px 0",
                            },
                            attrs: { size: "mini" },
                          },
                          [
                            _vm._v(
                              "累计送 " + _vm._s(_vm.pollingInfoData.gitf_count)
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.intentionData &&
                _vm.intentionData.series &&
                _vm.intentionData.series.length > 0
                  ? _c(
                      "div",
                      { staticClass: "series" },
                      [
                        _c("div", { staticClass: "title" }, [_vm._v("意向：")]),
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              height: "100px",
                              "indicator-position": "none",
                            },
                          },
                          _vm._l(
                            _vm.intentionData.series,
                            function (item, index) {
                              return _c("el-carousel-item", { key: index }, [
                                _c("div", { staticClass: "series-item" }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.avatar,
                                      alt: "",
                                      width: "100",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "（" +
                                        _vm._s(item.count) +
                                        "）"
                                    ),
                                  ]),
                                ]),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "tabs" },
                  _vm._l(_vm.tabData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class:
                          _vm.currentTab == item.type
                            ? "tab-item active"
                            : "tab-item",
                        on: {
                          click: function ($event) {
                            _vm.currentTab = item.type
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentTab == 1,
                        expression: "currentTab == 1",
                      },
                    ],
                    staticClass: "interaction",
                    attrs: { id: "interaction1" },
                  },
                  [
                    _vm.pollingVData
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.loadInteractionV,
                                expression: "loadInteractionV",
                              },
                            ],
                            staticClass: "list",
                            attrs: {
                              "infinite-scroll-distance": 30,
                              "infinite-scroll-disabled": "disabledV",
                            },
                          },
                          _vm._l(_vm.pollingVData.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "interaction-item" },
                              [
                                item.content && item.content != ""
                                  ? _c(
                                      "div",
                                      { staticStyle: { height: "35px" } },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "clock" },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(item.publish_time)),
                                        ]),
                                        _vm.isToday(item.publish_time)
                                          ? _c(
                                              "span",
                                              { staticClass: "tag1" },
                                              [_vm._v("今天")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.content == "" || !item.content
                                        ? "送了礼物!"
                                        : item.content
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.loadingV
                      ? _c("p", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("加载中..."),
                        ])
                      : _vm._e(),
                    _vm.noMoreV
                      ? _c("p", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("没有更多了"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentTab == 2,
                        expression: "currentTab == 2",
                      },
                    ],
                    staticClass: "interaction",
                    attrs: { id: "interaction2" },
                  },
                  [
                    _vm.pollingAllData
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.loadInteractionAll,
                                expression: "loadInteractionAll",
                              },
                            ],
                            staticClass: "list",
                            attrs: {
                              "infinite-scroll-distance": 30,
                              "infinite-scroll-disabled": "disabledAll",
                            },
                          },
                          _vm._l(
                            _vm.pollingAllData.list,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "interaction-item" },
                                [
                                  item.content && item.content != ""
                                    ? _c(
                                        "div",
                                        { staticStyle: { height: "35px" } },
                                        [
                                          _c("svg-icon", {
                                            attrs: { "icon-class": "clock" },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(item.publish_time)),
                                          ]),
                                          _vm.isToday(item.publish_time)
                                            ? _c(
                                                "span",
                                                { staticClass: "tag1" },
                                                [_vm._v("今天")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "content" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.content == "" || !item.content
                                          ? "送了礼物!"
                                          : item.content
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.loadingAll
                      ? _c("p", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("加载中..."),
                        ])
                      : _vm._e(),
                    _vm.noMoreAll
                      ? _c("p", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("没有更多了"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("el-button", {
                  staticStyle: { margin: "10px 0px 10px 320px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-caret-top",
                    circle: "",
                  },
                  on: { click: _vm.scrollTop },
                }),
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.openAddModal },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("findCode", { ref: "findCode" }),
      _c("addTracking", {
        ref: "addTracking",
        attrs: { info: _vm.currentRow, isEdit: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }