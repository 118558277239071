"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("@/router");
var state = {
  accessRoute: []
};
var mutations = {
  SET_ROUTE: function SET_ROUTE(state, _ref) {
    var route = _ref.route;
    state.accessRoute = route;
  }
};
var actions = {
  generatRoute: function generatRoute(_ref2, _ref3) {
    var commit = _ref2.commit,
      state = _ref2.state;
    var _ref3$routes = _ref3.routes,
      routes = _ref3$routes === void 0 ? [] : _ref3$routes;
    return new Promise(function (resolve, reject) {
      var _r = routes;
      commit('SET_ROUTE', {
        route: _r
      });
      resolve(_r);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;