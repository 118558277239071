var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.currentMedia
            ? _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container",
                  on: { command: _vm.handleToggleMedia },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "user-avatar" },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "avatar",
                            attrs: {
                              src: _vm.avatarFormat(
                                _vm.currentMedia.avatar || ""
                              ),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/dev/default_head_logo.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("img", {
                          staticClass: "dy-logo",
                          attrs: {
                            src: require("@/assets/icon/dy.png"),
                            alt: "",
                            srcset: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" " + _vm._s(_vm.currentMedia.author_name) + " "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm._l(_vm.mediaList, function (item, index) {
                        return _c(
                          "el-dropdown-item",
                          { key: index, attrs: { command: item.author_id } },
                          [
                            _c(
                              "div",
                              { staticClass: "media-item" },
                              [
                                _c("div", { staticClass: "media-info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "user-avatar" },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "avatar",
                                          attrs: {
                                            src: _vm.avatarFormat(
                                              item.avatar || ""
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error",
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: {
                                                  src: require("@/assets/dev/default_head_logo.png"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("img", {
                                        staticClass: "dy-logo",
                                        attrs: {
                                          src: require("@/assets/icon/dy.png"),
                                          alt: "",
                                          srcset: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "media-name" }, [
                                    _c("div", [
                                      _vm._v(_vm._s(item.author_name)),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        item.oauth_status === 1
                                          ? _c(
                                              "a-tag",
                                              { attrs: { color: "green" } },
                                              [_vm._v(" 已授权 ")]
                                            )
                                          : _vm._e(),
                                        item.oauth_status === 2
                                          ? _c("a-tag", [_vm._v(" 未授权 ")])
                                          : _vm._e(),
                                        item.oauth_status === 3
                                          ? _c(
                                              "a-tag",
                                              { attrs: { color: "red" } },
                                              [_vm._v(" 授权失效 ")]
                                            )
                                          : _vm._e(),
                                        item.oauth_status === 4
                                          ? _c(
                                              "a-tag",
                                              { attrs: { color: "orange" } },
                                              [_vm._v(" 授权项不足 ")]
                                            )
                                          : _vm._e(),
                                        item.function_status === 0
                                          ? _c(
                                              "a-tag",
                                              { attrs: { color: "green" } },
                                              [_vm._v(" 功能全部开启 ")]
                                            )
                                          : _vm._e(),
                                        item.function_status === 1
                                          ? _c(
                                              "a-tag",
                                              { attrs: { color: "orange" } },
                                              [_vm._v(" 功能部分开启 ")]
                                            )
                                          : _vm._e(),
                                        item.function_status === 2
                                          ? _c("a-tag", [
                                              _vm._v(" 功能未开启 "),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("a-checkbox", {
                                  attrs: {
                                    checked:
                                      _vm.currentMedia.author_id ===
                                      item.author_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "change-tips" },
                        [
                          _c("a-icon", {
                            staticClass: "icon",
                            attrs: { type: "info-circle", theme: "filled" },
                          }),
                          _vm._v("若需要变更，可以点击这里"),
                          _c(
                            "a",
                            { on: { click: _vm.changeMedia } },
                            [
                              _c(
                                "a-spin",
                                {
                                  attrs: { spinning: _vm.isCheckMediaLoading },
                                },
                                [_vm._v(" 修改客情媒体号 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container" },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("svg-icon", {
                    staticStyle: {
                      "margin-right": "6px",
                      cursor: "pointer",
                      "font-size": "24px",
                    },
                    attrs: { "icon-class": "aftlogo" },
                  }),
                  _c(
                    "span",
                    [
                      _vm._v(_vm._s(_vm.userInformation.account)),
                      _vm.userInformation.dealerName
                        ? [
                            _vm._v(
                              "（" +
                                _vm._s(_vm.userInformation.dealerName) +
                                "）"
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dy-box",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _c("p", [_vm._v("风火轮账号")]),
                      _c("p", [
                        _vm._v(
                          "账号名：" + _vm._s(_vm.userInformation.account)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "使用人：" + _vm._s(_vm.userInformation.userName)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dy-box",
                      staticStyle: { padding: "0px 20px" },
                    },
                    [
                      _c("p", [_vm._v("经销商信息")]),
                      _c("p", [
                        _vm._v(
                          "经销商：" + _vm._s(_vm.userInformation.dealerName)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "品牌：" + _vm._s(_vm.userInformation.principalName)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "", id: "test-logout" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { display: "block" } },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "leave" } }),
                          _vm._v("退出登录"),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }