var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "user-qr-code",
          attrs: {
            title: "联系ta",
            visible: _vm.isVisiable,
            maskClosable: false,
          },
          on: { cancel: _vm.closeModal },
        },
        [
          _c("a-spin", { attrs: { spinning: _vm.isLoading } }, [
            _c(
              "div",
              { staticStyle: { "min-height": "246px" } },
              [
                !_vm.isLoading && this.dyQrcodeUrl
                  ? [
                      _c("div", { staticClass: "code-box" }, [
                        _c("img", {
                          attrs: { src: this.dyQrcodeUrl, alt: "", srcset: "" },
                        }),
                        _c("div", { staticClass: "nickname" }, [
                          _vm._v(" " + _vm._s(_vm.data.userNickname) + " "),
                        ]),
                        _c("div", { staticClass: "dy-name" }, [
                          _vm._v(
                            "抖音号：" + _vm._s(_vm.data.userDyCode || "无")
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v("使用抖音APP扫一扫即可联系用户"),
                      ]),
                    ]
                  : _vm._e(),
                !_vm.isLoading && !this.dyQrcodeUrl
                  ? [
                      _c("img", {
                        staticClass: "error-img",
                        attrs: {
                          src: require("@/assets/live_images/dy-qrcode-error.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v("暂未获取到用户抖音二维码"),
                      ]),
                      _c("div", { staticClass: "tips grey" }, [
                        _vm._v("请稍后重试"),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _vm.isLoading
                ? _c("a-button", { on: { click: _vm.closeModal } }, [
                    _vm._v(" 取消 "),
                  ])
                : _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" 关闭 ")]
                  ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }