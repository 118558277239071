"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _live_comment = _interopRequireDefault(require("@/api/live_comment"));
var _attention = _interopRequireDefault(require("@/api/attention"));
var _common = _interopRequireDefault(require("@/api/common"));
var _columns = _interopRequireDefault(require("./columns.js"));
var _ReplyStrategyDrawer = _interopRequireDefault(require("./components/ReplyStrategyDrawer.vue"));
var _ReplyRulesDrawer = _interopRequireDefault(require("./components/ReplyRulesDrawer.vue"));
var _ReplyModal = _interopRequireDefault(require("./components/ReplyModal.vue"));
var _TextEllipsis = _interopRequireDefault(require("@/components/TextEllipsis/TextEllipsis.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var configPayload = {
  columns: _columns.default.columns_all,
  http_api: _live_comment.default.getAllList
};
var _default = {
  components: {
    ReplyStrategyDrawer: _ReplyStrategyDrawer.default,
    ReplyRulesDrawer: _ReplyRulesDrawer.default,
    ReplyModal: _ReplyModal.default,
    TextEllipsis: _TextEllipsis.default
  },
  data: function data() {
    return {
      layout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      tab_active: 0,
      search_form: {
        content: "",
        videoId: undefined,
        sceneId: undefined,
        commentEndTime: "",
        commentStartTime: "",
        replyEndTime: "",
        replyStartTime: ""
      },
      comment_time: [],
      response_time: [],
      configPayload: configPayload,
      video_list: [],
      attention_list: [],
      strategy_visible: false,
      rules_visible: false,
      city_setting: {},
      confirm_loading: false,
      route_loading: false,
      active_id: null,
      show_modal: false,
      tab_str: "全部"
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.search_form.sceneId = this.$route.query.id;
    }
    // 策略已响应
    if ("".concat(this.$route.query.tab) === "2") {
      // 时间选择
      this.response_time = [this.moment().subtract(6, "days"), this.moment().endOf("day")];
      this.onChangeResDate(this.response_time);
    }
    // this.comment_time = [
    //   this.moment().subtract(6, "days"),
    //   this.moment().endOf("day"),
    // ];
    // this.onChangeDate(this.comment_time);
    this.filtrate.setTracking({
      en: "zxmk_yx_pljcqb_view"
    });
    this.getAttentionList();
    this.getVideoSource();
    if (this.$route.query.tab) {
      this.tab_active = 2;
      this.changeTabs(2);
    } else {
      this.$refs.cntable.getTableList(1, 10);
    }
  },
  methods: {
    disabledDate: function disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.moment().endOf("day");
    },
    disposeTrackStatus: function disposeTrackStatus(value) {
      var str = "";
      switch (value) {
        case 1:
          str = "用户未回复,去私信";
          break;
        case 2:
          str = "未跟进,去私信";
          break;
        case 3:
          str = "继续跟进,去私信";
          break;
        default:
          break;
      }
      return str;
    },
    resetTable: function resetTable() {
      this.$refs.cntable.getTableList(1, 10);
    },
    // 策略列表
    getAttentionList: function getAttentionList() {
      var _this = this;
      _attention.default.getVideoReply().then(function (res) {
        if (res.code == 200) {
          _this.attention_list = res.data;
        }
      });
    },
    // 来源视频列表
    getVideoSource: function getVideoSource() {
      var _this2 = this;
      _live_comment.default.getVideoSource().then(function (res) {
        if (res.code == 200) {
          _this2.video_list = res.data;
        }
      });
    },
    searchClick: function searchClick() {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs.cntable.getTableList(1, 10);
      });
      var _this$search_form = this.search_form,
        sceneId = _this$search_form.sceneId,
        content = _this$search_form.content,
        videoId = _this$search_form.videoId,
        commentStartTime = _this$search_form.commentStartTime,
        commentEndTime = _this$search_form.commentEndTime,
        replyStartTime = _this$search_form.replyStartTime,
        replyEndTime = _this$search_form.replyEndTime;
      this.filtrate.setTracking({
        en: "zxmk_yx_pljccx_click",
        kv: {
          filterItem: {
            comment: content,
            strategy: sceneId,
            videoSource: videoId,
            cTime: "".concat(commentStartTime, "-").concat(commentEndTime),
            replyTime: "".concat(replyStartTime, "-").concat(replyEndTime)
          },
          pageName: this.tab_str
        }
      });
    },
    resetSearch: function resetSearch() {
      var _this4 = this;
      this.$set(this, "search_form", {
        content: "",
        videoId: undefined,
        sceneId: undefined,
        commentEndTime: "",
        commentStartTime: "",
        replyEndTime: "",
        replyStartTime: ""
      });
      this.comment_time = [];
      this.response_time = [];
      this.$nextTick(function () {
        _this4.$refs.cntable.getTableList(1, 10);
      });
    },
    //评论日期选择框
    onChangeDate: function onChangeDate(dates) {
      var start_time = "";
      var end_time = "";
      if (dates.length) {
        start_time = this.moment(dates[0]._d).format("YYYY-MM-DD") + " 00:00:00";
        end_time = this.moment(dates[1]._d).format("YYYY-MM-DD HH:mm:ss");
        this.search_form.commentStartTime = start_time;
        this.search_form.commentEndTime = end_time;
      } else {
        this.search_form.commentStartTime = "";
        this.search_form.commentEndTime = "";
      }
    },
    onChangeResDate: function onChangeResDate(dates) {
      var start_time = "";
      var end_time = "";
      if (dates.length) {
        start_time = this.moment(dates[0]._d).format("YYYY-MM-DD") + " 00:00:00";
        end_time = this.moment(dates[1]._d).format("YYYY-MM-DD HH:mm:ss");
        this.search_form.replyStartTime = start_time;
        this.search_form.replyEndTime = end_time;
      } else {
        this.search_form.replyStartTime = "";
        this.search_form.replyEndTime = "";
      }
    },
    disposeLongTime: function disposeLongTime(value) {
      console.log(value);
      var result = Number(value);
      if (result) {
        var d = parseInt(result / (60 * 24));
        var h = parseInt(result % (60 * 24) / 60);
        var m = result % 60;
        if (d > 7) {
          return d + " 天 ";
        } else if (d <= 7 && d > 1) {
          return d + " 天 " + h + " 小时 ";
        } else {
          if (h > 0) {
            return h + " 小时 " + m + " 分钟 ";
          } else {
            return m + " 分钟 ";
          }
        }
      } else {
        return "——";
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 切换
    changeTabs: function changeTabs(value) {
      var _this5 = this;
      var et = {};
      switch (value) {
        case 0:
          if (this.search_form.statusList) {
            delete this.search_form.statusList;
          }
          this.configPayload = {
            columns: _columns.default.columns_all,
            http_api: _live_comment.default.getAllList
          };
          et = {
            en: "zxmk_yx_pljcqb_view"
          };
          this.tab_str = "全部";
          break;
        case 6:
          this.search_form.statusList = "";
          this.configPayload = {
            columns: _columns.default.columns_value,
            http_api: _live_comment.default.getSceneList
          };
          et = {
            en: "zxmk_yx_pljcjzpl_view"
          };
          this.tab_str = "价值评论";
          break;
        case 2:
          this.search_form.statusList = "2,6";
          this.configPayload = {
            columns: _columns.default.columns_success,
            http_api: _live_comment.default.getSceneList
          };
          et = {
            en: "zxmk_yx_pljcclyxy_view"
          };
          this.tab_str = "策略已响应";
          break;
        case 1:
          this.search_form.statusList = "1,4";
          this.configPayload = {
            columns: _columns.default.columns_fail,
            http_api: _live_comment.default.getSceneList
          };
          et = {
            en: "zxmk_yx_pljcclwxy_view"
          };
          this.tab_str = "策略未响应";
          break;
        default:
          break;
      }
      this.$nextTick(function () {
        _this5.$refs.cntable.getTableList(1, 10);
        _this5.filtrate.setTracking(et);
      });
    },
    /**
     * 打开自动回复drawer
     * @param {number} id 命中的策略Id
     * @param {string} name 命中的策略名称
     * @param {number} type 业务场景类型：1：策略，2：关键词规则
     */
    openAutoReplayDrawer: function openAutoReplayDrawer(id, name, type) {
      this.filtrate.setTracking({
        en: "zxmk_yx_pljcqyzdhf_click",
        kv: {
          strategyName: name
        }
      });
      console.log(type);
      if (type === 1) {
        this.$refs["strategyDrawer"].liveAutoReplyModel(id, name);
        this.strategy_visible = true;
      } else {
        this.$refs["rulesDrawer"].liveAutoReplyModel(id, name);
        this.rules_visible = true;
      }
    },
    startUseing: function startUseing(name) {
      this.filtrate.setTracking({
        en: "zxmk_yx_pljcqyzdhfqy_click",
        kv: {
          strategyName: name
        }
      });
    },
    closeDrawerStrategy: function closeDrawerStrategy() {
      this.straegy_visible = false;
    },
    // 查询目标城市配置
    getTargetCity: function getTargetCity() {
      var _this6 = this;
      _live_comment.default.getliveUserTargetCity().then(function (res) {
        console.log("目标城市配置", res);
        if (res.code == 200) {
          _this6.city_setting = res.data;
        }
      });
    },
    openComment: function openComment(id) {
      this.filtrate.setTracking({
        en: "zxmk_yx_pljchfpl_click",
        kv: {
          openID: id,
          pageName: this.tab_str
        }
      });
      this.show_modal = true;
      this.$refs.replyModal.getVideoComment(id);
    },
    closeModal: function closeModal() {
      this.show_modal = false;
    },
    selectTrack: function selectTrack(value) {
      this.filtrate.setTracking({
        en: "zxmk_yx_gjqk_click",
        kv: {
          filterItem: value
        }
      });
    },
    goInteraction: function goInteraction(id, videoId, authorOpenId) {
      this.filtrate.setTracking({
        en: "zxmk_yx_pljclysplj_click",
        kv: {
          openID: authorOpenId,
          pageName: this.tab_str
        }
      });
      this.$router.push({
        path: "/short_management",
        query: {
          type: "douyin",
          author_id: id,
          videoId: videoId
        }
      });
    },
    gotoPrivate: function gotoPrivate(id) {
      this.$router.push({
        path: "/private_letter_manage/conversation",
        query: {
          userOpenId: id
        }
      });
      this.filtrate.setTracking({
        en: "zxmk_yx_pljcsx_click",
        kv: {
          openID: id,
          pageName: this.tab_str
        }
      });
    }
  }
};
exports.default = _default;