var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "tips-box" }, [
        _c(
          "div",
          { staticClass: "title-header" },
          [
            _vm._v(" 私信智回 "),
            _c("a-switch", {
              attrs: { loading: _vm.isOpening },
              on: { click: _vm.switchCheck },
              model: {
                value: _vm.isOpened,
                callback: function ($$v) {
                  _vm.isOpened = $$v
                },
                expression: "isOpened",
              },
            }),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "1、用户进入会话，系统根据预设的对话流程，进行引导性提问和回复，最终获取用户的联系方式"
          ),
        ]),
        _c("p", [
          _vm._v("2、每次提问或回复用户时，由AI模型从多条话术中选择最适合的"),
        ]),
        _c(
          "div",
          { staticClass: "sub-header" },
          [
            _vm._v(" 自动转人工 "),
            _c("a-switch", {
              attrs: { size: "small", loading: _vm.isAutoOpening },
              on: { click: _vm.autoSwitchCheck },
              model: {
                value: _vm.autoForm.auto,
                callback: function ($$v) {
                  _vm.$set(_vm.autoForm, "auto", $$v)
                },
                expression: "autoForm.auto",
              },
            }),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "1、建议保持开启，客服可随时回复某用户的私信，系统将暂停该用户的Ai自动回复，其他用户不受影响"
          ),
        ]),
        _c("p", [
          _vm._v(
            "2、Ai自动回复暂停期间，若客服不及时回复粉丝消息（超过2分30秒）系统将恢复Ai自动回复，以保障3分钟回复率"
          ),
        ]),
        _c("p", [
          _vm._v(
            "3、Ai自动回复暂停期间，若粉丝长时间不回客服消息（超过20分）系统将恢复Ai自动回复，进行召回等操作"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-content" },
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.activeType,
                callback: function ($$v) {
                  _vm.activeType = $$v
                },
                expression: "activeType",
              },
            },
            [
              _c("a-tab-pane", { key: 1, attrs: { tab: "基础对话配置" } }),
              _c("a-tab-pane", { key: 2, attrs: { tab: "自定义回复配置" } }),
            ],
            1
          ),
          _c("BasicConfiguration", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeType == 1,
                expression: "activeType == 1",
              },
            ],
            ref: "BasicConfiguration",
          }),
          _c("CustomizedConfiguration", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeType == 2,
                expression: "activeType == 2",
              },
            ],
            ref: "CustomizedConfiguration",
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "启用自动转人工" },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("p", { staticClass: "color-orange" }, [
            _vm._v("务必先关闭其他自动回复私信工具！"),
          ]),
          _c("p", [
            _vm._v("否则系统把其他工具（如抖音自动回复）也会识别为人工回复。"),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isSubmit,
                    loading: _vm.isSubmit,
                  },
                  on: { click: _vm.handleOk },
                },
                [_vm._v("确认启用")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("OpenCheckModal", { ref: "OpenCheckModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }