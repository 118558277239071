"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToTime = convertToTime;
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
var _typeof2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _moment = _interopRequireDefault(require("moment"));
var _arguments = typeof arguments === "undefined" ? void 0 : arguments; //  type :'add' or 'reduce
var Calculation = function Calculation(type, num1, num2) {
  var temp1, temp2, a;
  try {
    // 获取temp1小数点后的长度
    temp1 = num1.toString().split('.')[1].length;
  } catch (e) {
    temp1 = 0;
  }
  try {
    // 获取temp2小数点后的长度
    temp2 = num2.toString().split('.')[1].length;
  } catch (e) {
    temp2 = 0;
  }
  // Math.max(temp1, temp2) 为了获取temp1和temp2两个值中较大的一个
  // Math.pow(a,b) 表示 a 的 b 次方
  a = Math.pow(10, Math.max(temp1, temp2));

  // 计算的方式是先将所有的小数乘为整数，待加减运算执行完之后再除去对应的 a 的值，将其变为小数输出
  // 先判断执行的方式是否是加法，不是的话则执行减法运算
  return type === 'add' ? (num1 * a + num2 * a) / a : (num1 * a - num2 * a) / a;
};

// 万、亿位处理
var numEdit = function numEdit(value) {
  if (value && typeof value === 'number') {
    if (Math.abs(value) > 100000000) {
      value = "".concat(parseFloat((value / 100000000).toFixed(2)), "\u4EBF");
    } else if (Math.abs(value) > 10000) {
      value = "".concat(parseFloat((value / 10000).toFixed(2)), "w");
    } else {
      value = parseFloat(value.toFixed(2));
    }
  }
  return value;
};
var numEditComma = function numEditComma(val) {
  if (!val) return 0.0;
  var USPrice = Number.parseFloat(val).toLocaleString('en-US');
  var lastDot = USPrice.toString().indexOf('.');
  // // 完全是整数, 需要添加小数点
  // if (lastDot === -1) USPrice += '.00'

  // // 返回数据是一位小数，用0补齐为两位小数
  // if (USPrice.toString().substring(lastDot + 1).length === 1) USPrice += '0'

  return USPrice;
};

/*
*
日期转换，毫秒数改为时间日期
*
*/
var timeChange = function timeChange(time, state) {
  var unixTimestamp = new Date(time);
  var commonTime = unixTimestamp.toLocaleString();
  if (state === 'LL') {
    Date.prototype.toLocaleString = function () {
      var h = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
      var m = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
      var s = this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds();
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate() + ' ' + h + ':' + m + ':' + s;
    };
    return commonTime;
  } else if (state === 'Ll') {
    Date.prototype.toLocaleString = function () {
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate() + ' ' + this.getHours() + ':' + this.getMinutes() + ':' + this.getSeconds();
    };
    return commonTime;
  } else {
    Date.prototype.toLocaleString = function () {
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate();
    };
    return commonTime;
  }
};

/*
 *   两个时间差
 */
var timeInterval = function timeInterval(startDate, endDate) {
  if (!startDate || !endDate) return;
  // 一般后端返回时间格式为2016-03-28 10:27:00，需要转为'2016/03/28 10:17:22'
  startDate = startDate.replace(/\-/g, '/');
  endDate = endDate.replace(/\-/g, '/');
  var sDate = new Date(startDate);
  var eDate = new Date(endDate);
  var timeI = eDate.getTime() - sDate.getTime();
  var day = Math.floor(timeI / (24 * 3600 * 1000));
  var leave1 = timeI % (24 * 3600 * 1000);
  var hour = Math.floor(leave1 / (3600 * 1000));
  var leave2 = leave1 % (3600 * 1000);
  var minutes = Math.floor(leave2 / (60 * 1000));
  var leave3 = leave2 % (60 * 1000);
  var seconds = Math.round(leave3 / 1000);
  var value = '';
  if (day) {
    value = "".concat(day, "\u5929").concat(hour, "\u5C0F\u65F6").concat(minutes, "\u5206").concat(seconds, "\u79D2");
    return value;
  }
  if (hour) {
    value = "".concat(hour, "\u5C0F\u65F6").concat(minutes, "\u5206").concat(seconds, "\u79D2");
    return value;
  }
  if (minutes) {
    value = "".concat(minutes, "\u5206").concat(seconds, "\u79D2");
    return value;
  }
  if (seconds) {
    value = "".concat(minutes, "\u5206").concat(seconds, "\u79D2");
    return value;
  }
};

/*
 *   判断是否为demo
 */
var isDemo = function isDemo() {
  if (window.location.host.indexOf('demo') >= 0) {
    return true;
  } else {
    return false;
  }
};
var parseTime = function parseTime(time, cFormat) {
  if (_arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
};

/*
  时间转换
<24小时，展示【{{h}}小时{{m}}分钟】
>24小时，展示【{{d}}天{{h}}小时】
>7天，展示【{{d}}天】

示例用法
const minutes = 175;

const formattedDuration = formatDuration(minutes);
console.log(formattedDuration);
*/
var formatDuration = function formatDuration(minutes) {
  var days = Math.floor(minutes / (60 * 24));
  var hours = Math.floor(minutes % (60 * 24) / 60);
  var remainingMinutes = minutes % 60;
  if (days < 1) {
    if (hours < 1) {
      return "".concat(remainingMinutes, "\u5206\u949F");
    } else {
      return "".concat(hours, "\u5C0F\u65F6").concat(remainingMinutes, "\u5206\u949F");
    }
  } else if (days < 7) {
    return "".concat(days, "\u5929").concat(hours, "\u5C0F\u65F6");
  } else {
    return "".concat(days, "\u5929");
  }
};

/**
 * @description 给定的秒数转换为小时、分钟和秒的格式
 * @param seconds
 */
function convertToTime(seconds) {
  var hasSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!seconds) return '-';
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor(seconds % 3600 / 60);
  var remainingSeconds = seconds % 60;
  var formattedTime = '';
  if (hours > 0) {
    formattedTime += hours + '小时' + (hasSpace ? ' ' : '');
  }
  if (minutes > 0 || hours > 0) {
    formattedTime += minutes + '分钟' + (hasSpace ? ' ' : '');
  }
  formattedTime += remainingSeconds + '秒';
  return formattedTime.trim();
}
var _default = {
  Calculation: Calculation,
  numEdit: numEdit,
  timeChange: timeChange,
  timeInterval: timeInterval,
  numEditComma: numEditComma,
  isDemo: isDemo,
  parseTime: parseTime,
  formatDuration: formatDuration,
  convertToTime: convertToTime
};
exports.default = _default;