var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emoji" }, [
    _c(
      "ul",
      { attrs: { id: "chatbox-emoji-panel" } },
      _vm._l(_vm.list, function (item, index) {
        return _c("li", {
          key: index,
          domProps: { innerHTML: _vm._s(item.node.outerHTML) },
          on: {
            click: function ($event) {
              return _vm.clickEmoji(item.symbol)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }