var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-card",
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.handleSubDaysChange },
                  model: {
                    value: _vm.subDays,
                    callback: function ($$v) {
                      _vm.subDays = $$v
                    },
                    expression: "subDays",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: 7 } }, [
                    _vm._v(" 近7天 "),
                  ]),
                  _c("a-radio-button", { attrs: { value: 30 } }, [
                    _vm._v(" 近30天 "),
                  ]),
                  _c("a-radio-button", { attrs: { value: 90 } }, [
                    _vm._v(" 近90天 "),
                  ]),
                ],
                1
              ),
              _c("a-range-picker", {
                staticClass: "ml-10",
                attrs: {
                  "disabled-date": _vm.disabledDate,
                  "allow-clear": false,
                },
                on: { change: _vm.handleDateChange },
                model: {
                  value: _vm.selectedDates,
                  callback: function ($$v) {
                    _vm.selectedDates = $$v
                  },
                  expression: "selectedDates",
                },
              }),
              _c("span", { staticClass: "ml-10 color-grey" }, [
                _vm._v("仅支持查询近90天（不含当天）"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "statistics-time" },
            [
              _vm._v(
                " 统计周期：" +
                  _vm._s(_vm.selectedDates[0].format("yyyy-MM-DD")) +
                  " 00:00-" +
                  _vm._s(_vm.selectedDates[1].format("yyyy-MM-DD")) +
                  " 23:59"
              ),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _vm._v(
                "最新数据更新到 " +
                  _vm._s(_vm.moment().subtract(1, "day").format("yyyy-MM-DD")) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "a-card",
            [
              _c("h2", { staticClass: "title" }, [_vm._v("整体数据")]),
              _vm._l(_vm.wholeDataList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "data-list" },
                  [
                    _vm._l(item, function (data, dataIndex) {
                      return [
                        dataIndex !== 0
                          ? _c("div", {
                              key: "block" + dataIndex,
                              staticStyle: { width: "15px" },
                            })
                          : _vm._e(),
                        data
                          ? _c(
                              "div",
                              {
                                key: dataIndex,
                                staticClass: "data-box",
                                class: {
                                  up: Number(data.rate) > 0,
                                  down: Number(data.rate) < 0,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _vm._v(" " + _vm._s(data.label) + " "),
                                    _c(
                                      "a-tooltip",
                                      { attrs: { title: data.tips } },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "question-circle" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "data-value" }, [
                                  _vm._v(_vm._s(data.value)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("div", [_vm._v("较上一周期")]),
                                    _c("div", { staticClass: "data-rate" }, [
                                      _vm._v(_vm._s(data.rate)),
                                      data.rate !== "-"
                                        ? _c("span", [_vm._v("%")])
                                        : _vm._e(),
                                    ]),
                                    Number(data.rate) > 0
                                      ? _c("a-icon", {
                                          staticClass: "data-rate-icon",
                                          attrs: { type: "caret-up" },
                                        })
                                      : _vm._e(),
                                    Number(data.rate) < 0
                                      ? _c("a-icon", {
                                          staticClass: "data-rate-icon",
                                          attrs: { type: "caret-down" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c("div", {
                              key: dataIndex,
                              staticClass: "data-box-emtpy",
                            }),
                      ]
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c(
            "a-card",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("h2", { staticClass: "title" }, [_vm._v("私信开口情况")]),
              _c("CommonChart", {
                staticStyle: { width: "100%", height: "500px" },
                attrs: {
                  id: "openMouthChart",
                  options: _vm.chatOpenMouthOptions,
                },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("h2", { staticClass: "title" }, [_vm._v("留资分析")]),
              _c("CommonChart", {
                staticStyle: { width: "100%", height: "500px" },
                attrs: { id: "leaveAnalyze", options: _vm.leaveAnalyzeOptions },
              }),
            ],
            1
          ),
          _c("a-card", { staticStyle: { "margin-top": "15px" } }, [
            _c("h2", { staticClass: "title" }, [_vm._v("留资转化分析")]),
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { overflow: "hidden" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-fill flex-shrink-0",
                    staticStyle: { width: "50%", height: "350px" },
                  },
                  [
                    _vm.isOpenPPLK
                      ? _c("div", { staticClass: "sub-title" }, [
                          _vm._v("私信留资分析"),
                        ])
                      : _vm._e(),
                    _c("CommonChart", {
                      attrs: {
                        id: "leaveFunnelAnalyzeLeft",
                        options: _vm.leaveFunnelAnalyzeLeftOptions,
                      },
                    }),
                  ],
                  1
                ),
                _vm.isOpenPPLK
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-fill flex-shrink-0",
                        staticStyle: { width: "50%", height: "350px" },
                      },
                      [
                        _vm.isOpenPPLK
                          ? _c("div", { staticClass: "sub-title" }, [
                              _vm._v("皮皮拉客留资占比分析"),
                            ])
                          : _vm._e(),
                        _c("CommonChart", {
                          attrs: {
                            id: "leaveFunnelAnalyzeLeft2",
                            options: _vm.leavePieAnalyzeRightOptions,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }