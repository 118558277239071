"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configPayload = void 0;
var _morning_meeting_manage = _interopRequireDefault(require("@/api/morning_meeting_manage"));
var configPayload = {
  columns: [{
    title: '跟进人',
    dataIndex: 'fhlUserName'
  }, {
    title: '拨打电话数',
    dataIndex: 'callNum'
  }, {
    title: '通话总时长',
    dataIndex: 'callDuration',
    scopedSlots: {
      customRender: 'callDuration'
    }
  }, {
    title: '平均通话时长',
    dataIndex: 'avgCallDuration',
    scopedSlots: {
      customRender: 'avgCallDuration'
    }
  }, {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }],
  http_api: _morning_meeting_manage.default.getCallRecordList
};
exports.configPayload = configPayload;