var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "principal-status mt-15" },
        [
          _c("any-switch", {
            attrs: {
              checkedVal: 1,
              unCheckedVal: 0,
              loading: _vm.isAuthorPrincipalUpdating,
            },
            on: { change: _vm.handleAuthorPrincipalOpenChange },
            model: {
              value: _vm.isAuthorPrincipalOpen,
              callback: function ($$v) {
                _vm.isAuthorPrincipalOpen = $$v
              },
              expression: "isAuthorPrincipalOpen",
            },
          }),
          _c("span", { staticClass: "normal-tip" }, [
            _vm._v(
              " 开启后，个人自定义策略开启且没有回复内容时，会用品牌推荐的进行回复 "
            ),
          ]),
        ],
        1
      ),
      _c("cn-table", {
        ref: "cnTable",
        staticClass: "mt-15",
        attrs: {
          initData: true,
          paginationSetting: false,
          configPayload: _vm.principalFirstRecallConfigPayload,
          selectValue: _vm.form,
          ortherPage: true,
          pageSize: 20,
          pageSizeOptions: [],
        },
        scopedSlots: _vm._u([
          {
            key: "reply_msg",
            fn: function (record) {
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    record.reply_msg_vo_list.length > 1
                      ? _c("template", { slot: "title" }, [
                          _c(
                            "pre",
                            {
                              staticStyle: {
                                "max-height": "300px",
                                "overflow-y": "auto",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.initMessage(record.reply_msg_vo_list)
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    record.reply_msg_vo_list.length > 0
                      ? _c("text-ellipsis", {
                          key: record.id,
                          attrs: {
                            placement: "topLeft",
                            value: _vm.initMessage(record.reply_msg_vo_list),
                            limit: 1,
                            tooltipShow: record.reply_msg_vo_list.length === 1,
                          },
                        })
                      : _vm._e(),
                    record.reply_msg_vo_list.length > 1
                      ? _c(
                          "a-button",
                          { staticClass: "pl-0", attrs: { type: "link" } },
                          [_vm._v("更多>>")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (record) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "pl-0",
                      attrs: { type: "link" },
                      on: {
                        click: function ($event) {
                          return _vm.openScriptEditionDrawer(record)
                        },
                      },
                    },
                    [_vm._v("查看回复")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("StrategyScriptPreviewDrawer", {
        ref: "StrategyScriptPreviewDrawer",
        on: {
          success: function () {
            return _vm.$refs["cnTable"].refreshList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }