"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 用户列表
  getUserList: function getUserList(_ref) {
    var current = _ref.current,
      page_size = _ref.page_size,
      user_id = _ref.user_id,
      day = _ref.day,
      fans_open_id = _ref.fans_open_id;
    return request({
      url: "/customer-chat/fans-list/".concat(user_id),
      method: 'POST',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        current: current,
        page_size: page_size,
        user_id: user_id
      },
      data: {
        day: day,
        fans_open_id: fans_open_id
      }
    });
  },
  // 获取MQTT CLIENT SETTING
  getMqttConfig: function getMqttConfig(_ref2) {
    var platform_type = _ref2.platform_type;
    return request({
      url: "/customer-chat/mqttClientSetting",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        platform_type: platform_type
      }
    });
  },
  // 获取MQTT CLIENT SETTING （左侧列表实时更新）
  getMqttConfigUser: function getMqttConfigUser(_ref3) {
    var platform_type = _ref3.platform_type;
    return request({
      url: "/customer-chat/mqttClientSetting",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        platform_type: platform_type
      }
    });
  },
  // 获取历史记录
  getHistoryList: function getHistoryList(_ref4) {
    var fans_open_id = _ref4.fans_open_id,
      author_open_id = _ref4.author_open_id,
      current = _ref4.current,
      page_size = _ref4.page_size;
    return request({
      url: '/customer-chat/chat-history-list',
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        fans_open_id: fans_open_id,
        author_open_id: author_open_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 获取最近及未读聊天记录
  getChatList: function getChatList(_ref5) {
    var fans_open_id = _ref5.fans_open_id,
      author_open_id = _ref5.author_open_id;
    return request({
      url: '/customer-chat/chat-list',
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        fans_open_id: fans_open_id,
        author_open_id: author_open_id
      }
    });
  },
  // 标记已读
  signRead: function signRead(_ref6) {
    var fans_open_id = _ref6.fans_open_id,
      author_open_id = _ref6.author_open_id;
    return request({
      url: '/customer-chat/read',
      method: 'POST',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      data: {
        fans_open_id: fans_open_id,
        author_open_id: author_open_id
      }
    });
  },
  // 回复
  replyMessage: function replyMessage(_ref7) {
    var fans_open_id = _ref7.fans_open_id,
      author_open_id = _ref7.author_open_id,
      content = _ref7.content,
      is_author_send = _ref7.is_author_send;
    return request({
      url: '/customer-chat/reply/v2',
      method: 'POST',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      data: {
        fans_open_id: fans_open_id,
        author_open_id: author_open_id,
        content: content,
        is_author_send: is_author_send
      }
    });
  }
};
exports.default = _default;