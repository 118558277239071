"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  getAwemeDetail: function getAwemeDetail(_ref) {
    var id = _ref.id;
    return request({
      url: "/mirror-data/douyin/aweme/".concat(id),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAwemeHotwords: function getAwemeHotwords(_ref2) {
    var id = _ref2.id;
    return request({
      url: "/mirror-data/douyin/aweme-comment/".concat(id, "/hotwords"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAwemeAvg: function getAwemeAvg(_ref3) {
    var id = _ref3.id;
    return request({
      url: "mirror-data/douyin/author/".concat(id, "/aweme-avg"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getFansStat: function getFansStat(_ref4) {
    var id = _ref4.id;
    return request({
      url: "/mirror-data/douyin/fans/stat",
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        author_id: id
      }
    });
  },
  getComment: function getComment(_ref5) {
    var id = _ref5.id,
      _ref5$params = _ref5.params,
      params = _ref5$params === void 0 ? {} : _ref5$params;
    return request({
      url: "/mirror-data/douyin/aweme-comment/".concat(id, "/comments"),
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: params
    });
  }
};
exports.default = _default;