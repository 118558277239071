"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    fileUrl: {
      type: String
    }
  },
  data: function data() {
    return {
      audioStatus: 'play',
      currentDuration: '0:00',
      totalDuration: '0:00',
      currentDurationNum: 0,
      maxDuration: 0,
      tempDuration: 0,
      playBackRate: '1.0',
      rateList: ['2.0', '1.5', '1.25', '1.0', '0.75', '0.5']
    };
  },
  methods: {
    setTotalDuration: function setTotalDuration(e) {
      this.totalDuration = this.transTime(Math.ceil(e.target.duration));
      this.maxDuration = Math.ceil(e.target.duration);
      this.playAudio();
    },
    // 播放暂停控制
    playAudio: function playAudio() {
      var recordAudio = this.$refs.audioRef; // 获取audio元素
      if (recordAudio.paused) {
        recordAudio.play();
        this.$emit('play');
        this.audioStatus = 'play';
      } else {
        recordAudio.pause();
        this.$emit('pause');
        this.audioStatus = 'pause';
      }
    },
    pauseAudio: function pauseAudio() {
      this.$refs.audioRef.pause();
      this.$emit('pause');
    },
    // 更新进度条与当前播放时间
    updateProgress: function updateProgress(e) {
      if (this.audioStatus === 'pause') return;
      this.currentDuration = this.transTime(e.target.currentTime);
      this.currentDurationNum = e.target.currentTime;
    },
    changeCurrentTime: function changeCurrentTime(e) {
      this.audioStatus = 'pause';
      this.$refs.audioRef.pause();
      this.tempDuration = e;
      this.$emit('pause');
    },
    afterChangeCurrentTime: function afterChangeCurrentTime(e) {
      this.$refs.audioRef.currentTime = this.tempDuration;
      this.$refs.audioRef.play();
      this.audioStatus = 'play';
      this.$emit('play');
    },
    onEnd: function onEnd() {
      this.currentDuration = this.transTime(0);
      this.currentDurationNum = 0;
      this.$emit('ended');
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime: function transTime(time) {
      var duration = parseInt(time);
      var minute = parseInt(duration / 60);
      var sec = duration % 60 + '';
      var isM0 = ':';
      if (minute === 0) {
        minute = '00';
      } else if (minute < 10) {
        minute = '0' + minute;
      }
      if (sec.length === 1) {
        sec = '0' + sec;
      }
      return minute + isM0 + sec;
    },
    setRate: function setRate(_ref) {
      var key = _ref.key;
      this.$refs.audioRef.playbackRate = Number(key);
      this.playBackRate = key;
    }
  }
};
exports.default = _default;