"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.string.repeat");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _LiveLine = _interopRequireDefault(require("./charts/LiveLine"));
var _PieChart = _interopRequireDefault(require("./charts/PieChart"));
var _WordCould = _interopRequireDefault(require("./charts/WordCould"));
var _MapChart = _interopRequireDefault(require("./charts/MapChart"));
var _mqttws31Min = _interopRequireDefault(require("@/utils/mqttws31.min.js"));
var _video = _interopRequireDefault(require("video.js"));
require("videojs-contrib-hls");
require("flv.js/dist/flv.min.js");
var _flv = _interopRequireDefault(require("flv.js"));
var _Loading = _interopRequireDefault(require("@/components/Loading"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
var _Progress = _interopRequireDefault(require("@/components/Item/Progress"));
var _mqttRemoval = _interopRequireDefault(require("@/utils/mqttRemoval"));
var _settings = _interopRequireDefault(require("../../store/modules/settings"));
var _balckList = _interopRequireDefault(require("@/utils/balckList"));
var _auth = require("@/utils/auth");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const {getBlackList} = api
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)("live_now"),
  mapState = _createNamespacedHelp.mapState,
  mapMutations = _createNamespacedHelp.mapMutations,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  components: {
    LiveLine: _LiveLine.default,
    WordCould: _WordCould.default,
    PieChart: _PieChart.default,
    MapChart: _MapChart.default,
    Loading: _Loading.default,
    Progress: _Progress.default
  },
  data: function data() {
    return {
      commentList: [],
      userId: (0, _auth.getToken)("vue_admin_template_token").slice(0, (0, _auth.getToken)("vue_admin_template_token").indexOf("-")),
      client: null,
      // 创建一个客户端实例
      showLoading: true,
      timer: null,
      screenWidth: 1920,
      timerWl: null,
      removal: null
      // blackList: []
    };
  },

  computed: _objectSpread({}, mapState({
    hotWord: function hotWord(state) {
      return state.hotWord;
    },
    fansStat: function fansStat(state) {
      return state.fansStat;
    },
    hotCarType: function hotCarType(state) {
      return state.hotCarType;
    },
    liveRecord: function liveRecord(state) {
      return state.liveRecord;
    },
    author: function author(state) {
      return state.author;
    },
    changeCount: function changeCount(state) {
      return [{
        name: "观看人数趋势",
        chartDate: {
          name: ["增量", "总量"],
          xAxis: state.liveRoomTrend.time_list,
          yAxis: [state.liveRoomTrend.user_count_increment, state.liveRoomTrend.user_count]
        },
        id: "lineA"
      }, {
        name: "粉丝变化趋势",
        chartDate: {
          name: ["增量", "总量"],
          xAxis: state.liveRoomTrend.time_list,
          yAxis: [state.liveRoomTrend.follow_count_increment, state.liveRoomTrend.follow_count]
        },
        id: "lineB"
      }, {
        name: "评论人数趋势",
        chartDate: {
          name: ["增量", "总量"],
          xAxis: state.commentTrend.time_list,
          yAxis: [state.commentTrend.increment, state.commentTrend.total]
        },
        id: "lineC"
      }, {
        name: "送礼数趋势",
        chartDate: {
          name: ["增量", "总量"],
          xAxis: state.liveRoomTrend.time_list,
          yAxis: [state.liveRoomTrend.gift_count_increment, state.liveRoomTrend.gift_count]
        },
        id: "lineD"
      }];
    },
    audienceAdress: function audienceAdress(state) {
      return state.audienceAdress;
    },
    adressList: function adressList(state) {
      return state.audienceAdress.slice(0, 4);
    },
    mqttConfigData: function mqttConfigData(state) {
      return state.mqttConfigData;
    },
    // mqtt
    forbiddenData: function forbiddenData(state) {
      return state.forbiddenData;
    },
    // 禁言
    current_f: function current_f(state) {
      return state.current_f;
    },
    // 禁言
    page_size_f: function page_size_f(state) {
      return state.page_size_f;
    },
    // 禁言
    isHaveForbidden: function isHaveForbidden(state) {
      return state.isHaveForbidden;
    },
    // 禁言
    isFirstForbidden: function isFirstForbidden(state) {
      return state.isFirstForbidden;
    },
    // 禁言
    videoUrl: function videoUrl(state) {
      return state.videoUrl;
    },
    fansChange: function fansChange(state) {
      return state.fansChange;
    },
    fansList: function fansList(state) {
      return [{
        name: '累计观看人数',
        value: state.liveWatchCount.accumulatedWatchingCount
      }, {
        name: '粉丝变化',
        value: state.liveWatchCount.increaseFansCount
      }, {
        name: '观看人数峰值',
        value: state.liveRecord.online_user_count_max
      }, {
        name: '直播前粉丝数',
        value: state.liveRecord.fans_count_before_live
      }];
    }
  })),
  created: function created() {
    // getBlackList().then(res => {
    //   this.blackList = res.data
    // })
    // platformType :快手2，抖音：1
    this.changeState({
      name: "room_id",
      data: this.$route.query.room_id
    });
    this.loadMqttConfig({
      platformType: 1,
      anchorId: this.$route.query.author_id,
      deviceId: this.userId
      // platformType: 1,
      // anchorId: 110813276090,
      // deviceId: this.userId
    });
  },

  watch: {
    screenWidth: function screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timerWl) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timerWl = true;
        var that = this;
        setTimeout(function () {
          document.getElementById('box').style.transform = "scale(".concat(that.screenWidth / 1920, ")");
          that.timerWl = false;
        }, 400);
      }
    },
    mqttConfigData: function mqttConfigData() {
      this.removal = new _mqttRemoval.default();
      if (this.common.isDemo()) {
        var message1 = {
          payloadString: '{"data":{"content":"还是思域帅","series":[{"manufactor_id":"151","series_name":"思域","brand_name":"本田","preferential_price":{"min":0,"max":16500,"source":"dealer"},"brand_id":"14","manufactor_name":"东风本田","series_id":"135"}],"time":1596104090000,"userId":"1972859335","userName":"邢洛凡"},"type":"1"}'
        };
        var message2 = {
          payloadString: '{"data":{"name":"LOL9035"},"type":"5"}'
        };
        var message3 = {
          payloadString: '{"data":{"content":"思域","series":[{"manufactor_id":"151","series_name":"思域","brand_name":"本田","preferential_price":{"min":0,"max":16500,"source":"dealer"},"brand_id":"14","manufactor_name":"东风本田","series_id":"135"}],"time":1596104090000,"userId":"1972859805","userName":"邢洛凡"},"type":"6"}'
        };
        this.onMessageArrived(message1);
        this.onMessageArrived(message2);
        this.onMessageArrived(message3);
      } else {
        this.MQTTconnect();
      }
    },
    videoUrl: function videoUrl() {
      if (this.videoUrl) {
        var that = this;
        console.log('视频链接', this.videoUrl);
        this.player = (0, _video.default)("live-video");
        this.player.src({
          src: that.videoUrl.replace(/^http/, "https")
        });
      }
    },
    isHaveForbidden: function isHaveForbidden(val) {
      console.log('bianle', val);
      if (val) {
        this.loadForbiddenList();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    // 监听屏幕变化
    this.resizeWidth();
    window.addEventListener('resize', function () {
      _this.resizeWidth();
    });
    function Timer() {
      this.timeID = null;
      this.func = null;
    }
    Timer.prototype.repeat = function (func, ms) {
      var _this2 = this;
      if (this.func === null) {
        this.func = func;
      }
      // 确保一个 Timer 实例只能重复一个 func
      if (this.func !== func) {
        return;
      }
      this.timeID = setTimeout(function () {
        func();
        _this2.repeat(func, ms);
      }, ms);
    };
    Timer.prototype.clear = function () {
      clearTimeout(this.timeID);
    };
    this.timer = new Timer();
    this.timer.repeat(this.getInitialData, 30000);
    this.getInitialData();
    // this.getLineData()
    var clear = setTimeout(function () {
      _this.showLoading = false;
      clearTimeout(clear);
    }, 1500);
    this.getLiveVideo();
    // setInterval(()=>{

    //   //  if(this.commentList.length>=6){
    //   //    this.commentList.shift(-7,-1)
    //   //  }
    //    this.commentList.unshift({
    //     id: parseInt(Math.random()*98000),
    //     name: '昂克赛拉',
    //     content:'有钱真好啊啊啊啊饭店服务的萨芬嘎的该发生啊'+parseInt(Math.random()*10000),
    //     avatar: 'https://p9-dy-ipv6.byteimg.com/img/tos-cn-i-0813/5bfa41a7a6d9456b87774126bb38af3a~c5_720x720.jpeg?from=4010531038'
    //   })

    //   // // 获取容器高度
    //   //  let listHeight = document.querySelector('.comment_lists').clientHeight;
    //   //  let arr = document.querySelectorAll('.list-item')
    //   //  let childHeigt = 0
    //   //  arr.forEach(item=>{
    //   //    return childHeigt += item.clientHeight;
    //   //  })

    //   //  console.log('list总高度',childHeigt)
    //   //  console.log('容器高度',listHeight)
    // },2000)
    // flv播放器初始化
    if (_flv.default.isSupported() && this.common.isDemo()) {
      var videoElement = document.getElementById("videoElement");
      var flvPlayer = _flv.default.createPlayer({
        type: "flv",
        url: "https://afanti-live-oss.oss-cn-hangzhou.aliyuncs.com/video/douyin/6850307789168839424.flv"
      });
      flvPlayer.attachMediaElement(videoElement);
      flvPlayer.load();
      flvPlayer.play();
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({
    // 屏幕变化设置比
    resizeWidth: function resizeWidth() {
      this.screenWidth = document.body.clientWidth;
    },
    format: function format(percentage) {
      return this.common.numEdit(percentage);
    }
  }, mapActions(["getLiveRecordAnaly", "getHotWord", "getCommentHot", "getAudience", "getCommentTrend", "getLiveRoomTrend", "getAudienceAdress", "loadMqttConfig", "getForbiddenList", "getLiveVideo", "getMqttFans"])), mapMutations(["changeState"])), {}, {
    getInitialData: function getInitialData() {
      this.getLiveRecordAnaly();
      this.getCommentHot();
      this.getHotWord();
      this.getAudience();
      this.getAudienceAdress();
      this.getCommentTrend();
      this.getLiveRoomTrend();
      this.getMqttFans({
        platformType: 1,
        roomId: this.$route.query.room_id
      });
      this.loadForbiddenList();
    },
    loadForbiddenList: function loadForbiddenList() {
      var params = {
        current: this.current_f,
        page_size: this.page_size_f,
        live_room_id: this.$route.query.room_id,
        platform: 'douyin'
      };
      if (!this.isFirstForbidden && this.forbiddenData.length > 0) {
        params.from_time = this.forbiddenData[0].ctime;
      }
      this.getForbiddenList(params);
    },
    // getLineData(){
    //   this.getCommentTrend();
    //   this.getLiveRoomTrend();
    // },
    // 创建mqtt实例，并连接
    MQTTconnect: function MQTTconnect() {
      var _this3 = this;
      this.client = new _mqttws31Min.default.MQTT.Client(this.mqttConfigData.endPoint, 443, this.mqttConfigData.clientId);
      this.client.connect({
        userName: this.mqttConfigData.userName,
        //连接账号
        password: this.mqttConfigData.password,
        //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: function onFailure(message) {
          setTimeout(_this3.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },
    //当客户端连接
    onConnect: function onConnect() {
      //建立连接后，进行订阅并发送消息。
      console.log("onConnect");
      this.client.subscribe(this.mqttConfigData.clientSubscribe.live.bulletTopic, {
        qos: 0
      });
    },
    // 当客户端失去连接时调用
    onConnectionLost: function onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    // addComment(commentData, data) {
    //   let obj = {
    //     id: newJson.data.userId,
    //     name: newJson.data.userName,
    //     content: commentData,
    //     avatar: newJson.data.avatar
    //   };
    //   this.commentList.push(obj);
    // },
    //当消息到达时
    // type 1:评论，4:礼物，5：进入直播间，6:关注
    // 抖音无法获取礼物数
    onMessageArrived: function onMessageArrived(message) {
      var newJson = JSON.parse(message.payloadString);
      console.log("onMessageArrived:", newJson);
      var bool = this.removal.isRemoval(newJson);
      var obj = {};
      var commentData = "";
      var that = this;
      if (this.commentList.length > 1000) {
        this.commentList.splice(500, 499);
      }
      if (bool && newJson.type == 1) {
        var result = false;
        result = _balckList.default.some(function (item) {
          if (item.name == newJson.data.userName) {
            return true;
          }
        });
        console.log(result);
        commentData = newJson.data.content;
        this.commentList.unshift({
          id: "".concat(parseInt(Math.random() * 10000)).concat(Date.parse(new Date())),
          name: newJson.data.userName,
          content: commentData,
          avatar: newJson.data.avatar ? newJson.data.avatar : require("../../assets/dev/defult-avatar.png"),
          isBlack: result
        });
      } else if (newJson.type == 4) {
        commentData = "\u9001\u4E86".concat(newJson.data.giftName);
        this.commentList.unshift({
          id: "".concat(parseInt(Math.random() * 10000)).concat(Date.parse(new Date())),
          name: newJson.data.userName,
          content: commentData,
          avatar: newJson.data.avatar ? newJson.data.avatar : require("../../assets/dev/defult-avatar.png")
        });
      } else if (newJson.type == 5) {
        // let result = false
        // result = blackList.some(function(item) {
        //   if (item.name == newJson.data.name) {
        //       return true;
        //   }
        // })
        // console.log(result)
        // commentData = "进入直播间";
        // this.commentList.unshift({
        //   id: `${parseInt(Math.random()*10000)}${Date.parse(new Date())}`,
        //   name: newJson.data.name,
        //   content: commentData,
        //   avatar: newJson.data.avatar?newJson.data.avatar:require('../../assets/dev/defult-avatar.png'),
        //   isBlack: result
        // });
      } else if (newJson.type == 6) {
        commentData = "点了关注";
        this.commentList.unshift({
          id: "".concat(parseInt(Math.random() * 10000)).concat(Date.parse(new Date())),
          name: newJson.data.name,
          content: commentData,
          avatar: newJson.data.avatar ? newJson.data.avatar : require("../../assets/dev/defult-avatar.png")
        });
      }
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.timer.clear();
    this.client.unsubscribe(this.mqttConfigData.clientSubscribe.live.bulletTopic);
    this.client.disconnect(); // 断开连接
    this.client = null;
    // 销毁video实例
    var videoDom = this.$refs.liveVideo;
    (0, _video.default)(videoDom).dispose();
    this.commentList = [];
    window.removeEventListener(this.resizeWidth);
  }
};
exports.default = _default;