var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "tips-box" }, [
      _c(
        "div",
        { staticClass: "title-header" },
        [
          _vm._v(" 评论智回 "),
          _c("a-switch", {
            attrs: { loading: _vm.isOpening },
            on: { change: _vm.updateStatus },
            model: {
              value: _vm.isOpened,
              callback: function ($$v) {
                _vm.isOpened = $$v
              },
              expression: "isOpened",
            },
          }),
        ],
        1
      ),
      _c("p", [
        _vm._v(
          "当用户评论命中策略时，自动发送设置好的回复内容到抖音视频评论区（所有人可见）"
        ),
      ]),
      _c("p", [_vm._v("1、每条评论（主播评论除外）只触发一次回复")]),
      _c("p", [_vm._v("2、命中多个关键词策略时，只触发优先级最高的")]),
      _c("p", [
        _vm._v(
          "3、个人自定义的关键词不允许重复，但可以与品牌推荐的重复。当存在相同关键词时，优先触发个人自定义的话术"
        ),
      ]),
      _c("p", [
        _vm._v(
          "4、同时命中关键词回复策略和智能回复策略，则优先触发关键词的话术"
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("用户留下价值评论后自动回复"),
        ]),
        _c("div", { staticClass: "sub-title" }, [
          _vm._v("若需要调整策略或话术，请联系阿凡提工作人员"),
        ]),
        _c("FunctionCarousel", { attrs: { imgList: _vm.imgList } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }