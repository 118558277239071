var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "history-item",
      style: {
        "align-items": _vm.position == "left" ? "flex-start" : "flex-end",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "info",
          style: {
            "flex-direction": _vm.position == "left" ? "row" : "row-reverse",
          },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                style: {
                  display: "flex",
                  "flex-direction":
                    _vm.position == "left" ? "row" : "row-reverse",
                },
              },
              [
                _c("span", { staticClass: "f14 c-b" }, [
                  _vm._v(
                    _vm._s(
                      _vm.item.is_author_send == 1
                        ? _vm.author.author_nickname
                        : _vm.author.nickname ||
                            _vm.getSimpleName(_vm.author.open_id)
                    )
                  ),
                ]),
                _vm.item.is_author_send == 1 && _vm.author.code
                  ? _c(
                      "span",
                      {
                        staticClass: "f14 c-g",
                        staticStyle: { margin: "0 6px" },
                      },
                      [_vm._v("(" + _vm._s(_vm.author.code) + ")")]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "f12 c-g",
                style: {
                  "text-align": _vm.position == "left" ? "left" : "right",
                },
              },
              [_vm._v(" " + _vm._s(_vm.getNewTime(_vm.item.ctime)) + " ")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "content",
          style: { background: _vm.position == "left" ? "#d8e4ff" : "#d7d7d7" },
        },
        [_vm._v(" " + _vm._s(_vm.item.content) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }