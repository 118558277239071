"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _echarts = _interopRequireDefault(require("echarts"));
var _HotCommentCar = _interopRequireDefault(require("@/components/Card/HotCommentCar"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      defultImg: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg',
      defultCover: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg',
      activeName: "first",
      // 顶部tab
      currentChartTab: 1,
      // chart-tab
      chartRadio: "显示增量",
      tableData: [{
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }, {
        date: "2020-05-15 00:14:50",
        num1: 1233,
        num2: 484,
        num3: 1287,
        num4: 25,
        num5: 245,
        num6: 3,
        num7: 123400,
        num8: 43
      }],
      adressData: [{
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }, {
        name: "广东",
        num: "25.33%"
      }],
      imgHref: process.env.VUE_APP_OSS_HOST,
      lineChart: null,
      barChart: null,
      pieChart: null,
      areaTableData: [],
      currentMid: null
    };
  },
  components: {
    HotCommentCar: _HotCommentCar.default
  },
  props: ["currentDetailData", "info"],
  computed: {},
  watch: {},
  methods: {
    getDetailData: function getDetailData(id) {
      this.activeName = "first";
      this.currentMid = id;
      this.$parent.$parent.queryHotAnalysis({
        monitAwemeRecordId: id
      });
      this.$parent.$parent.queryAudienceAnalysis({
        monitAwemeRecordId: id
      });
      this.$parent.$parent.queryTrend({
        monitAwemeRecordId: id
      });
      this.$parent.$parent.queryVideoComments({
        monitAwemeRecordId: id,
        page: 1,
        size: this.$parent.$parent.commentPageSize
      });
    },
    getComment: function getComment() {
      this.$parent.$parent.queryVideoComments({
        monitAwemeRecordId: this.currentMid,
        page: this.$parent.$parent.commentPageNo,
        size: this.$parent.$parent.commentPageSize
      });
    },
    tabClick: function tabClick() {
      // console.log(this.activeName)
      // if (this.activeName == 'first') {
      //   this.initChartLine()
      // } else if (this.activeName == 'second') {
      //   this.initBarChart()
      // } else {
      // }
    },
    chartTabClick: function chartTabClick(i) {
      this.currentChartTab = i;
      var lineData = this.$parent.$parent.trendData;
      var xData = [];
      var yData1 = [];
      var yData2 = [];
      var yData3 = [];
      lineData.forEach(function (item) {
        xData.unshift(item.crawl_time);
        yData1.unshift(item.digg_increment);
        yData2.unshift(item.comment_increment);
        yData3.unshift(item.share_increment);
      });
      if (i == 1) {
        this.initChartLine(xData, yData1);
      } else if (i == 2) {
        this.initChartLine(xData, yData2);
      } else if (i == 3) {
        this.initChartLine(xData, yData3);
      } else {}
    },
    // 点击更新
    detailUpdate: function detailUpdate(i) {
      var id = this.currentDetailData.monitAwemeRecordId;
      if (i == 1) {
        this.$parent.$parent.queryTrend({
          monitAwemeRecordId: id
        });
        this.chartTabClick(this.currentChartTab || 1);
      } else if (i == 2) {
        this.$parent.$parent.queryHotAnalysis({
          monitAwemeRecordId: id
        });
        this.$parent.$parent.queryVideoComments({
          monitAwemeRecordId: id,
          page: 1,
          size: this.$parent.$parent.commentPageSize
        });
      } else if (i == 3) {
        this.$parent.$parent.queryAudienceAnalysis({
          monitAwemeRecordId: id
        });
      } else {}
    },
    // 导出
    exportTable: function exportTable() {
      console.log(this.currentDetailData.monitAwemeRecordId);
      // this.$parent.$parent.exportTrend({
      //   monitAwemeRecordId: this.currentDetailData.monitAwemeRecordId,
      // });
      var httpObj = {
        method: "get",
        // 请求方式
        url: process.env.VUE_APP_OPENDATA_API + "/mirror-data/aweme-monit/export",
        // 请求地址
        headers: {
          token: (0, _auth.getToken)()
        },
        params: {
          monitAwemeRecordId: this.currentDetailData.monitAwemeRecordId
        },
        responseType: "blob" // 重点，请求的数据类型
      };

      this.$http(httpObj).then(function (response) {
        /* 兼容ie内核，360浏览器的兼容模式 */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var _blob = new Blob([response.data]);
          window.navigator.msSaveOrOpenBlob(_blob, "数据导出.xlsx");
        } else {
          /* 火狐谷歌的文件下载方式 */
          var blob = new Blob([response.data]);
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "数据导出.xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function (response) {
        console.log(response);
      });
    },
    // line-chart
    initChartLine: function initChartLine(xData, yData) {
      this.lineChart = _echarts.default.init(document.getElementById("lineChart"));
      this.lineChart.setOption({
        title: {
          text: "水量变化",
          subtext: "",
          textStyle: {
            color: "#fff"
          }
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        color: ["#0080ff", "#4cd5ce"],
        toolbox: {
          // feature: {
          //     saveAsImage: {}
          // }
        },
        grid: {
          left: "7%",
          right: "10%",
          top: "10%",
          bottom: "5%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          data: xData,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#6ba1bb",
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: "#0a2b52",
              width: 0.5 //这里是为了突出显示加上的
            }
          }
        }],

        yAxis: [{
          type: "value",
          axisLine: {
            onZero: false,
            show: false,
            lineStyle: {
              color: "#0a2b52",
              width: 1 //这里是为了突出显示加上的
            }
          },

          axisLabel: {
            show: true,
            textStyle: {
              color: "#6ba1bb" //字体颜色
            }
          },

          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(0,0,0,0.1)"
            }
          }
        }],
        series: [{
          name: this.currentChartTab == 1 ? "点赞数" : this.currentChartTab == 2 ? "评论数" : this.currentChartTab == 3 ? "转发数" : "粉丝数",
          type: "line",
          smooth: true,
          //  symbol: "none", //去掉折线点
          stack: 100,
          itemStyle: {
            normal: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "rgba(39, 179, 136, 1)" // 0% 处的颜色
              }, {
                offset: 1,
                color: "rgba(39, 179, 136, 0.1)" // 100% 处的颜色
              }]),

              //背景渐变色
              lineStyle: {
                // 系列级个性化折线样式
                width: 0.5,
                type: "solid",
                color: "rgba(39, 179, 136, 1)"
              }
            },
            emphasis: {
              color: "#02675f",
              lineStyle: {
                // 系列级个性化折线样式
                width: 0.5,
                type: "dotted",
                color: "#02675f" //折线的颜色
              }
            }
          },

          //线条样式
          symbolSize: 5,
          //折线点的大小
          areaStyle: {
            normal: {}
          },
          data: yData
        }]
      });
      window.onresize = this.lineChart.resize;
    },
    // bar-chart
    initBarChart: function initBarChart(xData, yData) {
      this.barChart = _echarts.default.init(document.getElementById("barChart"));
      this.barChart.setOption({
        tooltip: {},
        grid: {},
        xAxis: {
          data: xData
        },
        yAxis: {},
        series: [{
          type: "bar",
          data: yData,
          barWidth: 20,
          itemStyle: {
            normal: {
              color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: "rgba(39,199,125,0)"
              }, {
                offset: 1,
                color: "rgba(39,199,125,1)"
              }]),
              label: {
                show: true,
                position: "top",
                color: "#2CB58B",
                fontSize: 12
              },
              barBorderRadius: [4, 4, 0, 0]
            }
          },
          label: {
            show: true,
            position: "top",
            fontSize: "24"
          }
        }]
      });
      window.onresize = this.barChart.resize;
    },
    // pie-chart
    initPieChart: function initPieChart(data) {
      this.pieChart = _echarts.default.init(document.getElementById("pieChart"));
      var colorList = ["#5374F3", "#FF9B4C"];
      this.pieChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          bottom: "bottom",
          data: ["男", "女"]
        },
        series: [{
          name: "访问来源",
          type: "pie",
          radius: "55%",
          center: ["50%", "40%"],
          data: data,
          itemStyle: {
            normal: {
              color: function color(params) {
                return colorList[params.dataIndex];
              }
            },
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }]
      });
      window.onresize = this.pieChart.resize;
    }
  },
  mounted: function mounted() {
    // this.initChartLine(this.currentDetailData.detail.tab1.lineChart.y1);
    // this.initBarChart();
    // this.initPieChart();
  }
};
exports.default = _default;