"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _attention = _interopRequireDefault(require("@/api/attention"));
var _QuickReply = _interopRequireDefault(require("./QuickReply.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    straegy_visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      loading_dw: false,
      form: {
        message_content: ""
      },
      edit_data: {},
      rules: {
        message_content: [{
          required: true,
          message: "回复内容不能为空",
          trigger: "blur"
        }]
      },
      btn_loading: false,
      quick_modal: false
    };
  },
  components: {
    QuickReply: _QuickReply.default
  },
  methods: {
    saveEditMessage: function saveEditMessage() {
      var _this = this;
      this.$emit("startUseing", this.edit_data.sceneName);
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.btn_loading = true;
          var payload = {
            isRepeatSend: 0,
            replyMsg: _this.form.message_content,
            sceneId: _this.edit_data.sceneId,
            isOpened: 1
          };
          _attention.default.editLiveAutoReply(payload).then(function (res) {
            if (res.code == 200) {
              _this.$message.success("保存成功！");
              _this.$emit("closeDrawerStrategy");
              _this.$emit("resetTable");
            } else {
              _this.$message.error(res.message);
            }
          }).finally(function () {
            _this.btn_loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    liveAutoReplyModel: function liveAutoReplyModel(id) {
      var _this2 = this;
      this.loading_dw = true;
      _attention.default.liveAutoReplyModel(id).then(function (res) {
        if (res.code == 200) {
          _this2.form.message_content = res.data.replyMsg || "";
          _this2.edit_data = res.data;
        } else {
          _this2.$message.error(res.message);
        }
      }).finally(function () {
        _this2.loading_dw = false;
      });
    },
    onClose: function onClose() {
      this.$emit("closeDrawerStrategy");
    },
    goRouter: function goRouter() {
      this.$router.push({
        path: "/live_new_attention/strategy"
      });
    },
    // 关闭弹窗
    closeModal: function closeModal() {
      this.quick_modal = false;
    },
    openQuickReply: function openQuickReply() {
      this.quick_modal = true;
      this.$refs.quickReply.getQuickReplyList(this.edit_data.sceneId);
    },
    sendOneConent: function sendOneConent(value) {
      var _this3 = this;
      if (this.form.message_content) {
        this.$confirm("当前已输入回复内容，确认覆盖当前内容吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this3.form.message_content = value;
        }).catch(function () {
          console.log("cancel");
        });
      } else {
        this.form.message_content = value;
      }
      this.quick_modal = false;
    }
  }
};
exports.default = _default;