var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "adContainer" }, [
    _c(
      "div",
      { staticStyle: { padding: "20px 0 20px 20px", "margin-bottom": "20px" } },
      [
        _c(
          "span",
          {
            staticStyle: {
              "padding-bottom": "15px",
              "border-bottom": "2px solid #FC9965",
            },
          },
          [
            _c("svg-icon", {
              staticStyle: { color: "#FC9965", "font-size": "22px" },
              attrs: { "icon-class": "propaganda2" },
            }),
            _c(
              "span",
              { staticStyle: { color: "#14233f", "font-weight": "bold" } },
              [_vm._v("广告投放")]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticStyle: { "padding-left": "20px" } },
      [
        _c("h3", { staticStyle: { "margin-bottom": "20px" } }, [
          _vm._v("确定广告视频"),
        ]),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                [
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请输入内容",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.state,
                      callback: function ($$v) {
                        _vm.state = $$v
                      },
                      expression: "state",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 4 } }, [
              _c(
                "div",
                { staticStyle: { "padding-left": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        background: "#4A58F3",
                        "border-color": "#4A58F3",
                      },
                      attrs: { type: "success" },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "padding-top": "20px" } },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-right": "20px",
                        "padding-bottom": "60px",
                        "border-right": "1px solid #ddd",
                        overflow: "hidden",
                      },
                    },
                    [
                      _c("h3", { staticStyle: { "margin-bottom": "20px" } }, [
                        _vm._v("目标短视频"),
                      ]),
                      _c("div", { staticClass: "video_image_wrap" }, [
                        _c("img", {
                          attrs: { src: _vm.curVideo.cover, alt: "" },
                        }),
                      ]),
                      _c("ul", { staticClass: "ul_wrap" }, [
                        _c("li", [_vm._v(_vm._s(_vm.curVideo.content))]),
                        _c(
                          "li",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#7D8496",
                            },
                          },
                          [_vm._v("发布日： " + _vm._s(_vm.curVideo.time))]
                        ),
                        _c("li", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("当前短视频数值"),
                        ]),
                        _c("li", { staticClass: "li_add_padding" }, [
                          _vm._v("获赞：" + _vm._s(_vm.curVideo.digg)),
                        ]),
                        _c("li", { staticClass: "li_add_padding" }, [
                          _vm._v("评论：" + _vm._s(_vm.curVideo.comment)),
                        ]),
                        _c("li", { staticClass: "li_add_padding" }, [
                          _vm._v("分享：" + _vm._s(_vm.curVideo.share)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 18 } }, [
                  _c(
                    "div",
                    { staticClass: "video_form_wrap" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.form,
                            "label-width": "100px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "地域范围" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.area,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "area", $$v)
                                    },
                                    expression: "form.area",
                                  },
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "不限" },
                                  }),
                                  _vm._l(
                                    _vm.setting.area || [],
                                    function (item, index) {
                                      return _c("el-radio-button", {
                                        key: index,
                                        attrs: { label: item },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "活跃时间" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "time", $$v)
                                    },
                                    expression: "form.time",
                                  },
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "不限" },
                                  }),
                                  _c("el-radio-button", {
                                    attrs: { label: "近7天" },
                                  }),
                                  _c("el-radio-button", {
                                    attrs: { label: "近15天" },
                                  }),
                                  _c("el-radio-button", {
                                    attrs: { label: "近30天" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "本品车型" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.cartype,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cartype", $$v)
                                    },
                                    expression: "form.cartype",
                                  },
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "不限" },
                                  }),
                                  _vm._l(
                                    _vm.setting.self || [],
                                    function (item, index) {
                                      return _c("el-radio-button", {
                                        key: index,
                                        attrs: { label: item },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "竞品拦截" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.competition,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "competition", $$v)
                                    },
                                    expression: "form.competition",
                                  },
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "不限" },
                                  }),
                                  _vm._l(
                                    _vm.setting.competition || [],
                                    function (item, index) {
                                      return _c("el-radio-button", {
                                        key: index,
                                        attrs: { label: item },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "达人粉丝" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.talent,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "talent", $$v)
                                    },
                                    expression: "form.talent",
                                  },
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "不限" },
                                  }),
                                  _c("el-radio-button", {
                                    attrs: { label: "自定义" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h3", [_vm._v("预计触达潜客")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "0 0 30px 0",
                                "font-size": "46px",
                                color: "#8F94A2",
                                "font-weight": "500",
                              },
                            },
                            [_vm._v("260,203")]
                          ),
                          _c("h3", [_vm._v("你希望智能触达多少受众")]),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.audience,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "audience", $$v)
                                },
                                expression: "form.audience",
                              },
                            },
                            [
                              _c("el-radio-button", {
                                attrs: { label: "5000人+" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "1万人+" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "2.5万人+" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "5万人+" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "10万人+" },
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "自定义" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.audience === "自定义",
                                  expression: "form.audience === '自定义'",
                                },
                              ],
                              staticStyle: {
                                "padding-top": "10px",
                                width: "30%",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入受众数量" },
                                model: {
                                  value: _vm.form.customer_audience,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customer_audience", $$v)
                                  },
                                  expression: "form.customer_audience",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-top": "60px",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c("h3", [_vm._v("实付金额")]),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "padding-top": "3px",
                                          "font-size": "26px",
                                          color: "#E34E3A",
                                          "font-weight": "500",
                                        },
                                      },
                                      [_vm._v("￥ 100,000.00")]
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 10 } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              background: "#E34E3A",
                                            },
                                            attrs: { type: "danger" },
                                            on: { click: _vm.handleSubmit },
                                          },
                                          [_vm._v("支付")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#666",
                                              position: "relative",
                                              top: "8px",
                                            },
                                          },
                                          [_vm._v(" 同步加入短视频监控")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }