var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "propaganda_wrap" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "brand_card__wrap",
                staticStyle: { padding: "20px" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-bottom": "50px",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "padding-bottom": "15px",
                          "border-bottom": "2px solid #fc9965",
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            color: "#fc9965",
                            "font-size": "22px",
                          },
                          attrs: { "icon-class": "propaganda2" },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#14233f",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("潜客雷达")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { "padding-bottom": "30px" } },
                  [_c("title-head", [_vm._v("近15天-全网潜客")])],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "increase_number" },
                  [
                    _vm.getAllData
                      ? _c("count-to", {
                          attrs: {
                            startVal: 0,
                            endVal: _vm.getAllData.total || 0,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("title-head", [_vm._v("区域分布")]),
                    _c("div", { ref: "areaDom", staticClass: "area_wrap" }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "padding-top": "40px" } },
                  [
                    _c("title-head", [_vm._v("车型分布")]),
                    _c("div", {
                      ref: "cartypeDom",
                      staticClass: "cartype_wrap",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 18 } }, [
            _c(
              "div",
              { staticStyle: { "margin-left": "20px" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "brand_card__wrap",
                    staticStyle: {
                      padding: "20px",
                      "min-height": "250px",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "padding-bottom": "15px",
                          "border-bottom": "2px solid #fc9965",
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            color: "#fc9965",
                            "font-size": "22px",
                          },
                          attrs: { "icon-class": "propaganda2" },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#14233f",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("来源分布")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "padding-bottom": "10px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "60px",
                                    "padding-bottom": "60px",
                                    "border-right": "1px solid #f1f2f5",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-bottom": "15px",
                                            },
                                          },
                                          [
                                            _c("title-head", [
                                              _vm._v(
                                                "品牌+门店+门店个人号-潜客占比"
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "#17233d",
                                            },
                                          },
                                          [
                                            _vm.getSourceMapData
                                              ? _c(
                                                  "b",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "50px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "disposeDecimal"
                                                        )(
                                                          (_vm.getSourceMapData
                                                            .manufactor_total /
                                                            _vm.getSourceMapData
                                                              .total) *
                                                            100
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [_vm._v("%")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-bottom": "15px",
                                              },
                                            },
                                            [
                                              _c("title-head", [
                                                _vm._v("直播潜客占比"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                color: "#e34e3a",
                                              },
                                            },
                                            [
                                              _c(
                                                "b",
                                                {
                                                  staticStyle: {
                                                    "font-size": "50px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("disposeDecimal")(
                                                        (_vm.getCommentCountData ||
                                                          0) * 100
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticStyle: { "padding-top": "60px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "15px" },
                                    },
                                    [
                                      _c("title-head", [
                                        _vm._v("外部主播-潜客占比"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        color: "#17233d",
                                      },
                                    },
                                    [
                                      _vm.getSourceMapData
                                        ? _c(
                                            "b",
                                            {
                                              staticStyle: {
                                                "font-size": "50px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("disposeDecimal")(
                                                    ((_vm.getSourceMapData
                                                      .total -
                                                      _vm.getSourceMapData
                                                        .manufactor_total) /
                                                      _vm.getSourceMapData
                                                        .total) *
                                                      100
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "20px" },
                                        },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-row",
                  { staticStyle: { height: "900px" } },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { height: "100%" }, attrs: { span: 8 } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "brand_card__wrap",
                            staticStyle: { padding: "20px", height: "100%" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "50px" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "padding-bottom": "15px",
                                      "border-bottom": "2px solid #fc9965",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        color: "#fc9965",
                                        "font-size": "22px",
                                      },
                                      attrs: { "icon-class": "propaganda2" },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#14233f",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [_vm._v("互动分布")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "210px",
                                  padding: "80px 0",
                                  display: "flex",
                                  "justify-content": "center",
                                  margin: "0 auto",
                                },
                              },
                              [
                                _vm.getAllData
                                  ? _c("ring", {
                                      attrs: {
                                        per: _vm._f("disposeDecimal")(
                                          (_vm.getAllData.aweme_comment_count /
                                            _vm.getAllData.total) *
                                            100
                                        ),
                                        title: "短视频互动",
                                        color: "rgba(100,169,243,1.00)",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "210px",
                                  padding: "80px 0",
                                  display: "flex",
                                  "justify-content": "center",
                                  margin: "0 auto",
                                },
                              },
                              [
                                _vm.getAllData
                                  ? _c("ring", {
                                      attrs: {
                                        per: _vm._f("disposeDecimal")(
                                          (_vm.getAllData.live_comment_count /
                                            _vm.getAllData.total) *
                                            100
                                        ),
                                        title: "直播间互动",
                                        color: "rgba(39,199,125,1.00)",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          height: "100%",
                          width: "calc(66.66% - 20px)",
                          "margin-left": "20px",
                          background: "#fff",
                        },
                        attrs: { span: 16 },
                      },
                      [
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              "indicator-position": "none",
                              autoplay: false,
                              height: "900px",
                            },
                          },
                          _vm._l(
                            _vm.getSeriesMapData,
                            function (c_item, index) {
                              return _c(
                                "el-carousel-item",
                                { key: index, staticStyle: { height: "100%" } },
                                [
                                  _vm.getSeriesMapData
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "brand_card__wrap",
                                          staticStyle: {
                                            "margin-left": "0px",
                                            padding: "20px",
                                            height: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "haoying_notify" },
                                            [_vm._v("马上，潜客包定向投放")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cartype_name_wrap",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cartype_image_wrap",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        _vm.imgHref +
                                                        "/" +
                                                        c_item.image,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "40px",
                                                    "padding-top": "10px",
                                                    color: "#333",
                                                    "font-size": "24px",
                                                    "line-height": "50px",
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(c_item.series) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "padding-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-bottom": "5px",
                                                      },
                                                    },
                                                    [_vm._v("活跃潜客(15天)")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "26px",
                                                        "font-weight": "500",
                                                        color: "#e34e3a",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            c_item.active_potential_count
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "border-right":
                                                              "1px solid #f1f2f5",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "padding-bottom":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-bottom":
                                                                      "15px",
                                                                    "border-bottom":
                                                                      "2px solid #fc9965",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fc9965",
                                                                          "font-size":
                                                                            "22px",
                                                                        },
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "propaganda2",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#14233f",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "本品潜客"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    c_item
                                                                      .series_comments
                                                                      .length ==
                                                                    0,
                                                                  expression:
                                                                    "c_item.series_comments.length == 0",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "empty-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 暂无数据 "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "add_scroll",
                                                            },
                                                            _vm._l(
                                                              c_item.series_comments ||
                                                                [],
                                                              function (
                                                                item,
                                                                ind
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: ind,
                                                                    staticClass:
                                                                      "comment_wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: item.avatar,
                                                                                alt: "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _c("h3", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.user_name
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "5px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.content
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#8f94a2",
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.publish_time
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            padding: "0 20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "padding-bottom":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-bottom":
                                                                      "15px",
                                                                    "border-bottom":
                                                                      "2px solid #fc9965",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#fc9965",
                                                                          "font-size":
                                                                            "22px",
                                                                        },
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "propaganda2",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#14233f",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "竞品拦截"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    c_item
                                                                      .enemy_comments
                                                                      .length ==
                                                                    0,
                                                                  expression:
                                                                    "c_item.enemy_comments.length == 0",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "empty-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 暂无数据 "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "add_scroll",
                                                            },
                                                            _vm._l(
                                                              c_item.enemy_comments ||
                                                                [],
                                                              function (
                                                                item,
                                                                ind
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: ind,
                                                                    staticClass:
                                                                      "comment_wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: item.avatar,
                                                                                alt: "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _c("h3", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.user_name
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "5px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.content
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#8f94a2",
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.publish_time
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }