"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var _default = {
  // 获取一次召回列表
  getFirstRecallList: function getFirstRecallList(params) {
    return request({
      url: "/first/recall/web/strategyRuleList",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用一次召回策略
  switchFirstRecallStrategy: function switchFirstRecallStrategy(params) {
    return request({
      url: "/first/recall/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护一次召回话术
  updateFirstRecallScriptList: function updateFirstRecallScriptList(data) {
    return request({
      url: "/first/recall/web/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 查询一次召回策略主播品牌启停详情
  getFirstRecallAuthorPrincipalSetting: function getFirstRecallAuthorPrincipalSetting(params) {
    return request({
      url: "/first/recall/web/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 一次召回策略主播品牌启用/停用
  switchFirstRecallAuthorPrincipal: function switchFirstRecallAuthorPrincipal(params) {
    return request({
      url: "/first/recall/web/switch/author/principal",
      method: 'GET',
      params: params
    });
  },
  // 获取二次召回列表
  getSecondRecallList: function getSecondRecallList(params) {
    return request({
      url: "/second/recall/web/strategyRuleList",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用二次召回策略
  switchSecondRecallStrategy: function switchSecondRecallStrategy(params) {
    return request({
      url: "/second/recall/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护二次召回话术
  updateSecondRecallScriptList: function updateSecondRecallScriptList(data) {
    return request({
      url: "/second/recall/web/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 查询二次召回策略主播品牌启停详情
  getSecondRecallAuthorPrincipalSetting: function getSecondRecallAuthorPrincipalSetting(params) {
    return request({
      url: "/second/recall/web/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 二次召回策略主播品牌启用/停用
  switchSecondRecallAuthorPrincipal: function switchSecondRecallAuthorPrincipal(params) {
    return request({
      url: "/second/recall/web/switch/author/principal",
      method: 'GET',
      params: params
    });
  },
  // 获取在线召回列表
  getOnlineRecallList: function getOnlineRecallList(params) {
    return request({
      url: "/online/recall/web/strategyRuleList",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用在线召回策略
  switchOnlineRecallStrategy: function switchOnlineRecallStrategy(params) {
    return request({
      url: "/online/recall/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护在线召回话术
  updateOnlineRecallScriptList: function updateOnlineRecallScriptList(data) {
    return request({
      url: "/online/recall/web/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 查询在线召回策略主播品牌启停详情
  getOnlineRecallAuthorPrincipalSetting: function getOnlineRecallAuthorPrincipalSetting(params) {
    return request({
      url: "/online/recall/web/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 在线召回策略主播品牌启用/停用
  switchOnlineRecallAuthorPrincipal: function switchOnlineRecallAuthorPrincipal(params) {
    return request({
      url: "/online/recall/web/switch/author/principal",
      method: 'GET',
      params: params
    });
  }
};
exports.default = _default;