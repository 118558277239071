"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLarkId = getLarkId;
exports.getToken = getToken;
exports.getUserId = getUserId;
exports.removeToken = removeToken;
exports.removeUserId = removeUserId;
exports.setLarkId = setLarkId;
exports.setToken = setToken;
exports.setUserId = setUserId;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'vue_admin_template_token';
var UserIdKey = 'userId';
var LarkId = 'LarkId';
function getToken() {
  return window.localStorage.getItem(TokenKey);
}
function setToken(token) {
  return window.localStorage.setItem(TokenKey, token);
}
function removeToken() {
  return window.localStorage.removeItem(TokenKey);
}
function getUserId() {
  return window.localStorage.getItem(UserIdKey);
}
function setUserId(id) {
  return window.localStorage.setItem(UserIdKey, id);
}
function removeUserId() {
  return window.localStorage.removeItem(UserIdKey);
}
function setLarkId(id) {
  return window.localStorage.setItem(LarkId, id);
}
function getLarkId() {
  return window.localStorage.getItem(LarkId);
}