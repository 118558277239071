"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//

var defaultImg = require('@/assets/dev/img-default.png');
var _default = {
  props: {
    src: {
      type: String,
      default: defaultImg
    }
  },
  data: function data() {
    return {
      showSrc: defaultImg
    };
  },
  watch: {
    src: {
      handler: function handler(newVal) {
        var _this = this;
        if (newVal) {
          var newImg = new Image();
          newImg.src = this.src;
          newImg.onerror = function () {
            _this.showSrc = defaultImg;
          };
          newImg.onload = function () {
            _this.showSrc = newVal;
          };
        }
      },
      immediate: true
    }
  },
  methods: {},
  created: function created() {}
};
exports.default = _default;