"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _common = _interopRequireDefault(require("@/api/common"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _moment = _interopRequireDefault(require("moment"));
/*
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
 * */
function formatNumber(number, decimals, dec_point, thousands_sep, roundtag) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  roundtag = roundtag || 'ceil'; //"ceil","floor","round"
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function toFixedFix(n, prec) {
      var k = Math.pow(10, prec);
      return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

/*
 * 判断是否为数组
 */
function judgeObject(arg) {
  if (!Array.isArray) {
    return Object.prototype.toString.call(arg) == '[object Array]';
  }
}

/*
 *小数处理
 * number => 传入数字
 * point => 保留几位小数 (默认为2)
 * zeroFill => 是否去掉补零 （Boolean值，默认为false）
 */
function disposeDecimal(number, point, zeroFill) {
  var num = number;
  if (num) {
    num = Number(number);
    if (typeof num === 'number' && num != NaN) {
      num = point ? num.toFixed(point) : num.toFixed(2);
      if (zeroFill) {
        return num;
      } else {
        return parseFloat(num);
      }
    }
    return 0;
  }
  return 0;
}

// 补零颗粒
function disposeZero(val) {
  return disposeDecimal(val, 4, true);
}

/*
 *数据处理
 *value => 传入数据
 * type => 处理类型(0:补亿或万（可不传，转为零)；1:补逗号；）
 * point => 保留几位小数 (默认为2)
 * zeroFill => 是否去掉补零 （Boolean值，默认为false）
 */
function changeNum(value, point, zeroFill, type) {
  var _value = +value;
  if (isNaN(_value)) {
    return 0;
  }
  var num = disposeDecimal(_value, point, zeroFill);
  var types = type ? type : 0;
  switch (types) {
    case 0:
      // num = disposeDecimal(value,point,zeroFill);
      if (Math.abs(num) >= 100000000) {
        num = "".concat(disposeDecimal(num / 100000000, point, zeroFill), "\u4EBF");
      } else if (Math.abs(num) >= 10000) {
        num = "".concat(disposeDecimal(num / 10000, point, zeroFill), "w");
      }
      break;
    case 1:
      num = Number.parseFloat(num).toLocaleString('en-US');
    default:
      break;
  }
  return num;
}

// 添加逗号颗粒
function numCode(val) {
  return changeNum(val, '', '', 1);
}

// {{ 1232134 | changeNum }} 万位亿位处理，保留两位小数,不补零
// {{ 1232134 | numCode }} 添加逗号，保留两位小数，不补零
// {{ 1232134 | disposeDecimal }} 保留两位小数，不补零
// {{ 1232134 | disposeZero }} 保留四位小数，补零
// {{ this.filtrate.changeNum(439942394,2,true) }} 调用函数传参
function setTracking(value) {
  // console.log(' window.localStorage.getItem("user_message")', window.localStorage.getItem("user_message"))
  var user_message = window.localStorage.getItem('user_message') ? JSON.parse(window.localStorage.getItem('user_message')) : {};
  var user_id = user_message.user_id,
    dealer_name = user_message.dealer_name,
    dealer_id = user_message.dealer_id,
    name = user_message.name,
    user_name = user_message.user_name,
    principal_name = user_message.principal_name,
    principal_id = user_message.principal_id;
  var params = {
    ap: 'aft-crm-dy',
    cm: {
      os: 'PC',
      muid: 9,
      //模块id
      ud: dealer_name,
      //所属经销商
      udid: dealer_id,
      //经销商id
      ub: principal_name,
      //所属品牌
      ubid: principal_id,
      //品牌id
      uid: user_id,
      //使用人id
      rn: name,
      //使用人
      un: user_name,
      //风火轮账号
      t: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss') //日期
    }
    // et: {
    //   en: "Account_Obtain", //分类：账号获取
    //   kv: {
    //     mediaType: this.platform, //媒体号类型（抖音、快手）
    //     mediaID: this.userInfo.code, //媒体号ID
    //     mediaName: this.userInfo.nickname, //媒体号昵称
    //   },
    // },
  };

  params.et = value;
  _common.default.setTracking(params).then(function (res) {
    if (res.code == 200) {
      console.log('埋点成功');
    } else {
      console.error('埋点失败');
    }
  });
}
var _default = {
  formatNumber: formatNumber,
  judgeObject: judgeObject,
  disposeDecimal: disposeDecimal,
  changeNum: changeNum,
  disposeZero: disposeZero,
  numCode: numCode,
  setTracking: setTracking
};
exports.default = _default;