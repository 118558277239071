var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_box" },
    [
      _c(
        "a-card",
        [
          _c(
            "p",
            [
              _c("a-icon", {
                staticClass: "icon_s",
                attrs: { type: "exclamation-circle" },
              }),
              _vm._v(" 默认展示近7天（含当天）内互动的用户数据 "),
            ],
            1
          ),
          _vm.infoData.forbiddenOperation
            ? _c("alertMarning", {
                attrs: { description: _vm.infoData.announcementInfo },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tabs_box" },
            [
              _c(
                "a-tabs",
                {
                  on: { change: _vm.changeTabs },
                  model: {
                    value: _vm.tab_active,
                    callback: function ($$v) {
                      _vm.tab_active = $$v
                    },
                    expression: "tab_active",
                  },
                },
                [
                  _c("a-tab-pane", { key: 4, attrs: { tab: "命中策略" } }),
                  _c("a-tab-pane", { key: 2, attrs: { tab: "策略已回复" } }),
                  _c("a-tab-pane", { key: 3, attrs: { tab: "策略未处理" } }),
                ],
                1
              ),
              _c(
                "a",
                { staticClass: "setting_box", on: { click: _vm.openModal } },
                [
                  _c("a-icon", {
                    staticStyle: { "margin-right": "4px" },
                    attrs: { type: "setting", theme: "filled" },
                  }),
                  _vm._v(
                    " 配置目标城市：" +
                      _vm._s(_vm.city_setting.isOpened != 0 ? "自定义" : "全部")
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "a-form-model",
                _vm._b(
                  { ref: "searchFrom", attrs: { model: _vm.search_form } },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "城市" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    "default-active-first-option": false,
                                    "filter-option": _vm.filterOption,
                                    placeholder: "请选择城市",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.cityCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search_form, "cityCode", $$v)
                                    },
                                    expression: "search_form.cityCode",
                                  },
                                },
                                _vm._l(_vm.buyCarList, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.city_code,
                                      attrs: { value: item.city_code },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "命中策略" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    "default-active-first-option": false,
                                    "filter-option": _vm.filterOption,
                                    placeholder: "请选择命中策略",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.sceneId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search_form, "sceneId", $$v)
                                    },
                                    expression: "search_form.sceneId",
                                  },
                                },
                                _vm._l(
                                  _vm.base_dictionaries.hitStrategyDtoList,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.sceneId,
                                        attrs: { value: item.sceneId },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.sceneName) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "用户昵称" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxLength: 100,
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.search_form.userNickname,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.search_form,
                                      "userNickname",
                                      $$v
                                    )
                                  },
                                  expression: "search_form.userNickname",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "互动类型" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    "default-active-first-option": false,
                                    "filter-option": _vm.filterOption,
                                    placeholder: "请选择",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.strategyTypeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.search_form,
                                        "strategyTypeCode",
                                        $$v
                                      )
                                    },
                                    expression: "search_form.strategyTypeCode",
                                  },
                                },
                                _vm._l(
                                  _vm.base_dictionaries.strategyTypeList,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.code,
                                        attrs: { value: item.code },
                                      },
                                      [_vm._v(" " + _vm._s(item.value) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "互动时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: ["开始时间", "结束时间"],
                                  format: "YYYY-MM-DD HH:mm",
                                  inputReadOnly: "",
                                  "show-time": {
                                    defaultValue: _vm.arrayMonent,
                                  },
                                  "disabled-date": _vm.disabledDate,
                                  ranges: {
                                    今日: [
                                      _vm.moment().startOf("day"),
                                      _vm.moment(),
                                    ],
                                  },
                                  allowClear: false,
                                },
                                on: { change: _vm.onChangeDate },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.tab_active === 2 || _vm.tab_active === 4,
                              expression:
                                "tab_active === 2 || tab_active === 4",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "策略回复时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: ["开始时间", "结束时间"],
                                  format: "YYYY-MM-DD HH:mm",
                                  inputReadOnly: "",
                                  "show-time": {
                                    defaultValue: _vm.arrayMonent,
                                  },
                                  "disabled-date": _vm.disabledDate,
                                  ranges: {
                                    今日: [
                                      _vm.moment().startOf("day"),
                                      _vm.moment(),
                                    ],
                                  },
                                },
                                on: { change: _vm.onChangeAttenDate },
                                model: {
                                  value: _vm.attention_time,
                                  callback: function ($$v) {
                                    _vm.attention_time = $$v
                                  },
                                  expression: "attention_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.tab_active === 1 || _vm.tab_active === 4,
                              expression:
                                "tab_active === 1 || tab_active === 4",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择",
                                            "allow-clear": "",
                                          },
                                          model: {
                                            value:
                                              _vm.search_form
                                                .autoReplyStatusList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.search_form,
                                                "autoReplyStatusList",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "search_form.autoReplyStatusList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.status_list,
                                          function (item) {
                                            return _c(
                                              "a-select-option",
                                              { key: item.id },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: {
                                            width: "320px",
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            placeholder: "请选择失败原因",
                                            "allow-clear": "",
                                          },
                                          model: {
                                            value:
                                              _vm.search_form.failureCauseCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.search_form,
                                                "failureCauseCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "search_form.failureCauseCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.base_dictionaries
                                            .failureCauseList,
                                          function (item) {
                                            return _c(
                                              "a-select-option",
                                              { key: item.code },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tab_active === 2,
                              expression: "tab_active === 2",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "跟进情况" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: "请选择跟进情况",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.trackStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.search_form,
                                        "trackStatus",
                                        $$v
                                      )
                                    },
                                    expression: "search_form.trackStatus",
                                  },
                                },
                                _vm._l(_vm.follow_up_list, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tab_active === 2,
                              expression: "tab_active === 2",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "策略有效留资" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.infoType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search_form, "infoType", $$v)
                                    },
                                    expression: "search_form.infoType",
                                  },
                                },
                                _vm._l(_vm.info_type_list, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10p",
                          attrs: { icon: "redo", type: "dashed" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10p",
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchClick },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "cn-table",
            {
              ref: "cntable",
              attrs: {
                configPayload: _vm.configPayload,
                selectValue: _vm.search_form,
                initData: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "userInfo",
                  fn: function (text) {
                    return _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "row-box user-box" },
                        [
                          _c("div", [
                            _c("span", [_vm._v("昵")]),
                            _c("span", [_vm._v("称:")]),
                          ]),
                          _c("text-ellipsis", {
                            key: text.userId,
                            attrs: { value: text.userNickname },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "row-box user-box" },
                        [
                          _c("div", [
                            _c("span", [_vm._v("抖")]),
                            _c("span", [_vm._v("音")]),
                            _c("span", [_vm._v("号:")]),
                          ]),
                          _c("text-ellipsis", {
                            key: text.userId,
                            attrs: { value: text.userDyCode || "无" },
                          }),
                          text.userDyCode
                            ? _c("a-icon", {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: text.userDyCode,
                                    expression: "text.userDyCode",
                                    arg: "copy",
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:success",
                                    value: _vm.handleCopySuccess,
                                    expression: "handleCopySuccess",
                                    arg: "success",
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:error",
                                    value: _vm.handleCopyFail,
                                    expression: "handleCopyFail",
                                    arg: "error",
                                  },
                                ],
                                staticClass: "copy-btn",
                                attrs: { type: "copy" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row-box user-box" }, [
                        _c("div", [
                          _c("span", [_vm._v("城")]),
                          _c("span", [_vm._v("市:")]),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(text.city ? text.city : "未知")),
                        ]),
                      ]),
                    ])
                  },
                },
                {
                  key: "recentInteractionTypes",
                  fn: function (text) {
                    return _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "row-box anthor-box" },
                        [
                          _c("div", [
                            _c("span", [_vm._v("类")]),
                            _c("span", [_vm._v("型:")]),
                          ]),
                          _c("text-ellipsis", {
                            key: text.userId,
                            attrs: { value: text.strategyTypeValue },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "row-box anthor-box" },
                        [
                          _c("div", [
                            _c("span", [_vm._v("互")]),
                            _c("span", [_vm._v("动")]),
                            _c("span", [_vm._v("时")]),
                            _c("span", [_vm._v("间:")]),
                          ]),
                          _c("text-ellipsis", {
                            key: text.userId,
                            attrs: { value: text.actionTime },
                          }),
                        ],
                        1
                      ),
                    ])
                  },
                },
                {
                  key: "liveInteractContents",
                  fn: function (text) {
                    return _c("div", {}, [
                      text.liveInteractContents
                        ? _c("ul", { staticClass: "contents_ul" }, [
                            _c(
                              "li",
                              { staticClass: "contens_li" },
                              [
                                _c(
                                  "a-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      title: _vm.disposeLiveInteractContents(
                                        text.strategyTypeCode,
                                        text.liveInteractContents[0]
                                      ),
                                    },
                                  },
                                  [
                                    _c("a", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.disposeLiveInteractContents(
                                            text.strategyTypeCode,
                                            text.liveInteractContents[0]
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("span", [_vm._v("无")]),
                    ])
                  },
                },
                {
                  key: "sceneName",
                  fn: function (text) {
                    return _c("div", {}, [
                      text.sceneName
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-tag",
                                { attrs: { type: "warning", effect: "dark" } },
                                [_vm._v(" " + _vm._s(text.sceneName) + " ")]
                              ),
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    title:
                                      "皮皮拉客专属的运营策略，有疑问可以联系工作人员",
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: text.strategyTypeValue == 3,
                                        expression:
                                          "text.strategyTypeValue == 3",
                                      },
                                    ],
                                    attrs: { type: "question-circle" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v("无")]),
                      text.displayOpenAutoReply
                        ? _c(
                            "a",
                            {
                              staticClass: "btn_style",
                              on: {
                                click: function ($event) {
                                  return _vm.openDrawerStrategy(
                                    text.sceneId,
                                    text.sceneName
                                  )
                                },
                              },
                            },
                            [_vm._v("启用自动回复")]
                          )
                        : _vm._e(),
                    ])
                  },
                },
                {
                  key: "autoReplyStatus",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.disposeStatus(text.autoReplyStatus)) +
                            " "
                        ),
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              placement: "topRight",
                              title: text.errorMsg,
                            },
                          },
                          [
                            text.autoReplyStatus == 3
                              ? _c("div", { staticClass: "reply_msg" }, [
                                  _vm._v(" " + _vm._s(text.errorMsg) + " "),
                                ])
                              : _vm._e(),
                            text.autoReplyStatus == 1
                              ? _c("div", { staticClass: "reply_msg" }, [
                                  _vm._v(
                                    " " + _vm._s(text.unResponseCause) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "action",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        text.autoReplyStatus === 2
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "padding-left": "0" },
                                attrs: {
                                  type: "link",
                                  loading:
                                    _vm.active_id == text.userId &&
                                    _vm.route_loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goPrivatLetter(
                                      text.userOpenId,
                                      text.userId,
                                      text.displayPrompt
                                    )
                                  },
                                },
                              },
                              [_vm._v("私信 ")]
                            )
                          : _c(
                              "a-button",
                              {
                                staticStyle: { "padding-left": "0" },
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDyUserQrCodeModal(text)
                                  },
                                },
                              },
                              [_vm._v("联系ta ")]
                            ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "userChatContents",
                  fn: function (text) {
                    return _c("div", {}, [
                      text.userChatContents
                        ? _c(
                            "ul",
                            { staticClass: "contents_ul" },
                            [
                              _c("div", [_vm._v(_vm._s(text.userChatTime))]),
                              _vm._l(
                                text.userChatContents,
                                function (item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "contens_li" },
                                    [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: item,
                                          },
                                        },
                                        [_c("a", [_vm._v(_vm._s(item))])]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c("span", [_vm._v("无")]),
                    ])
                  },
                },
                {
                  key: "userWaitTime",
                  fn: function (text) {
                    return _c("div", {}, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.disposeLongTime(text.userWaitTime)) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "authorTrackContents",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        text.authorTrackContents
                          ? _c(
                              "ul",
                              { staticClass: "contents_ul" },
                              _vm._l(
                                text.authorTrackContents,
                                function (item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "contens_li" },
                                    [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: item,
                                          },
                                        },
                                        [_c("a", [_vm._v(_vm._s(item))])]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "padding-left": "0" },
                            attrs: {
                              type: "link",
                              loading:
                                _vm.active_id == text.userId &&
                                _vm.route_loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goPrivatLetter(
                                  text.userOpenId,
                                  text.userId,
                                  text.displayPrompt
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.disposeTrackStatus(text.trackStatus)) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "replyMsg",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        _c("div", [
                          _vm._v(_vm._s(text.replyTime || text.autoReplyTime)),
                        ]),
                        text.replyMsg
                          ? _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  title: text.replyMsg,
                                },
                              },
                              [
                                _c("div", { staticClass: "reply_msg" }, [
                                  _vm._v(_vm._s(text.replyMsg)),
                                ]),
                              ]
                            )
                          : _c("div", [_vm._v("无")]),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "infoType",
                  fn: function (text) {
                    return _c(
                      "div",
                      {},
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(text.infoType == 1 ? "未留资" : "私信留资")
                          ),
                        ]),
                        _vm._l(text.phoneNums, function (item) {
                          return _c("div", { key: item }, [
                            _vm._v(_vm._s(item)),
                          ])
                        }),
                      ],
                      2
                    )
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "recentInteractionTypesTitle" },
                  slot: "recentInteractionTypesTitle",
                },
                [
                  _vm._v(" 最近互动类型 "),
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                        _c("div", [_vm._v("关注：关注了主播")]),
                        _c("div", [_vm._v("直播互动：在主播直播间发表了评论")]),
                        _c("div", [_vm._v("定向：命中了皮皮拉客专属策略")]),
                        _c("div", [_vm._v("视频评论：评论了主播视频")]),
                        _c("div", [_vm._v("视频点赞：点赞了主播视频")]),
                        _c("div", [_vm._v("互动时间：最近一次互动的时间")]),
                      ]),
                      _c("a-icon", { attrs: { type: "question-circle" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "infoTypeTitle" }, slot: "infoTypeTitle" },
                [
                  _vm._v(" 策略有效留资 "),
                  _c(
                    "a-tooltip",
                    {
                      attrs: {
                        placement: "top",
                        title:
                          "用户留资前30分钟的最近3条主播消息含皮皮拉客发的记为皮皮拉客留资，30分钟内无数据则看前24小时内",
                      },
                    },
                    [_c("a-icon", { attrs: { type: "question-circle" } })],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("reply-dawer", {
        ref: "strategydawer",
        attrs: { straegy_visible: _vm.straegy_visible },
        on: {
          closeDrawerStrategy: _vm.closeDrawerStrategy,
          resetTable: _vm.resetTable,
          startUseing: _vm.startUseing,
        },
      }),
      _c("city-setting-modal", {
        attrs: {
          visible_modal: _vm.visible_modal,
          city_setting: _vm.city_setting,
          confirm_loading: _vm.confirm_loading,
        },
        on: {
          getTargetCity: _vm.getTargetCity,
          closeModal: _vm.closeModal,
          saveCity: _vm.saveCity,
          closeCitySetting: _vm.closeCitySetting,
        },
      }),
      _c("tips-modal"),
      _c("dy-user-qr-code-modal", { ref: "DyUserQrCodeModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }