"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
//
var _default2 = {
  name: 'MyChart',
  mixins: [_resize.default],
  props: {
    options: {
      type: Object
    },
    id: {
      type: String
    },
    defaultStyle: {
      type: Object,
      default: function _default() {
        return {
          width: '100%',
          height: '100%'
        };
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    options: function options(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.initChart();
        });
      }
    }
  },
  methods: {
    initChart: function initChart() {
      if (this.chart) {
        this.chart.setOption(this.options, true);
      } else {
        var dom = document.getElementById(this.id);
        if (dom) {
          this.chart = _echarts.default.init(dom);
          this.chart.setOption(this.options, true);
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.options) {
      this.initChart();
    }
  }
};
exports.default = _default2;