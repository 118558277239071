"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _app = _interopRequireDefault(require("./modules/app"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _user = _interopRequireDefault(require("./modules/user"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _dashboard = _interopRequireDefault(require("./modules/dashboard"));
var _anchor = _interopRequireDefault(require("./modules/anchor"));
var _video = _interopRequireDefault(require("./modules/video"));
var _live_record = _interopRequireDefault(require("./modules/live_record"));
var _new_video = _interopRequireDefault(require("./modules/new_video"));
var _map_options = _interopRequireDefault(require("./modules/map_options"));
var _intelligence_play = _interopRequireDefault(require("./modules/intelligence_play"));
var _live_stream = _interopRequireDefault(require("./modules/live_stream"));
var _live_analyze = _interopRequireDefault(require("./modules/live_analyze"));
var _live_now = _interopRequireDefault(require("./modules/live_now"));
var _monitoring_video = _interopRequireDefault(require("./modules/monitoring_video"));
var _monitoring_live = _interopRequireDefault(require("./modules/monitoring_live"));
var _propaganda = _interopRequireDefault(require("./modules/propaganda"));
var _account = _interopRequireDefault(require("./modules/account"));
var _private_letter = _interopRequireDefault(require("./modules/private_letter"));
var _short_management = _interopRequireDefault(require("./modules/short_management"));
var _short_comment = _interopRequireDefault(require("./modules/short_comment"));
var _return_visit = _interopRequireDefault(require("./modules/return_visit"));
var _retention_records = _interopRequireDefault(require("./modules/retention_records"));
var _auth = _interopRequireDefault(require("./modules/auth"));
// import minute_dashboard from './modules/minute_dashboard'

// import model_list from './modules/model_list'
// import model_performance from './modules/model_performance'
// import ranking from './modules/ranking'
// import video_operate from './modules/video_operate'
// import brand_analysis from './modules/brand_analysis'
// import home from './modules/home'
// import hot_topic from './modules/hot_topic'
// import minute_live from './modules/minute_live'
// import store_live from './modules/store_live'
// import brand_rectangle from './modules/brand_rectangle'
// import brand_kol from './modules/brand_kol'
// import negative from './modules/negative';
// import koa_car from './modules/koa_car'
// import operations_assistant from './modules/operations_assistant'
// import store_manage from './modules/store_manage'
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  state: function state() {
    return {
      curAuthorId: '86488375517'
    };
  },
  modules: {
    app: _app.default,
    settings: _settings.default,
    user: _user.default,
    permission: _permission.default,
    dashboard: _dashboard.default,
    anchor: _anchor.default,
    video: _video.default,
    // minute_dashboard,
    live_record: _live_record.default,
    // model_list,
    // model_performance,
    // ranking,
    // video_operate,
    // brand_analysis,
    // home,
    // hot_topic,
    new_video: _new_video.default,
    map_options: _map_options.default,
    intelligence_play: _intelligence_play.default,
    live_stream: _live_stream.default,
    live_analyze: _live_analyze.default,
    // minute_live,
    // store_live,
    // brand_rectangle,
    live_now: _live_now.default,
    monitoring_video: _monitoring_video.default,
    monitoring_live: _monitoring_live.default,
    // brand_kol,
    // negative,
    // koa_car,
    propaganda: _propaganda.default,
    // operations_assistant,
    account: _account.default,
    // store_manage,
    private_letter: _private_letter.default,
    short_management: _short_management.default,
    short_comment: _short_comment.default,
    return_visit: _return_visit.default,
    retention_records: _retention_records.default,
    auth: _auth.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;