"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _echarts = _interopRequireDefault(require("echarts"));
var _default = {
  color: ['#3398DB'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },

    formatter: function formatter(params, ticket, callback) {
      var item = params[0];
      return "".concat(item.name, " <br> \u6F5C\u5BA2\u5206\u5E03: ").concat(item.data, "%");
    }
  },
  grid: {
    left: '15%',
    right: '5%',
    bottom: '10%',
    top: '10%'
  },
  xAxis: [{
    type: 'category',
    data: ['南区', '东区', '北区', '西区'],
    axisTick: {
      alignWithLabel: true
    }
  }],
  yAxis: [{
    type: 'value',
    axisTick: {
      inside: false
    },
    axisLine: {
      show: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed"
      }
    }
  }],
  series: [{
    name: '潜客分布',
    type: 'bar',
    barWidth: '45%',
    data: [10280, 9716, 7010, 5331],
    itemStyle: {
      normal: {
        color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
          offset: 0,
          color: 'rgba(100,169,243,0.00)'
        }, {
          offset: 1,
          color: 'rgba(100,169,243,1.00)'
        }])
      }
    }
  }]
};
exports.default = _default;