"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _lodash = _interopRequireDefault(require("lodash"));
var _keyword_reply = _interopRequireDefault(require("@/api/keyword_reply"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      isEdit: false,
      isEditable: true,
      isVisible: false,
      isLoading: false,
      isSaving: false,
      form: {
        keyWordNameList: [""],
        keyWordResponseList: [{
          message: "",
          operationType: 1,
          // 操作类型 1-新增 2-修改 3-删除
          responseId: "",
          blurIndex: 0,
          emojiPickerVisible: false
        }],
        matchType: 2,
        // 关键词匹配类型,1-包含全部 2-包含任意
        ruleId: 0
      },
      removeKeyWordResponseList: [],
      plainOptions: [{
        label: "包含全部",
        value: 1
      }, {
        label: "包含任意",
        value: 2
      }],
      exitKeywordList: []
    };
  },
  computed: {
    title: function title() {
      var result = "";
      if (!this.isEditable) {
        result = "查看";
      } else if (this.isEdit) {
        result = "编辑";
      } else {
        result = "新增";
      }
      return "".concat(result, "\u56DE\u590D\u89C4\u5219");
    }
  },
  methods: {
    /**
     * 打开drawer
     * @param {object} data 传递的数据
     * @param {array} keywordList 关键词列表
     * @param {boolean} editable 是否可以编辑
     */
    openDrawer: function openDrawer(data, keywordList) {
      var _this = this;
      var editable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      Object.assign(this.$data, this.$options.data());
      this.exitKeywordList = keywordList;
      if (data) {
        var copyData = _lodash.default.cloneDeep(data);
        this.isEdit = true;
        this.form.ruleId = copyData.id;
        this.form.matchType = copyData.matchType;
        this.form.keyWordNameList = copyData.keyWordNames;
        this.form.keyWordResponseList = copyData.keyWordTechniqueContents ? copyData.keyWordTechniqueContents.map(function (item) {
          return {
            message: item.message,
            operationType: 2,
            responseId: item.responseId,
            blurIndex: 0,
            emojiPickerVisible: false
          };
        }) : [{
          message: "",
          operationType: 1,
          responseId: "",
          blurIndex: 0,
          emojiPickerVisible: false
        }];
      }
      this.isEditable = editable;
      this.isVisible = true;
      this.$nextTick(function () {
        _this.$refs.form.clearValidate();
        _this.form.keyWordResponseList.forEach(function (_, index) {
          _this.$refs["text-".concat(index, "-input")][0].clearValidate();
        });
      });
    },
    /**
     * 关闭弹窗
     */
    close: function close() {
      this.isVisible = false;
    },
    /**
     * 关键校验词规则
     * @param {object} rule 规则
     * @param {string} value 校验值
     * @param {Function} callback 回调函数
     */
    blurKeyWord: function blurKeyWord(rule, value, callback) {
      var index = rule.index;
      var reg = /[,，]/g;
      if (!value) {
        callback(new Error("关键词不能为空"));
      } else if (reg.test(value)) {
        callback(new Error("关键词不支持中英文逗号"));
      } else if (this.form.keyWordNameList.filter(function (_, kIndex) {
        return kIndex !== index;
      }).concat(this.exitKeywordList).includes(value)) {
        callback(new Error("该关键词已存在，请重新输入"));
      } else {
        callback();
      }
    },
    /**
     * 新增关键词
     */
    addKeyWord: function addKeyWord() {
      if (this.form.keyWordNameList.length < 10) {
        this.form.keyWordNameList.push("");
      }
    },
    /**
     * 删除关键词
     * @param {number} index 关键词索引
     */
    removeKeyWord: function removeKeyWord(index) {
      this.form.keyWordNameList.splice(index, 1);
      this.$refs.form.validate();
    },
    /**
     * 回复内容校验
     * @param {*} rule
     * @param {*} _
     * @param {*} callback
     * @return {*}
     */
    replyTextValid: function replyTextValid(rule, _, callback) {
      var index = rule.index;
      var value = this.form.keyWordResponseList[index].message;
      if (!value) {
        callback("请输入回复内容");
        return;
      }
      callback();
    },
    /**
     * 新增回复
     */
    addReply: function addReply() {
      this.form.keyWordResponseList.push({
        message: "",
        operationType: 1,
        responseId: ""
      });
    },
    /**
     * 删除回复
     * @param {number} index 回复索引
     */
    removeReply: function removeReply(index) {
      var removeItem = this.form.keyWordResponseList.splice(index, 1)[0];
      this.$refs.form.validate();
      if (removeItem.responseId) {
        this.removeKeyWordResponseList.push(_objectSpread(_objectSpread({}, removeItem), {}, {
          operationType: 3
        }));
      }
    },
    /**
     * 保存
     */
    save: function save() {
      var _this2 = this;
      // 校验回话有没有超过100个字
      var isError = false;
      this.form.keyWordResponseList.forEach(function (item, index) {
        if (!item.message || item.message.length > 100) {
          _this2.$refs["text-".concat(index, "-input")][0].onFieldBlur();
          isError = true;
        }
      });
      if (isError) return;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.isSaving = true;
          var data = _lodash.default.cloneDeep(_this2.form);
          data.keyWordResponseList = [].concat((0, _toConsumableArray2.default)(data.keyWordResponseList), (0, _toConsumableArray2.default)(_this2.removeKeyWordResponseList));
          var API = _this2.isEdit ? _keyword_reply.default.updateKeyWordV1_11_0(data) : _keyword_reply.default.createKeyWordV1_11_0(data);
          API.then(function (res) {
            if (res.code === 200) {
              _this2.$message.success("保存成功！");
              _this2.close();
              _this2.$emit("resetTable");
            } else {
              _this2.$message.error(res.message);
            }
          }).catch(function () {
            _this2.$message.error("保存失败！");
          }).finally(function () {
            return _this2.isSaving = false;
          });
        }
      });
    }
  },
  created: function created() {}
};
exports.default = _default;