var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > 0
    ? _c(
        "div",
        { staticClass: "pagination-options" },
        [
          _c("a-pagination", {
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              "default-current": _vm.current,
              "default-pageSize": _vm.size,
              total: _vm.total,
              "show-total": function (total) {
                return "共 " + total + " 条"
              },
              "page-size": _vm.size,
              "page-size-options": _vm.options,
            },
            on: {
              "update:pageSize": function ($event) {
                _vm.size = $event
              },
              "update:page-size": function ($event) {
                _vm.size = $event
              },
              change: _vm.bindCurrentChange,
              showSizeChange: _vm.bindSizeChange,
            },
            model: {
              value: _vm.current,
              callback: function ($$v) {
                _vm.current = $$v
              },
              expression: "current",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }