var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "title-header" }, [
            _vm._v("人工回复时段设置"),
          ]),
          _c("p", [_vm._v("人工时段内AI只在以下三种情况自动发送私信：")]),
          _c("p", [
            _vm._v("1、用户进入会话，发送一条欢迎语，及时引导用户开口。"),
          ]),
          _c("p", [
            _vm._v(
              "2、未留资用户沉默一段时间后，发送一条召回话术，引导用户留资（平均增加10%线索量）。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "3、用户留资后，发送一条类似 “收到” 的话术，节省人工重复操作。"
            ),
          ]),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            [
              _c("drag-week-time", {
                staticStyle: { width: "800px" },
                attrs: { colspan: 2 },
                model: {
                  value: _vm.timeStr,
                  callback: function ($$v) {
                    _vm.timeStr = $$v
                  },
                  expression: "timeStr",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "content", staticStyle: { "margin-bottom": "24px" } },
          [
            _c(
              "a-button",
              {
                staticClass: "save-btn",
                attrs: { type: "primary", loading: _vm.saving },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }