"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
var _live_analyze = _interopRequireDefault(require("@/api/live_analyze"));
var _ = _interopRequireDefault(require(".."));
var _common = _interopRequireDefault(require("@/utils/common"));
var getLiveMessage = _live_analyze.default.getLiveMessage,
  _getCommentAnalysis = _live_analyze.default.getCommentAnalysis,
  getHotCarType = _live_analyze.default.getHotCarType,
  _getCommentList = _live_analyze.default.getCommentList,
  _getAudience = _live_analyze.default.getAudience,
  _getAnalysisChart = _live_analyze.default.getAnalysisChart,
  _getHotWord = _live_analyze.default.getHotWord,
  _getAudienceAdress = _live_analyze.default.getAudienceAdress,
  getLiveTime = _live_analyze.default.getLiveTime,
  getCarCommentList = _live_analyze.default.getCarCommentList;
var _default = {
  namespaced: true,
  state: {
    liveRecord: {},
    liveComment: [],
    tableLoading: false,
    commentList: {
      list: [],
      pagination: {}
    },
    getAudience: {},
    hotCarType: [],
    analysisLine: {},
    commentTotal: '',
    room_id: '',
    author_id: '',
    current: 1,
    page_size: 25,
    loading: false,
    noMore: false,
    word: '',
    hotWord: [],
    fansStat: {},
    audienceAdress: [],
    begin_time: '',
    end_time: '',
    live_count: [],
    jubgeComment: true
  },
  mutations: {
    changeState: function changeState(state, payload) {
      var data = payload.data,
        name = payload.name;
      state[name] = data;
    },
    CHANGE_LOADING: function CHANGE_LOADING(state, result) {
      state.tableLoading = result;
    },
    CHANGE_LINE_CHART: function CHANGE_LINE_CHART(state, payload) {
      var data = payload.data;
      var nameTips = ['观看人数', '点赞人数', '评论人数', '送礼人数'];
      var lineData = [data.total_user, data.like_count, data.comment_user_count, data.follow_count];
      var time = data.time;
      var d = {
        nameTips: nameTips,
        lineData: lineData,
        time: time
      };
      state.analysisLine = d;
    },
    CHANGE_LIVE_COMMENT: function CHANGE_LIVE_COMMENT(state, payload) {
      var data = payload.data,
        name = payload.name;
      state.liveComment = data;
      // state.commentTotal = numEdit(total)
    },

    CHANGE_COMMENT_LIST: function CHANGE_COMMENT_LIST(state, payload) {
      var data = payload.data;
      if (state.current == 2) {
        state.commentList = data;
      } else {
        state.commentList.pagination = data.pagination;
        state.commentList.list = [].concat((0, _toConsumableArray2.default)(state.commentList.list), (0, _toConsumableArray2.default)(data.list));
      }
    },
    CHANGE_FANS_STAT: function CHANGE_FANS_STAT(state, payload) {
      var data = payload.data;
      console.log(data);
      if (!data.count.length) {
        state.fansStat = null;
        return;
      }
      var total = data.count[0] + data.count[1];
      var man = (data.count[0] / total).toFixed(2);
      var girl = (data.count[1] / total).toFixed(2);
      state.fansStat = {
        gender: {
          percentage: [man, girl],
          desc: ['男', '女']
        }
      };
    },
    CHANGE_LIVE_RECORD: function CHANGE_LIVE_RECORD(state, payload) {
      var data = payload.data;
      var timeInterval = _common.default.timeInterval,
        numEdit = _common.default.numEdit;
      var arr = [];
      for (var key in data) {
        var arrDate = data[key];
        for (var i = 0; i < arrDate.length; i++) {
          var obj = {
            room_id: arrDate[i].room_id,
            publish_time: arrDate[i].publish_time.slice(11),
            time: timeInterval(arrDate[i].publish_time, arrDate[i].finish_time),
            count: numEdit(arrDate[i].user_count)
          };
          arr.push(obj);
        }
      }
      state.live_count = arr;
    },
    CHANGE_HOTCAR_PICTURE: function CHANGE_HOTCAR_PICTURE(state, payload) {
      var data = payload.data;
      var count = data.map(function (item) {
        return item.count;
      });
      count.sort(function (a, b) {
        return b - a;
      });
      for (var i = 0; i < data.length; i++) {
        data[i].image = process.env.VUE_APP_OSS_HOST + '/' + data[i].image;
        data[i].rate = data[i].count / count[0] * 80;
      }
      state.hotCarType = data;
    },
    CHANGE_PROVINCE: function CHANGE_PROVINCE(state, payload) {
      var data = payload.data;
      var count = data.map(function (item) {
        return item.num;
      });
      count.sort(function (a, b) {
        return b - a;
      });
      for (var i = 0; i < data.length; i++) {
        data[i].image = process.env.VUE_APP_OSS_HOST + '/' + data[i].image;
        data[i].rate = data[i].num / count[0] * 80;
        data[i].tips = (data[i].count * 100).toFixed(1);
      }
      state.audienceAdress = data;
    }
  },
  actions: {
    getLiveRecordAnaly: function getLiveRecordAnaly(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      var room_id = state.room_id;
      return getLiveMessage({
        roomId: room_id
      }).then(function (res) {
        commit('changeState', {
          data: res.data,
          name: 'liveRecord'
        });
      });
    },
    getCommentAnalysis: function getCommentAnalysis(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      var room_id = state.room_id;
      return _getCommentAnalysis(room_id).then(function (res) {
        commit('CHANGE_LIVE_COMMENT', {
          data: res.data,
          name: 'liveComment'
        });
      });
    },
    getCommentHot: function getCommentHot(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      var room_id = state.room_id;
      return getHotCarType(room_id).then(function (res) {
        commit('CHANGE_HOTCAR_PICTURE', {
          data: res.data,
          name: 'hotCarType'
        });
      });
    },
    getCommentList: function getCommentList(_ref4) {
      var commit = _ref4.commit,
        state = _ref4.state;
      var current = state.current,
        page_size = state.page_size,
        room_id = state.room_id,
        word = state.word,
        jubgeComment = state.jubgeComment;
      commit('changeState', {
        data: current + 1,
        name: 'current'
      });
      commit('changeState', {
        data: true,
        name: 'loading'
      });
      var precise = '';
      if (jubgeComment) {
        precise = _getCommentList;
      } else {
        precise = getCarCommentList;
      }
      return precise({
        room_id: room_id,
        current: current,
        page_size: page_size,
        word: word
      }).then(function (res) {
        commit('CHANGE_COMMENT_LIST', {
          data: res.data
        });
        setTimeout(function () {
          if (current >= res.data.pagination.pages) {
            commit('changeState', {
              data: true,
              name: 'noMore'
            });
          }
          commit('changeState', {
            data: false,
            name: 'loading'
          });
        }, 200);
      });
    },
    getAudience: function getAudience(_ref5) {
      var commit = _ref5.commit,
        state = _ref5.state;
      var room_id = state.room_id;
      return _getAudience(room_id).then(function (res) {
        commit('CHANGE_FANS_STAT', {
          data: res.data
        });
      });
    },
    getAnalysisChart: function getAnalysisChart(_ref6) {
      var commit = _ref6.commit,
        state = _ref6.state;
      var room_id = state.room_id;
      return _getAnalysisChart(room_id).then(function (res) {
        commit('CHANGE_LINE_CHART', {
          data: res.data
        });
      });
    },
    getHotWord: function getHotWord(_ref7) {
      var commit = _ref7.commit,
        state = _ref7.state;
      var room_id = state.room_id;
      return _getHotWord(room_id).then(function (res) {
        commit('changeState', {
          data: res.data,
          name: 'hotWord'
        });
      });
    },
    getAudienceAdress: function getAudienceAdress(_ref8) {
      var commit = _ref8.commit,
        state = _ref8.state;
      var room_id = state.room_id;
      return _getAudienceAdress(room_id).then(function (res) {
        res.data = res.data;
        commit('CHANGE_PROVINCE', {
          data: res.data
          // name: 'audienceAdress'
        });
      });
    },
    getLiveCalender: function getLiveCalender(_ref9) {
      var commit = _ref9.commit,
        state = _ref9.state;
      var begin_time = state.begin_time,
        end_time = state.end_time,
        author_id = state.author_id;
      return getLiveTime({
        begin_time: begin_time,
        end_time: end_time,
        author_id: author_id
      }).then(function (res) {
        commit('CHANGE_LIVE_RECORD', {
          data: res.data
        });
      });
    } // getCarCommentList({
    //   commit,state
    // }){
    //   const {current,page_size,room_id,word} = state;
    //   return getCarCommentList(room_id)
    //   .then((res)=>{
    //   })
    // }
  }
};
exports.default = _default;