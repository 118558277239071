"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.undertakeResponseConfigPayload = exports.strategyConfigPayload = exports.statusTypeList = exports.smartResponseConfigPayload = exports.settingAuthorColumns = exports.productResponsePrincipalConfigPayload = exports.productResponseAuthorConfigPayload = exports.principalKeywordResponseConfigPayload = exports.listTypeList = exports.conditionMap = exports.conditionList = exports.commonConfigPayload = exports.classifyTabList = exports.authorKeywordResponseConfigPayload = void 0;
var _pplk_response_manage = _interopRequireDefault(require("@/api/pplk_response_manage"));
var classifyTabList = [
// { label: '产品应答', value: '产品应答' },
// { label: '城市应答', value: '城市应答' },
{
  label: '关键词应答',
  value: '关键词应答'
}
// { label: '智能应答', value: '智能应答' },
// { label: '兜底应答', value: '兜底应答' },
];
exports.classifyTabList = classifyTabList;
var listTypeList = [{
  label: '个人自定义',
  value: 2
}, {
  label: '品牌推荐',
  value: 1
}];
exports.listTypeList = listTypeList;
var statusTypeList = [{
  label: '开启',
  value: 1
}, {
  label: '关闭',
  value: 0
}];
exports.statusTypeList = statusTypeList;
var productResponsePrincipalConfigPayload = {
  columns: [{
    title: '优先级',
    dataIndex: 'priority',
    scopedSlots: {
      customRender: 'autoIndex'
    },
    width: 80
  }, {
    title: '功能启/停',
    dataIndex: 'status',
    width: 100,
    scopedSlots: {
      customRender: 'status'
    }
  }, {
    title: '产品',
    dataIndex: 'productName'
  }, {
    title: '关键词应答',
    dataIndex: 'keyWordCount',
    scopedSlots: {
      customRender: 'keyWordCount'
    }
  }, {
    title: 'NLP应答',
    dataIndex: 'nlpCount',
    scopedSlots: {
      customRender: 'nlpCount'
    }
  }, {
    title: '更新人',
    dataIndex: 'modifier',
    width: 100
  }, {
    title: '最近更新时间',
    dataIndex: 'mtime',
    width: 180
  }, {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    }
  }],
  http_api: _pplk_response_manage.default.getProductReplyList
};
exports.productResponsePrincipalConfigPayload = productResponsePrincipalConfigPayload;
var productResponseAuthorConfigPayload = {
  columns: [{
    title: '优先级',
    dataIndex: 'priority',
    scopedSlots: {
      customRender: 'autoIndex'
    },
    width: 80
  }, {
    title: '主播启/停',
    dataIndex: 'status',
    width: 100,
    scopedSlots: {
      customRender: 'status'
    }
  }, {
    title: '产品',
    dataIndex: 'productName'
  }, {
    title: '关键词应答',
    dataIndex: 'keyWordCount',
    scopedSlots: {
      customRender: 'keyWordCount'
    }
  }, {
    title: 'NLP应答',
    dataIndex: 'nlpCount',
    scopedSlots: {
      customRender: 'nlpCount'
    }
  }, {
    title: '更新人',
    dataIndex: 'modifier',
    width: 100
  }, {
    title: '最近更新时间',
    dataIndex: 'mtime',
    width: 180
  }, {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    }
  }],
  http_api: _pplk_response_manage.default.getProductReplyList
};
exports.productResponseAuthorConfigPayload = productResponseAuthorConfigPayload;
var strategyConfigPayload = {
  columns: [{
    title: '优先级',
    dataIndex: 'priority',
    width: 80,
    scopedSlots: {
      customRender: 'autoIndex'
    }
  }, {
    title: '说明',
    customRender: function customRender() {
      return '用户进入主播私信会话页';
    }
  }, {
    title: '回复内容',
    dataIndex: 'reply_msg_vo_list',
    scopedSlots: {
      customRender: 'reply_msg'
    }
  }, {
    title: '近7天回复私信数',
    dataIndex: 'chat_reply_num',
    width: 160,
    customRender: function customRender(text) {
      return text || '-';
    }
  }, {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    scopedSlots: {
      customRender: 'status'
    }
  }, {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    },
    width: 250
  }],
  http_api: _pplk_response_manage.default.getProductStrategyGroupList
};
exports.strategyConfigPayload = strategyConfigPayload;
var settingAuthorColumns = [{
  title: '主播昵称',
  dataIndex: 'authorName'
}, {
  title: '操作',
  dataIndex: 'action',
  fixed: 'right',
  scopedSlots: {
    customRender: 'action'
  }
}];
exports.settingAuthorColumns = settingAuthorColumns;
var conditionList = [{
  label: '同时满足',
  value: 1
}, {
  label: '满足其一',
  value: 2
}];
exports.conditionList = conditionList;
var conditionMap = conditionList.reduce(function (acc, cur) {
  acc[cur.value] = cur.label;
  return acc;
}, {});
exports.conditionMap = conditionMap;
var keywordResponseColumns = [{
  title: '优先级',
  dataIndex: 'priority',
  scopedSlots: {
    customRender: 'autoIndex'
  },
  width: 80
}, {
  title: '关键词组',
  width: 140,
  dataIndex: 'key_word_group'
}, {
  title: '关键词',
  dataIndex: 'keyWords',
  scopedSlots: {
    customRender: 'keyWords'
  }
}, {
  title: '回复内容',
  dataIndex: 'reply_msg_vo_list',
  scopedSlots: {
    customRender: 'reply_msg'
  }
}, {
  title: '近7天回复私信数',
  dataIndex: 'chat_reply_num',
  width: 160,
  customRender: function customRender(text) {
    return text || '-';
  }
}, {
  title: '状态',
  dataIndex: 'status',
  width: 100,
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  width: 250,
  scopedSlots: {
    customRender: 'action'
  }
}];
var authorKeywordResponseConfigPayload = {
  columns: keywordResponseColumns,
  http_api: _pplk_response_manage.default.getKeywordResponseList
};
exports.authorKeywordResponseConfigPayload = authorKeywordResponseConfigPayload;
var principalKeywordResponseConfigPayload = {
  columns: keywordResponseColumns.filter(function (item) {
    return item.title !== '状态';
  }),
  http_api: _pplk_response_manage.default.getKeywordResponseList
};
exports.principalKeywordResponseConfigPayload = principalKeywordResponseConfigPayload;
var strategyColumns = [{
  title: '优先级',
  width: 80,
  scopedSlots: {
    customRender: 'autoIndex'
  }
}, {
  title: '启用/停用',
  dataIndex: 'status',
  width: 100,
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '策略名称',
  dataIndex: 'strategy_name'
}, {
  title: '话术数量',
  dataIndex: 'reply_msg_count'
}, {
  title: '更新人',
  dataIndex: 'modifier',
  width: 100
}, {
  title: '最近更新时间',
  dataIndex: 'mtime',
  width: 180
}, {
  title: '操作',
  dataIndex: 'action',
  fixed: 'right',
  scopedSlots: {
    customRender: 'action'
  }
}];
var smartResponseConfigPayload = {
  columns: strategyColumns,
  http_api: _pplk_response_manage.default.getSmartResponseList
};
exports.smartResponseConfigPayload = smartResponseConfigPayload;
var undertakeResponseConfigPayload = {
  columns: strategyColumns.filter(function (item) {
    return item.title !== '优先级';
  }),
  http_api: _pplk_response_manage.default.getUndertakeResponseList
};
exports.undertakeResponseConfigPayload = undertakeResponseConfigPayload;
var commonConfigPayload = [];
exports.commonConfigPayload = commonConfigPayload;