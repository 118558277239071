"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var openHost = process.env.VUE_APP_OPENDATA_API;
var url = "".concat(openHost, "/mirror-data");
var openNr = new _NetworldRequest.default({
  baseURL: url
}).getAxios();

/** 
 * 开播日历
*/
var liveCalender = '/douyin/live/show/calendar';
var _default = {
  // 主播基本信息
  getAuthourMessage: function getAuthourMessage(param) {
    return openNr.get("/douyin/author/".concat(param));
  },
  // 直播记录数据统计
  getLiveRecord: function getLiveRecord(param) {
    return openNr.get("/douyin/live/record/".concat(param));
  },
  getLiveCalender: function getLiveCalender(param) {
    var begin_time = param.begin_time,
      end_time = param.end_time,
      author_id = param.author_id;
    return openNr.get("".concat(liveCalender, "/").concat(author_id), {
      params: {
        begin_time: begin_time,
        end_time: end_time
      }
    });
  }
};
exports.default = _default;