"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//

var schema = [{
  name: "date",
  index: 0,
  text: "日"
}, {
  name: "like",
  index: 1,
  text: "点赞数"
}, {
  name: "play",
  index: 2,
  text: "播放量"
}, {
  name: "comment",
  index: 3,
  text: "评论数"
}];
var itemStyle = {
  opacity: 0.8,
  shadowBlur: 10,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowColor: "rgba(0, 0, 0, 0.5)"
};
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bubble-chart"
    },
    id: {
      type: String,
      default: "bubble-chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "600px"
    },
    bubbleChat: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    // this.initChart();
  },
  watch: {
    bubbleChat: function bubbleChat() {
      this.initChart();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        backgroundColor: "#404a59",
        color: ["#dd4444", "#fec42c", "#80F1BE"],
        legend: {
          top: 10,
          textStyle: {
            color: "#fff",
            fontSize: 16
          }
        },
        grid: {
          left: "10%",
          right: 150,
          top: "18%",
          bottom: "10%"
        },
        tooltip: {
          padding: 10,
          backgroundColor: "#222",
          borderColor: "#777",
          borderWidth: 1,
          formatter: function formatter(obj) {
            var value = obj.value;
            return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">' + obj.seriesName + " " + value[0] + "日：" + value[4] + "</div>" + schema[1].text + "：" + value[1] + "<br>" + schema[2].text + "：" + value[2] + "<br>" + schema[3].text + "：" + value[3];
          }
        },
        xAxis: {
          type: "category",
          name: "日期",
          data: this.bubbleChat.date,
          // nameGap: 16,
          nameTextStyle: {
            color: "#fff",
            fontSize: 14
          },
          // max: 31,
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          }
        },
        yAxis: {
          type: "value",
          // name: "AQI指数",
          nameLocation: "end",
          nameGap: 20,
          nameTextStyle: {
            color: "#fff",
            fontSize: 16
          },
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          splitLine: {
            show: false
          }
        },
        visualMap: [{
          left: "right",
          top: "10%",
          dimension: 2,
          min: 5000,
          max: 100000,
          itemWidth: 30,
          itemHeight: 120,
          calculable: true,
          precision: 0.1,
          text: ["圆半径：播放数"],
          textGap: 30,
          textStyle: {
            color: "#fff"
          },
          inRange: {
            symbolSize: [10, 70]
          },
          outOfRange: {
            symbolSize: [10, 70],
            color: ["rgba(255,255,255,.2)"]
          },
          controller: {
            inRange: {
              color: ["#c23531"]
            },
            outOfRange: {
              color: ["#444"]
            }
          }
        }, {
          left: "right",
          bottom: "5%",
          dimension: 3,
          min: 1000,
          max: 10000,
          itemHeight: 120,
          precision: 0.1,
          text: ["明暗：评论数"],
          textGap: 30,
          textStyle: {
            color: "#fff"
          },
          inRange: {
            colorLightness: [1, 0.5]
          },
          outOfRange: {
            color: ["rgba(255,255,255,.2)"]
          },
          controller: {
            inRange: {
              color: ["#c23531"]
            },
            outOfRange: {
              color: ["#444"]
            }
          }
        }],
        series: _lodash.default.get(this.bubbleChat, "list", []).map(function (val) {
          val.type = "scatter";
          val.itemStyle = itemStyle;
          return val;
        }) /*[
           {
            name: "北京",
            type: "scatter",
            itemStyle: itemStyle,
            data: dataBJ
           },
           {
            name: "上海",
            type: "scatter",
            itemStyle: itemStyle,
            data: dataSH
           },
           {
            name: "广州",
            type: "scatter",
            itemStyle: itemStyle,
            data: dataGZ
           }
           ]*/
      });
    }
  }
};
exports.default = _default;