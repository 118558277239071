"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var vuex = _interopRequireWildcard(require("vuex"));
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("short_comment"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  name: "short_comment",
  components: {},
  data: function data() {
    return {
      userId: (0, _auth.getUserId)("userId"),
      currentLeftList: 0,
      replyValue: "",
      activeName: "first",
      currentClickItem: null // 当前选中左侧评论信息
    };
  },

  watch: {
    pollingLeftData: function pollingLeftData() {
      // 首次加载时获取详情信息--默认查第一个
      if (this.pollingLeftData.list && this.pollingLeftData.page_num == 1 && this.pollingLeftData.list.length > 0) {
        // 存储当前评论点击信息
        this.currentClickItem = this.pollingLeftData.list[0];
        // 获取右侧信息
        this.getVideoAndComment(this.pollingLeftData.list[0], true);
      }
    },
    replyMessageResult: function replyMessageResult(val) {
      if (val.data && val.errcode == 0) {
        this.replyValue = '';
        this.loadReplyCommentData(true);
        this.loadOtherCommentData(true);
      }
    }
  },
  computed: _objectSpread({}, mapState({
    // 本周新增
    newWeekData: function newWeekData(state) {
      return state.newWeekData;
    },
    // 左侧价值评论
    currentNoLeft: function currentNoLeft(state) {
      return state.currentNoLeft;
    },
    // 当前页数
    loadingLeft: function loadingLeft(state) {
      return state.loadingLeft;
    },
    // 加载
    noMoreLeft: function noMoreLeft(state) {
      return state.noMoreLeft;
    },
    // 没有更多
    pollingLeftData: function pollingLeftData(state) {
      return state.pollingLeftData;
    },
    // 列表数据
    disabledLeft: function disabledLeft() {
      return this.loadingLeft || this.noMoreLeft;
    },
    videoInfoData: function videoInfoData(state) {
      return state.videoInfoData;
    },
    // 视频信息
    pageSize: function pageSize(state) {
      return state.pageSize;
    },
    // 每页条数

    // 右侧回复列表
    currentNoReply: function currentNoReply(state) {
      return state.currentNoReply;
    },
    // 当前页数
    loadingReply: function loadingReply(state) {
      return state.loadingReply;
    },
    // 加载
    noMoreReply: function noMoreReply(state) {
      return state.noMoreReply;
    },
    // 没有更多
    replyCommentData: function replyCommentData(state) {
      return state.replyCommentData;
    },
    // 列表数据
    disabledReply: function disabledReply() {
      return this.loadingReply || this.noMoreReply;
    },
    // 右侧此用户其他评论列表
    currentNoOther: function currentNoOther(state) {
      return state.currentNoOther;
    },
    // 当前页数
    loadingOther: function loadingOther(state) {
      return state.loadingOther;
    },
    // 加载
    noMoreOther: function noMoreOther(state) {
      return state.noMoreOther;
    },
    // 没有更多
    otherCommentData: function otherCommentData(state) {
      return state.otherCommentData;
    },
    // 列表数据
    disabledOther: function disabledOther() {
      return this.loadingOther || this.noMoreOther;
    },
    replyMessageResult: function replyMessageResult(state) {
      return state.replyMessageResult;
    }
  })),
  mounted: function mounted() {},
  created: function created() {
    this.loadNewByWeek();
    this.loadInteractionLeft(true);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["loadPollingLeft", "getVideoInfo", "loadReplyComment", "loadOtherComment", "getNewByWeek", "replyMessage"])), mapMutations(["changeState"])), {}, {
    loadNewByWeek: function loadNewByWeek() {
      this.getNewByWeek({
        author_id: this.$route.query.author_id
      });
    },
    loadInteractionLeft: function loadInteractionLeft(isFirst) {
      if (isFirst) {
        this.changeState({
          data: 1,
          name: "currentNoLeft"
        });
      }
      this.loadPollingLeft({
        user_id: this.userId,
        author_id: this.$route.query.author_id,
        current: isFirst ? 1 : this.currentNoLeft,
        page_size: this.pageSize
      });
    },
    loadReplyCommentData: function loadReplyCommentData(isFirst) {
      if (isFirst) {
        this.changeState({
          data: 1,
          name: "currentNoReply"
        });
      }
      console.log(this.currentClickItem);
      console.log(encodeURIComponent(this.currentClickItem.item_id));
      this.loadReplyComment({
        item_id: encodeURIComponent(this.currentClickItem.item_id),
        parent_comment_id: encodeURIComponent(this.currentClickItem.comment_id),
        author_id: this.$route.query.author_id,
        current: isFirst ? 1 : this.currentNoReply,
        page_size: this.pageSize
      });
    },
    loadOtherCommentData: function loadOtherCommentData(isFirst) {
      if (isFirst) {
        this.changeState({
          data: 1,
          name: "currentNoOther"
        });
      }
      this.loadOtherComment({
        item_id: encodeURIComponent(this.currentClickItem.item_id),
        comment_user_id: this.currentClickItem.comment_user_id,
        comment_id: encodeURIComponent(this.currentClickItem.comment_id),
        current: isFirst ? 1 : this.currentNoOther,
        page_size: this.pageSize
      });
    },
    // 左侧评论点击
    commentClick: function commentClick(item, index) {
      this.currentLeftList = index;
      // 存储当前评论点击信息
      this.currentClickItem = item;
      console.log(item);
      this.getVideoAndComment(item, true);
    },
    // 右侧所有信息更新
    getVideoAndComment: function getVideoAndComment(item, isFirst) {
      // 获取视频详情
      this.getVideoInfo({
        item_id: encodeURIComponent(item.item_id),
        time: this.currentClickItem.create_time,
        author_id: this.$route.query.author_id
      });
      this.loadReplyCommentData(isFirst);
      this.loadOtherCommentData(isFirst);
    },
    replyBtnClick: function replyBtnClick() {
      if (!this.currentClickItem.comment_id) {
        console.log("请选择一条评论信息回复");
        this.$message.warning("请选择一条评论信息回复");
        return false;
      }
      if (this.replyValue.length == 0) {
        this.$message.warning("请输入内容");
        return false;
      }
      this.replyMessage({
        author_id: this.$route.query.author_id,
        content: this.replyValue,
        comment_id: this.currentClickItem.comment_id,
        item_id: this.currentClickItem.item_id,
        open_id: this.currentClickItem.open_id
      });
    },
    routerTo: function routerTo(data) {
      this.$router.push({
        name: "short_management",
        params: data,
        query: {
          author_id: this.$route.query.author_id
        }
      });
    }
  })
};
exports.default = _default;