var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.activeStyle ? "activeStyle" : "",
      attrs: { id: "progress_box" },
    },
    [
      _c(
        "div",
        {
          staticClass: "progress_box",
          style: {
            height: _vm.strokeWidth + "px",
            "line-height": _vm.strokeWidth + "px",
            "font-size": _vm.strokeWidth - 1 + "px",
            background: _vm.boxBgd,
            "border-radius": _vm.strokeWidth - _vm.strokeWidth / 2 + "px",
          },
        },
        [
          _c("div", {
            staticClass: "process_rate",
            style: {
              "border-radius": _vm.strokeWidth - _vm.strokeWidth / 2 + "px",
              background: _vm.rateBgd,
              width: _vm.percentage + "%",
            },
          }),
          _vm.showText && !_vm.showOutside
            ? _c("div", { staticClass: "rate_tips" }, [
                _vm._v(_vm._s(_vm.text)),
              ])
            : _vm._e(),
        ]
      ),
      _vm.showText && _vm.showOutside
        ? _c(
            "div",
            {
              staticClass: "outside_tips",
              style: {
                "padding-left": "5px",
                "line-height": _vm.strokeWidth + "px",
              },
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }