"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initLeaveFunnelAnalyzeOptions = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var chatOpenMouthTypeMap = {
  enterConversationSum: '进入私信会话人数',
  chatOpenMouthSum: '私信会话开口人数',
  chatLeaveSum: '私信留资数',
  ppLeaveSum: '皮皮拉客留资数'
};
var initLeaveFunnelAnalyzeOptions = function initLeaveFunnelAnalyzeOptions(list) {
  var newList = list.map(function (item) {
    return "".concat(item.count).includes('转化率') ? _objectSpread(_objectSpread({}, item), {}, {
      labelLine: {
        show: false
      },
      itemStyle: {
        emphasis: {
          color: '#E9E9E9'
        }
      }
    }) : item;
  });
  var option = {
    color: ['#6E9FF4', '#E9E9E9', '#F75C5C', '#E9E9E9', '#FFCC55'],
    grid: {
      x: 50,
      y: 60,
      x2: 50,
      y2: 35
    },
    series: [{
      type: 'funnel',
      left: '0',
      width: '80%',
      minSize: 100,
      maxSize: '80%',
      gap: 0,
      label: {
        show: true,
        position: 'right',
        normal: {
          lineHeight: '16',
          formatter: function formatter(param) {
            return "".concat(param.data.count).includes('转化率') ? '' : chatOpenMouthTypeMap[param.name];
          },
          rich: {}
        }
      },
      labelLine: {
        length: 20,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        emphasis: {
          disabled: true
        }
      },
      data: newList
    }, {
      type: 'funnel',
      left: '0',
      width: '80%',
      minSize: 100,
      maxSize: '80%',
      gap: 0,
      label: {
        show: true,
        position: 'inside',
        formatter: function formatter(param) {
          return "".concat(param.data.count).includes('转化率') ? "{rate|".concat(param.data.count, "}") : param.data.count;
        },
        rich: {
          rate: {
            color: '#777'
          }
        }
      },
      labelLine: {
        length: 20,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        emphasis: {
          disabled: true
        }
      },
      data: newList
    }]
  };
  return option;
};
exports.initLeaveFunnelAnalyzeOptions = initLeaveFunnelAnalyzeOptions;