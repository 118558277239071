var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "配置目标城市",
            visible: _vm.visible_modal,
            "confirm-loading": _vm.confirm_loading,
            maskClosable: false,
            destroyOnClose: "",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "div",
            [
              _c("a-switch", {
                attrs: { checked: _vm.checked_status },
                on: { change: _vm.onChangeSwitch },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pd-12" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.checked_status
                    ? "配置开启状态：表示仅监测目标城市的新关注用户"
                    : "配置关闭状态：表示监测所有城市的新关注用户"
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "pdb-12" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.checked_status
                    ? "你也可以关闭功能，监测所有城市的"
                    : "你也可以开启功能，只监测目标城市的"
                ) +
                " "
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checked_status,
                  expression: "checked_status",
                },
              ],
              staticClass: "city_box",
            },
            [
              _c(
                "p",
                [
                  _c("span", {}, [_vm._v("请选择省（最多可选一个）：")]),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: {
                        "show-search": "",
                        "default-active-first-option": false,
                        "filter-option": _vm.filterOption,
                        placeholder: "请选择省份",
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.capital,
                        callback: function ($$v) {
                          _vm.capital = $$v
                        },
                        expression: "capital",
                      },
                    },
                    _vm._l(_vm.provinceList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.region_code,
                          attrs: { value: item.region_code },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("span", [_vm._v("请选择市（最多可选三个）：")]),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: {
                        "show-search": "",
                        "default-active-first-option": false,
                        "filter-option": _vm.filterOption,
                        placeholder: "请选择城市",
                        "allow-clear": "",
                        mode: "multiple",
                      },
                      on: { change: _vm.countTag },
                      model: {
                        value: _vm.city_code,
                        callback: function ($$v) {
                          _vm.city_code = $$v
                        },
                        expression: "city_code",
                      },
                    },
                    _vm._l(_vm.buyCarList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.city_code,
                          attrs: { value: item.city_code },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("p", [
                _vm._v("最多支持选择1个省份 加 3个城市，可只选择省/市"),
              ]),
              _c(
                "a-checkbox",
                { attrs: { checked: _vm.checked_filter, disabled: "" } },
                [_vm._v(" 同时过滤历史已监测到的非目标城市用户 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }