"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}
var _default = {
  // 获取用户信息
  getUserMessage: function getUserMessage(id) {
    return request({
      baseURL: process.env.VUE_APP_AI_VIDEO_APP_API,
      url: '/login/getLoginInfo',
      method: 'get',
      params: {
        user_id: id
      }
    });
  },
  //埋点
  setTracking: function setTracking(params) {
    return request({
      baseURL: '/tracking',
      url: '/v1/point-all/add-rocketmq',
      method: 'post',
      data: params
    });
  },
  // 获取主播信息
  getAuthorInfo: function getAuthorInfo() {
    return request({
      url: "/main/author/get/user/authorId",
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      method: 'GET'
    });
  }
};
exports.default = _default;