"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var _default = {
  // 获取关键词回复规则列表
  getKeywordReplyRulesList: function getKeywordReplyRulesList() {
    return request({
      url: "/video/key-word/list",
      method: "get"
    });
  },
  // 新增关键词回复
  createKeyWord: function createKeyWord(data) {
    return request({
      url: "/video/key-word/create",
      method: "post",
      data: data
    });
  },
  // 编辑关键词回复
  updateKeyWord: function updateKeyWord(data) {
    return request({
      url: "/video/key-word/update/detail",
      method: "post",
      data: data
    });
  },
  // 删除关键词回复
  deleteKeyWordReplyByRuleId: function deleteKeyWordReplyByRuleId(ruleId) {
    return request({
      url: "/video/key-word/delete/".concat(ruleId),
      method: "get"
    });
  },
  // 获取关键词回复功能状态
  getKeyWordReplyStatus: function getKeyWordReplyStatus() {
    return request({
      url: "/video/key-word/get/function/status",
      method: "get"
    });
  },
  // 更新关键词回复功能状态
  updateKeyWordReplyStatus: function updateKeyWordReplyStatus() {
    return request({
      url: "/video/key-word/update/function/status",
      method: "get"
    });
  },
  // 调整优先级
  sortKeyWordReplyRule: function sortKeyWordReplyRule(data) {
    return request({
      url: "/video/key-word/strategySort",
      method: "post",
      data: data
    });
  },
  // 关键词回复规则-启用/停用规则
  switchKeyWordReplyRule: function switchKeyWordReplyRule(ruleId) {
    return request({
      url: "/video/key-word/update/status/".concat(ruleId),
      method: "get"
    });
  }
};
exports.default = _default;