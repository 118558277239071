import { render, staticRenderFns } from "./BasePagination.vue?vue&type=template&id=5fcd0c0a&scoped=true&"
import script from "./BasePagination.vue?vue&type=script&lang=js&"
export * from "./BasePagination.vue?vue&type=script&lang=js&"
import style0 from "./BasePagination.vue?vue&type=style&index=0&id=5fcd0c0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fcd0c0a",
  null
  
)

/* custom blocks */
import block0 from "./BasePagination.vue?vue&type=custom&index=0&blockType=base-pagination&%3AcurrentPage=currentPage&%3ApageSize=pageSize&%3Atotal=total&%40onChange=onChange&%40onShowSizeChange=onChange"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fcd0c0a')) {
      api.createRecord('5fcd0c0a', component.options)
    } else {
      api.reload('5fcd0c0a', component.options)
    }
    module.hot.accept("./BasePagination.vue?vue&type=template&id=5fcd0c0a&scoped=true&", function () {
      api.rerender('5fcd0c0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BasePagination.vue"
export default component.exports