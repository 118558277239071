"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _icons = _interopRequireDefault(require("@/icons"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// console.log(icons)
function execCopy(text) {
  var input = document.createElement('INPUT');
  input.style.opacity = 0;
  input.style.position = 'absolute';
  input.style.left = '-100000px';
  document.body.appendChild(input);
  input.value = text;
  input.select();
  input.setSelectionRange(0, text.length);
  document.execCommand('copy');
  document.body.removeChild(input);
  return true;
}
var _default = {
  name: "iconsPage",
  data: function data() {
    return {
      icons: _icons.default
    };
  },
  created: function created() {
    console.log(this.$router.options);
  },
  computed: {},
  methods: {
    translateIconClass: function translateIconClass(item) {
      var iconId = item.default.id;
      return iconId.replace(/^icon\-/, "");
    },
    handleClick: function handleClick(item) {
      var iconId = item.default.id;
      var iconClass = iconId.replace(/^icon\-/, "");
      iconClass = "<svg-icon icon-class=\"".concat(iconClass, "\"></svg-icon>");
      execCopy(iconClass);
      this.$message.success("\"".concat(iconClass, "\"\u590D\u5236\u6210\u529F"));
    }
  }
};
exports.default = _default;