var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.limitFileLength > 1 ? "upload-wrap" : "upload-wrap set-width",
    },
    [
      _c(
        "div",
        { class: _vm.is_default_upload ? "upload-box default" : "upload-box" },
        [
          !_vm.canDrag
            ? _c(
                "a-upload",
                {
                  attrs: {
                    "custom-request": _vm.customRequest,
                    "list-type": _vm.listType,
                    accept: _vm.acceptList,
                    "file-list": _vm.file_list,
                    "show-upload-list": {
                      showRemoveIcon: _vm.isShowRemoveIcon,
                    },
                    "before-upload": _vm.beforeUpload,
                    remove: _vm.handleRemove,
                  },
                  on: { preview: _vm.handlePreview, change: _vm.handleChange },
                },
                [
                  _vm.file_list && _vm.file_list.length < _vm.limitFileLength
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.canDrag
            ? _c(
                "a-upload-dragger",
                {
                  class: {
                    hidden: !(
                      _vm.file_list &&
                      _vm.file_list.length < _vm.limitFileLength
                    ),
                  },
                  attrs: {
                    "custom-request": _vm.customRequest,
                    "list-type": _vm.listType,
                    accept: _vm.acceptList,
                    "file-list": _vm.file_list,
                    "show-upload-list": {
                      showRemoveIcon: _vm.isShowRemoveIcon,
                    },
                    "before-upload": _vm.beforeUpload,
                    remove: _vm.handleRemove,
                  },
                  on: { preview: _vm.handlePreview, change: _vm.handleChange },
                },
                [
                  _vm.file_list && _vm.file_list.length < _vm.limitFileLength
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("点击或拖拽到此上传"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.is_progress && _vm.limitFileLength < 2
            ? _c("div", { staticClass: "progress" }, [
                _vm._v(_vm._s(_vm.progress) + "%"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("span", { staticClass: "upload-title" }, [
            _vm._v(_vm._s(_vm.uploadTitle)),
          ]),
          _vm.isVod
            ? _c("a-switch", {
                attrs: { size: "small" },
                model: {
                  value: _vm.is_vod,
                  callback: function ($$v) {
                    _vm.is_vod = $$v
                  },
                  expression: "is_vod",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }