"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/login',
  name: 'login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/icons',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/icons'));
    });
  },
  hidden: true
}, {
  path: '/test_video_player',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/test_video_player'));
    });
  },
  hidden: true
},
// ================= maintain router =================
// 首页
{
  path: '/',
  component: _layout.default,
  redirect: '/private_message_analysis',
  children: [{
    path: '/auth_tips',
    name: 'auth_tips',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth_tips/auth_tips.vue'));
      });
    },
    hidden: true,
    meta: {
      title: '授权提示'
    }
  }]
},
// 私信分析页面
{
  path: '/private_message_analysis',
  component: _layout.default,
  redirect: '/private_message_analysis/index',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_message_analysis/index'));
      });
    },
    meta: {
      title: '私信分析',
      icon: 'dashboard'
    }
  }]
},
// 欢迎页 - 没权限
// {
//   path: '/',
//   component: Layout,
//   redirect: '/welcome',
//   children: [{
//     path: '/welcome',
//     component: () => import('@/views/welcome/index'),
//     meta: {
//       title: '首页',
//       icon: 'dashboard'
//     }
//   }, ]
// },

// {
//   path: '/ranking',
//   component: Layout,
//   children: [{
//     path: '',
//     name: 'ranking',
//     component: () => import('@/views/ranking/index'),
//     meta: {
//       title: '车系排行',
//       icon: 'dashboard'
//     },

//   }]
// },

// 监测
{
  path: '/monitoring',
  component: _layout.default,
  redirect: '/monitoring/live',
  children: [
  // 直播监控
  {
    path: '/monitoring/live',
    name: 'monitoring_live',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitoring_live/index'));
      });
    },
    meta: {
      title: '直播监控'
      // icon: 'monitoring'
    }
  },
  // 短视频监控
  {
    path: '/monitoring/video',
    name: 'monitoring_video',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitoring_video/index'));
      });
    },
    meta: {
      title: '短视频监控'
      // icon: 'monitoring'
    }
  }]
},
// 智能伴播
{
  path: '/intelligence',
  component: _layout.default,
  redirect: '/intelligence/play',
  children: [
  // 智能伴播
  {
    path: '/intelligence/play',
    name: 'intelligence_play',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/intelligence_play/index'));
      });
    },
    meta: {
      title: '智能伴播'
      // icon: 'monitoring'
    }
  },
  // 智能伴播-登陆页
  {
    path: '/intelligence/login',
    name: 'intelligence_login',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/intelligence_login/index'));
      });
    },
    meta: {
      title: '智能伴播登陆'
      // icon: 'monitoring'
    }
  },
  // 智能伴播-潜客挖掘-登陆页
  {
    path: '/intelligence/excavate_login',
    name: 'intelligence_excavate_login',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/intelligence_excavate_login/index'));
      });
    },
    meta: {
      title: '智能伴播登陆'
      // icon: 'monitoring'
    }
  },
  // 智能伴播-潜客挖掘
  {
    path: '/intelligence/excavate',
    name: 'intelligence_excavate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/intelligence_excavate/index'));
      });
    },
    meta: {
      title: '潜客挖掘'
      // icon: 'monitoring'
    }
  },
  // 智能伴播-意向跟踪
  {
    path: '/intelligence/intention_tracking',
    name: 'intention_tracking',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/intention_tracking/index'));
      });
    },
    meta: {
      title: '意向跟踪'
      // icon: 'monitoring'
    }
  }]
},
// 直播记录
{
  path: '/live_record',
  component: _layout.default,
  children: [{
    path: '',
    name: 'live_record',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_record/index'));
      });
    },
    meta: {
      title: '查记录'
      // icon: 'channel'
    }
  }]
},
// 授权
{
  path: '/authorization',
  component: _layout.default,
  children: [{
    path: '/authorization/douyin',
    name: 'authorization',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/authorization/index'));
      });
    },
    meta: {
      title: '抖音授权',
      icon: 'channel'
    }
  }]
},
// 实时直播
{
  path: '/live_now',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/live_now'));
    });
  }
},
// 授权
{
  path: '/empower',
  component: _layout.default,
  children: [{
    path: '/empower',
    name: 'empower',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/empower/index'));
      });
    },
    meta: {
      title: '授权',
      icon: 'channel'
    }
  }]
},
// 授权
{
  path: '/empower_success',
  component: _layout.default,
  children: [{
    path: '/empower_success',
    name: 'empower_success',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/empower_success/index'));
      });
    },
    meta: {
      title: '授权成功',
      icon: 'channel'
    }
  }]
},
// 门店管理
// {
//   path: '/store_manage',
//   component: Layout,
//   redirect: "/store_manage",
//   children: [
//     // 门店管理
//     {
//       path: "/store_manage",
//       name: "store_manage",
//       component: () => import('@/views/store_manage/index'),
//       meta: {
//         title: '门店管理',
//         icon: 'store'
//       }
//     },
//     // 门店管理--详情
//     {
//       path: "/store_manage_detail",
//       name: "store_manage_detail",
//       component: () => import('@/views/store_manage/detail'),
//       meta: {
//         title: '门店详情',
//         icon: 'store'
//       }
//     }
//   ]
// },
// 私信管理
// {
//   path: '/private_letter',
//   component: Layout,
//   redirect: "/private_letter",
//   children: [
//     // 私信管理
//     {
//       path: "/private_letter",
//       name: "private_letter",
//       component: () => import('@/views/private_letter/index'),
//       meta: { title: '私信管理', icon: 'store' }
//     }
//   ]
// },
// 短片价值评论登录
{
  path: '/short_comment_login',
  component: _layout.default,
  redirect: '/short_comment_login',
  children: [
  // 短片价值评论
  {
    path: '/short_comment_login',
    name: 'short_comment_login',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/short_comment_login/index'));
      });
    },
    meta: {
      title: '短片价值评论登录',
      icon: 'store'
    }
  }]
},
// 回访统计登录
{
  path: '/return_visit_login',
  component: _layout.default,
  redirect: '/return_visit_login',
  children: [
  // 回访统计登录
  {
    path: '/return_visit_login',
    name: 'return_visit_login',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/return_visit_login/index'));
      });
    },
    meta: {
      title: '回访统计登录',
      icon: 'store'
    }
  }]
},
// // 短片互动管理登录
// {
//   path: '/short_management_login',
//   component: Layout,
//   redirect: "/short_management_login",
//   children: [
//     // 短片互动管理
//     {
//       path: "/short_management_login",
//       name: "short_management_login",
//       component: () => import('@/views/short_management_login/index'),
//       meta: {
//         title: '短片互动管理登录',
//         icon: 'store'
//       }
//     }
//   ]
// },
// 留资记录表登录
{
  path: '/retention_records_login',
  component: _layout.default,
  redirect: '/retention_records_login',
  children: [
  // 留资记录表
  {
    path: '/retention_records_login',
    name: 'retention_records_login',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/retention_records_login/index'));
      });
    },
    meta: {
      title: '留资记录表登录',
      icon: 'store'
    }
  }]
},
// 留资记录表
{
  path: '/retention_records',
  component: _layout.default,
  redirect: '/retention_records',
  children: [
  // 留资记录表
  {
    path: '/retention_records',
    name: 'retention_records',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/retention_records/index'));
      });
    },
    meta: {
      title: '留资记录表',
      icon: 'store'
    }
  }]
},
// 短片互动管理
// {
//   path: '/short_management',
//   component: Layout,
//   redirect: "/short_management",
//   children: [
//     // 短片互动管理
//     {
//       path: "/short_management",
//       name: "short_management",
//       component: () => import('@/views/short_management/index'),
//       meta: { title: '短片互动管理', icon: 'store' }
//     }
//   ]
// },
// 短片价值评论
{
  path: '/short_comment',
  component: _layout.default,
  redirect: '/short_comment',
  children: [
  // 短片价值评论
  {
    path: '/short_comment',
    name: 'short_comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/short_comment/index'));
      });
    },
    meta: {
      title: '短片价值评论',
      icon: 'store'
    }
  }]
},
// 回访统计
{
  path: '/return_visit',
  component: _layout.default,
  redirect: '/return_visit',
  children: [
  // 回访统计
  {
    path: '/return_visit',
    name: 'return_visit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/return_visit/index'));
      });
    },
    meta: {
      title: '回访统计',
      icon: 'store'
    }
  }]
},
// 我的账号-经销商
{
  path: '/account',
  component: _layout.default,
  redirect: '/account',
  children: [
  // 我的账号
  {
    path: '/account',
    name: 'account',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/index'));
      });
    },
    meta: {
      title: '我的账号(经销商)',
      icon: 'account'
    }
  }]
},
// 我的账号-主机厂
{
  path: '/account_z',
  component: _layout.default,
  redirect: '/account_z',
  children: [
  // 我的账号
  {
    path: '/account_z',
    name: 'account_z',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account_z/index'));
      });
    },
    meta: {
      title: '我的账号(主机厂)',
      icon: 'account'
    }
  }]
},
// {
//   path: '/2',
//   component: Layout,
//   redirect: '/hot_news',
//   children: [{
//     path: 'hot_topic',
//     name: 'hot_topic',
//     component: () => import('@/views/hot_news/topic/index'),
//     meta: { title: '热点话题', icon: 'dashboard' }

//   },
//   {
//     path: 'hot_matter',
//     name: 'hot_matter',
//     component: () => import('@/views/hot_news/matter/index'),
//     meta: { title: '热点素材', icon: 'dashboard' }
//   }
// ]
// },
// 品牌
// {
//   path: '/brand',
//   component: Layout,
//   redirect: '/brand/list',
//   name: 'minute_dashboard',
//   meta: {
//     title: '品牌',
//     icon: 'brand'
//   },
//   children: [
//     // 品牌直播榜单
//     {
//       path: '/brand/live/list',
//       component: () => import('@/views/minute_live/index'),
//       name: 'minute_live_index',
//       meta: {
//         title: '品牌直播榜单',
//         icon: 'brand'
//       },
//     },
//     // 品牌榜单
//     {
//       path: '/brand/list',
//       component: () => import('@/views/minute_dashboard/index'),
//       name: 'minute_dashboard_index',
//       meta: {
//         title: '品牌榜单',
//         icon: 'brand'
//       },
//     },
//     // {
//     //   path: '/brand/analysis',
//     //   component: () => import('@/views/analysis/index'),
//     //   name: 'analysis',
//     //   // hidden: true
//     //   meta: { title: '4S店分析', icon: 'eye-open' },
//     // },

//     // 4S店分析
//     {
//       path: '/brand/matrix',
//       component: () => import('@/views/brand_analysis/index'),
//       meta: {
//         title: '4S店分析',
//         icon: 'brand'
//       },
//     },
//   ]
// },
{
  path: '/store',
  component: _layout.default,
  redirect: '/store/index',
  name: 'store',
  children: [{
    path: '/store/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/store/index'));
      });
    },
    name: 'store_index',
    meta: {
      title: '门店',
      icon: 'tree'
    }
  }]
},
// 广宣
{
  path: '/propaganda',
  component: _layout.default,
  redirect: '/propaganda/marketing',
  children: [
  // 潜客雷达
  {
    path: '/propaganda/marketing',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/propaganda/index'));
      });
    }
  },
  // 广告投放
  {
    path: '/propaganda/advertisement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/propaganda/advertisement'));
      });
    }
  }]
},
// 直播记录
{
  path: '/live_stream',
  name: 'live_stream',
  component: _layout.default,
  children: [{
    path: '/live/stream/record',
    name: 'live_stream_record',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_stream_record/index'));
      });
    }
  }, {
    path: '/live/stream/analyze',
    name: 'live_stream_analyze',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_analyze/index'));
      });
    }
  }]
},
// ========================================

// 直播新关注触达
// {
//   path: '/live_new_attention',
//   component: Layout,
//   redirect: '/live_new_attention/attention_monitor',
//   meta: {
//     title: '高潜攀谈',
//     icon: 'store',
//   },
//   children: [
//     // 新关注监测
//     {
//       path: '/live_new_attention/attention_monitor',
//       name: 'attention_monitor',
//       component: () => import('@/views/live_new_attention/attention_monitor'),
//       meta: {
//         title: '互动监测',
//       },
//     },
//     {
//       path: '/live_new_attention/strategy',
//       name: 'strategy',
//       component: () => import('@/views/live_new_attention/strategy'),
//       meta: {
//         title: '触达策略',
//       },
//     },
//   ],
// },
{
  path: '/live_new_attention',
  component: _layout.default,
  redirect: '/live_new_attention/attention_monitor',
  meta: {
    icon: 'store'
  },
  children: [{
    path: '/live_new_attention/attention_monitor',
    name: 'attention_monitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_new_attention/attention_monitor'));
      });
    },
    meta: {
      title: '高潜互动'
    }
  }
  // {
  //   path: '/live_new_attention/strategy',
  //   name: 'strategy',
  //   component: () => import('@/views/live_new_attention/strategy'),
  //   meta: {
  //     title: '触达策略',
  //   },
  // },
  ]
},
// 评论管理
{
  path: '/live_new_comment',
  component: _layout.default,
  redirect: '/live_new_comment/monitor',
  meta: {
    title: '评论管理',
    icon: 'profile'
  },
  children: [
  // 评论监测
  {
    path: '/live_new_comment/monitor',
    name: 'comment_monitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_new_comment/monitor'));
      });
    },
    meta: {
      title: '评论监测'
    }
  }, {
    path: '/live_new_comment/comment_smart_reply',
    name: 'comment_smart_reply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_new_comment/comment_smart_reply_new'));
      });
    },
    meta: {
      title: '评论智回'
    }
  }, {
    path: '/short_management_login',
    name: 'short_management_login',
    // component: () => import('@/views/short_management_login/index'),
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_new_comment/short_management'));
      });
    },
    meta: {
      title: '视频评论'
    }
  }, {
    path: '/short_management',
    name: 'short_management',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/short_management/index'));
      });
    },
    meta: {
      title: '视频评论'
    }
  }]
},
// 私信管理
{
  path: '/private_letter_manage',
  component: _layout.default,
  redirect: '/private_letter_manage/conversation',
  meta: {
    title: '私信管理',
    icon: 'mail'
  },
  children: [
  // 私信管理
  {
    path: '/private_letter_manage/conversation',
    name: 'conversation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/conversation'));
      });
    },
    meta: {
      title: '私信会话'
    }
  }, {
    path: '/private_letter_manage/private_message_reception',
    name: 'private_message_reception',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/private_message_reception'));
      });
    },
    meta: {
      title: '私信接待'
    }
  }, {
    path: '/private_letter_manage/private_message_smart_reply',
    name: 'private_message_smart_reply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/private_message_smart_reply'));
      });
    },
    meta: {
      title: '私信智回'
    }
  }, {
    path: '/private_letter_manage/private_message_customer_recall',
    name: 'private_message_customer_recall',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/private_message_customer_recall'));
      });
    },
    meta: {
      title: '私信潜客召回'
    }
  },
  // {
  //   path: '/private_letter_manage/intellect_reply',
  //   name: 'intellect_reply',
  //   component: () => import('@/views/private_letter_manage/intellect_reply'),
  //   meta: {
  //     title: '私信智回',
  //   },
  // },
  {
    path: '/private_letter_manage/keyword_reply',
    name: 'keyword_reply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/keyword_reply'));
      });
    },
    meta: {
      title: '关键词回复'
    }
  }, {
    path: '/private_letter_manage/manual_time_setting',
    name: 'manual_time_setting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/manual_time_setting'));
      });
    },
    meta: {
      title: '人工回复设置'
    }
  }, {
    path: '/private_letter_manage/routine_conversation',
    name: 'routine_conversation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/private_letter_manage/routine_conversation'));
      });
    },
    meta: {
      title: '私信智回'
    }
  }]
}, {
  path: '/morning_meeting_manage',
  component: _layout.default,
  redirect: '/morning_meeting_manage/index',
  meta: {
    icon: 'meeting'
  },
  children: [{
    path: '/morning_meeting_manage/index',
    name: 'morning_meeting_manage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/morning_meeting_manage/morning_meeting_manage'));
      });
    },
    meta: {
      title: '早会管理'
    }
  }]
},
// 配置目标城市
{
  path: '/setting',
  component: _layout.default,
  redirect: '/setting/setting_city',
  meta: {
    title: '配置目标城市',
    icon: 'setting'
  },
  children: [
  // 新关注监测
  {
    path: '/setting/setting_city',
    name: 'setting_city',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/live_new_attention/setting_city'));
      });
    },
    meta: {
      title: '配置目标城市'
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
},
// 留资数据
{
  path: '/retention_data',
  component: _layout.default,
  redirect: '/retention_data',
  children: [{
    path: '/retention_data',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/retention_data/index'));
      });
    },
    meta: {
      title: '留资数据',
      icon: 'pie-chart'
    }
  }]
},
// 云端成片
{
  path: '/cloud_video',
  component: _layout.default,
  redirect: '/cloud_video',
  children: [{
    path: '/cloud_video',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/cloud_video/index'));
      });
    },
    meta: {
      title: '云端成片',
      icon: 'video-camera'
    }
  }, {
    path: '/cloud_video_download',
    name: 'cloud_video_download',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/cloud_video/download_task.vue'));
      });
    },
    meta: {
      hidden: true,
      title: '云端成片下载任务',
      icon: 'video-camera'
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;