"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var request = new _NetworldRequest.default().getAxios();
var _default = {
  getIsOpenPp: function getIsOpenPp() {
    return request({
      url: "/pcChatAnalyze/isOpenPp",
      method: 'GET'
    });
  },
  // 整体数据
  getEntiretyData: function getEntiretyData(params) {
    return request({
      url: "/pcChatAnalyze/entiretyData",
      method: 'GET',
      params: params
    });
  },
  // 私信开口情况
  getChatOpenMouth: function getChatOpenMouth(params) {
    return request({
      url: "/pcChatAnalyze/chatOpenMouth",
      method: 'GET',
      params: params
    });
  },
  // 留资分析
  getLeaveAnalyze: function getLeaveAnalyze(params) {
    return request({
      url: "/pcChatAnalyze/leaveAnalyze",
      method: 'GET',
      params: params
    });
  },
  // 留资漏斗分析
  getLeaveFunnelAnalyze: function getLeaveFunnelAnalyze(params) {
    return request({
      url: "/pcChatAnalyze/leaveFunnelAnalyze",
      method: 'GET',
      params: params
    });
  }
};
exports.default = _default;