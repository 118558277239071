"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var _default = {
  // 获取线索统计
  getClueStatistics: function getClueStatistics(params) {
    return request({
      url: "/clueFollowUp/clue/statistics",
      method: 'GET',
      params: params
    });
  },
  // 获取打电话统计列表
  getCallRecordList: function getCallRecordList(params) {
    return request({
      url: "/clueFollowUp/staffMeeting/list",
      method: 'GET',
      params: params
    });
  },
  // 获取打电话详情
  getCallRecordDetail: function getCallRecordDetail(params) {
    return request({
      url: "/clueFollowUp/call/recordInfo",
      method: 'GET',
      params: params
    });
  },
  // 获取预留手机号码
  getPhoneNumber: function getPhoneNumber(params) {
    return request({
      url: "/clueFollowUp/call/getReserveNum",
      method: 'GET',
      params: params
    });
  }
};
exports.default = _default;