"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var _default = {
  // 获取通用欢迎语列表
  getCommonList: function getCommonList(params) {
    return request({
      url: "/common/greeting/web/strategyRuleList",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用通用欢迎语策略
  switchCommonStrategy: function switchCommonStrategy(params) {
    return request({
      url: "/common/greeting/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护通用欢迎语话术
  updateCommonScriptList: function updateCommonScriptList(data) {
    return request({
      url: "/common/greeting/web/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 查询策略主播品牌启停详情
  getCommonAuthorPrincipalSetting: function getCommonAuthorPrincipalSetting() {
    return request({
      url: "/common/greeting/web/principal/setting",
      method: 'GET'
    });
  },
  // 策略主播品牌启用/停用
  switchCommonAuthorPrincipal: function switchCommonAuthorPrincipal(params) {
    return request({
      url: "/common/greeting/web/switch/author/principal",
      method: 'GET',
      params: params
    });
  }
};
exports.default = _default;