"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.match");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: function _default() {
        return {
          width: "874px",
          height: "300px"
        };
      }
    },
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    styleObject: function styleObject() {
      var obj = this.boxStyle;
      for (var attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";
        obj[attr] = unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData: function chartData() {
      this.initChart();
    }
  },
  mounted: function mounted() {
    // this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      var titleArr = [];
      var seriesArr = [];
      var colors = [["#62A3F3", "#EFF6FF"], ["#FFAE8E", "#EFF6FF"], ["#73DD95", "#EFF6FF"]];
      this.chartData.forEach(function (item, index) {
        seriesArr.push({
          name: item.name,
          type: 'pie',
          clockWise: false,
          radius: ['40%', '57%'],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][1],
              shadowBlur: 0,
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          hoverAnimation: false,
          center: [index * 33 + 15 + '%', '50%'],
          //  center: ['50%', '50%'],
          data: [{
            value: item.value,
            label: {
              normal: {
                formatter: function formatter(params) {
                  return params.seriesName + '\n' + params.value + '%';
                },
                position: 'center',
                show: true,
                textStyle: {
                  fontSize: '16',
                  color: '#17233D'
                }
              }
            }
          }, {
            value: 100 - item.value,
            name: 'invisible',
            itemStyle: {
              normal: {
                color: colors[index][1]
              },
              emphasis: {
                color: colors[index][1]
              }
            }
          }]
        });
      });
      this.chart.setOption({
        backgroundColor: "#fff",
        title: titleArr,
        series: seriesArr
      });
    }
  }
};
exports.default = _default2;