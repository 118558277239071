var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-container" },
    [
      _vm.title
        ? _c("div", { staticClass: "top-title" }, [_vm._v("热门评论")])
        : _vm._e(),
      _c(
        "el-row",
        { staticClass: "table-header f14 c-b" },
        [
          _c("el-col", { attrs: { span: 6 } }, [_vm._v("用户名称")]),
          _c("el-col", { attrs: { span: 13 } }, [_vm._v("评论内容")]),
          _c("el-col", { attrs: { span: 5 } }, [_vm._v("互动")]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "infinite-list-wrapper hot-discuss",
          staticStyle: { overflow: "auto" },
        },
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.discussLoad,
                  expression: "discussLoad",
                },
              ],
              staticClass: "list",
              attrs: {
                "infinite-scroll-distance": 30,
                "infinite-scroll-disabled": "disabled",
              },
            },
            _vm._l(_vm.cData, function (item, index) {
              return _c(
                "li",
                { key: index, staticClass: "list-item" },
                [
                  _c(
                    "el-row",
                    { staticClass: "list-item-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "list-item-row-name",
                          attrs: { span: 6 },
                        },
                        [
                          _c("img", {
                            attrs: { src: item.fans_avatar, alt: "" },
                          }),
                          _c("div", [
                            _c("span", { staticClass: "f14 c-b" }, [
                              _vm._v(_vm._s(item.fans_nickname)),
                            ]),
                            _c("span", { staticClass: "f12 c-g" }, [
                              _vm._v(_vm._s(item.publish_time)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "f14 c-b", attrs: { span: 13 } },
                        [_vm._v(_vm._s(item.content))]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "f14 c-b",
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 5 },
                        },
                        [_vm._v(_vm._s(item.digg_count))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm.discussLoading
            ? _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("加载中..."),
              ])
            : _vm._e(),
          _vm.discussNoMore
            ? _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("没有更多了"),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }