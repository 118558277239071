"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 全网潜客以及互动分布接口
  getAll: function getAll() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/all",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 评论用户区域分布
  getAreaMap: function getAreaMap() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/area-map",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 统计主机厂商下不同车系的短视频数
  getSeriesMap: function getSeriesMap() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/series-map",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 短视频互动量来源分布
  getSourceMap: function getSourceMap() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/source-map",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 统计品牌跟门店直播中提及到车系的评论数（主动营销触达）
  getCommentCount: function getCommentCount() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/series/comment-count",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 统计带有该车系标题的短视频下有关该车系的评论数
  getActive: function getActive() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/series/active-potential",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 统计带有该车系标题的短视频下有关该车系的所有评论
  getPotentials: function getPotentials() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/series/potentials",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 统计带有该车系标题的短视频下有关该车系竞品的所有评论
  getCompetitor: function getCompetitor() {
    return request({
      url: "/mirror-data/douyin/potentital-customer/series/competitor",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  }
};
exports.default = _default;