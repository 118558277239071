"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _echarts = _interopRequireDefault(require("echarts"));
var _moment = _interopRequireDefault(require("moment"));
require("echarts-wordcloud/dist/echarts-wordcloud");
require("echarts-wordcloud/dist/echarts-wordcloud.min");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      defultImg: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg',
      defultCover: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg',
      imgHref: process.env.VUE_APP_OSS_HOST,
      lineChart1: null,
      lineChart2: null,
      cloudChart: null,
      funnelChart: null,
      detailFunnel: null,
      barChart: null,
      commentShow: false,
      // 评论弹窗
      rightTop: {
        position: 'absolute',
        top: '30px',
        right: '20px'
      },
      rightBottom: {
        position: 'absolute',
        bottom: '30px',
        right: '20px'
      },
      leftBottom: {
        position: 'absolute',
        bottom: '30px',
        left: '20px'
      },
      leftTop: {
        position: 'absolute',
        top: '30px',
        left: '20px'
      }
    };
  },
  components: {},
  props: ["currentDetailData", "detailInfo", "chartData"],
  computed: {
    disabled: function disabled() {
      return this.$parent.$parent.commentLoading || this.$parent.$parent.commentNoMore;
    },
    isPositive: function isPositive() {
      return this.detailInfo.fans_count_before_live <= this.detailInfo.fans_count_after_live;
    }
  },
  watch: {
    // currentDetailData(val) {
    // this.initChartLine1();
    // this.initChartLine2();
    // this.initCloudChart();
    // this.initFunnelChart();
    // this.initBarChart();
    // }
  },
  methods: {
    getHours: function getHours(sTime, eTime) {
      var startTime = this.moment(sTime);
      var endTime = this.moment(eTime);
      var dura = endTime.format('x') - startTime.format('x');
      var tempTime = this.moment.duration(dura);
      return "".concat(tempTime.days() * 24 + tempTime.hours(), "\u5C0F\u65F6").concat(tempTime.minutes(), "\u5206\u949F").concat(tempTime.seconds(), "\u79D2");
    },
    openComment: function openComment() {
      this.commentShow = true;
      this.$parent.$parent.getComment({
        room_id: this.currentDetailData.room_id,
        current: 1,
        page_size: this.$parent.$parent.commentPageSize
      });
    },
    commentLoad: function commentLoad() {
      this.$parent.$parent.getComment({
        room_id: this.currentDetailData.room_id,
        current: this.$parent.$parent.commentPageNo,
        page_size: this.$parent.$parent.commentPageSize
      });
    },
    // line-chart1
    initChartLine1: function initChartLine1(xData, yData) {
      var _this = this;
      this.$nextTick(function () {
        _this.lineChart1 = _echarts.default.init(document.getElementById("line-chart1"));
        _this.lineChart1.setOption({
          title: {
            text: "人数峰值趋势",
            subtext: "",
            textStyle: {
              color: "#fff"
            }
          },
          backgroundColor: "#fff",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985"
              }
            }
          },
          color: ["#0080ff", "#4cd5ce"],
          grid: {
            left: "6%",
            right: "6%",
            top: "14%",
            bottom: "5%",
            containLabel: true
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLabel: {
              show: true,
              interval: 4,
              textStyle: {
                color: "#6ba1bb",
                fontSize: 12
              }
            },
            axisLine: {
              lineStyle: {
                color: "#0a2b52",
                width: 0.5 //这里是为了突出显示加上的
              }
            }
          }],

          yAxis: [{
            type: "value",
            axisLine: {
              onZero: false,
              show: false,
              lineStyle: {
                color: "#0a2b52",
                width: 1 //这里是为了突出显示加上的
              }
            },

            axisLabel: {
              show: true,
              textStyle: {
                color: "#6ba1bb" //字体颜色
              }
            },

            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(0,0,0,0.1)"
              }
            }
          }],
          series: [{
            name: "观看数",
            type: "line",
            smooth: true,
            //  symbol: "none", //去掉折线点
            stack: 100,
            itemStyle: {
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(131, 212, 95, 0.7)" // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: "rgba(131, 212, 95, 0.5)" // 100% 处的颜色
                }, {
                  offset: 1,
                  color: "rgba(131, 212, 95, 0.2)" // 100% 处的颜色
                }]),

                //背景渐变色
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 0.5,
                  type: "solid",
                  color: "rgba(131, 212, 95, 1)"
                }
              },
              emphasis: {
                color: "#02675f",
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 0.5,
                  type: "dotted",
                  color: "#02675f" //折线的颜色
                }
              }
            },

            //线条样式
            symbolSize: 5,
            //折线点的大小
            areaStyle: {
              normal: {}
            },
            data: yData
          }]
        });
        window.onresize = _this.lineChart1.resize;
      });
    },
    // line-chart2
    initChartLine2: function initChartLine2(xData, yData) {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.lineChart2 = _echarts.default.init(document.getElementById("line-chart2"));
        _this2.lineChart2.setOption({
          title: {
            text: "评论条数趋势",
            subtext: "",
            textStyle: {
              color: "#fff"
            }
          },
          backgroundColor: "#fff",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985"
              }
            }
          },
          color: ["#0080ff", "#4cd5ce"],
          grid: {
            left: "6%",
            right: "6%",
            top: "14%",
            bottom: "5%",
            containLabel: true
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLabel: {
              show: true,
              interval: 4,
              textStyle: {
                color: "#6ba1bb",
                fontSize: 12
              }
            },
            axisLine: {
              lineStyle: {
                color: "#0a2b52",
                width: 0.5 //这里是为了突出显示加上的
              }
            }
          }],

          yAxis: [{
            type: "value",
            axisLine: {
              onZero: false,
              show: false,
              lineStyle: {
                color: "#0a2b52",
                width: 1 //这里是为了突出显示加上的
              }
            },

            axisLabel: {
              show: true,
              textStyle: {
                color: "#6ba1bb" //字体颜色
              }
            },

            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(0,0,0,0.1)"
              }
            }
          }],
          series: [{
            name: "评论数",
            type: "line",
            smooth: true,
            //  symbol: "none", //去掉折线点
            stack: 100,
            itemStyle: {
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(131, 212, 95, 0.7)" // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: "rgba(131, 212, 95, 0.5)" // 100% 处的颜色
                }, {
                  offset: 1,
                  color: "rgba(131, 212, 95, 0.2)" // 100% 处的颜色
                }]),

                //背景渐变色
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 0.5,
                  type: "solid",
                  color: "rgba(131, 212, 95, 1)"
                }
              },
              emphasis: {
                color: "#02675f",
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 0.5,
                  type: "dotted",
                  color: "#02675f" //折线的颜色
                }
              }
            },

            //线条样式
            symbolSize: 5,
            //折线点的大小
            areaStyle: {
              normal: {}
            },
            data: yData
          }]
        });
        window.onresize = _this2.lineChart2.resize;
      });
    },
    // 词云
    initCloudChart: function initCloudChart(data) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.cloudChart = _echarts.default.init(document.getElementById("cloudChart"));
        _this3.cloudChart.setOption({
          tooltip: {},
          backgroundColor: "#fff",
          title: {
            text: "粉丝关注点",
            textStyle: {
              align: "center",
              color: "#fff",
              fontSize: 20
            },
            top: "0%",
            left: "center"
          },
          series: [{
            type: "wordCloud",
            gridSize: 2,
            sizeRange: [20, 60],
            rotationRange: [90, -90],
            rotationStep: 90,
            shape: "star",
            //width: 600,
            //height: 400,
            textStyle: {
              normal: {
                color: function color() {
                  var colors = ["#4A58F3", "#FCB56B", "#F67C52", "#FFEDB8", "#61A0F3", "#E34E3A", "#9D89FF", "#73DD95", "#F68586"];
                  // return 'rgb(' + [
                  //     Math.round(Math.random() * (255 - 120) + 120),
                  //     Math.round(Math.random() * (255 - 120) + 120),
                  //     Math.round(Math.random() * (255 - 100) + 100)
                  // ].join(',') + ')';
                  return colors[Math.round(Math.random() * 8)];
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333"
              }
            },
            data: data
          }]
        });
      });
    },
    // 漏斗图
    initFunnelChart: function initFunnelChart(data) {
      var _this4 = this;
      this.detailFunnel = data;
      this.$nextTick(function () {
        _this4.funnelChart = _echarts.default.init(document.getElementById("funnelChart"));
        _this4.funnelChart.setOption({
          title: {
            text: "",
            subtext: ""
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} : {c}"
          },
          legend: {
            top: "bottom",
            left: "center",
            textStyle: {
              color: "#000"
            },
            itemHeight: 10,
            data: ["所有评论", "有效评论", "有效评论人数"]
          },
          calculable: true,
          series: [{
            name: "",
            type: "funnel",
            left: "10%",
            right: "15%",
            top: 0,
            //x2: 80,
            bottom: 60,
            width: "80%",
            // height: {totalHeight} - y - y2,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            color: [{
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: "#FF9B4C" // 0% 处的颜色
              }, {
                offset: 1,
                color: "#E34E3A" // 100% 处的颜色
              }]
            }, {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: "#8BACFA" // 0% 处的颜色
              }, {
                offset: 1,
                color: "#5374F3" // 100% 处的颜色
              }]
            }, {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: "#27C77D" // 0% 处的颜色
              }, {
                offset: 1,
                color: "#27B388" // 100% 处的颜色
              }]
            }],

            label: {
              show: true,
              position: "right",
              formatter: "{c|{c}}",
              rich: {
                b: {
                  color: "#fff",
                  fontSize: 16
                }
              }
            },
            labelLine: {
              normal: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: "solid"
                }
              }
            },
            itemStyle: {
              normal: {
                borderColor: "#fff",
                borderWidth: 1
              }
            },
            data: data
          }, {
            name: "",
            type: "funnel",
            left: "10%",
            top: 0,
            //x2: 80,
            bottom: 60,
            width: "80%",
            sort: "descending",
            gap: 2,
            label: {
              show: true,
              position: "center",
              color: "#ffffff",
              fontSize: 10,
              fontWeight: 400,
              padding: [-5, 0, 0, 0],
              fontFamily: "PingFang SC Regular"
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid"
              }
            },
            data: data
          }]
        });
        window.onresize = _this4.funnelChart.resize;
      });
    },
    // 底部柱形图
    initBarChart: function initBarChart(xData, yData) {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.barChart = _echarts.default.init(document.getElementById("barChart"));
        _this5.barChart.setOption({
          color: ["#6496f9"],
          grid: {
            left: "6%",
            right: "6%",
            top: "7%",
            bottom: "2%",
            containLabel: true
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          xAxis: {
            data: xData,
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0
            }
          },
          yAxis: [{
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            type: "value",
            // max: 100,
            splitNumber: 5
          }],
          series: [{
            name: "互动量",
            type: "bar",
            barWidth: "55%",
            itemStyle: {
              normal: {
                color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: "rgba(100,169,243,0.00)"
                }, {
                  offset: 1,
                  color: "#516EF3"
                }])
              }
            },
            data: yData
          }]
        });
        window.onresize = _this5.barChart.resize;
      });
    }
  },
  mounted: function mounted() {
    window.moment = _moment.default;
    // this.initChartLine1();
    // this.initChartLine2();
    // this.initCloudChart();
    // this.initFunnelChart();
    // this.initBarChart();
  }
};
exports.default = _default;