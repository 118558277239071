"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: '提交'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    hasPadding: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      emptyFun: function emptyFun() {}
    };
  },
  methods: {
    submit: function submit() {
      this.$emit('submit');
    }
  },
  created: function created() {}
};
exports.default = _default;