"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}

// 缓存上一个请求的cancel，用于取消上一个尚未结束的请求
var cancel = null; // 一个请求对应一个cancel
var _module = "/live-crm";
var CancelToken = buildCancelToken();
var _default = {
  /**
   * 评论相关
   */
  //   全部列表
  getAllList: function getAllList(params) {
    return request({
      url: "/mkms/videoComment/list",
      method: "get",
      params: params
    });
  },
  /*
   * 价值评论，策略已响应，策略未响应列表
   * statusList:1未响应，2已响应，空为价值
   */
  getSceneList: function getSceneList(params) {
    return request({
      url: "/mkms/videoComment/scene",
      method: "get",
      params: params
    });
  },
  //   获取视频评论基础信息
  getVideoComment: function getVideoComment(id) {
    return request({
      url: "/mkms/videoComment/replyInit/".concat(id),
      method: "get"
    });
  },
  //   回复视频评论
  replyComment: function replyComment(data) {
    return request({
      url: "/mkms/videoComment/replyComment",
      method: "post",
      data: data
    });
  },
  // 来源视频列表
  getVideoSource: function getVideoSource() {
    return request({
      url: "/mkms/videoComment/video",
      method: "get"
    });
  },
  // 查询策略/规则对应话术内容
  getWordResponseBySceneType: function getWordResponseBySceneType(params) {
    return request({
      url: "/mkms/videoAutoReplyModel/wordResponseBySceneType",
      method: "get",
      params: params
    });
  },
  // 查询回复策略列表
  getReplyList: function getReplyList() {
    return request({
      url: "/mkms/videoAutoReplyModel/list",
      method: "get"
    });
  },
  // 查询视频评论智能回复策略功能开关设置
  getVideoStrategySetting: function getVideoStrategySetting() {
    return request({
      url: "/mkms/videoAutoReplyModel/videoStrategySetting",
      method: "get"
    });
  },
  // 开启/停用视频评论智能回复策略功能
  changeVideoStrategySetting: function changeVideoStrategySetting() {
    return request({
      url: "/mkms/videoAutoReplyModel/videoStrategySwitch",
      method: "get"
    });
  },
  // 调整优先级
  sortStrategy: function sortStrategy(data) {
    return request({
      url: "/mkms/videoAutoReplyModel/strategySort",
      method: "post",
      data: data
    });
  },
  //   开启/关闭回复策略
  replySwitch: function replySwitch(data) {
    return request({
      url: "/mkms/videoAutoReplyModel/switch",
      method: "post",
      data: data
    });
  },
  // 启用自动回复策略
  useAutoReply: function useAutoReply(data) {
    return request({
      url: "/mkms/videoAutoReplyModel/startAutoReply",
      method: "post",
      data: data
    });
  },
  //   查询回复策略配置
  getVideoReplySetting: function getVideoReplySetting(sceneId) {
    return request({
      url: "/mkms/videoAutoReplyModel/".concat(sceneId),
      method: "get"
    });
  },
  //   查询回复策略示例评论
  getReplyTemplate: function getReplyTemplate(params) {
    return request({
      url: "/mkms/videoAutoReplyModel/sampleComment/list",
      method: "get",
      params: params
    });
  },
  //   编辑回复策略规则
  editLiveVideoReply: function editLiveVideoReply(data) {
    return request({
      url: "/mkms/videoAutoReplyModel/videoAutoReplyRule",
      method: "post",
      data: data
    });
  },
  //   获取一键输入回复内容列表
  getQuickReplyList: function getQuickReplyList(params) {
    return request({
      url: "/mkms/videoAutoReplyModel/commentOneClickRepliesContent",
      method: "get",
      params: params
    });
  }
};
exports.default = _default;