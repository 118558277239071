var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empower" }, [
    _c("div", { staticClass: "empower-container" }, [
      _c("div", { staticClass: "first" }, [
        _c(
          "div",
          { staticClass: "f24 fw", staticStyle: { margin: "74px 0 8px" } },
          [_vm._v(_vm._s(_vm.name))]
        ),
        _c("div", { staticClass: "f14 c-g" }, [
          _vm._v("抖音 " + _vm._s(_vm.code)),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/dev/tip.png"),
            width: "639px",
            alt: "",
          },
        }),
        _c("div", { staticClass: "sure-btn", on: { click: _vm.handleAuth } }, [
          _vm._v("立即授权"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }