var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-alert", {
    attrs: {
      message: "功能优化升级中",
      description: _vm.description,
      type: "warning",
      "show-icon": "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }