var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tooltip",
    _vm._b({}, "a-tooltip", _vm.$attrs, false),
    [
      _vm.tooltipShow && _vm.title
        ? _c("template", { slot: "title" }, [
            _c("pre", [_vm._v(_vm._s(_vm.title))]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "text-limit" }, [
        _c("span", { ref: "onlyOne", staticClass: "hide-text" }, [
          _vm._v("..."),
        ]),
        _c("pre", { ref: "hideText", staticClass: "hide-text" }),
        _c("pre", [_vm._v(_vm._s(_vm.resultStr))]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }