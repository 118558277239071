var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "视频播放",
        visible: _vm.visible,
        footer: null,
        width: 520,
      },
      on: { cancel: _vm.onClose },
    },
    [
      _c("video", {
        staticClass: "video",
        attrs: {
          src: _vm.videoUrl,
          controls: "",
          autoplay: _vm.isAutoplay,
          controlslist: "nodownload",
        },
        on: {
          contextmenu: function ($event) {
            $event.preventDefault()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }