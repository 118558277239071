var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "如何找到用户：",
            visible: _vm.dialogVisible,
            width: "600px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "padding-left": "80px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#02a7f0",
                  margin: "12px 0",
                  "font-weight": "bold",
                },
              },
              [
                _vm._v(
                  "请打开【" +
                    _vm._s(_vm.type == "douyin" ? "抖音" : "快手") +
                    "】APP按下图提示操作"
                ),
              ]
            ),
            _vm.type == "kuaishou"
              ? _c("img", {
                  attrs: {
                    width: "400",
                    src: require("@/assets/dev/kuaishouCode.jpg"),
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    width: "400",
                    src: require("@/assets/dev/douyinCode.jpg"),
                    alt: "",
                  },
                }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }