"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof3 = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _typeof2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
var _mqttws31Min = _interopRequireDefault(require("@/utils/mqttws31.min.js"));
var _moment = _interopRequireDefault(require("moment"));
var _ParamTable = _interopRequireDefault(require("./components/ParamTable"));
var _mqttRemoval = _interopRequireDefault(require("@/utils/mqttRemoval"));
var vuex = _interopRequireWildcard(require("vuex"));
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("intelligence_play"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  name: "IntelligencePlay",
  data: function data() {
    return {
      id: 1,
      // 临时自增id
      removal: null,
      currentUserData: {
        nick_name: "",
        avatar: ""
      },
      imgHref: process.env.VUE_APP_OSS_HOST,
      // 标签颜色集合
      tagStyles: [{
        background: "#fff3e5",
        color: "#ff9558"
      }, {
        background: "#e2e9ff",
        color: "#4c72fc"
      }, {
        background: "#d7f2ec",
        color: "#00ae8a"
      }, {
        background: "#ffe1dd",
        color: "#f63e2c"
      }, {
        background: "#fff3e5",
        color: "#ff9558"
      }, {
        background: "#e2e9ff",
        color: "#4c72fc"
      }, {
        background: "#d7f2ec",
        color: "#00ae8a"
      }, {
        background: "#ffe1dd",
        color: "#f63e2c"
      }],
      userCount: 0,
      // 观看人数
      likeCount: 0,
      // 点赞人数
      currentFansId: null,
      // 点击评论当前的fansid
      currentSeriesIds: [],
      // 点击评论当前的seriesid
      question: "",
      // 问题
      client: null,
      // 创建一个客户端实例
      userId: (0, _auth.getUserId)("userId"),
      currentIntentionData: {},
      //当前选中意向车型数据
      allMessageData: [],
      // mqtt返回所有的消息队列
      // 弹幕
      barrageData: [],
      commentData: [],
      linkData: ["10个活跃直播间办法", "转化微信3个小技巧", "最近车市话题", "直播脚本面板~"],
      topData: [],
      tabData1: [{
        name: "价值互动",
        type: 1
      }, {
        name: "全部互动",
        type: 2
      }],
      currentTab1: 1,
      tabData2: [{
        name: "参数/价格",
        type: 1
      }, {
        name: "资料提示",
        type: 2
      }],
      currentTab2: 1,
      //参数、价格数据
      paramData: [],
      // 表格数据
      tableData: [],
      fileDialog: false,
      // 政策文件弹窗
      imgList: [],
      // 图片预览集合
      paramDrawer: false,
      // 参数点击抽屉
      currentImgTag: 0,
      //当前点击资料提示index
      openMsg: true // 是否弹出消息通知
    };
  },

  components: {
    ParamTable: _ParamTable.default
  },
  computed: _objectSpread({}, mapState({
    mqttConfigData: function mqttConfigData(state) {
      return state.mqttConfigData;
    },
    // mqtt
    userListData: function userListData(state) {
      return state.userListData;
    },
    // 直播账号列表
    currentNoV: function currentNoV(state) {
      return state.currentNoV;
    },
    // 价值互动当前页数
    loadingV: function loadingV(state) {
      return state.loadingV;
    },
    // 价值互动加载
    noMoreV: function noMoreV(state) {
      return state.noMoreV;
    },
    // 价值互动没有更多
    currentNoAll: function currentNoAll(state) {
      return state.currentNoAll;
    },
    // 全部互动当前页数
    loadingAll: function loadingAll(state) {
      return state.loadingAll;
    },
    // 全部互动加载
    noMoreAll: function noMoreAll(state) {
      return state.noMoreAll;
    },
    // 全部互动没有更多
    pageSize: function pageSize(state) {
      return state.pageSize;
    },
    // 每页条数
    pollingInfoData: function pollingInfoData(state) {
      return state.pollingInfoData;
    },
    // 直播间粉丝跟主播的互动信息
    pollingVData: function pollingVData(state) {
      return state.pollingVData;
    },
    // 查询价值互动
    pollingAllData: function pollingAllData(state) {
      return state.pollingAllData;
    },
    // 查询全部互动
    intentionData: function intentionData(state) {
      return state.intentionData;
    },
    // 粉丝意向车型
    fileData: function fileData(state) {
      return state.fileData;
    },
    // 政策文件
    cartypeConfigData: function cartypeConfigData(state) {
      return state.cartypeConfigData;
    },
    // 车型配置分类列表
    competitorData: function competitorData(state) {
      return state.competitorData;
    },
    // 车系竞品列表
    discountData: function discountData(state) {
      return state.discountData;
    },
    // 优惠信息
    paramTableData: function paramTableData(state) {
      return state.paramTableData;
    },
    // 参数配置信息
    imgData: function imgData(state) {
      return state.imgData;
    },
    // 资料提示图片数据
    disabledV: function disabledV() {
      return this.loadingV || this.noMoreV;
    },
    disabledAll: function disabledAll() {
      return this.loadingAll || this.noMoreAll;
    }
  })),
  watch: {
    mqttConfigData: function mqttConfigData() {
      this.removal = new _mqttRemoval.default();
      this.MQTTconnect();
    },
    userListData: function userListData() {
      if (this.$route.query.type == "douyin") {
        this.currentUserData = this.userListData["douyinlist"][0];
      } else {
        this.currentUserData = this.userListData["kuaishoulist"][0];
      }
      if (this.currentUserData.author_id != this.$route.query.author_id) {
        this.$router.push("/index");
      }
    },
    intentionData: function intentionData() {
      // 当前选中信息赋值
      console.log('this.intentionData', this.intentionData);
      this.currentIntentionData = this.intentionData.series.length ? this.intentionData.series[0] : {};
      if (this.intentionData.series.length > 0) {
        // 查询相关竞品 默认查第一个
        this.loadCompetitor({
          series_id: this.intentionData.series[0].series_id
        });
      } else {
        this.changeState({
          data: [],
          name: "competitorData"
        });
      }
    },
    // 意向 竞品被点击触发 查询下面列表 和 车系资料
    currentIntentionData: function currentIntentionData() {
      var series_id = this.currentIntentionData.enemy_id || this.currentIntentionData.series_id;
      if (series_id) {
        this.loadCartypeConfig({
          series_id: series_id
        });
        this.loadImgData({
          series_id: series_id
        });
      }
      this.paramData = [];
      this.tableData = [];
    },
    // 配置列表 表格返回数据处理
    cartypeConfigData: function cartypeConfigData() {
      var _this = this;
      var _loop = function _loop(key) {
        if (_this.cartypeConfigData[key].length == 0) {
          return {
            v: false
          };
        }
        _this.paramData.push({
          type: key,
          count: _this.cartypeConfigData[key].length,
          discount_price: _this.cartypeConfigData[key][0].discount_price,
          config: _this.cartypeConfigData[key][0].config
        });
        _this.cartypeConfigData[key].forEach(function (item) {
          var obj = item;
          obj.type = key;
          _this.tableData.push(obj);
        });
      };
      for (var key in this.cartypeConfigData) {
        var _ret = _loop(key);
        if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
      }
    },
    // 政策文件data返回
    fileData: function fileData() {
      var _this2 = this;
      this.fileDialog = true;
      this.imgList = [];
      this.fileData.list.forEach(function (item) {
        _this2.imgList.push("".concat(_this2.imgHref, "/").concat(item.img_url));
      });
    },
    // 参数抽屉表格数据格式处理
    paramTableData: function paramTableData() {
      var _this3 = this;
      var newObj = {};
      this.paramTableData.groups.forEach(function (item, index) {
        newObj[item.id + item.name] = [];
        _this3.paramTableData.configs["".concat(item.id)].forEach(function (item2, index2) {
          var val = "";
          for (var key in _this3.paramTableData.values) {
            if (item2.id == _this3.paramTableData.values[key].config_id) {
              val = _this3.paramTableData.values[key].config_value;
            }
          }
          newObj[item.id + item.name].push({
            name: item2.name,
            value: val
          });
        });
      });
      this.paramTableData.newObj = newObj;
      this.paramDrawer = true;
    },
    // 每次更新资料数据 重制当前选中索引为0
    imgData: function imgData() {
      this.currentImgTag = 0;
      // 如果资料提示有数据，跳转到资料提示tab
      if (this.imgData.length > 0) {
        this.currentTab2 = 2;
      } else {
        this.currentTab2 = 1;
      }
    }
  },
  created: function created() {
    // 获取当前type 和 author_id 和 room_id
    if (!this.$route.query.type || !this.$route.query.author_id || !this.$route.query.room_id) {
      this.$router.push({
        path: "/index"
      });
    }
    this.loadUserList({
      user_id: this.userId
    });
    if (this.$route.query.room_id) {
      this.getMqttConfig({
        platformType: this.$route.query.type == "douyin" ? 1 : 2,
        roomId: this.$route.query.room_id,
        deviceId: this.userId
      });
    }
  },
  mounted: function mounted() {
    console.log("test---------");
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["loadUserList", "getMqttConfig", "loadPollingInfo", "loadPollingV", "loadPollingAll", "loadIntention", "loadFile", "loadCartypeConfig", "loadCompetitor", "loadDiscount", "loadCarConfig", "loadImgData"])), mapMutations(["changeState"])), {}, {
    // 点击评论 查询相关信息
    commentClick: function commentClick(item) {
      var _this4 = this;
      // 存入当店点击的seriesid
      this.currentSeriesIds = [];
      if (item.series && item.series.length > 0) {
        item.series.forEach(function (i) {
          _this4.currentSeriesIds.push(i.series_id);
        });
      }
      this.currentFansId = item.id;
      this.question = item.count;
      // 查信息
      this.loadPollingInfo({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        fans_id: this.currentFansId,
        author_id: this.$route.query.author_id
      });
      // 查车型
      this.loadIntention({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        comment_validate: 1,
        fans_id: this.currentFansId,
        author_id: this.$route.query.author_id
        // series_ids: JSON.stringify(this.currentSeriesIds),
      });
      // 查互动
      this.loadInteractionV(true);
      this.loadInteractionAll(true);
    },
    // 加载 价值互动
    loadInteractionV: function loadInteractionV(isFirst) {
      this.loadPollingV({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        fans_id: this.currentFansId,
        author_id: this.$route.query.author_id,
        current: isFirst ? 1 : this.currentNoV,
        page_size: this.pageSize,
        comment_validate: 1
      });
    },
    // 加载全部互动
    loadInteractionAll: function loadInteractionAll(isFirst) {
      this.loadPollingAll({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        fans_id: this.currentFansId,
        author_id: this.$route.query.author_id,
        current: isFirst ? 1 : this.currentNoAll,
        page_size: this.pageSize,
        comment_validate: 0
      });
    },
    // 意向或竞品tab点击
    carTabClick: function carTabClick(item, type) {
      this.currentIntentionData = item;
      // type == 1 为点击意向tab 需查询竞品
      if (type == 1) {
        this.loadCompetitor({
          series_id: item.series_id
        });
      }
    },
    // 创建mqtt实例，并连接
    MQTTconnect: function MQTTconnect() {
      var _this5 = this;
      this.client = new _mqttws31Min.default.MQTT.Client(this.mqttConfigData.end_point, 443, this.mqttConfigData.client_id);
      this.client.connect({
        userName: this.mqttConfigData.user_name,
        //连接账号
        password: this.mqttConfigData.password,
        //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: function onFailure(message) {
          setTimeout(_this5.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },
    //当客户端连接
    onConnect: function onConnect() {
      //建立连接后，进行订阅并发送消息。
      console.log("onConnect");
      this.client.subscribe(this.mqttConfigData.client_subscribe.live.bulletTopic, {
        qos: 0
      });
    },
    // 当客户端失去连接时调用
    onConnectionLost: function onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    //当消息到达时
    onMessageArrived: function onMessageArrived(message) {
      if (JSON.parse(message.payloadString).type == 11) {
        console.log("onMessageArrived:", JSON.parse(message.payloadString));
      }
      var newJson = JSON.parse(message.payloadString);
      var bool = this.removal.isRemoval(newJson);
      // type == 1 ? 左侧列表 + 顶部列表 : type == 2 ? 观看量 + 点赞量 : type == 4 ? 礼物信息
      if (bool && newJson.type == 11) {
        this.addComment(newJson.data);
        if (newJson.data.series && newJson.data.series.length > 0) {
          for (var i = 0; i < newJson.data.series.length; i++) {
            newJson.data.series[i].time = this.setMoment(newJson.data.time || Date.parse(new Date()));
            newJson.data.series[i].key = newJson.data.time ? newJson.data.time + this.radomNum() : Date.parse(new Date()) + this.radomNum();
            console.log('series i', newJson.data.series[i]);
            this.addTop(newJson.data.series[i]);
          }
        }
      } else if (newJson.type == 12) {
        // 如果存在并且不为空，赋值
        if (newJson.data.likeCount && newJson.data.likeCount != "" && newJson.data.likeCount != "null") {
          this.likeCount = newJson.data.likeCount;
        }
        if (newJson.data.userCount && newJson.data.userCount != "" && newJson.data.userCount != "null") {
          this.userCount = newJson.data.userCount;
        }
      } else if (bool && newJson.type == 14) {
        var msg = newJson.data.describe;
        this.alertBarrage(msg);
      } else if (bool && newJson.type == 15) {
        var _msg = "".concat(newJson.data.name, " \u8FDB\u5165\u4E86\u76F4\u64AD\u95F4");
        this.alertBarrage(_msg);
      } else if (bool && newJson.type == 16) {
        var _msg2 = "".concat(newJson.data.name, " \u70B9\u4E86\u5173\u6CE8");
        this.alertBarrage(_msg2);
      } else if (bool && newJson.type == 17) {
        var _msg3 = "".concat(newJson.data.userName, " \u70B9\u8D5E\u4E86");
        this.alertBarrage(_msg3);
      }
    },
    // 礼物信息
    alertBarrage: function alertBarrage(msg) {
      if (this.openMsg) {
        this.$notify({
          title: "",
          dangerouslyUseHTMLString: true,
          duration: 10000,
          customClass: "barrage",
          showClose: false,
          message: msg
        });
      }
    },
    // 测试添加评论
    addComment: function addComment(data) {
      this.commentData.splice(0, 0, {
        id: data.userId,
        name: data.userName,
        count: data.content,
        series: data.series || [],
        key: data.userId + Date.parse(new Date()) + this.radomNum()
      });
      // var anchor = document.body.querySelector("#commentList");

      // anchor.scrollTo({
      //   top: anchor.offsetTop,
      //   behavior: "smooth"
      // })

      if (this.commentData.length > 100) {
        this.commentData = this.commentData.splice(0, 100);
      }
    },
    // 测试添加顶部车型
    addTop: function addTop(data) {
      console.log(data);
      console.log((0, _typeof2.default)(data.preferential_price));
      if (this.id == data.series_id) {
        return false;
      }
      data.preferential_price = JSON.parse(data.preferential_price);
      this.id = data.series_id;
      this.topData.splice(0, 0, data);
    },
    // 查看政策文件
    openFile: function openFile() {
      this.loadFile({
        series_id: this.currentIntentionData.enemy_id || this.currentIntentionData.series_id
      });
    },
    // 打开参数抽屉
    openParam: function openParam(id) {
      // this.paramDrawer = true
      this.loadCarConfig({
        cartypeId: id
      });
    },
    // 点击表格加载优惠信息
    tableClick: function tableClick(val) {
      if (val && val.id) {
        this.loadDiscount({
          cartypeId: val.id
          // discount_price: val.discount_price,
        });
      }
    },
    // 处理时间差
    editTime: function editTime(day) {
      if ((0, _moment.default)().diff(day, "days") < 1) {
        return "不久前来过";
      } else {
        return "".concat((0, _moment.default)().diff(day, "days"), "\u5929\u524D\u6765\u8FC7");
      }
    },
    // 时间戳转换
    setMoment: function setMoment(time) {
      var newTime = time || Date.parse(new Date());
      return (0, _moment.default)(newTime).format("HH:mm:ss");
    },
    // 处理是否为今天
    isToday: function isToday(day) {
      return (0, _moment.default)().isSame(day, "d");
    },
    // 生成随机数
    radomNum: function radomNum() {
      return Math.ceil(Math.random() * 1000000);
    }
  }),
  beforeDestroy: function beforeDestroy() {
    console.log("销毁");
    this.openMsg = false;
    this.$notify.close();
    if (this.client) {
      this.client.unsubscribe(this.mqttConfigData.clientSubscribe.live.bulletTopic);
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
  }
};
exports.default = _default;