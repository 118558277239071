"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  platform: function platform(state) {
    return state.app.platform;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  list: function list(state) {
    return state.user.list;
  },
  nickName: function nickName(state) {
    return state.user.name;
  },
  accessRoute: function accessRoute(state) {
    return state.permission.accessRoute;
  }
};
var _default = getters;
exports.default = _default;