"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BaseButton',
  data: function data() {
    return {};
  },
  props: {
    icon: String,
    type: String,
    loading: Boolean,
    disabled: Boolean,
    title: String,
    size: String,
    shape: String
  },
  methods: {
    // 点击
    onClickBtn: function onClickBtn() {
      this.$emit('onClickBtn');
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;