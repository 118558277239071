// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/live_images/dy-qrcode-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-7a97e6ff].user-qr-code .ant-modal-content{border-radius:4px}.code-box[data-v-7a97e6ff]{width:400px;height:400px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center;background-size:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 auto}.code-box img[data-v-7a97e6ff]{width:230px;height:230px;margin-top:56px}.code-box .nickname[data-v-7a97e6ff]{margin-top:12px;font-size:27px;color:#ffffff;width:80%;text-align:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;-webkit-line-clamp:1}.code-box .dy-name[data-v-7a97e6ff]{margin-top:3px;font-size:15px;color:rgba(255,255,255,0.55)}.error-img[data-v-7a97e6ff]{display:block;margin:0 auto;width:199px;height:184px;padding-left:25px;padding-bottom:10px}.tips[data-v-7a97e6ff]{text-align:center;margin-top:10px}.tips.grey[data-v-7a97e6ff]{color:rgba(0,0,0,0.3)}\n", ""]);
// Exports
module.exports = exports;
