"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 左侧评论列表--价值评论
  getPollingLeft: function getPollingLeft(_ref) {
    var user_id = _ref.user_id,
      author_id = _ref.author_id,
      current = _ref.current,
      page_size = _ref.page_size;
    return request({
      url: "/comment-validate/list",
      method: 'GET',
      params: {
        user_id: user_id,
        author_id: author_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 视频信息
  getVideoInfo: function getVideoInfo(_ref2) {
    var author_id = _ref2.author_id,
      time = _ref2.time,
      item_id = _ref2.item_id;
    return request({
      url: "/comment-validate/detail/aweme-info",
      method: 'GET',
      params: {
        author_id: author_id,
        time: time,
        item_id: item_id
      }
    });
  },
  // 右侧回复列表
  getReplyComment: function getReplyComment(_ref3) {
    var item_id = _ref3.item_id,
      parent_comment_id = _ref3.parent_comment_id,
      author_id = _ref3.author_id,
      current = _ref3.current,
      page_size = _ref3.page_size;
    return request({
      url: "/comment-validate/detail/comment-list",
      method: 'GET',
      params: {
        item_id: item_id,
        parent_comment_id: parent_comment_id,
        author_id: author_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 右侧此用户其他评论列表
  getOtherComment: function getOtherComment(_ref4) {
    var item_id = _ref4.item_id,
      comment_user_id = _ref4.comment_user_id,
      comment_id = _ref4.comment_id,
      current = _ref4.current,
      page_size = _ref4.page_size;
    return request({
      url: "/comment-validate/detail/comment-other",
      method: 'GET',
      params: {
        item_id: item_id,
        comment_user_id: comment_user_id,
        comment_id: comment_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 本周新增
  getNewByWeek: function getNewByWeek(_ref5) {
    var author_id = _ref5.author_id;
    return request({
      url: "/comment-validate/find-new-count",
      method: 'GET',
      params: {
        author_id: author_id
      }
    });
  },
  // 回复
  replyMessage: function replyMessage(_ref6) {
    var item_id = _ref6.item_id,
      comment_id = _ref6.comment_id,
      content = _ref6.content,
      author_id = _ref6.author_id,
      open_id = _ref6.open_id;
    return request({
      url: "/comment/detail/reply",
      method: 'POST',
      data: {
        item_id: item_id,
        comment_id: comment_id,
        content: content,
        author_id: author_id,
        open_id: open_id
      }
    });
  }
};
exports.default = _default;