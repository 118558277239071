"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _common = _interopRequireDefault(require("../../utils/common"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    // 进度条的宽度
    strokeWidth: {
      type: Number,
      default: 13
    },
    // 是否显示进度条文字内容
    showText: {
      type: Boolean,
      default: true
    },
    // 提示文字显示的位置
    showOutside: {
      type: Boolean,
      default: false
    },
    // 提示文字
    text: {
      type: [Number, String],
      default: ""
    },
    // 底层盒子背景色
    boxBgd: {
      type: String,
      default: "#F1F2F5"
    },
    // 是否添加active效果
    activeStyle: {
      type: Boolean,
      default: false
    },
    // 所占比例 建议以最高数为基数，最高数为百分之八十
    percentage: {
      type: Number,
      default: 80,
      required: true
    },
    // 指示条背景色
    rateBgd: {
      type: String,
      default: '#4a58f3'
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {}
};
exports.default = _default;