"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    id: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "400px"
    },
    overall: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    // this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    overall: function overall() {
      this.initChart();
    }
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        grid: {
          left: "center"
        },
        // title: {
        //   text: "雷达图"
        // },
        tooltip: {},
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#fff",
              backgroundColor: "#999",
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          indicator: [{
            name: "主播矩阵",
            max: 100
          }, {
            name: "门店组织",
            max: 100
          }, {
            name: "产品互动",
            max: 100
          }, {
            name: "内容矩阵",
            max: 100
          }, {
            name: "品牌圈粉",
            max: 100
          }]
        },
        series: [{
          name: "综合评价",
          type: "radar",
          // areaStyle: {normal: {}},
          data: [{
            value: [this.overall.author.score, this.overall.dealer.score, this.overall.interaction.score, this.overall.content.score, this.overall.fans.score]
            // name: "预算分配（Allocated Budget）"
          }
          // {
          //   value: [5000, 14000, 28000, 31000, 42000, 21000],
          //   name: "实际开销（Actual Spending）"
          // }
          ]
        }]
      });
    }
  }
};
exports.default = _default;