var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "audio",
      {
        ref: "audioRef",
        staticStyle: { display: "none" },
        attrs: { controls: "" },
        on: {
          timeupdate: _vm.updateProgress,
          loadedmetadata: _vm.setTotalDuration,
          ended: _vm.onEnd,
        },
      },
      [
        _c("source", { attrs: { src: _vm.fileUrl, type: "audio/mpeg" } }),
        _vm._v(" 您的浏览器不支持音频播放 "),
      ]
    ),
    _c(
      "div",
      { staticClass: "audio-box" },
      [
        _c(
          "span",
          {
            staticClass: "audio-length-current",
            attrs: { id: "audioCurTime" },
          },
          [_vm._v(_vm._s(_vm.currentDuration))]
        ),
        _c("a-slider", {
          staticClass: "progress-bar",
          attrs: { max: _vm.maxDuration, tipFormatter: _vm.transTime },
          on: {
            change: _vm.changeCurrentTime,
            afterChange: _vm.afterChangeCurrentTime,
          },
          model: {
            value: _vm.currentDurationNum,
            callback: function ($$v) {
              _vm.currentDurationNum = $$v
            },
            expression: "currentDurationNum",
          },
        }),
        _c("span", { staticClass: "audio-length-total" }, [
          _vm._v(_vm._s(_vm.totalDuration)),
        ]),
        _c("a-divider", { attrs: { type: "vertical" } }),
        _c(
          "a-dropdown",
          [
            _c(
              "a",
              {
                staticClass: "ant-dropdown-link",
                on: {
                  click: function (e) {
                    return e.preventDefault()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.playBackRate) + "x ")]
            ),
            _c(
              "a-menu",
              {
                attrs: { slot: "overlay" },
                on: { click: _vm.setRate },
                slot: "overlay",
              },
              _vm._l(_vm.rateList, function (item) {
                return _c("a-menu-item", { key: item }, [
                  _vm._v(" " + _vm._s(item) + " "),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }