"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
var _default = {
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {},
  props: ["idName", "title", "chartData"],
  watch: {
    chartData: function chartData(val) {
      if (val) {
        this.initChart();
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.idName));
      var options = {
        title: {
          text: this.title,
          left: 0
        },
        backgroundColor: "#fff",
        tooltip: {},
        grid: {
          top: "20%",
          left: "10%",
          right: "4%",
          bottom: "10%",
          containLabel: false
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
              color: "#233e64"
            }
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置
            textStyle: {
              color: "#333",
              margin: 15
            }
          },
          axisTick: {
            show: false
          },
          data: this.chartData[1]
        }],
        yAxis: [{
          type: "value",
          splitNumber: 7,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ccc"
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: "#333"
            }
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: "异常流量",
          type: "line",
          smooth: false,
          //是否平滑曲线显示
          symbol: 'circle',
          // 默认是空心圆（中间是白色的），改成实心圆
          symbolSize: 0,
          lineStyle: {
            normal: {
              color: "#5875F3" // 线条颜色
            }
          },

          areaStyle: {
            color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "rgba(74,88,243,0.6)"
            }, {
              offset: 1,
              color: "rgba(74,88,243,0.1)"
            }])
          },
          data: this.chartData[0]
        }]
      };
      this.chart.setOption(options);
    }
  }
};
exports.default = _default;