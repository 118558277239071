"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var vuex = _interopRequireWildcard(require("vuex"));
var _lodash = _interopRequireDefault(require("lodash"));
var _common = _interopRequireDefault(require("@/api/common"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _CrmVideoItem = _interopRequireDefault(require("@/components/Item/CrmVideoItem"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("short_management"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  name: "short_management",
  components: {
    CrmVideoItem: _CrmVideoItem.default
  },
  data: function data() {
    return {
      currentVideo: {},
      replyValue: "",
      activeName: "first",
      otherName: "other",
      searchNumber: "",
      searchNumberV: "",
      openCommentId: "",
      currentCommentItem: {},
      author_id: null
    };
  },
  watch: {
    videoData: function videoData() {
      var num = 0;
      if (this.videoData.list && this.videoData.list.length > 0) {
        if (this.$route.params && this.$route.params.current) {
          for (var i = 0; i < this.videoData.list.length; i++) {
            if (this.videoData.list[i].item_id == this.$route.params.item_id) {
              num = i;
            }
          }
        }
        this.currentVideo = this.videoData.list[num];
        this.loadAllComment();
      }
    },
    replyMessageResult: function replyMessageResult(val) {
      if (val.data && val.errcode == 0) {
        this.replyValue = "";
        this.loadReplyListData(true);
      }
    }
  },
  computed: _objectSpread({}, mapState({
    pageSize: function pageSize(state) {
      return state.pageSize;
    },
    // 每页条数

    currentNoVideo: function currentNoVideo(state) {
      return state.currentNoVideo;
    },
    // 当前页数
    loadingVideo: function loadingVideo(state) {
      return state.loadingVideo;
    },
    // 加载
    noMoreVideo: function noMoreVideo(state) {
      return state.noMoreVideo;
    },
    // 没有更多
    videoData: function videoData(state) {
      return state.videoData;
    },
    // 查询左侧视频列表
    disabledVideo: function disabledVideo() {
      return this.loadingVideo || this.noMoreVideo;
    },
    currentNoComment: function currentNoComment(state) {
      return state.currentNoComment;
    },
    // 当前页数
    loadingComment: function loadingComment(state) {
      return state.loadingComment;
    },
    // 加载
    noMoreComment: function noMoreComment(state) {
      return state.noMoreComment;
    },
    // 没有更多
    commentData: function commentData(state) {
      return state.commentData;
    },
    // 查询全部评论列表
    disabledComment: function disabledComment() {
      return this.loadingComment || this.noMoreComment;
    },
    currentNoCommentV: function currentNoCommentV(state) {
      return state.currentNoCommentV;
    },
    // 当前页数
    loadingCommentV: function loadingCommentV(state) {
      return state.loadingCommentV;
    },
    // 加载
    noMoreCommentV: function noMoreCommentV(state) {
      return state.noMoreCommentV;
    },
    // 没有更多
    commentDataV: function commentDataV(state) {
      return state.commentDataV;
    },
    // 查询全部评论列表
    disabledCommentV: function disabledCommentV() {
      return this.loadingCommentV || this.noMoreCommentV;
    },
    currentNoReply: function currentNoReply(state) {
      return state.currentNoReply;
    },
    // 当前页数
    loadingReply: function loadingReply(state) {
      return state.loadingReply;
    },
    // 加载
    noMoreReply: function noMoreReply(state) {
      return state.noMoreReply;
    },
    // 没有更多
    replyData: function replyData(state) {
      return state.replyData;
    },
    // 查询回复列表
    disabledReply: function disabledReply() {
      return this.loadingReply || this.noMoreReply;
    },
    currentNoOther: function currentNoOther(state) {
      return state.currentNoOther;
    },
    // 当前页数
    loadingOther: function loadingOther(state) {
      return state.loadingOther;
    },
    // 加载
    noMoreOther: function noMoreOther(state) {
      return state.noMoreOther;
    },
    // 没有更多
    otherData: function otherData(state) {
      return state.otherData;
    },
    // 此用户其他评论
    disabledOther: function disabledOther() {
      return this.loadingOther || this.noMoreOther;
    },
    replyMessageResult: function replyMessageResult(state) {
      return state.replyMessageResult;
    } // 回复消息返回
  })),
  mounted: function mounted() {},
  created: function created() {
    var meg = window.localStorage.getItem("userInformation") ? JSON.parse(window.localStorage.getItem("userInformation")) : {};
    this.author_id = meg.authorId || "";
    this.changeState({
      data: null,
      name: "otherData"
    });
    if (this.$route.params && this.$route.params.current) {
      this.loadVideoList({
        item_id: this.$route.query.videoId || "",
        author_id: this.author_id,
        current: this.$route.params.current,
        page_size: this.pageSize
      });
      this.changeState({
        data: this.$route.params.current,
        name: "currentNoVideo"
      });
    } else {
      console.log(1);
      this.loadVideoListData(true);
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["loadPollingLeft", "loadVideoList", "loadCommentList", "loadCommentListV", "loadReplyList", "loadOtherList", "replyMessage"])), mapMutations(["changeState"])), {}, {
    loadVideoListData: function loadVideoListData(isFirst) {
      console.log("this.$route.query.videoId", this.$route.query.videoId);
      this.loadVideoList({
        item_id: this.$route.query.videoId || "",
        author_id: this.author_id,
        current: isFirst ? 1 : this.currentNoVideo,
        page_size: this.pageSize
      });
    },
    loadCommentListData: function loadCommentListData(isFirst) {
      this.loadCommentList({
        item_id: encodeURIComponent(this.currentVideo.item_id),
        comment_validate: 0,
        days: this.searchNumber,
        current: isFirst ? 1 : this.currentNoComment,
        page_size: this.pageSize
      });
    },
    loadCommentListDataV: function loadCommentListDataV(isFirst) {
      this.loadCommentListV({
        item_id: encodeURIComponent(this.currentVideo.item_id),
        comment_validate: 1,
        days: this.searchNumberV,
        current: isFirst ? 1 : this.currentNoCommentV,
        page_size: this.pageSize
      });
    },
    loadReplyListData: function loadReplyListData(isFirst) {
      this.loadReplyList({
        item_id: encodeURIComponent(this.currentVideo.item_id),
        parent_comment_id: encodeURIComponent(this.openCommentId),
        author_id: this.author_id,
        current: isFirst ? 1 : this.currentNoReply,
        page_size: this.pageSize
      });
    },
    loadOtherListData: function loadOtherListData(isFirst) {
      this.loadOtherList({
        item_id: encodeURIComponent(this.currentVideo.item_id),
        comment_id: encodeURIComponent(this.currentCommentItem.comment_id),
        comment_user_id: this.currentCommentItem.comment_user_id,
        current: isFirst ? 1 : this.currentNoOther,
        page_size: this.pageSize
      });
    },
    videoClick: function videoClick(item) {
      this.changeState({
        data: null,
        name: "otherData"
      });
      this.currentVideo = item;
      this.currentCommentItem = {};
      this.loadAllComment();
    },
    loadAllComment: function loadAllComment() {
      this.loadCommentListData(true);
      this.loadCommentListDataV(true);
    },
    commentClick: function commentClick(item) {
      console.log(item);
      this.openCommentId = item.comment_id;
      this.currentCommentItem = item;
      // 获取当前评论
      this.loadReplyListData(true);
      this.loadOtherListData(true);
    },
    replyCommentClick: function replyCommentClick(item) {
      console.log(item);
      this.currentCommentItem = item;
      this.loadOtherListData(true);
    },
    replyBtnClick: function replyBtnClick() {
      var _this = this;
      this.filtrate.setTracking({
        en: "zxmk_yx_spplhf_click"
      });
      if (!this.currentCommentItem.comment_id) {
        console.log("请选择一条评论信息回复");
        this.$message.warning("请选择一条评论信息回复");
        return false;
      }
      if (this.replyValue.length == 0) {
        this.$message.warning("请输入内容");
        return false;
      }
      this.replyMessage({
        author_id: this.author_id,
        content: this.replyValue,
        comment_id: this.currentCommentItem.comment_id,
        item_id: this.currentVideo.item_id,
        open_id: this.currentVideo.open_id
      }).then(function (res) {
        // 清空消息
        _this.replyValue = "";
        if (res.code === 200) {
          _this.$message.success("回复成功");
          _this.commentClick(_this.currentCommentItem);
        } else {
          _this.$message.error(res.message);
        }
      }).catch(function (res) {
        console.log(res);
      });
    },
    refreshClick: function refreshClick() {
      this.loadVideoListData(true);
    },
    gotoScenePage: function gotoScenePage(value) {
      this.filtrate.setTracking({
        en: "zxmk_yx_spplcllj_click"
      });
      this.$router.push({
        path: "/live_new_comment/comment_smart_reply",
        query: {
          sceneId: value
        }
      });
    }
  })
};
exports.default = _default;