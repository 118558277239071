"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}

// 缓存上一个请求的cancel，用于取消上一个尚未结束的请求
var cancel = null; // 一个请求对应一个cancel
var _module = '/live-crm';
var CancelToken = buildCancelToken();
var _default = {
  //   首页-直播关注统计留资明细
  getLiveProperty: function getLiveProperty(params) {
    return request({
      url: "/mkms/marketingStatistic/leaveInfo/list",
      method: 'get',
      params: params
    });
  },
  //   首页-直播关注统计转化明细
  getLiveEffect: function getLiveEffect(params) {
    return request({
      url: "/mkms/marketingStatistic/liveFollow/list",
      method: 'get',
      params: params
    });
  },
  //   增加互动匹配数
  getIncrInteract: function getIncrInteract() {
    return request({
      url: "/mkms/marketingStatistic/incrOpenInteract",
      method: 'get'
    });
  },
  getLiveIncrInteract: function getLiveIncrInteract() {
    return request({
      url: "/mkms/marketingStatistic/incrLiveInteract",
      method: 'get'
    });
  },
  // 首页-直播关注统计留资明细
  getLeaveDetail: function getLeaveDetail(params) {
    return request({
      url: "/mkms/marketingStatistic/leaveDetail",
      method: 'get',
      params: params
    });
  },
  // 首页-直播关注统计留资明细
  getLeaveDetailV1_22_0: function getLeaveDetailV1_22_0(data) {
    return request({
      url: "/clue/follow/up/manage/list",
      method: 'post',
      data: data
    });
  },
  // 首页-直播关注统计留资明细详情
  getLiveLeaveDetail: function getLiveLeaveDetail(payload) {
    return request({
      url: "/mkms/marketingStatistic/leaveDetail/".concat(payload.userOpenId),
      method: 'get',
      params: payload.params
    });
  },
  // 留资数据-留资来源接口
  getLeaveSource: function getLeaveSource(params) {
    return request({
      url: "/mkms/marketingStatistic/leaveSource",
      method: 'get',
      params: params
    });
  },
  // 留资数据-编辑跟进状态/备注
  updateFollowUpStateAndRemarks: function updateFollowUpStateAndRemarks(data) {
    return request({
      url: "/clue/follow/up/update/followup/state/and/remarks",
      method: 'post',
      data: data
    });
  },
  // 导出留资数据
  exportExcelLiveLeaveDetail: function exportExcelLiveLeaveDetail(data) {
    return request({
      responseType: 'blob',
      url: "/mkms/marketingStatistic/exportExcel",
      method: 'post',
      data: data
    });
  },
  // 导出留资数据
  exportExcelLiveLeaveDetailV1_22_0: function exportExcelLiveLeaveDetailV1_22_0(data) {
    return request({
      responseType: 'blob',
      url: "/clue/follow/up/export/excel",
      method: 'post',
      data: data
    });
  },
  // 获取授权链接
  getAuthorizationUrl: function getAuthorizationUrl() {
    return request({
      url: "/mkms/first/isEnterpriseAccount",
      method: 'GET'
    });
  }
};
exports.default = _default;