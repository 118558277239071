"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.warningStyle = exports.logStyle = exports.errorStyle = exports.default = void 0;
var errorStyle = "color: #cc3300;background: #FFBABA;font-size: 12px;padding: 5px;";
exports.errorStyle = errorStyle;
var warningStyle = "color: #ffcc00; font-size: 12px;padding: 5px;";
exports.warningStyle = warningStyle;
var logStyle = "color: #0f0";
exports.logStyle = logStyle;
var _default = {
  error: function error(msg) {
    console.log("%c\u274C [aft-mirror]::error:: ".concat(msg), errorStyle);
  },
  warning: function warning(msg) {
    console.log("%c\uD83D\uDEA7 [aft-mirror]::warning:: ".concat(msg), warningStyle);
  },
  log: function log(msg) {
    console.log("%c\uD83D\uDE8C [aft-mirror]::log:: ".concat(msg), logStyle);
  }
};
exports.default = _default;