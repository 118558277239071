"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}
var _default = {
  // 获取关键词状态
  getKeyWordgStatus: function getKeyWordgStatus() {
    return request({
      url: "/mkms/key-word/get/function/status",
      method: "get"
    });
  },
  // 更新关键词状态
  updateKeyWordStatus: function updateKeyWordStatus() {
    return request({
      url: "/mkms/key-word/update/function/status",
      method: "get"
    });
  },
  // 更新规则状态
  updateKeyWordStatusRuleId: function updateKeyWordStatusRuleId(ruleId) {
    return request({
      url: "/mkms/key-word/update/status/".concat(ruleId),
      method: "get"
    });
  },
  // 删除规则状态
  deleteKeyWordStatusRuleId: function deleteKeyWordStatusRuleId(ruleId) {
    return request({
      url: "/mkms/key-word/delete/".concat(ruleId),
      method: "get"
    });
  },
  // 查询触达策略列表
  getKeyWordList: function getKeyWordList() {
    return request({
      url: "/mkms/key-word/rule/list",
      method: "get"
    });
  },
  // 新增关键词回复
  createKeyWord: function createKeyWord(data) {
    console.log(data);
    return request({
      url: "/mkms/key-word/create",
      method: "post",
      data: data
    });
  },
  // 编辑关键词回复
  updateKeyWord: function updateKeyWord(data) {
    return request({
      url: "/mkms/key-word/update/detail",
      method: "post",
      data: data
    });
  },
  // 1.11.0 新接口
  // 关键词回复规则-列表查询
  getKeywordReplyRulesListV1_11_0: function getKeywordReplyRulesListV1_11_0() {
    return request({
      url: "/chat/key-word/rule/list",
      method: "get"
    });
  },
  // 获取关键词回复功能状态
  getKeyWordReplyStatusV1_11_0: function getKeyWordReplyStatusV1_11_0() {
    return request({
      url: "/chat/key-word/get/function/status",
      method: "get"
    });
  },
  // 关键词回复功能-启用/停用
  updateKeyWordReplyStatusV1_11_0: function updateKeyWordReplyStatusV1_11_0() {
    return request({
      url: "/chat/key-word/update/function/status",
      method: "GET"
    });
  },
  // 关键词回复规则-新增
  createKeyWordV1_11_0: function createKeyWordV1_11_0(data) {
    return request({
      url: "/chat/key-word/create",
      method: "post",
      data: data
    });
  },
  // 关键词回复规则-更新规则
  updateKeyWordV1_11_0: function updateKeyWordV1_11_0(data) {
    return request({
      url: "/chat/key-word/update/detail",
      method: "post",
      data: data
    });
  },
  // 关键词回复规则-根据ID删除规则
  deleteKeyWordReplyByRuleIdV1_11_0: function deleteKeyWordReplyByRuleIdV1_11_0(ruleId) {
    return request({
      url: "/chat/key-word/delete/".concat(ruleId),
      method: "GET"
    });
  },
  // 关键词回复规则-启用/停用规则
  switchKeyWordReplyRuleV1_11_0: function switchKeyWordReplyRuleV1_11_0(ruleId) {
    return request({
      url: "/chat/key-word/update/status/".concat(ruleId),
      method: "GET",
      ruleId: ruleId
    });
  },
  // 关键词回复规则-调整优先级
  sortKeyWordReplyRuleV1_11_0: function sortKeyWordReplyRuleV1_11_0(data) {
    return request({
      url: "/chat/key-word/strategySort",
      method: "post",
      data: data
    });
  }
};
exports.default = _default;