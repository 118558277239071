var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cloud-video" },
    [
      _c(
        "a-card",
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "searchFrom", attrs: { model: _vm.form } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-row",
                {
                  attrs: { type: "flex", justify: "space-between", gutter: 16 },
                },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "创建日期" } },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { placeholder: "开始时间" },
                            model: {
                              value: _vm.form.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startTime", $$v)
                              },
                              expression: "form.startTime",
                            },
                          }),
                          _vm._v(" ~ "),
                          _c("a-date-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { placeholder: "结束时间" },
                            model: {
                              value: _vm.form.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "endTime", $$v)
                              },
                              expression: "form.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "任务名称" } },
                        [
                          _c("a-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入任务名称" },
                            model: {
                              value: _vm.form.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taskName", $$v)
                              },
                              expression: "form.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "视频名称" } },
                        [
                          _c("a-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请输入视频名称",
                              maxLength: 100,
                              "allow-clear": "",
                            },
                            model: {
                              value: _vm.form.criptName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "criptName", $$v)
                              },
                              expression: "form.criptName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "抖音昵称" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                multiple: "",
                                "option-filter-prop": "children",
                                "allow-clear": "",
                                placeholder: "请选择抖音昵称",
                              },
                              model: {
                                value: _vm.form.authorIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "authorIds", $$v)
                                },
                                expression: "form.authorIds",
                              },
                            },
                            _vm._l(_vm.dyNickNameList, function (item) {
                              return _c("a-select-option", { key: item.id }, [
                                _vm._v(_vm._s(item.name)),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "视频分类" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              staticStyle: { margin: "8px" },
                              model: {
                                value: _vm.form.videoType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "videoType", $$v)
                                },
                                expression: "form.videoType",
                              },
                            },
                            [
                              _c("a-radio-button", { attrs: { value: "" } }, [
                                _vm._v(" 全部 "),
                              ]),
                              _vm._l(_vm.videoTypeList, function (item, index) {
                                return _c(
                                  "a-radio-button",
                                  { key: index, attrs: { value: item.code } },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model",
            { attrs: { layout: "inline" } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "发布抖音" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticStyle: { margin: "8px" },
                      model: {
                        value: _vm.form.publicStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "publicStatus", $$v)
                        },
                        expression: "form.publicStatus",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _vm._l(_vm.dyStatusList, function (item, index) {
                        return _c(
                          "a-radio-button",
                          { key: index, attrs: { value: item.code } },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticStyle: { margin: "8px" },
                      model: {
                        value: _vm.form.productId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productId", $$v)
                        },
                        expression: "form.productId",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _vm._l(_vm.productList, function (item, index) {
                        return _c(
                          "a-radio-button",
                          { key: index, attrs: { value: item.id } },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn-wrapper" },
                [
                  _c(
                    "a-button",
                    { attrs: { icon: "redo" }, on: { click: _vm.handleReset } },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "primary", icon: "search" },
                      on: {
                        click: function ($event) {
                          return _vm.getTableData(true)
                        },
                      },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.listLoading } },
        [
          _c(
            "a-card",
            { staticStyle: { "margin-top": "20px" } },
            [
              _vm.tableData.length > 0
                ? _c(
                    "a-row",
                    {
                      staticClass: "operation",
                      attrs: { type: "flex", justify: "space-between" },
                    },
                    [
                      _c("a-button", { on: { click: _vm.allSelect } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tableData.length ===
                                _vm.selectedRowKeys.length
                                ? "取消全选"
                                : "全选"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c("a-button", { on: { click: _vm.handleCopy } }, [
                            _vm._v("批量获取视频名称和链接"),
                          ]),
                          _c(
                            "a-dropdown",
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "ml-10",
                                  attrs: { type: "primary" },
                                },
                                [_vm._v("批量下载")]
                              ),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDownload(true)
                                          },
                                        },
                                      },
                                      [_vm._v("打包下载")]
                                    ),
                                  ]),
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDownload(false)
                                          },
                                        },
                                      },
                                      [_vm._v("不打包下载")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tableData.length > 0
                ? _c(
                    "a-checkbox-group",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.selectedRowKeys,
                        callback: function ($$v) {
                          _vm.selectedRowKeys = $$v
                        },
                        expression: "selectedRowKeys",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "video-list" },
                        _vm._l(_vm.tableData, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "video-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleRowClick(item.id)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "img-wrapper" },
                                [
                                  _c("base-img", {
                                    attrs: { src: item.coverUrl },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.preview(item.awemeUrl)
                                      },
                                    },
                                  }),
                                  _c("a-checkbox", {
                                    staticClass: "check-box",
                                    attrs: { value: item.id },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  }),
                                  item.publicStatus === "已发布"
                                    ? _c(
                                        "a-tag",
                                        {
                                          staticClass: "video-tag",
                                          attrs: { color: "blue" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.publicStatus) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "info-wrapper" }, [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "video-title",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.preview(item.awemeUrl)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.criptName) + " ")]
                                  ),
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v(_vm._s(item.taskName)),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v(
                                      "产品名称：" + _vm._s(item.productName)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v(
                                      "视频分类：" +
                                        _vm._s(item.videoCategoryName)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v(
                                      "抖音昵称：" + _vm._s(item.authorName)
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("base-pagination", {
                        attrs: {
                          "current-page": _vm.pagination.current,
                          "page-size": _vm.pagination.pageSize,
                          total: _vm.pagination.total,
                          options: ["100"],
                        },
                        on: {
                          onChange: _vm.handlePaginationChange,
                          onShowSizeChange: _vm.handlePaginationChange,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticStyle: { margin: "50px 0" } },
                    [_c("a-empty")],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("VideoPreviewModal", {
        ref: "VideoPreviewModal",
        attrs: { isAutoplay: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }