"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _default = {
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    window.addEventListener("resize", this.resizeEmit);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener("resize", this.resizeEmit);
  },
  methods: {
    resizeEmit: _lodash.default.throttle(function () {
      this.$root.$emit("textellipsisResize");
    }, 500)
  }
};
exports.default = _default;