var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        visible: _vm.visible,
        title: "查看关键词组",
        loading: _vm.saving,
        width: "620",
        maskClosable: false,
      },
      on: { close: _vm.closeDrawer },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "关键词组名", prop: "group_name" } },
                [_c("span", [_vm._v(_vm._s(_vm.form.group_name))])]
              ),
              _c("a-form-model-item", { attrs: { label: "关键词" } }, [
                _c(
                  "div",
                  { staticClass: "keyword-list" },
                  [
                    _vm._l(_vm.form.details_list, function (word, index) {
                      return [
                        _c("div", { key: index, staticClass: "keyword-item" }, [
                          _c("div", [_vm._v(_vm._s(word.details_name))]),
                          _c(
                            "div",
                            [
                              _c("a-tag", [
                                _vm._v(
                                  _vm._s(_vm.conditionMap[word.match_type])
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _vm.form.details_list.length > 1 &&
                        index !== _vm.form.details_list.length - 1
                          ? _c("div", { key: "or" + index }, [_vm._v(" 或 ")])
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              _vm.form.listTypeName
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "类型", prop: "listTypeName" } },
                    [_vm._v(" " + _vm._s(_vm.form.listTypeName) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }