var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.videoData
    ? _c("div", { staticClass: "video-item", style: _vm.parentStyle }, [
        _c("div", { staticClass: "video-item-top" }, [
          _c("img", {
            attrs: { src: _vm.videoData.cover, alt: "", width: _vm.imgWidth },
          }),
          _c("div", { staticClass: "mask", style: _vm.mskPadding }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.videoData.title) + " "),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }