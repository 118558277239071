"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default = {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      default: false
    },
    checkedVal: {
      default: true
    },
    unCheckedVal: {
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    newChecked: function newChecked() {
      return this.checked === this.checkedVal;
    }
  },
  methods: {
    changeValue: function changeValue(val) {
      this.$emit('change', val ? this.checkedVal : this.unCheckedVal);
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;