var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.title,
        placement: "right",
        visible: _vm.isVisible,
        maskClosable: false,
        width: 540,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.isLoading, tip: "加载中...", size: "large" } },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c("h4", [_vm._v("关键词")]),
              _vm._l(_vm.form.keyWordNameList, function (keyword, index) {
                return _c(
                  "a-form-model-item",
                  {
                    key: index,
                    attrs: {
                      prop: "keyWordNameList." + index,
                      rules: {
                        validator: _vm.blurKeyWord,
                        index: index,
                        keyword: keyword,
                        trigger: "change",
                        required: true,
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "keyword-input" },
                      [
                        _c("a-input", {
                          staticClass: "input",
                          attrs: {
                            placeholder: "请输入",
                            "max-length": 10,
                            "read-only": !_vm.isEditable,
                          },
                          model: {
                            value: _vm.form.keyWordNameList[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.form.keyWordNameList, index, $$v)
                            },
                            expression: "form.keyWordNameList[index]",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "input-after" },
                          [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(" " + _vm._s(keyword.length) + "/10 "),
                            ]),
                            _vm.isEditable &&
                            _vm.form.keyWordNameList.length > 1
                              ? _c("a-icon", {
                                  staticClass: "delete",
                                  attrs: {
                                    type: "minus-circle-o",
                                    disabled:
                                      _vm.form.keyWordNameList.length === 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeKeyWord(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm.isEditable
                ? _c(
                    "a-form-model-item",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.form.keyWordNameList.length === 10,
                          },
                          on: { click: _vm.addKeyWord },
                        },
                        [_vm._v(" 添加关键词 ")]
                      ),
                      _c("span", { staticClass: "add-tips" }, [
                        _vm._v(
                          " 最多支持添加10个关键词，每个关键词最多10个字 "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("h4", [_vm._v("匹配方式")]),
          _c(
            "a-form-model",
            {
              class: _vm.isEditable ? "" : "disable-box",
              attrs: { prop: "form.matchType" },
            },
            [
              _c("p", { staticClass: "match-tips" }, [
                _vm._v(
                  " 如果用户的私信里包含任何关键词，系统将随机回复以下内容中的一条 "
                ),
              ]),
            ]
          ),
          _c("h4", [_vm._v("回复内容")]),
          _vm._l(_vm.form.keyWordResponseList, function (domain, index) {
            return _c(
              "a-form-model-item",
              {
                key: "res-" + index,
                ref: "text-" + index + "-input",
                refInFor: true,
                attrs: {
                  prop: "keyWordResponseList." + index + ".message",
                  rules: {
                    index: index,
                    validator: _vm.replyTextValid,
                    trigger: ["change", "blur"],
                  },
                },
              },
              [
                _c("div", { staticClass: "keyword-input content" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("a-textarea", {
                        ref: "text-" + index,
                        refInFor: true,
                        attrs: {
                          placeholder: "请输入回复内容",
                          rows: 5,
                          "read-only": !_vm.isEditable,
                          "max-length": 100,
                        },
                        on: {
                          change: function () {
                            return _vm.$refs[
                              "text-" + index + "-input"
                            ][0].onFieldChange()
                          },
                          blur: function (e) {
                            return _vm.$refs[
                              "text-" + index + "-input"
                            ][0].onFieldBlur()
                          },
                        },
                        model: {
                          value: domain.message,
                          callback: function ($$v) {
                            _vm.$set(
                              domain,
                              "message",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "domain.message",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input-after content" },
                    [
                      _vm.isEditable && _vm.form.keyWordResponseList.length > 1
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: { title: "确认删除该回复内容吗？" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeReply(index)
                                },
                              },
                            },
                            [
                              _c("a-icon", {
                                staticClass: "delete",
                                attrs: {
                                  type: "minus-circle-o",
                                  disabled:
                                    _vm.form.keyWordResponseList.length === 1,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "tips_count" }, [
                        _vm._v(_vm._s(domain.message.length) + "/100"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            )
          }),
          _vm.isEditable
            ? _c(
                "a-form-model-item",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.form.keyWordResponseList.length === 3,
                      },
                      on: { click: _vm.addReply },
                    },
                    [_vm._v(" 添加话术 ")]
                  ),
                  _c("span", { staticClass: "add-tips" }, [
                    _vm._v("最多可添加3条，命中关键词时，随机选择1条发送私信"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticStyle: { height: "70px" } }),
      _vm.isEditable
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "8px" },
                  on: { click: _vm.close },
                },
                [_vm._v(" 取消")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.isSaving },
                  on: { click: _vm.save },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }