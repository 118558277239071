"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var requestFhlApp = (0, _request.default)({
  baseURL: process.env.VUE_APP_FHL_APP_API
});
var _default = {
  // 检查是否已经设置主营媒体号
  getAuthorBindCheck: function getAuthorBindCheck(params) {
    return request({
      url: "/main/author/bind/check",
      method: 'GET',
      params: params
    });
  },
  // 获取用户dealerId
  getUserDealerId: function getUserDealerId() {
    return request({
      url: "/main/author/get/user/dealerId",
      method: 'GET'
    });
  },
  // 获取绑定的媒体号
  getBindMediaList: function getBindMediaList(params) {
    return requestFhlApp({
      url: "/ums/author/list",
      method: 'GET',
      params: params
    });
  },
  // 获取审核中的媒体号
  getCheckMediaList: function getCheckMediaList() {
    return requestFhlApp({
      url: "/ums/author/pendingOrFailAuthList",
      method: 'GET',
      params: {
        page: 1,
        size: 100,
        status: 3
      }
    });
  },
  // 获取可设置客情媒体号数据
  getCanBindMediaCount: function getCanBindMediaCount() {
    return request({
      url: "/more/media/get/account",
      method: 'GET'
    });
  },
  // 设置抖音主营媒体号
  setMedia: function setMedia(data) {
    return request({
      url: "/main/author/bind/dy/author",
      method: 'POST',
      data: data
    });
  },
  // 设置多个抖音主营媒体号
  setMediaList: function setMediaList(data) {
    return request({
      url: "/more/media/app/submit",
      method: 'POST',
      data: data
    });
  },
  // 获取是否授权
  getAuthorizationStatus: function getAuthorizationStatus() {
    return request({
      url: '/mkms/first/isAuthorization/detail',
      method: 'get'
    });
  },
  // 获取授权链接
  getAuthorizationUrl: function getAuthorizationUrl() {
    return request({
      url: "/mkms/first/isEnterpriseAccount",
      method: 'GET'
    });
  },
  // 获取抖音授权详情
  getDouYinAuthorizationDetail: function getDouYinAuthorizationDetail() {
    return request({
      url: "/auth/app/dy",
      method: 'GET'
    });
  }
};
exports.default = _default;