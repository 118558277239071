"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 通过code查询主播信息
  getAuthorInfo: function getAuthorInfo(_ref) {
    var code = _ref.code;
    return request({
      url: '/mirror-data/live-monit/live/author',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        code: code
      }
    });
  },
  // 查询直播列表
  getLiveList: function getLiveList(_ref2) {
    var status = _ref2.status,
      word = _ref2.word,
      current = _ref2.current,
      size = _ref2.size;
    return request({
      url: '/mirror-data/live-monit/live/all',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        status: status,
        word: word,
        current: current,
        size: size
      }
    });
  },
  // 创建直播监控
  createLive: function createLive(_ref3) {
    var code = _ref3.code,
      start_time = _ref3.start_time,
      duration = _ref3.duration;
    return request({
      url: '/mirror-data/live-monit/live',
      method: 'PUT',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        code: code,
        start_time: start_time,
        duration: duration
      }
    });
  },
  // 剩余未使用的监控数量
  getUnused: function getUnused() {
    return request({
      url: '/mirror-data/live-monit/live/unUsedCount',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 详情--直播间信息
  getDetailInfo: function getDetailInfo(_ref4) {
    var roomId = _ref4.roomId;
    return request({
      url: "/mirror-data/live-monit/live/info/".concat(roomId),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 详情--linechart
  getDetailLine: function getDetailLine(_ref5) {
    var roomId = _ref5.roomId;
    return request({
      url: "/mirror-data/live-monit/live/staticstis//".concat(roomId),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 详情--cloudchart
  getDetailCloud: function getDetailCloud(_ref6) {
    var room_id = _ref6.room_id;
    return request({
      url: "/mirror-data/live-monit/live/hotwords",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        room_id: room_id
      }
    });
  },
  // 详情--barchart
  getDetailBar: function getDetailBar(_ref7) {
    var room_id = _ref7.room_id;
    return request({
      url: "/mirror-data/live-monit/live/series",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        room_id: room_id
      }
    });
  },
  // 详情--FunnelChart
  getDetailFunnel: function getDetailFunnel(_ref8) {
    var room_id = _ref8.room_id;
    return request({
      url: "/mirror-data/live-monit/live/".concat(room_id, "/comment/value-new"),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 详情--查看评论
  getComment: function getComment(_ref9) {
    var room_id = _ref9.room_id,
      page_size = _ref9.page_size,
      current = _ref9.current,
      word = _ref9.word;
    return request({
      url: "/mirror-data/live-monit/live/".concat(room_id, "/comment"),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        page_size: page_size,
        current: current,
        word: word
      }
    });
  }
};
exports.default = _default;