"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TipsType = exports.TipsText = exports.TipsButtonText = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _TipsText, _TipsButtonText;
var TipsType = {
  NOT_SETTING: 'not_setting',
  NOT_ADD: 'not_add',
  CHECKING: 'checking',
  NOT_AUTH: 'not_auth',
  NOT_ENOUGH_AUTH: 'not_enough_auth',
  IS_PERSONAL: 'is_personal'
};
exports.TipsType = TipsType;
var TipsText = (_TipsText = {}, (0, _defineProperty2.default)(_TipsText, TipsType.NOT_SETTING, '暂未设置客情主营媒体号'), (0, _defineProperty2.default)(_TipsText, TipsType.NOT_ADD, '暂未添加抖音媒体号'), (0, _defineProperty2.default)(_TipsText, TipsType.CHECKING, '添加的抖音号{抖音昵称}正在审核中'), (0, _defineProperty2.default)(_TipsText, TipsType.NOT_AUTH, '抖音号未授权'), (0, _defineProperty2.default)(_TipsText, TipsType.NOT_ENOUGH_AUTH, '抖音授权项更新，请重新授权'), (0, _defineProperty2.default)(_TipsText, TipsType.IS_PERSONAL, '个人号/普通企业号无法使用客情功能请切换蓝v/员工号'), _TipsText);
exports.TipsText = TipsText;
var TipsButtonText = (_TipsButtonText = {}, (0, _defineProperty2.default)(_TipsButtonText, TipsType.NOT_SETTING, '去设置'), (0, _defineProperty2.default)(_TipsButtonText, TipsType.NOT_ADD, '查看添加入口'), (0, _defineProperty2.default)(_TipsButtonText, TipsType.CHECKING, '查看添加入口'), (0, _defineProperty2.default)(_TipsButtonText, TipsType.NOT_AUTH, '去授权'), (0, _defineProperty2.default)(_TipsButtonText, TipsType.NOT_ENOUGH_AUTH, '去授权'), (0, _defineProperty2.default)(_TipsButtonText, TipsType.IS_PERSONAL, '去切换'), _TipsButtonText);
exports.TipsButtonText = TipsButtonText;