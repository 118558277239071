"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _iconfont = _interopRequireDefault(require("@/utils/iconfont"));
var _zh_CN = _interopRequireDefault(require("ant-design-vue/lib/locale-provider/zh_CN"));
var _textellipsisResizeMixin = _interopRequireDefault(require("@/components/TextEllipsis/textellipsisResizeMixin"));
var _Authorization = _interopRequireDefault(require("@/components/Auth/Authorization.vue"));
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  mixins: [_textellipsisResizeMixin.default],
  components: {
    Authorization: _Authorization.default
  },
  created: function created() {
    var _this = this;
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem('store')) {
      var state = Object.assign({}, this.$store.state, JSON.parse(decodeURIComponent(localStorage.getItem('store'))));
      this.$store.replaceState(state);
    }
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', function () {
      // 不保存auth数据
      delete _this.$store.state.auth;
      localStorage.setItem('store', encodeURIComponent(JSON.stringify(_this.$store.state)));
    });
    // 飞书环境默认折叠菜单栏
    if (window.h5sdk) {
      this.$store.dispatch('app/togglePlatform', 'feishu');
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    } else {
      this.$store.dispatch('app/togglePlatform', 'browser');
    }
  },
  data: function data() {
    return {
      locale: _zh_CN.default
    };
  },
  mounted: function mounted() {
    (0, _iconfont.default)(window);
  }
};
exports.default = _default;