"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ["paramTableData"],
  data: function data() {
    return {
      tableData: [],
      leftList: [],
      titleName: '',
      topData: [{
        name: '发动机',
        pName: '1基本参数',
        value: ''
      }, {
        name: '能源类型',
        pName: '1基本参数',
        value: ''
      }, {
        name: '轴距(mm)',
        pName: '2车身',
        value: ''
      }, {
        name: '变速箱',
        pName: '1基本参数',
        value: ''
      }, {
        name: '前悬架类型',
        pName: '5底盘转向',
        value: ''
      }, {
        name: '后悬架类型',
        pName: '5底盘转向',
        value: ''
      }, {
        name: '上市时间',
        pName: '1基本参数',
        value: ''
      }, {
        name: '级别',
        pName: '1基本参数',
        value: ''
      }]
    };
  },
  computed: {},
  watch: {
    paramTableData: function paramTableData() {
      this.leftList = [];
      this.tableData = [];
      this.leftList = this.paramTableData.groups;
      for (var key in this.paramTableData.newObj) {
        this.tableData.push({
          name: key,
          value: this.paramTableData.newObj[key]
        });
      }
      this.getTopData();
    }
  },
  mounted: function mounted() {
    this.leftList = [];
    this.tableData = [];
    this.leftList = this.paramTableData.groups;
    for (var key in this.paramTableData.newObj) {
      this.tableData.push({
        name: key,
        value: this.paramTableData.newObj[key]
      });
    }
    this.getTopData();
  },
  methods: {
    goAnchor: function goAnchor(selector) {
      var anchor = document.body.querySelector(selector);
      // document.body.querySelector(".container-right").scrollTop = anchor.offsetTop - 100; // chrome
      document.body.querySelector(".container-right").scrollTo({
        top: anchor.offsetTop - 96,
        behavior: "smooth"
      });
      // document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    getValueByName: function getValueByName(index, name) {
      var _this = this;
      this.tableData[index].value.filter(function (item) {
        if (item.name == name) {
          _this.titleName = item.value;
        }
      });
    },
    getTopData: function getTopData() {
      var _this2 = this;
      // 获取车型名称
      this.tableData[0].value.filter(function (item) {
        if (item.name == '车型名称') {
          _this2.titleName = item.value;
        }
      });
      this.topData.forEach(function (item2) {
        _this2.tableData.filter(function (item3) {
          if (item2.pName == item3.name) {
            item3.value.filter(function (item4) {
              if (item2.name == item4.name) {
                item2.value = item4.value;
              }
            });
          }
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {}
};
exports.default = _default;