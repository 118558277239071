"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _home = _interopRequireDefault(require("@/api/home.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tipVisible: false,
      incrIntercat: {}
    };
  },
  created: function created() {
    var _this = this;
    _home.default.getIncrInteract().then(function (res) {
      if (res.code == 200) {
        var empower_fans_info = res.data.empower_fans_info;
        _this.incrIntercat = res.data;
        if (empower_fans_info != 1) {
          _this.tipVisible = true;
        } else {
          _this.tipVisible = false;
        }
      } else {
        _this.$message.error(res.message);
      }
    });
  },
  methods: {
    goRouter: function goRouter() {
      this.tipVisible = false;
      this.$router.push({
        path: "/index"
      });
      // this.tipVisible =false
    }
  }
};
exports.default = _default;