var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        padding: "36px 28px",
        background: "#f5f7fa",
        height: "1420px",
      },
    },
    [
      _c(
        "el-row",
        {
          staticClass: "mar-b20",
          staticStyle: {
            height: "124px",
            background: "#fff",
            position: "relative",
          },
        },
        [
          _vm.currentDetailData
            ? _c(
                "div",
                { staticClass: "div-container flex-start" },
                [
                  _c("img", {
                    staticStyle: { margin: "0 12px 0 28px" },
                    attrs: {
                      src: _vm.currentDetailData.avatar || _vm.defultImg,
                      width: "90px",
                      height: "90px",
                      alt: "",
                    },
                  }),
                  _c("div", [
                    _c("div", { staticClass: "f16 fb c-black" }, [
                      _vm._v(_vm._s(_vm.currentDetailData.title)),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0" } },
                      [
                        _c("span", { staticClass: "c-blue f12" }, [
                          _vm._v(_vm._s(_vm.currentDetailData.nickname)),
                        ]),
                        _c("svg-icon", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            color: "#4A58F3",
                            "margin-left": "10px",
                          },
                          attrs: { "icon-class": "checkmark" },
                        }),
                        _c("span", { staticClass: "c-8F94A2 f12" }, [
                          _vm._v(
                            "已认证，" +
                              _vm._s(
                                _vm.currentDetailData.verity_type == 1
                                  ? "门店"
                                  : "品牌"
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "c-8F94A2 f12" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.currentDetailData.publish_time)),
                      ]),
                      _c("span", { staticStyle: { "margin-left": "12px" } }, [
                        _vm._v(
                          "直播时长 " +
                            _vm._s(
                              _vm.getHours(
                                _vm.currentDetailData.publish_time,
                                _vm.currentDetailData.finish_time
                              )
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.currentDetailData.status == 4
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mini-btn",
                          staticStyle: { background: "#d8d8d8", color: "#fff" },
                          attrs: { size: "mini" },
                        },
                        [_vm._v("监控完成")]
                      )
                    : _vm._e(),
                  _vm.currentDetailData.status == 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mini-btn",
                          staticStyle: {
                            background:
                              "linear-gradient(180deg, #27c77d 0%, #27b388 100%)",
                            color: "#fff",
                          },
                          attrs: { size: "mini" },
                        },
                        [_vm._v("监控中")]
                      )
                    : _vm._e(),
                  _vm.currentDetailData.status == 3
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mini-btn",
                          staticStyle: { background: "#e74f3e", color: "#fff" },
                          attrs: { size: "mini" },
                        },
                        [_vm._v("待监控")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        background: "#4A58F3",
                        color: "#fff",
                        position: "absolute",
                        right: "52px",
                        top: "68px",
                        border: "none",
                      },
                      on: { click: _vm.openComment },
                    },
                    [_vm._v("查直播评论")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-row",
        {
          staticClass: "mar-b20",
          staticStyle: {
            height: "550px",
            "margin-left": "0",
            "margin-right": "0",
          },
          attrs: { gutter: 40 },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                height: "550px",
                background: "#fff",
                padding: "40px 47px",
              },
              attrs: { span: 11 },
            },
            [
              _vm.detailInfo
                ? _c("div", { staticClass: "div-container flex-col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex-start",
                        staticStyle: { height: "68px", "margin-top": "15px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-col",
                            staticStyle: { width: "232px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "blue-title",
                                staticStyle: { width: "106px" },
                              },
                              [_vm._v("累计观看数")]
                            ),
                            _c("span", { staticClass: "red-span" }, [
                              _vm._v(_vm._s(_vm.detailInfo.total_user)),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "shu" }),
                        _c("div", { staticClass: "flex-col" }, [
                          _c(
                            "span",
                            {
                              staticClass: "blue-title",
                              staticStyle: { width: "126px" },
                            },
                            [_vm._v("在线人数峰值")]
                          ),
                          _c("span", { staticClass: "red-span" }, [
                            _vm._v(
                              _vm._s(_vm.detailInfo.online_user_count_max)
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex-start",
                        staticStyle: { height: "56px", margin: "40px 0 20px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-col",
                            staticStyle: { width: "232px" },
                          },
                          [
                            _c("span", { staticClass: "f14 c-black" }, [
                              _vm._v("总互动量"),
                            ]),
                            _c("span", { staticClass: "black-span" }, [
                              _vm._v(
                                _vm._s(_vm.detailInfo.total_polling_count)
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "shu" }),
                        _c("div", { staticClass: "flex-col" }, [
                          _c("span", { staticClass: "f14 c-black" }, [
                            _vm._v("评论数"),
                          ]),
                          _c("span", { staticClass: "black-span" }, [
                            _vm._v(_vm._s(_vm.detailInfo.comment_count)),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex-start",
                        staticStyle: { height: "56px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-col",
                            staticStyle: { width: "232px" },
                          },
                          [
                            _c("span", { staticClass: "f14 c-black" }, [
                              _vm._v("送礼总值"),
                            ]),
                            _c("span", { staticClass: "black-span" }, [
                              _vm._v(_vm._s(_vm.detailInfo.total_gift_count)),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "shu" }),
                        _c("div", { staticClass: "flex-col" }, [
                          _c("span", { staticClass: "f14 c-black" }, [
                            _vm._v("送礼人数"),
                          ]),
                          _c("span", { staticClass: "black-span" }, [
                            _vm._v(_vm._s(_vm.detailInfo.gift_uv_count)),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "flex-col" }, [
                      _c("span", { staticStyle: { margin: "30px 0 10px" } }, [
                        _vm._v("粉丝变化"),
                      ]),
                      _c("div", { staticClass: "num-chart" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-col-center",
                            style: _vm.isPositive
                              ? _vm.rightTop
                              : _vm.rightBottom,
                          },
                          [
                            _c("span", { staticClass: "f14 c-black" }, [
                              _vm._v("直播结束时粉丝数"),
                            ]),
                            _c("span", { staticClass: "b-span" }, [
                              _vm._v(
                                _vm._s(_vm.detailInfo.fans_count_after_live)
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "36%",
                              left: "42%",
                            },
                          },
                          [
                            _c("svg-icon", {
                              class: _vm.isPositive ? "g-span" : "r-span",
                              staticStyle: { "font-size": "40px" },
                              attrs: {
                                "icon-class": _vm.isPositive ? "up" : "down",
                              },
                            }),
                            _c(
                              "span",
                              { class: _vm.isPositive ? "g-span" : "r-span" },
                              [
                                _vm._v(
                                  _vm._s(
                                    Math.abs(
                                      _vm.detailInfo.fans_count_after_live -
                                        _vm.detailInfo.fans_count_before_live
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-col-center",
                            style: _vm.isPositive
                              ? _vm.leftBottom
                              : _vm.leftTop,
                          },
                          [
                            _c("span", { staticClass: "f14 c-black" }, [
                              _vm._v("直播开始时粉丝数"),
                            ]),
                            _c("span", { staticClass: "b-span" }, [
                              _vm._v(
                                _vm._s(_vm.detailInfo.fans_count_before_live)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 13 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "mar-b20",
                  staticStyle: { height: "265px", background: "#fff" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "div-container",
                      staticStyle: { padding: "28px 48px" },
                    },
                    [
                      _c("span", { staticClass: "blue-title" }, [
                        _vm._v("观看人数趋势"),
                      ]),
                      _c("div", {
                        staticStyle: { width: "100%", height: "220px" },
                        attrs: { id: "line-chart1" },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "el-row",
                { staticStyle: { height: "265px", background: "#fff" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "div-container",
                      staticStyle: { padding: "28px 48px" },
                    },
                    [
                      _c("span", { staticClass: "blue-title" }, [
                        _vm._v("评论数趋势"),
                      ]),
                      _c("div", {
                        staticStyle: { width: "100%", height: "220px" },
                        attrs: { id: "line-chart2" },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "mar-b20",
          staticStyle: {
            height: "265px",
            "margin-left": "0",
            "margin-right": "0",
          },
          attrs: { gutter: 40 },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: { height: "265px", background: "#fff" },
              attrs: { span: 11 },
            },
            [
              _c("div", { staticClass: "div-container" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "265px" },
                  attrs: { id: "cloudChart" },
                }),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                height: "265px",
                padding: "27px",
                background: "#fff",
                "border-left": "20px solid rgb(245, 247, 250)",
              },
              attrs: { span: 13 },
            },
            [
              _c("div", { staticClass: "div-container flex-start" }, [
                _c("div", {
                  staticStyle: { width: "70%", height: "100%" },
                  attrs: { id: "funnelChart" },
                }),
                _vm.detailFunnel
                  ? _c(
                      "div",
                      {
                        staticClass: "data-box flex-col",
                        staticStyle: { width: "30%", height: "100%" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "f14",
                            staticStyle: {
                              width: "105px",
                              margin: "12px 0 15px",
                            },
                          },
                          [_vm._v("有效评论：")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#E34E3A",
                              "font-size": "32px",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("disposeDecimal")(
                                  (_vm.chartData.validate /
                                    _vm.chartData.total) *
                                    100
                                )
                              ) + "%"
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "f14",
                            staticStyle: {
                              width: "105px",
                              margin: "20px 0 15px",
                            },
                          },
                          [_vm._v("有效评论人数：")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#E34E3A",
                              "font-size": "32px",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("disposeDecimal")(
                                  (_vm.chartData.valuable /
                                    _vm.chartData.comment_user_count) *
                                    100
                                )
                              ) + "%"
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: {
            height: "370px",
            background: "#fff",
            "margin-left": "0",
            "margin-right": "0",
          },
          attrs: { gutter: 40 },
        },
        [
          _c("div", { staticClass: "div-container" }, [
            _c(
              "span",
              {
                staticClass: "blue-title",
                staticStyle: {
                  width: "105px",
                  margin: "28px 0 0 36px",
                  display: "inline-block",
                },
              },
              [_vm._v("车型提及：")]
            ),
            _c("div", {
              staticStyle: { width: "1200px", height: "320px" },
              attrs: { id: "barChart" },
            }),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "monitioring-dialog",
          attrs: {
            visible: _vm.commentShow,
            "append-to-body": "",
            width: "519px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.commentShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("直播评论:")]
          ),
          _c("div", { staticClass: "comment-dialog-main" }, [
            _c(
              "div",
              {
                staticClass: "infinite-list-wrapper hot-comment",
                staticStyle: { overflow: "auto" },
              },
              [
                _vm.$parent.$parent.detailCommentData
                  ? _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "infinite-scroll",
                            rawName: "v-infinite-scroll",
                            value: _vm.commentLoad,
                            expression: "commentLoad",
                          },
                        ],
                        staticClass: "list",
                        attrs: {
                          "infinite-scroll-distance": 30,
                          "infinite-scroll-disabled": "disabled",
                        },
                      },
                      _vm._l(
                        _vm.$parent.$parent.detailCommentData.list,
                        function (item, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "comment-item" },
                            [
                              _c("div", [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.user_name)),
                                ]),
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(_vm._s(item.publish_time)),
                                ]),
                              ]),
                              _c("span", { staticClass: "content" }, [
                                _vm._v(_vm._s(item.content)),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.$parent.$parent.commentLoading
                  ? _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("加载中..."),
                    ])
                  : _vm._e(),
                _vm.$parent.$parent.commentNoMore
                  ? _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("没有更多了"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }