var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cloud-video-download" },
    [
      _c(
        "a-card",
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading, tip: "打包中..." } },
            [
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  "下载时请勿刷新页面，非打包下载请允许浏览器同时下载多个文件"
                ),
              ]),
              _c("a-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.downloadList,
                  pagination: false,
                  "row-key": function (r, i) {
                    return "" + i
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "status",
                    fn: function (text, record) {
                      return _c(
                        "div",
                        {},
                        [
                          record.status === "待下载"
                            ? _c("a-tag", [_vm._v(_vm._s(record.status))])
                            : record.status === "下载完成"
                            ? _c("a-tag", { attrs: { color: "green" } }, [
                                _vm._v(_vm._s(record.status)),
                              ])
                            : _c("a-tag", { attrs: { color: "blue" } }, [
                                _vm._v(_vm._s(record.status)),
                              ]),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }