var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        title: "温馨提示",
        closable: false,
        maskClosable: false,
      },
    },
    [
      _c("p", [
        _vm._v(
          "检测到未设置门店地址，当用户咨询到店时，将无法回复，请前往APP设置地址"
        ),
      ]),
      _c("p", [_vm._v("设置路径：APP-我的-设置-设置地址")]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "center",
            },
          },
          [
            _c(
              "a-button",
              {
                staticStyle: { width: "50%" },
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v(" 我知道了 ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }