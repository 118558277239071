"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var vuex = _interopRequireWildcard(require("vuex"));
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("intelligence_play"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  name: "short_comment_login",
  data: function data() {
    return {
      hzOssHost: process.env.VUE_APP_HZ_OSS_HOST,
      userId: (0, _auth.getUserId)("userId")
    };
  },
  components: {},
  computed: _objectSpread(_objectSpread({}, (0, vuex.mapGetters)(['nickName', 'avatar'])), mapState({
    userListData: function userListData(state) {
      return state.userListData;
    } // 直播账号列表
  })),
  created: function created() {
    this.loadUserList({
      user_id: this.userId
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(["loadUserList"])), mapMutations(["changeState"])),
  mounted: function mounted() {
    var all = document.querySelectorAll('*');
    var el;
    for (var i = 0; i < all.length; i++) {
      if (all[i].__vue__) {
        el = all[i];
        break;
      }
    }
    console.log(Object.getPrototypeOf(el));
    if (el) {
      var Vue = Object.getPrototypeOf(el.__vue__).constructor;
      console.log(Vue);
      while (Vue.super) {
        Vue = Vue.super;
      }
      window.postMessage({
        devtoolsEnabled: Vue.config.devtools,
        vueDetected: true
      }, '*');
    }
  }
};
exports.default = _default;