"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var vuex = _interopRequireWildcard(require("vuex"));
var _AddTracking = _interopRequireDefault(require("./AddTracking"));
var _mapState;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _vuex$createNamespace = vuex.createNamespacedHelpers("intelligence_play"),
  mapState = _vuex$createNamespace.mapState,
  mapActions = _vuex$createNamespace.mapActions,
  mapMutations = _vuex$createNamespace.mapMutations;
var _default = {
  data: function data() {
    return {
      currentRow: {},
      currentTab: 1,
      tabData: [{
        name: "价值互动",
        type: 1
      }, {
        name: "全部互动",
        type: 2
      }]
    };
  },
  components: {
    addTracking: _AddTracking.default
  },
  computed: _objectSpread({}, mapState((_mapState = {
    loadingV: function loadingV(state) {
      return state.loadingV;
    },
    // 价值互动加载
    noMoreV: function noMoreV(state) {
      return state.noMoreV;
    },
    // 价值互动没有更多
    pollingInfoData: function pollingInfoData(state) {
      return state.pollingInfoData;
    }
  }, (0, _defineProperty2.default)(_mapState, "pollingInfoData", function pollingInfoData(state) {
    return state.pollingInfoData;
  }), (0, _defineProperty2.default)(_mapState, "intentionData", function intentionData(state) {
    return state.intentionData;
  }), (0, _defineProperty2.default)(_mapState, "pollingAllData", function pollingAllData(state) {
    return state.pollingAllData;
  }), (0, _defineProperty2.default)(_mapState, "pollingVData", function pollingVData(state) {
    return state.pollingVData;
  }), (0, _defineProperty2.default)(_mapState, "loadingAll", function loadingAll(state) {
    return state.loadingAll;
  }), (0, _defineProperty2.default)(_mapState, "noMoreAll", function noMoreAll(state) {
    return state.noMoreAll;
  }), _mapState))),
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["loadAuthorInfo", "loadPollingInfo", "loadPollingAll", "loadPollingV", "loadIntention"])), mapMutations(["changeState"])), {}, {
    // 当前消息人选中
    handleCurrentChange: function handleCurrentChange(val) {
      console.log(123, val);
      this.currentRow = val;
      if (!this.currentRow) {
        return false;
      }
      if (!this.currentRow.id) {
        this.changeState({
          data: null,
          name: "pollingInfoData"
        });
        this.changeState({
          data: null,
          name: "pollingVData"
        });
        this.changeState({
          data: null,
          name: "pollingAllData"
        });
        this.changeState({
          data: null,
          name: "intentionData"
        });
        // this.$message.warning("当前用户没有获取到ID");
        return false;
      }
      // 查信息
      this.loadPollingInfo({
        type: 1,
        fans_id: this.currentRow.id,
        author_id: this.currentRow.author_id
      });
      // 查意向
      this.loadIntention({
        type: 1,
        fans_id: this.currentRow.id,
        author_id: this.currentRow.author_id
      });
      this.loadInteractionV(true);
      this.loadInteractionAll(true);
    },
    // 加载 价值互动
    loadInteractionV: function loadInteractionV(isFirst) {
      if (!this.currentRow.id) {
        this.$message.warning("未获取到fans_id!");
        return false;
      }
      this.loadPollingV({
        type: 1,
        fans_id: this.currentRow.id,
        author_id: this.currentRow.author_id,
        current: isFirst ? 1 : this.currentNoV,
        page_size: this.pageSize,
        comment_validate: 1
      });
    },
    // 加载全部互动
    loadInteractionAll: function loadInteractionAll(isFirst) {
      if (!this.currentRow.id) {
        // this.$message.warning('未获取到fans_id!')
        return false;
      }
      this.loadPollingAll({
        type: this.type == "douyin" ? 1 : 2,
        fans_id: this.currentRow.id,
        author_id: this.currentRow.author_id,
        current: isFirst ? 1 : this.currentNoAll,
        page_size: this.pageSize,
        comment_validate: 0
      });
    },
    // 处理时间差
    editTime: function editTime(day) {
      if (day) {
        if (this.moment().diff(day, "days") < 1) {
          return "不久前来过";
        } else {
          return "".concat(this.moment().diff(day, "days"), "\u5929\u524D\u6765\u8FC7");
        }
      } else {
        return "\u672A\u77E5\u8BBF\u95EE\u65F6\u95F4";
      }
    },
    // 处理是否为今天
    isToday: function isToday(day) {
      // console.log(moment())
      // console.log(day)
      // console.log(moment().isSame(day, "d"))
      return this.moment().isSame(day, "d");
    },
    // 返回顶部
    scrollTop: function scrollTop() {
      document.body.querySelector("#interaction1").scrollTo({
        top: 0,
        behavior: "smooth"
      });
      document.body.querySelector("#interaction2").scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 打开加入跟踪弹窗
    openAddModal: function openAddModal() {
      this.$refs.addTracking.openModal();
    }
  })
};
exports.default = _default;