var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intelligence-excavate" }, [
    _c("div", { staticClass: "intelligence-excavate-container" }, [
      _c(
        "div",
        { staticClass: "excavate-left" },
        [
          _vm.authorInfoData && _vm.authorInfoData[_vm.type + "list"].length > 0
            ? _c(
                "div",
                { staticClass: "excavate-left-info" },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.authorInfoData[_vm.type + "list"][0].avatar,
                      alt: "",
                    },
                  }),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "26px",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.authorInfoData[_vm.type + "list"][0].nick_name
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "平台号： " +
                          _vm._s(_vm.authorInfoData[_vm.type + "list"][0].code)
                      ),
                    ]),
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { path: "/retention_records_login" } } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary" },
                        },
                        [_vm._v("切换账号")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "search-div" },
            [
              _c(
                "div",
                { staticClass: "margin-right margin-bottom" },
                [
                  _c("span", [_vm._v("留资日期: ")]),
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptions2,
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.searchForm.contactTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "contactTime", $$v)
                      },
                      expression: "searchForm.contactTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "margin-right margin-bottom" },
                [
                  _c("span", [_vm._v("回访批次: ")]),
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.searchForm.visitTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "visitTime", $$v)
                      },
                      expression: "searchForm.visitTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "margin-right margin-bottom",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.getTableList(true)
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "margin-bottom",
                  attrs: { type: "success", icon: "el-icon-download" },
                  on: { click: _vm.exportClick },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTable,
                  expression: "loadingTable",
                },
              ],
              ref: "singleTable",
              staticStyle: { width: "100%", margin: "10px 0 10px" },
              attrs: {
                data: _vm.customerList ? _vm.customerList.list : [],
                border: "",
                "highlight-current-row": "",
                height: "600",
              },
              on: { "current-change": _vm.handleCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "留资日期", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.ctime.slice(0, 10)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "fans_id", label: "平台号", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fans_nickname",
                  label: "昵称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "contact", label: "联系方式", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "series_id",
                  label: "意向车型",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "省市", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "visit_batch",
                  label: "回访批次",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.searchForm.currentPage,
                  "page-size": _vm.searchForm.pageSize,
                  layout: "prev, pager, next, jumper",
                  total: _vm.searchForm.total || 0,
                },
                on: {
                  "current-change": _vm.pageChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.searchForm, "currentPage", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.searchForm, "currentPage", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.customerList &&
      _vm.customerList.list &&
      _vm.customerList.list.length > 0
        ? _c(
            "div",
            { staticClass: "excavate-right" },
            [
              _c("div", { staticClass: "excavate-right-title" }, [
                _vm._v("客户详情："),
              ]),
              _vm.pollingInfoData && _vm.currentRow
                ? _c("div", { staticClass: "excavate-right-info" }, [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.pollingInfoData.avatar ||
                          require("@/assets/dev/defult-avatar.png"),
                        alt: "",
                      },
                    }),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "font-weight": "bold",
                            width: "210px",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "white-space": "nowrap",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.pollingInfoData.nickname) + " "
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { "font-size": "16px" } }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "18px" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.currentRow.gender == 0
                                  ? "未知"
                                  : _vm.currentRow.gender == 1
                                  ? "男"
                                  : "女"
                              )
                            ),
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.currentRow.area))]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.pollingInfoData
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "16px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: {
                            "background-image":
                              "linear-gradient(179deg,#64a9f3 0%,#516ef3 100%)",
                            margin: "0px 10px 5px 0",
                          },
                          attrs: { size: "mini" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editTime(
                                _vm.pollingInfoData.last_live_enter_time
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: {
                            "background-image":
                              "linear-gradient(180deg,#fbbc6c 0%,#fc9965 100%)",
                            margin: "0px 10px 5px 0",
                          },
                          attrs: { size: "mini" },
                        },
                        [
                          _vm._v(
                            "本月捧场 " +
                              _vm._s(_vm.pollingInfoData.enter_count30) +
                              "次"
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: {
                            "background-image":
                              "linear-gradient(180deg,#27c77d 0%,#27b388 100%)",
                            margin: "0px 10px 5px 0",
                          },
                          attrs: { size: "mini" },
                        },
                        [
                          _vm._v(
                            "累计送 " + _vm._s(_vm.pollingInfoData.gitf_count)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.intentionData &&
              _vm.intentionData.series &&
              _vm.intentionData.series.length > 0
                ? _c(
                    "div",
                    { staticClass: "series" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("意向：")]),
                      _c(
                        "el-carousel",
                        {
                          attrs: {
                            height: "100px",
                            "indicator-position": "none",
                          },
                        },
                        _vm._l(
                          _vm.intentionData.series,
                          function (item, index) {
                            return _c("el-carousel-item", { key: index }, [
                              _c("div", { staticClass: "series-item" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.imgHref + "/" + item.image,
                                    alt: "",
                                    width: "100",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(item.series_name) +
                                      "（" +
                                      _vm._s(item.count) +
                                      "）"
                                  ),
                                ]),
                              ]),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tabs" },
                _vm._l(_vm.tabData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        _vm.currentTab == item.type
                          ? "tab-item active"
                          : "tab-item",
                      on: {
                        click: function ($event) {
                          _vm.currentTab = item.type
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab == 1,
                      expression: "currentTab == 1",
                    },
                  ],
                  staticClass: "interaction",
                  attrs: { id: "interaction1" },
                },
                [
                  _vm.pollingVData
                    ? _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.loadInteractionV,
                              expression: "loadInteractionV",
                            },
                          ],
                          staticClass: "list",
                          attrs: {
                            id: "list1",
                            "infinite-scroll-distance": 30,
                            "infinite-scroll-disabled": "disabledV",
                          },
                        },
                        _vm._l(_vm.pollingVData.list, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "interaction-item" },
                            [
                              item.content && item.content != ""
                                ? _c(
                                    "div",
                                    { staticStyle: { height: "35px" } },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "clock" },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(item.publish_time)),
                                      ]),
                                      _vm.isToday(item.publish_time)
                                        ? _c("span", { staticClass: "tag1" }, [
                                            _vm._v("今天"),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.content == "" || !item.content
                                        ? "送了礼物!"
                                        : item.content
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.loadingV
                    ? _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("加载中..."),
                      ])
                    : _vm._e(),
                  _vm.noMoreV
                    ? _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("没有更多了"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab == 2,
                      expression: "currentTab == 2",
                    },
                  ],
                  staticClass: "interaction",
                  attrs: { id: "interaction2" },
                },
                [
                  _vm.pollingAllData
                    ? _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.loadInteractionAll,
                              expression: "loadInteractionAll",
                            },
                          ],
                          staticClass: "list",
                          attrs: {
                            id: "list2",
                            "infinite-scroll-distance": 30,
                            "infinite-scroll-disabled": "disabledAll",
                          },
                        },
                        _vm._l(_vm.pollingAllData.list, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "interaction-item" },
                            [
                              item.content && item.content != ""
                                ? _c(
                                    "div",
                                    { staticStyle: { height: "35px" } },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "clock" },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(item.publish_time)),
                                      ]),
                                      _vm.isToday(item.publish_time)
                                        ? _c("span", { staticClass: "tag1" }, [
                                            _vm._v("今天"),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.content == "" || !item.content
                                        ? "送了礼物!"
                                        : item.content
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.loadingAll
                    ? _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("加载中..."),
                      ])
                    : _vm._e(),
                  _vm.noMoreAll
                    ? _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("没有更多了"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("el-button", {
                staticStyle: { margin: "10px 0px 10px 320px" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-caret-top",
                  circle: "",
                },
                on: { click: _vm.scrollTop },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }