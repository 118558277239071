"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "BasePagination",
  data: function data() {
    return {
      current: 0,
      size: 0
    };
  },
  props: {
    currentPage: {
      require: true,
      type: Number
    },
    pageSize: {
      require: true,
      type: Number
    },
    total: {
      require: true,
      type: Number
    },
    options: {
      type: Array,
      default: function _default() {
        return ['5', '10', '15'];
      }
    }
  },
  watch: {
    currentPage: {
      handler: function handler(val) {
        this.current = val;
      },
      immediate: true,
      deep: true
    },
    pageSize: {
      handler: function handler(val) {
        this.size = val;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 分页事件-修改当前页码
    bindCurrentChange: function bindCurrentChange(current, pageSize) {
      this.$emit('onChange', current, pageSize);
    },
    // 分页事件-修改当前页数
    bindSizeChange: function bindSizeChange(current, pageSize) {
      this.$emit('onShowSizeChange', 1, pageSize);
    }
  },
  mounted: function mounted() {}
};
exports.default = _default2;