"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _slicedToArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var dayHour = 24; // 一天24h
var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
var createArr = function createArr(len) {
  return Array.from(Array(len)).map(function (ret, id) {
    return id;
  });
};
var formatWeekTime = function formatWeekTime(col) {
  var timestamp = 1542384000000; // '2018-11-17 00:00:00'
  var beginStamp = timestamp + col * 1800000; // col * 30 * 60 * 1000
  var endStamp = beginStamp + 1800000;
  var begin = (0, _moment.default)(beginStamp).format('HH:mm');
  var end = (0, _moment.default)(endStamp).format('HH:mm');
  end = end === '00:00' ? '24:00' : (0, _moment.default)(endStamp).format('HH:mm');
  return "".concat(begin, "~").concat(end);
};
function splicing(list) {
  var same;
  var i = -1;
  var len = list.length;
  var arr = [];
  if (!len) return;
  while (++i < len) {
    var item = list[i];
    if (item.check) {
      if (item.check !== Boolean(same)) {
        arr.push.apply(arr, ['、', item.begin, '~', item.end]);
      } else if (arr.length) {
        arr.pop();
        arr.push(item.end);
      }
    }
    same = Boolean(item.check);
  }
  arr.shift();
  return arr.join('');
}
var _default2 = {
  name: 'DragWeekTime',
  props: {
    value: {
      type: String
    },
    colspan: {
      type: Number,
      default: function _default() {
        return 2;
      }
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    styleValue: function styleValue() {
      return {
        width: "".concat(this.width, "px"),
        height: "".concat(this.height, "px"),
        left: "".concat(this.left, "px"),
        top: "".concat(this.top, "px")
      };
    },
    data: function data() {
      var _this = this;
      var idx = 0;
      return weekArr.map(function (ret, index) {
        var children = function children(ret, row, max) {
          return createArr(max).map(function (t, col) {
            var n = _this.value.substr(idx, 1);
            var check = Boolean(parseInt(n));
            idx++;
            return {
              check: check,
              week: ret,
              value: formatWeekTime(col),
              begin: formatWeekTime(col).split('~')[0],
              end: formatWeekTime(col).split('~')[1],
              row: row,
              col: col
            };
          });
        };
        return {
          value: ret,
          row: index,
          child: children(ret, index, dayHour * _this.colspan)
        };
      });
    },
    selectValue: function selectValue() {
      return this.data.map(function (item) {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child)
        };
      });
    },
    selectState: function selectState() {
      return this.selectValue.some(function (ret) {
        return ret.value;
      });
    },
    selectClasses: function selectClasses() {
      return function (n) {
        return n.check ? 'ui-selected' : '';
      };
    }
  },
  methods: {
    cellEnter: function cellEnter(item) {
      var ele = this.$el.querySelector("td[data-week='".concat(item.row, "'][data-time='").concat(item.col, "']"));
      if (ele && !this.mode) {
        this.left = ele.offsetLeft;
        this.top = ele.offsetTop;
      } else {
        if (item.col <= this.col && item.row <= this.row) {
          this.width = (this.col - item.col + 1) * ele.offsetWidth;
          this.height = (this.row - item.row + 1) * ele.offsetHeight;
          this.left = ele.offsetLeft;
          this.top = ele.offsetTop;
        } else if (item.col >= this.col && item.row >= this.row) {
          this.width = (item.col - this.col + 1) * ele.offsetWidth;
          this.height = (item.row - this.row + 1) * ele.offsetHeight;
          if (item.col > this.col && item.row === this.row) this.top = ele.offsetTop;
          if (item.col === this.col && item.row > this.row) this.left = ele.offsetLeft;
        } else if (item.col > this.col && item.row < this.row) {
          this.width = (item.col - this.col + 1) * ele.offsetWidth;
          this.height = (this.row - item.row + 1) * ele.offsetHeight;
          this.top = ele.offsetTop;
        } else if (item.col < this.col && item.row > this.row) {
          this.width = (this.col - item.col + 1) * ele.offsetWidth;
          this.height = (item.row - this.row + 1) * ele.offsetHeight;
          this.left = ele.offsetLeft;
        }
      }
    },
    cellDown: function cellDown(item) {
      var ele = this.$el.querySelector("td[data-week='".concat(item.row, "'][data-time='").concat(item.col, "']"));
      this.check = Boolean(item.check);
      this.mode = 1;
      if (ele) {
        this.width = ele.offsetWidth;
        this.height = ele.offsetHeight;
      }
      this.row = item.row;
      this.col = item.col;
    },
    cellUp: function cellUp(item) {
      if (item.col <= this.col && item.row <= this.row) {
        this.selectWeek([item.row, this.row], [item.col, this.col], !this.check);
      } else if (item.col >= this.col && item.row >= this.row) {
        this.selectWeek([this.row, item.row], [this.col, item.col], !this.check);
      } else if (item.col > this.col && item.row < this.row) {
        this.selectWeek([item.row, this.row], [this.col, item.col], !this.check);
      } else if (item.col < this.col && item.row > this.row) {
        this.selectWeek([this.row, item.row], [item.col, this.col], !this.check);
      }
      this.width = 0;
      this.height = 0;
      this.mode = 0;
    },
    selectWeek: function selectWeek(row, col, check) {
      var _this2 = this;
      var _row = (0, _slicedToArray2.default)(row, 2),
        minRow = _row[0],
        maxRow = _row[1];
      var _col = (0, _slicedToArray2.default)(col, 2),
        minCol = _col[0],
        maxCol = _col[1];
      this.data.forEach(function (item) {
        item.child.forEach(function (t) {
          if (t.row >= minRow && t.row <= maxRow && t.col >= minCol && t.col <= maxCol) {
            _this2.$set(t, 'check', check);
          }
        });
      });
      var data = this.data.map(function (item) {
        return item.child.map(function (ret) {
          return ret.check ? '1' : '0';
        }).join('');
      }).join('');
      this.$emit('input', data);
    },
    clearWeektime: function clearWeektime() {
      this.$emit('input', createArr(48 * 7).map(function () {
        return '0';
      }).join(''));
    }
  },
  data: function data() {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      mode: 0,
      row: 0,
      col: 0,
      theadArr: createArr(dayHour)
    };
  }
};
exports.default = _default2;