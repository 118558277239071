var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
            "label-position": "left",
          },
        },
        [
          _vm.isLogin
            ? [
                _c("div", { staticClass: "title-container" }, [
                  _c("h3", { staticClass: "title" }, [_vm._v("登录中")]),
                ]),
                _c(
                  "div",
                  { staticClass: "loading-box" },
                  [
                    _c("a-icon", {
                      staticClass: "loading",
                      attrs: { type: "loading" },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "title-container" }, [
                  _vm.isFeishu
                    ? _c("div", [
                        _c("h3", { staticClass: "title" }, [_vm._v("绑定")]),
                        _c("p", {}, [
                          _vm._v("飞书登录成功 请登录风火轮账号完成绑定"),
                        ]),
                      ])
                    : _c("h3", { staticClass: "title" }, [
                        _vm._v("欢 迎 登 录"),
                      ]),
                ]),
                _vm.phoneOrPassWord
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "username" } },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                          1
                        ),
                        _c("el-input", {
                          ref: "username",
                          attrs: {
                            placeholder: "输入用户名",
                            name: "username",
                            type: "text",
                            tabindex: "1",
                            "auto-complete": "on",
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.phoneOrPassWord
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "password" },
                            }),
                          ],
                          1
                        ),
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "输入密码",
                            name: "password",
                            tabindex: "2",
                            "auto-complete": "on",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showPwd },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class":
                                  _vm.passwordType === "password"
                                    ? "eye"
                                    : "eye-open",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.phoneOrPassWord
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "phone" } },
                      [
                        _c("span", { staticClass: "svg-container" }, [
                          _c("i", { staticClass: "el-icon-phone" }),
                        ]),
                        _c("el-input", {
                          ref: "phone",
                          attrs: {
                            placeholder: "请输入手机号码",
                            name: "phone",
                            tabindex: "1",
                            "auto-complete": "on",
                          },
                          model: {
                            value: _vm.loginForm.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "phone", $$v)
                            },
                            expression: "loginForm.phone",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.phoneOrPassWord
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "authCode" } },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "password" },
                            }),
                          ],
                          1
                        ),
                        _c("el-input", {
                          ref: "authCode",
                          attrs: {
                            placeholder: "请输入短信验证",
                            name: "authCode",
                            tabindex: "1",
                          },
                          model: {
                            value: _vm.loginForm.authCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "authCode", $$v)
                            },
                            expression: "loginForm.authCode",
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "show-pwd" },
                          [
                            _vm.timer_long > 0 && _vm.verify_loading
                              ? _c(
                                  "span",
                                  { attrs: { slot: "append" }, slot: "append" },
                                  [_vm._v(_vm._s(_vm.timer_long) + "秒后重发")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "append", type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getPhoneCode($event)
                                      },
                                    },
                                    slot: "append",
                                  },
                                  [_vm._v("获取验证码")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "100%",
                      "margin-bottom": "16px",
                      "font-weight": "600",
                      height: "44px",
                      "font-size": "16px",
                      color: "#36348e",
                    },
                    attrs: {
                      id: "test-login",
                      loading: _vm.loading,
                      type: "default",
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [_vm._v("登 录")]
                ),
                _c(
                  "div",
                  { staticClass: "check-login" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: { click: _vm.changeLoginType },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.phoneOrPassWord ? "短信登录" : "密码登录")
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }