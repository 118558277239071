"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "pie-chart"
    },
    id: {
      type: String,
      default: "pie-chart"
    },
    width: {
      type: String,
      default: "400px"
    },
    height: {
      type: String,
      default: "400px"
    },
    pieData: {
      type: Object,
      default: {}
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    // this.initChart();
  },
  watch: {
    pieData: function pieData() {
      this.initChart();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        color: ['#B7BCFA', '#FC9F66'],
        legend: {
          data: this.pieData.gender.desc,
          // orient: 'vertical',
          bottom: 20
        },
        title: {
          show: this.showTitle,
          text: "性别分布"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: ({d}%)"
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "20%",
          containLabel: false
        },
        series: [{
          name: "性别分布",
          type: "pie",
          radius: ["0%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              position: "outside",
              alignTo: "none",
              bleedMargin: 1
              // formatter: '{b} {d}% '
            }
          },

          emphasis: {
            label: {
              show: true,
              fontSize: "12",
              fontWeight: "bold"
            }
          },
          itemStyle: {
            borderWidth: 10,
            borderColor: '#fff'
          },
          data: [{
            value: this.pieData.gender.percentage[0],
            name: this.pieData.gender.desc[0]
          }, {
            value: this.pieData.gender.percentage[1],
            name: this.pieData.gender.desc[1]
          }]
        }]
      });
    }
  }
};
exports.default = _default;