"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 查询价值互动
  getPollingLeft: function getPollingLeft(_ref) {
    var fans_id = _ref.fans_id,
      author_id = _ref.author_id,
      current = _ref.current,
      page_size = _ref.page_size;
    return request({
      url: "/mirror-data/douyin/live-polling/fans/polling/v",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        fans_id: fans_id,
        author_id: author_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 左侧短视频列表
  getVideoList: function getVideoList(_ref2) {
    var item_id = _ref2.item_id,
      author_id = _ref2.author_id,
      current = _ref2.current,
      page_size = _ref2.page_size;
    return request({
      url: "/comment/list",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        item_id: item_id,
        author_id: author_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 全部评论 and 价值评论 （父级）
  getCommentList: function getCommentList(_ref3) {
    var item_id = _ref3.item_id,
      comment_validate = _ref3.comment_validate,
      days = _ref3.days,
      current = _ref3.current,
      page_size = _ref3.page_size;
    return request({
      url: "/comment/detail",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        item_id: item_id,
        comment_validate: comment_validate,
        days: days,
        current: current,
        page_size: page_size
      }
    });
  },
  // 点击父级comment 查询 回复列表
  getReplyList: function getReplyList(_ref4) {
    var item_id = _ref4.item_id,
      parent_comment_id = _ref4.parent_comment_id,
      author_id = _ref4.author_id,
      current = _ref4.current,
      page_size = _ref4.page_size;
    return request({
      url: "/comment/detail/comment-reply",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        item_id: item_id,
        parent_comment_id: parent_comment_id,
        author_id: author_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 此用户其他评论
  getOtherList: function getOtherList(_ref5) {
    var item_id = _ref5.item_id,
      comment_id = _ref5.comment_id,
      comment_user_id = _ref5.comment_user_id,
      current = _ref5.current,
      page_size = _ref5.page_size;
    return request({
      url: "/comment/detail/comment-other",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        item_id: item_id,
        comment_id: comment_id,
        comment_user_id: comment_user_id,
        current: current,
        page_size: page_size
      }
    });
  },
  // 回复
  replyMessage: function replyMessage(_ref6) {
    var item_id = _ref6.item_id,
      comment_id = _ref6.comment_id,
      content = _ref6.content,
      author_id = _ref6.author_id,
      open_id = _ref6.open_id;
    return request({
      url: "/comment/detail/reply",
      method: 'POST',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      data: {
        item_id: item_id,
        comment_id: comment_id,
        content: content,
        author_id: author_id,
        open_id: open_id
      }
    });
  }
};
exports.default = _default;