"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  title: {
    text: '地域分布',
    subtext: '',
    x: 'left',
    backgroundColor: '#FFFFFF'
  },
  tooltip: {
    trigger: 'item'
  },
  // 左侧小导航图标
  visualMap: {
    show: true,
    x: 'right',
    y: 'center',
    splitList: [{
      start: 500,
      end: 600
    }, {
      start: 400,
      end: 500
    }, {
      start: 300,
      end: 400
    }, {
      start: 200,
      end: 300
    }, {
      start: 100,
      end: 200
    }, {
      start: 0,
      end: 100
    }],
    color: ['#5475f5', '#9feaa5', '#85daef', '#74e2ca', '#e6ac53', '#9fb5ea'],
    formatter: function formatter(value) {
      // 标签的格式化工具。
      return value; // 范围标签显示内容。
    }
  },

  // 配置属性
  series: [{
    name: '数据',
    type: 'map',
    mapType: 'china',
    roam: true,
    label: {
      normal: {
        show: false // 省份名称
      },

      emphasis: {
        show: false
      }
    },
    data: [] // 数据
  }]
};
exports.default = _default;