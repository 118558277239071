var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormDrawer",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        loading: _vm.saving,
        width: "620",
        maskClosable: false,
        submitText: "保存",
      },
      on: { close: _vm.closeDrawer, submit: _vm.save },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "关键词组名", prop: "group_name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "最多20个字",
                      "max-length": 20,
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.group_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "group_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.group_name",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.form.details_list, function (word, index) {
                return _c(
                  "a-form-model-item",
                  {
                    key: index,
                    ref: "keyword" + index,
                    refInFor: true,
                    staticClass: "keyword-model-item",
                    attrs: {
                      label: index === 0 ? "关键词" : " ",
                      colon: index === 0,
                      required: false,
                      prop: "details_list." + index + ".details_name",
                      rules: {
                        required: true,
                        message: "请输入关键词",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "word-item" },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "140px" },
                            model: {
                              value: word.match_type,
                              callback: function ($$v) {
                                _vm.$set(word, "match_type", $$v)
                              },
                              expression: "word.match_type",
                            },
                          },
                          _vm._l(_vm.conditionList, function (item, cIndex) {
                            return _c(
                              "a-select-option",
                              { key: cIndex, attrs: { value: item.value } },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                          1
                        ),
                        _c("a-input", {
                          staticClass: "keyword-input",
                          attrs: {
                            placeholder: "最多50个字，多个关键词 “,” 隔开",
                            "max-length": 50,
                            "allow-clear": "",
                          },
                          on: {
                            blur: function ($event) {
                              _vm.$refs["keyword" + index][0].onFieldBlur()
                            },
                          },
                          model: {
                            value: word.details_name,
                            callback: function ($$v) {
                              _vm.$set(
                                word,
                                "details_name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "word.details_name",
                          },
                        }),
                        _vm.form.details_list.length > 1
                          ? _c("a-icon", {
                              staticClass: "icon delete",
                              attrs: { type: "minus-circle" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeKeyword(index)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("a-icon", {
                          staticClass: "icon add",
                          attrs: { type: "plus-circle" },
                          on: {
                            click: function ($event) {
                              return _vm.addKeyword(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.details_list.length > 1 &&
                    index !== _vm.form.details_list.length - 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              height: "24px",
                              "line-height": "24px",
                            },
                          },
                          [_vm._v(" 或 ")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm.form.listTypeName
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "类型", prop: "listTypeName" } },
                    [_vm._v(" " + _vm._s(_vm.form.listTypeName) + " ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }