"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _Radar = _interopRequireDefault(require("@/components/Charts/Radar"));
var _BarChart = _interopRequireDefault(require("@/components/Charts/BarChart"));
var _LineChart = _interopRequireDefault(require("@/components/Charts/LineChart"));
var _AreaChart = _interopRequireDefault(require("@/components/Charts/AreaChart"));
var _BubbleChart = _interopRequireDefault(require("@/components/Charts/BubbleChart"));
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)("dashboard"),
  mapState = _createNamespacedHelp.mapState,
  mapMutations = _createNamespacedHelp.mapMutations,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  data: function data() {
    return {};
  },
  components: {
    Radar: _Radar.default,
    BarChart: _BarChart.default,
    LineChart: _LineChart.default,
    AreaChat: _AreaChart.default,
    BubbleChat: _BubbleChart.default
  },
  name: "Dashboard",
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["name"])), mapState({
    dashboardState: function dashboardState(state) {
      return state;
    },
    provinceTop10: function provinceTop10(state) {
      return _lodash.default.get(state, "dealerArea.data", []).map(function (val) {
        return {
          name: val.name,
          count: val.value[2]
        };
      }).sort(function (a, b) {
        return b.count - a.count;
      }).filter(function (val, idx) {
        return idx <= 9;
      });
    },
    tobeImporve: function tobeImporve(state) {
      return _lodash.default.get(state, "interationSeries.series", []).filter(function (val) {
        return !!val.lt_avg;
      });
    },
    topics: function topics(state) {
      return _lodash.default.get(state, "topics", []);
    },
    anchorRecommend: function anchorRecommend(state) {
      return _lodash.default.get(state, "anchorRecommend", []);
    },
    enemies: function enemies(state) {
      return _lodash.default.get(state, "enemies", {});
    },
    selectEnemy: function selectEnemy(state) {
      return state.selectEnemy;
    },
    enemiesInfo: function enemiesInfo(state) {
      return _lodash.default.get(state, "enemiesInfo", {});
    }
  })),
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations(["changeSelectEnemy"])), mapActions(["initDashboard", "handleClickInterval", "getEnemySeries"])), {}, {
    handleDealerAweme: function handleDealerAweme(e) {
      console.log(e.currentTarget.getAttribute("data-type"));
      var interval = e.currentTarget.getAttribute("data-type") || "week";
      if (_lodash.default.get(this.dashboardState, "dealerAweme.interval", "week") !== interval) {
        this.handleClickInterval({
          interval: interval
        });
      }
    },
    handleCheckAnchorDetail: function handleCheckAnchorDetail(idx, row) {
      var author_id = row.author_id;
      this.$router.push("/anchor/".concat(author_id));
    },
    handleClickVideo: function handleClickVideo(e) {
      console.log("e", e);
      var id = e.currentTarget.getAttribute("data-id");
      console.log(id);
      if (id) {
        this.$router.push("/video/".concat(id));
      }
    },
    handleSelectChange: function handleSelectChange(enemy_id, id) {
      this.changeSelectEnemy({
        id: id,
        enemy_id: enemy_id
      });
      this.getEnemySeries({
        series_id: id,
        enemy_id: enemy_id
      });
    }
  }),
  mounted: function mounted() {
    console.log("pages mounted");
    this.initDashboard();
  }
};
exports.default = _default;