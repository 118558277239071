"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TextLimit',
  props: {
    value: {
      type: String
    },
    // 显示几行
    limit: {
      type: Number,
      default: 1
    },
    // 尾巴，默认省略 ...
    tail: {
      type: String
    },
    tooltipShow: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: function value() {
      var _this = this;
      this.$nextTick(function () {
        _this.init();
      });
    }
  },
  data: function data() {
    return {
      resultStr: this.value,
      resultTail: this.tail || '...',
      tooltipDisabled: true
    };
  },
  computed: {
    title: function title() {
      return this.tooltipDisabled ? '' : this.value;
    }
  },
  methods: {
    init: function init() {
      // 如果没有传进来值，不用处理
      if (!this.value) {
        return;
      }
      // 获取每一行文本的高度
      var lineHeight = this.$refs.onlyOne.clientHeight;
      // 获取隐藏文本标签（通过往这个标签添加文本判断实时的高度来处理最终需要显示的文本）
      var hideText = this.$refs.hideText;
      // 隐藏文本标签添加传进来的文本
      hideText.innerHTML = this.value;
      // 获取隐藏文本高度
      var currentHeight = hideText.clientHeight;
      // 如果小于等于需要限制的行数，不用处理
      var lineNum = Math.ceil(currentHeight / lineHeight);
      if (lineNum <= this.limit) {
        this.resultStr = this.value;
        return;
      }

      // 允许提示
      this.tooltipDisabled = false;
      // 文本长度
      var len = this.value.length;
      // lineNum - 1 是为了获取到的值大于限制行，好做 -- 处理
      var end = Math.ceil(len * (this.limit / (lineNum - 1)));
      // 性能优化，先弄成最多+1行
      if (lineNum > this.limit + 1) {
        end = Math.ceil(len / lineNum * (this.limit + 1));
      }

      // 不断减掉一个字，直达行数达到要求
      while (lineNum > this.limit) {
        end--;
        hideText.innerHTML = this.value.slice(0, end) + this.resultTail;
        currentHeight = this.$refs.hideText.clientHeight;
        lineNum = Math.ceil(currentHeight / lineHeight);
      }
      this.resultStr = this.value.slice(0, end) + this.resultTail;
    }
  },
  mounted: function mounted() {
    this.init();
    this.$root.$on('textellipsisResize', this.init);
  },
  beforeDestroy: function beforeDestroy() {
    this.$root.$off('textellipsisResize', this.init);
  }
};
exports.default = _default;