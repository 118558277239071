"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.settingAuthorColumns = exports.personalizationConfigPayload = exports.listTypeList = exports.conditionMap = exports.conditionList = exports.commonPrincipalConfigPayload = exports.commonAuthorConfigPayload = exports.classifyTabList = exports.advertisementConfigPayload = void 0;
var _pplk_reception_manage = _interopRequireDefault(require("@/api/pplk_reception_manage"));
var classifyTabList = [{
  label: '广告用户欢迎语',
  value: '广告用户欢迎语'
}, {
  label: '个性化欢迎语',
  value: '个性化欢迎语'
}, {
  label: '通用欢迎语',
  value: '通用欢迎语'
}];
exports.classifyTabList = classifyTabList;
var listTypeList = [{
  label: '个人自定义',
  value: 2
}, {
  label: '品牌推荐',
  value: 1
}];
exports.listTypeList = listTypeList;
var advertisementConfigPayload = {
  columns: [{
    title: '启用/停用',
    dataIndex: 'status',
    width: 100,
    scopedSlots: {
      customRender: 'status'
    }
  }, {
    title: '策略名称',
    dataIndex: 'strategy_name'
  }, {
    title: '话术数量',
    dataIndex: 'word_count'
  }, {
    title: '更新人',
    dataIndex: 'modifier',
    width: 100
  }, {
    title: '最近更新时间',
    dataIndex: 'mtime',
    width: 180
  }, {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    }
  }],
  http_api: _pplk_reception_manage.default.getAdvertisementList
};
exports.advertisementConfigPayload = advertisementConfigPayload;
var strategyColumns = [{
  title: '策略名称',
  dataIndex: 'strategy_name',
  width: 150
}, {
  title: '说明',
  customRender: function customRender() {
    return '用户进入主播私信会话页';
  }
}, {
  title: '回复内容',
  dataIndex: 'reply_msg_vo_list',
  scopedSlots: {
    customRender: 'reply_msg'
  }
}, {
  title: '近7天回复私信数',
  dataIndex: 'chat_reply_num',
  width: 160,
  customRender: function customRender(text) {
    return text || '-';
  }
}, {
  title: '状态',
  dataIndex: 'status',
  width: 100,
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  width: 100,
  scopedSlots: {
    customRender: 'action'
  }
}];
var personalizationConfigPayload = {
  columns: strategyColumns,
  http_api: _pplk_reception_manage.default.getPersonalizationList
};
exports.personalizationConfigPayload = personalizationConfigPayload;
var commonAuthorConfigPayload = {
  columns: strategyColumns,
  http_api: _pplk_reception_manage.default.getCommonList
};
exports.commonAuthorConfigPayload = commonAuthorConfigPayload;
var commonPrincipalConfigPayload = {
  columns: strategyColumns.filter(function (item) {
    return item.title !== '状态';
  }),
  http_api: _pplk_reception_manage.default.getCommonList
};
exports.commonPrincipalConfigPayload = commonPrincipalConfigPayload;
var settingAuthorColumns = [{
  title: '主播昵称',
  dataIndex: 'authorName'
}, {
  title: '操作',
  dataIndex: 'action',
  fixed: 'right',
  scopedSlots: {
    customRender: 'action'
  }
}];
exports.settingAuthorColumns = settingAuthorColumns;
var conditionList = [{
  label: '同时满足',
  value: 1
}, {
  label: '满足其一',
  value: 2
}];
exports.conditionList = conditionList;
var conditionMap = conditionList.reduce(function (acc, cur) {
  acc[cur.value] = cur.label;
  return acc;
}, {});
exports.conditionMap = conditionMap;