var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-table",
        _vm._b(
          {
            attrs: {
              columns: _vm.configPayload.columns,
              "data-source": _vm.table_list,
              rowKey: function (record, index) {
                return index
              },
              pagination: false,
              loading: _vm.table_loading,
              scroll: { x: 1200 },
              bordered: "",
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.configPayload.columns, function (column) {
                  return {
                    key: column.scopedSlots
                      ? column.scopedSlots.customRender
                      : "",
                    fn: function (text, record, index) {
                      return [
                        _vm._t(
                          column.scopedSlots
                            ? column.scopedSlots.customRender
                            : "",
                          null,
                          null,
                          Object.assign({}, record, {
                            $index: index,
                            $list: _vm.table_list,
                          })
                        ),
                      ]
                    },
                  }
                }),
                {
                  key: "autoIndex",
                  fn: function (text, record, index) {
                    return [
                      _vm.paginationSetting
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.page_size +
                                  index +
                                  1
                              )
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(index + 1))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "a-table",
          _vm.$attrs,
          false
        ),
        [
          _vm._l(_vm.configPayload.columns, function (column) {
            return _c(
              "template",
              {
                slot:
                  column.slots && column.slots.title ? column.slots.title : "",
              },
              [
                _vm._t(
                  column.slots && column.slots.title ? column.slots.title : ""
                ),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm.paginationSetting
        ? _c("base-pagination", {
            attrs: {
              currentPage: _vm.pagination.pageNum,
              pageSize: _vm.pagination.pageSize,
              total: _vm.pagination.total,
            },
            on: { onChange: _vm.onChange, onShowSizeChange: _vm.onChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }