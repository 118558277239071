"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var vuex = _interopRequireWildcard(require("vuex"));
var _ExcleExport = _interopRequireDefault(require("@/components/Table/ExcleExport"));
var _RecordLineChart = _interopRequireDefault(require("@/components/Charts/LineChart/RecordLineChart"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _vuex$createNamespace = vuex.createNamespacedHelpers("live_record"),
  mapState = _vuex$createNamespace.mapState,
  mapMutations = _vuex$createNamespace.mapMutations,
  mapActions = _vuex$createNamespace.mapActions;
var _default = {
  name: "live_record",
  components: {
    ExcleExport: _ExcleExport.default,
    RecordLineChart: _RecordLineChart.default
  },
  data: function data() {
    return {
      searchVal: "",
      //搜索input
      currentName: "",
      drawer: false,
      currentTab: null,
      //当前tab列表索引
      currentRow: null // 详情数据
    };
  },

  watch: {},
  computed: _objectSpread(_objectSpread({}, mapState({
    leftListData: function leftListData(state) {
      return state.leftListData;
    },
    // 左侧列表
    currentNo: function currentNo(state) {
      return state.currentNo;
    },
    // 左侧列表 - 页码
    pageSize: function pageSize(state) {
      return state.pageSize;
    },
    // 左侧列表 - 每页条数
    discussLoading: function discussLoading(state) {
      return state.discussLoading;
    },
    // 左侧列表 - 加载
    discussNoMore: function discussNoMore(state) {
      return state.discussNoMore;
    },
    // 左侧列表 - 没有更多
    tableData: function tableData(state) {
      return state.tableData;
    },
    // 右侧表格
    voteData: function voteData(state) {
      return state.voteData;
    },
    // 渠道直播统计
    detailData: function detailData(state) {
      return state.detailData;
    } // 详情chart
  })), {}, {
    disabled: function disabled() {
      return this.discussLoading || this.discussNoMore;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    // 表格数据初始查询条件
    console.log(this.$refs);
    this.$nextTick(function () {
      _this.$refs.excelTable.search(true);
    });
  },
  created: function created() {
    this.getStorelist(1, 1);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["getleftList", "getTableData", "getRecordDetail"])), mapMutations(["changeState"])), {}, {
    // type = 1 查全部 current = 1 第一页
    getStorelist: function getStorelist(type, current, val) {
      if (type == 1) {
        this.currentName = "";
      } else if (val) {
        this.currentName = val;
      }
      var anchor = document.body.querySelector("#leftList");
      if (anchor && current == 1) {
        anchor.scrollTo({
          top: 0
        });
      }
      this.getleftList({
        nickname: this.currentName,
        page_size: this.pageSize,
        current: current == 1 ? 1 : this.currentNo
      });
    },
    // tab点击
    tabClick: function tabClick(index, id) {
      if (index == "all") {
        this.currentTab = null;
        this.$refs.excelTable.getData();
        // 重置左侧列表
        this.searchVal = "";
        this.getStorelist(1, 1);
      } else {
        this.currentTab = index;
        this.$refs.excelTable.getData(id);
      }
    },
    // 打开详情
    openDetail: function openDetail(row) {
      this.getRecordDetail({
        roomId: row.room_id
      });
      this.currentRow = row;
      this.drawer = true;
    }
  })
};
exports.default = _default;