"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
require("echarts-wordcloud/dist/echarts-wordcloud");
require("echarts-wordcloud/dist/echarts-wordcloud.min");
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: function _default() {
        return {
          width: "400px",
          height: "200px"
        };
      }
    },
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    styleObject: function styleObject() {
      var obj = this.boxStyle;
      for (var attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";
        obj[attr] = unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData: function chartData() {
      this.initChart();
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById('chartid'));
      this.chart.setOption({
        tooltip: {
          show: true,
          position: 'top',
          textStyle: {
            fontSize: 30
          }
        },
        series: [{
          type: "wordCloud",
          // 网格大小，各项之间间距
          gridSize: 15,
          // 形状 circle 圆，cardioid  心， diamond 菱形，
          // triangle-forward 、triangle 三角，star五角星
          shape: 'circle',
          // 字体大小范围
          sizeRange: [8, 30],
          // 文字旋转角度范围
          rotationRange: [0, 90],
          // 旋转步值
          rotationStep: 90,
          // 自定义图形
          // maskImage: maskImage,
          left: 'center',
          top: 'center',
          right: null,
          bottom: null,
          // 画布宽
          width: '90%',
          // 画布高
          height: '80%',
          // 是否渲染超出画布的文字
          drawOutOfBound: false,
          textStyle: {
            normal: {
              color: function color() {
                return 'rgb(' + [Math.round(Math.random() * 200 + 55), Math.round(Math.random() * 200 + 55), Math.round(Math.random() * 200 + 55)].join(',') + ')';
              }
            },
            emphasis: {
              shadowBlur: 10,
              shadowColor: '#2ac'
            }
          },
          data: this.chartData
        }]
      });
    }
  }
};
exports.default = _default2;