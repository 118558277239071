"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false
    };
  },
  methods: {
    openModal: function openModal() {
      this.visible = true;
    }
  },
  created: function created() {}
};
exports.default = _default;