"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _Calender = _interopRequireDefault(require("@/components/Calender"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('live_stream'),
  mapState = _createNamespacedHelp.mapState,
  mapMutations = _createNamespacedHelp.mapMutations,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  name: "LiveStreamRecord",
  components: {
    Calender: _Calender.default
  },
  data: function data() {
    return {
      currentPage: 1,
      // 当前页码
      pageSize: 5,
      // 每页的数据条数
      month: '',
      lastMonth: ''
    };
  },
  created: function created() {
    this.changeState({
      name: 'authorId',
      data: this.$route.query.id
    });
  },
  computed: _objectSpread({}, mapState({
    liveRecordList: function liveRecordList(state) {
      return state.liveRecordList;
    },
    tableLoading: function tableLoading(state) {
      return state.tableLoading;
    },
    liveRecord: function liveRecord(state) {
      return state.liveRecord;
    },
    current_month: function current_month(state) {
      return _.get(state, "liveRecord.current_month[0]", {});
    },
    last_month: function last_month(state) {
      return _.get(state, "liveRecord.last_month[0]", {});
    },
    rotateActive: function rotateActive(state) {
      return state.rotateActive;
    },
    athourMessage: function athourMessage(state) {
      return state.athourMessage;
    }
  })),
  mounted: function mounted() {
    this.getLiveStreamRecord();
    this.getAuthourMessage();
    this.getCurrentMonth();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(["getLiveStreamRecord", "getAuthourMessage"])), mapMutations(['changeState'])), {}, {
    getNewDate: function getNewDate() {
      this.changeState({
        name: 'rotateActive',
        data: true
      });
      this.getLiveStreamRecord();
    },
    getCurrentMonth: function getCurrentMonth() {
      var d = new Date();
      this.month = d.getMonth() + 1;
      this.lastMonth = d.getMonth();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
    },
    liveDetail: function liveDetail(type, data) {
      var room_id = data.room_id,
        author = data.author,
        title = data.title;
      if (!type) {
        this.$router.push({
          path: '/live/stream/analyze',
          query: {
            room_id: room_id,
            author_id: author.id
          }
        });
      } else {
        var routeUrl = this.$router.resolve({
          path: '/live_now',
          query: {
            room_id: room_id,
            author_id: author.id
          }
        });
        window.open(routeUrl.href, '_blank');
      }
    }
  })
};
exports.default = _default;