"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VueAxios = void 0;
exports.default = generateRequest;
require("core-js/modules/es6.regexp.replace");
var _axios2 = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("@/store"));
var _notification = _interopRequireDefault(require("ant-design-vue/es/notification"));
var _axios3 = require("./axios");
var _cookies = _interopRequireDefault(require("@/common/cookies"));
var _router = _interopRequireDefault(require("@/router"));
var _auth = require("@/utils/auth");
var timer = null;
function generateRequest(config) {
  var defaultConfig = {
    // timeout: 1000 * 30,
    timeout: 600000,
    needTocken: true,
    baseURL: process.env.VUE_APP_LIVE_CRM_API,
    isNeedMessage: true,
    isNeedToken: true
  };
  var _config = Object.assign({}, defaultConfig, config);
  var _axios = _axios2.default.create({
    baseURL: _config.baseURL,
    timeout: _config.timeout
  });
  _axios.interceptors.request.use(function (config) {
    var token = (0, _auth.getToken)();

    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token && _config.isNeedToken) {
      //config.headers['Access-Token'] = token
      // config.headers['token'] = token
      config.headers['Authorization'] = "".concat(token);
    }
    return config;
  }, errorHandler);
  _axios.interceptors.response.use(function (response) {
    if (response.data.code === 1001) {
      _notification.default.error({
        message: '注意',
        description: response.data.msg
      });
      _store.default.dispatch('Logout');
      return Promise.reject(response.data.msg);
    } else {
      return response.data;
    }
  }, errorHandler);
  return _axios;
}

// // 创建 axios 实例
// const request = axios.create({
//   // API 请求的默认前缀
//   baseURL: base_url,
//   timeout: 1000 * 30 // 请求超时时间
// })

// 异常拦截处理器
var errorHandler = function errorHandler(error) {
  if (error.response) {
    var data = error.response.data;
    // 从 localstorage 获取 token
    var token = (0, _auth.getToken)();

    // if (error.response.status === 403) {
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    // }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      clearTimeout(timer);
      timer = null;
      timer = setTimeout(function () {
        _notification.default.error({
          message: '登录过期',
          description: '登录已过期，请重新登录。'
        });
      }, 1000);
      (0, _auth.removeToken)();
      _router.default.currentRoute.path !== 'login' && _router.default.replace({
        path: 'login',
        query: {
          redirect: _router.default.currentRoute.path
        }
      });
      // if (token) {
      //   store.dispatch('Logout').then(() => {
      //     setTimeout(() => {
      //       window.location.reload()
      //     }, 1500)
      //   })
      // }
    }
  }

  return Promise.reject(error);
};

// // request interceptor
// request.interceptors.request.use(config => {
//   const token = cookies.getCookies("token")

//   // 如果 token 存在
//   // 让每个请求携带自定义 token 请根据实际情况自行修改
//   if (token) {
//     //config.headers['Access-Token'] = token
//     config.headers['token'] = token
//   }
//   return config
// }, errorHandler)

// // response interceptor
// request.interceptors.response.use((response) => {
//   return response.data
// }, errorHandler)

var installer = {
  vm: {},
  install: function install(Vue) {
    Vue.use(_axios3.VueAxios, generateRequest());
  }
};

// export default request
exports.VueAxios = installer;