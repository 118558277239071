var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "loading" } }, [
      _c("div", { staticClass: "robotBody" }, [
        _c("span", { staticClass: "robot" }, [
          _c("span"),
          _c("span"),
          _c("span"),
          _c("span"),
        ]),
        _c("div", { staticClass: "base" }, [
          _c("span", { staticClass: "wing" }),
          _c("div", { staticClass: "face" }),
        ]),
      ]),
      _c("div", { staticClass: "longfazers" }, [
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
      ]),
      _c("h1", [_vm._v("拼命连接中. . . ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }