"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ReplyRulesPane = _interopRequireDefault(require("./components/ReplyRulesPane.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ReplyRulesPane: _ReplyRulesPane.default
  },
  data: function data() {
    return {
      activeKey: "1"
    };
  },
  methods: {},
  mounted: function mounted() {
    // 判断是否有参数
    // const sceneType = this.$route.query.sceneType;
    // if (sceneType) {
    //   this.activeKey = `${sceneType}`;
    // }
  }
};
exports.default = _default;