var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "monitoring-live-item" }, [
    _c("div", { staticClass: "monitoring-live-item-top" }, [
      _c("div", { staticClass: "head-box" }, [
        _c("img", {
          attrs: { src: _vm.itemData.cover || _vm.defultCover, alt: "" },
        }),
        _c("div", [
          _c(
            "h4",
            { staticClass: "f16 c-b", staticStyle: { margin: "4px 0 0 0" } },
            [_vm._v(_vm._s(_vm.itemData.title))]
          ),
          _c(
            "span",
            {
              staticClass: "f14 c-g",
              staticStyle: { margin: "8px 0", display: "inline-block" },
            },
            [
              _vm._v(
                "监控时长: " + _vm._s(_vm.itemData.monitor_duration) + "小时"
              ),
            ]
          ),
          _vm.itemData.verity_type == 1 || _vm.itemData.verity_type == 2
            ? _c(
                "div",
                [
                  _c("svg-icon", {
                    staticStyle: { "font-size": "16px", color: "#4A58F3" },
                    attrs: { "icon-class": "checkmark" },
                  }),
                  _c("span", { staticClass: "f14 c-g" }, [
                    _vm._v(
                      "已认证，" +
                        _vm._s(_vm.itemData.verity_type == 1 ? "门店" : "品牌")
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("div", [
          _c("img", {
            attrs: { src: _vm.itemData.avatar || _vm.defultImg, alt: "" },
          }),
          _c("span", { staticClass: "f12" }, [
            _vm._v(_vm._s(_vm.itemData.nickname)),
          ]),
        ]),
        _c("span", { staticClass: "f12" }, [
          _vm._v(
            "类型: " +
              _vm._s(_vm.itemData.monit_type == 1 ? "直播监控" : "短视频监控")
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "monitoring-live-item-bottom" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemData.status && _vm.itemData.status != 2,
              expression: "itemData.status && itemData.status != 2",
            },
          ],
          staticClass: "monitoring-live-item-bottom-main",
        },
        [
          _c(
            "div",
            { staticClass: "head" },
            [
              _c("span", { staticClass: "c-g f12" }, [_vm._v("人气峰值趋势")]),
              _vm.itemData.status == 4
                ? _c(
                    "el-button",
                    {
                      staticStyle: { background: "#d8d8d8", color: "#fff" },
                      attrs: { size: "mini" },
                    },
                    [_vm._v("监控完成")]
                  )
                : _vm._e(),
              _vm.itemData.status == 1
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        background:
                          "linear-gradient(180deg, #27c77d 0%, #27b388 100%)",
                        color: "#fff",
                      },
                      attrs: { size: "mini" },
                    },
                    [_vm._v("监控中")]
                  )
                : _vm._e(),
              _vm.itemData.status == 3
                ? _c(
                    "el-button",
                    {
                      staticStyle: { background: "#e74f3e", color: "#fff" },
                      attrs: { size: "mini" },
                    },
                    [_vm._v("待监控")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "chart", attrs: { id: _vm.idName } }),
          _c("div", { staticClass: "time" }, [
            _c("span", [
              _vm._v("监控时间: " + _vm._s(_vm.itemData.monitor_time)),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemData.status && _vm.itemData.status == 2,
              expression: "itemData.status && itemData.status == 2",
            },
          ],
          staticClass: "no-live",
        },
        [
          _vm._m(0),
          _c("div", [_vm._v("监控时间:")]),
          _c("div", [_vm._v(_vm._s(_vm.itemData.monitor_time))]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "mask" },
      [
        _vm.itemData.status == 4 || _vm.itemData.status == 1
          ? _c(
              "el-button",
              {
                staticStyle: {
                  width: "30%",
                  background:
                    "linear-gradient(180deg, #64A9F3 0%, #516EF3 100%)",
                  border: "#52c41a",
                  color: "#fff",
                  "margin-top": "20px",
                },
                on: {
                  click: function ($event) {
                    return _vm.openDetail(_vm.itemData)
                  },
                },
              },
              [_vm._v("查看详情")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#df3a41" } }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _c("span", [_vm._v("监控失败，预约时间内没有发现直播")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }