"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initLeavePieAnalyzeOptions = void 0;
require("core-js/modules/es6.function.name");
var chartTypeMap = {
  naturalLeaveSum: '自然留资数',
  ppLeaveSum: '皮皮拉客留资数'
};
var initLeavePieAnalyzeOptions = function initLeavePieAnalyzeOptions(list, allCount) {
  var option = {
    color: ['#F75C5C', '#6E9FF4', '#FFCC55'],
    grid: {
      x: 50,
      y: 60,
      x2: 50,
      y2: 35
    },
    title: [{
      text: "".concat(allCount),
      subtext: '总量',
      textStyle: {
        fontSize: 18,
        color: '#666',
        fontWeight: 'bold'
      },
      subtextStyle: {
        fontSize: 12,
        color: '#666'
      },
      textAlign: 'center',
      x: '49%',
      y: '43.5%'
    }],
    series: [{
      type: 'pie',
      minAngle: 40,
      // 最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
      avoidLabelOverlap: true,
      radius: ['26%', '60%'],
      center: ['50%', '50%'],
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 3
      },
      label: {
        formatter: function formatter(param) {
          console.log(param);
          return "{value|".concat(param.value, "} {leave|(").concat(param.percent, "%)} \n {name|").concat(chartTypeMap[param.name], "}");
        },
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          value: {
            fontSize: 18,
            lineHeight: 20,
            height: 20,
            fontWeight: 'bold'
          },
          leave: {
            fontSize: 18,
            lineHeight: 20,
            height: 20,
            fontWeight: 'bold'
          },
          name: {
            fontSize: 12,
            lineHeight: 20,
            align: 'left'
          }
        }
      },
      labelLine: {
        length: 30,
        length2: 4
      },
      data: list
    }]
  };
  return option;
};
exports.initLeavePieAnalyzeOptions = initLeavePieAnalyzeOptions;