var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "600px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "add-tracking-container" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("客户跟踪：")]),
              _vm.info
                ? _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "info-left" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.info.avatar ||
                            require("@/assets/dev/defult-avatar.png"),
                          width: "50",
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.info.nickname))]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.info.gender == 0
                                ? "未知"
                                : _vm.info.gender == 1
                                ? "男"
                                : "女"
                            ) +
                              " " +
                              _vm._s(_vm.info.area)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info-right" }, [
                      _c("span", { staticClass: "code" }, [
                        _vm._v(_vm._s(_vm.info.code)),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "link",
                          on: { click: _vm.openFindModal },
                        },
                        [_vm._v("找他？")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "c-red f18", staticStyle: { margin: "12px 0" } },
                [_vm._v("请根据您的意向判断更高跟踪信息")]
              ),
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "意向车型：" } },
                    _vm._l(_vm.series, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择车系",
                              },
                              model: {
                                value: _vm.series[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.series, index, $$v)
                                },
                                expression: "series[index]",
                              },
                            },
                            _vm._l(_vm.seriesList || [], function (t) {
                              return _c("el-option", {
                                key: t.id,
                                attrs: { label: t.name, value: t.id },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.series.length < 3,
                                  expression: "series.length < 3",
                                },
                              ],
                              attrs: { plain: "" },
                              on: { click: _vm.addSeriesClick },
                            },
                            [_vm._v("+")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index != 0,
                                  expression: "index != 0",
                                },
                              ],
                              staticStyle: { "margin-top": "12px" },
                              attrs: { plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.reduceSeriesClick(index)
                                },
                              },
                            },
                            [_vm._v("-")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "意向判断：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择意向" },
                          model: {
                            value: _vm.favorite_level,
                            callback: function ($$v) {
                              _vm.favorite_level = $$v
                            },
                            expression: "favorite_level",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "H", value: "H" },
                          }),
                          _c("el-option", {
                            attrs: { label: "A", value: "A" },
                          }),
                          _c("el-option", {
                            attrs: { label: "B", value: "B" },
                          }),
                          _c("el-option", {
                            attrs: { label: "C", value: "C" },
                          }),
                          _c("el-option", {
                            attrs: { label: "F", value: "F" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "购车城市：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择省份" },
                          on: { change: _vm.provChange },
                          model: {
                            value: _vm.province_code,
                            callback: function ($$v) {
                              _vm.province_code = $$v
                            },
                            expression: "province_code",
                          },
                        },
                        _vm._l(_vm.provinceList, function (t) {
                          return _c("el-option", {
                            key: t.id,
                            attrs: { label: t.name, value: t.region_code },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择城市" },
                          model: {
                            value: _vm.city_code,
                            callback: function ($$v) {
                              _vm.city_code = $$v
                            },
                            expression: "city_code",
                          },
                        },
                        _vm._l(_vm.cityList, function (t) {
                          return _c("el-option", {
                            key: t.id,
                            attrs: { label: t.name, value: t.region_code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addTrackingClick },
                },
                [_vm._v(_vm._s(_vm.isEdit ? "确定" : "加入跟踪"))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("findCode", { ref: "findCode" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }