"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _home = _interopRequireDefault(require("@/api/home"));
var _Fptable = _interopRequireDefault(require("@/views/home/components/Fptable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'index',
  components: {
    Fptable: _Fptable.default
  },
  data: function data() {
    return {
      timeBtn: 2,
      btn: [{
        value: 0,
        name: '今天'
      }, {
        value: 1,
        name: '昨天'
      }, {
        value: 2,
        name: '近7天'
      }, {
        value: 3,
        name: '近30天'
      }],
      leaveTypeList: [{
        value: 1,
        name: '全部'
      }, {
        value: 2,
        name: '高潜攀谈'
      }, {
        value: 3,
        name: '私信智回'
      }, {
        value: 4,
        name: '自然留资'
      }],
      time: [],
      contact: undefined,
      startDay: '',
      endDay: '',
      leaveType: 1,
      listData: [],
      showPhone: false,
      tableLoading: false,
      loadExcel: false,
      pagination: {}
    };
  },
  mounted: function mounted() {
    var _this$$route$query = this.$route.query,
      startDay = _this$$route$query.startDay,
      endDay = _this$$route$query.endDay,
      num = _this$$route$query.num;
    console.log(startDay, endDay, num);
    if (startDay && endDay && num) {
      this.timeBtn = Number(num);
      this.leaveType = 2;
      this.changeDay(Number(num));
    } else {
      this.timeBtn = 2;
      this.leaveType = 1;
      this.changeDay(2);
    }
    // this.getList(1)
  },

  methods: {
    getList: function getList(value) {
      var _this = this;
      if (this.tableLoading) return;
      this.tableLoading = true;
      var payload = {
        contact: this.contact,
        start_day: this.startDay,
        end_day: this.endDay,
        page_size: 10,
        page_num: value || 1
      };
      _home.default.getLeaveDetailV1_22_0(payload).then(function (res) {
        _this.tableLoading = false;
        _this.listData = res.list || [];
        _this.pagination = {
          current: res.page_num,
          pageSize: 10,
          total: res.total,
          showTotal: function showTotal(total) {
            return "\u5171".concat(total, "\u6761\u8BB0\u5F55");
          },
          hideOnSinglePage: true,
          onChange: function onChange(page, pageSize) {
            _this.changePagination(page);
          }
        };
      }).catch(function () {}).finally(function () {
        _this.tableLoading = false;
      });
    },
    /**
     * 改变日期
     */
    changeDay: function changeDay(e) {
      var num = e;
      if (e.target) {
        num = e.target.value;
      }
      var start_t = '';
      var end_t = this.moment().subtract(0, 'days').format('YYYY-MM-DD');
      switch (num) {
        case 0:
          start_t = this.moment().subtract(0, 'days').format('YYYY-MM-DD');
          break;
        case 1:
          start_t = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
          end_t = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
          break;
        case 2:
          start_t = this.moment().subtract(7, 'days').format('YYYY-MM-DD');
          break;
        case 3:
          start_t = this.moment().subtract(30, 'days').format('YYYY-MM-DD');
          break;
        default:
          break;
      }
      this.time = [start_t, end_t];
      this.startDay = start_t;
      this.endDay = end_t;
      this.getList(1);
    },
    disabledDate: function disabledDate(current) {
      return current < this.moment().subtract(90, 'days') || current > this.moment().subtract(0, 'day');
    },
    /**
     * 选择日期
     * @param {array} dates 日期数组
     */
    onChangeDate: function onChangeDate(dates) {
      var start_time = '';
      var end_time = '';
      if (dates.length) {
        start_time = this.moment(dates[0]._d).format('YYYY-MM-DD');
        end_time = this.moment(dates[1]._d).format('YYYY-MM-DD');
        this.startDay = start_time;
        this.endDay = end_time;
      } else {
        this.startDay = '';
        this.endDay = '';
      }
      this.time = [start_time, end_time];
      this.timeBtn = null;
      this.getList(1);
    },
    /**
     * 显示完整电话号码
     */
    showPhoneType: function showPhoneType() {
      this.filtrate.setTracking({
        en: 'zxmk_yx_lzmxzslxfs_click',
        kv: {
          state: this.attentionType ? '开启' : '关闭'
        }
      });
    },
    /**
     * 翻页
     */
    changePagination: function changePagination(value) {
      this.getList(value);
    },
    /**
     * 导出Excel
     */
    exportTable: function exportTable() {
      var _this2 = this;
      this.loadExcel = true;
      var params = {
        start_day: this.startDay,
        end_day: this.endDay,
        contact: this.contact
      };
      _home.default.exportExcelLiveLeaveDetailV1_22_0(params).then(function (res) {
        var fileName = "\u7559\u8D44\u660E\u7EC6_".concat(_this2.moment().format('MM月DD日HH点mm导出'));
        var data = res;
        if (data.type == 'application/json') {
          var reader = new FileReader();
          var that = _this2;
          reader.onload = function () {
            var _JSON$parse = JSON.parse(reader.result),
              message = _JSON$parse.message;
            that.$message.error(message);
          };
          reader.readAsText(data);
          that.loadExcel = false;
          return;
        }
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([res], {
            type: 'Content-Type: application/json'
          });
          window.navigator.msSaveOrOpenBlob(blob, fileName + '.xlsx');
        } else {
          var _blob = new Blob([res]);
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(_blob);
          downloadElement.href = href;
          downloadElement.download = fileName + '.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this2.loadExcel = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    }
  }
};
exports.default = _default;