"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {};
  },
  props: {
    position: {
      type: String,
      default: "left"
    },
    item: {
      type: Object,
      default: {}
    },
    author: {
      type: Object,
      default: {}
    }
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    getSimpleName: function getSimpleName(name) {
      if (name) {
        return name.slice(0, name.indexOf("-"));
      } else {
        return "--";
      }
    },
    getNewTime: function getNewTime(time) {
      if (time) {
        return time.slice(0, 16);
      } else {
        return "--";
      }
    }
  }
};
exports.default = _default;