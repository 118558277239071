"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  getAnchor: function getAnchor(_ref) {
    var id = _ref.id;
    return request({
      url: "/mirror-data/douyin/author/".concat(id),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAnchorStat: function getAnchorStat(_ref2) {
    var id = _ref2.id;
    return request({
      url: "/mirror-data/douyin/author/".concat(id, "/stat"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAnchorAweme: function getAnchorAweme(_ref3) {
    var id = _ref3.id;
    return request({
      url: "/mirror-data/douyin/author/".concat(id, "/aweme"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  }
};
exports.default = _default;