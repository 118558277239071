var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _vm.dataList && _vm.dataList.length > 0
        ? _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              {
                key: item.strategyId,
                staticClass: "box",
                class: { first: index === 0 },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.strategyName)),
                ]),
                _vm.isTimeCallback(item.strategyName)
                  ? _c(
                      "div",
                      { staticClass: "txt" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTimeCallbackText(
                                item.strategyDescription
                              )[0]
                            ) +
                            " "
                        ),
                        _c("a-time-picker", {
                          staticStyle: { width: "80px" },
                          attrs: { format: "HH:mm" },
                          on: {
                            openChange: function (e) {
                              return !e && _vm.changeRecallTime(item)
                            },
                            change: function (e) {
                              return !e && _vm.changeRecallTime(item)
                            },
                          },
                          model: {
                            value: item.timingRecall,
                            callback: function ($$v) {
                              _vm.$set(item, "timingRecall", $$v)
                            },
                            expression: "item.timingRecall",
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTimeCallbackText(
                                item.strategyDescription
                              )[1]
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "txt" }, [
                      _vm._v(_vm._s(item.strategyDescription)),
                    ]),
                item.detailsId
                  ? _c("div", { staticClass: "keyword" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("关键词:")]),
                      _c(
                        "div",
                        { staticClass: "input-box" },
                        [
                          !item.isEdit
                            ? _c(
                                "div",
                                { staticStyle: { padding: "4px 11px" } },
                                [_vm._v(_vm._s(item.detailsName))]
                              )
                            : _c("a-textarea", {
                                ref: "keyword_" + item.strategyId,
                                refInFor: true,
                                staticClass: "input",
                                attrs: {
                                  placeholder: "请输入关键词",
                                  "auto-size": { minRows: 2, maxRows: 4 },
                                  maxLength: 200,
                                  disabled: !item.isEdit,
                                },
                                model: {
                                  value: item.detailsName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "detailsName", $$v)
                                  },
                                  expression: "item.detailsName",
                                },
                              }),
                          _c(
                            "div",
                            { staticClass: "bottom" },
                            [
                              !item.isEdit
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn edit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "btn",
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.close(item)
                                          },
                                        },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: item.isSubmit,
                                          loading: item.isSubmit,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.save(item)
                                          },
                                        },
                                      },
                                      [_vm._v("保存")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("textareaContent", {
                  ref: "textareaContent_" + item.strategyId,
                  refInFor: true,
                  attrs: { "data-list": item.replyMsgVos || [] },
                  on: {
                    delete: function ($event) {
                      return _vm.onDelete(arguments, item)
                    },
                    submit: function ($event) {
                      return _vm.onSubmit(arguments, item)
                    },
                    changeEnable: function ($event) {
                      return _vm.onEnableChange(arguments, item)
                    },
                  },
                }),
                item.addImage
                  ? _c("div", { staticClass: "keyword" }, [
                      _c("div", { staticClass: "key" }, [
                        _vm._v(
                          "回复图片：发送文字后紧接着发送一张图片（如优惠政策）"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "img-list-wrapper" },
                        [
                          _c("vod-base-upload", {
                            ref: "saleRelationUploadRef",
                            refInFor: true,
                            attrs: {
                              "accept-list": _vm.acceptList,
                              "upload-title": "",
                              "has-file-list": item.customImgList,
                              "is-nolmal": true,
                              limitFileLength: 5,
                              fileParams: _vm.uploadFileParams,
                              confirmDelete: true,
                            },
                            on: {
                              handlePreview: function ($event) {
                                return _vm.handlePreviewOpen(arguments)
                              },
                              handleUrl: function (e) {
                                return e && _vm.addReplyImage(e, item)
                              },
                              handleRemove: function (e) {
                                return _vm.deleteReplyImage(e, item)
                              },
                            },
                          }),
                          item.systemImgList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "padding-right": "15px",
                                  },
                                },
                                [
                                  _c("a-divider", {
                                    staticStyle: { height: "103px" },
                                    attrs: { type: "vertical" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(item.systemImgList, function (imgItem) {
                            return _c(
                              "div",
                              {
                                key: imgItem.imgId,
                                staticClass: "preview-img-box",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: imgItem.imgUrl,
                                    width: "103px",
                                    height: "103px",
                                  },
                                }),
                                _c("div", { staticClass: "mask" }),
                                _c("div", { staticClass: "img-name" }, [
                                  _vm._v("系统话术"),
                                  imgItem.isEnabled === 2
                                    ? _c("span", [_vm._v("(已禁用)")])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "shadow-box" },
                                  [
                                    _c("a-icon", {
                                      staticClass: "icon",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { type: "eye" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreviewOpen([
                                            imgItem.imgUrl,
                                          ])
                                        },
                                      },
                                    }),
                                    imgItem.isEnabled === 1
                                      ? _c("a-icon", {
                                          staticClass: "icon",
                                          staticStyle: {
                                            cursor: "pointer",
                                            "font-size": "14px",
                                            "margin-left": "10px",
                                          },
                                          attrs: { type: "stop" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeReplyImage(
                                                imgItem,
                                                item
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    imgItem.isEnabled === 2
                                      ? _c("a-icon", {
                                          staticClass: "icon",
                                          staticStyle: {
                                            cursor: "pointer",
                                            "font-size": "14px",
                                            "margin-left": "10px",
                                          },
                                          attrs: { type: "check-circle" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeReplyImage(
                                                imgItem,
                                                item
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          })
        : _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")]),
      _c("preview-modal", {
        attrs: { visible: _vm.previewVisible, "preview-src": _vm.previewSrc },
        on: {
          cancel: function ($event) {
            _vm.previewVisible = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }