var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v("回复内容:")]),
    _c(
      "div",
      { ref: "listWrap", staticClass: "list-wrap" },
      [
        _vm._l(_vm.customMsgList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              class: { system: item.isSystem === 1 },
            },
            [
              _c("a-textarea", {
                ref: "textarea_" + item.id,
                refInFor: true,
                staticClass: "txt",
                attrs: {
                  placeholder: "请输入回复内容",
                  maxLength: 300,
                  disabled: !item.isEdit,
                },
                model: {
                  value: item.replyMsg,
                  callback: function ($$v) {
                    _vm.$set(item, "replyMsg", $$v)
                  },
                  expression: "item.replyMsg",
                },
              }),
              _c("div", { staticClass: "bottom" }, [
                _c("div", { staticClass: "system-script" }),
                _c(
                  "div",
                  { staticClass: "btn-box" },
                  [
                    !item.isEdit
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "btn edit",
                              on: {
                                click: function ($event) {
                                  return _vm.editTactics(item)
                                },
                              },
                            },
                            [_c("a-icon", { attrs: { type: "edit" } })],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "btn delete" },
                            [
                              !item.isSubmit
                                ? _c("a-icon", {
                                    attrs: { type: "delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteTactics(item)
                                      },
                                    },
                                  })
                                : _c("a-icon", { attrs: { type: "loading" } }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "a-button",
                            {
                              staticClass: "btn",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.close(item, index)
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "btn",
                              attrs: {
                                size: "small",
                                type: "primary",
                                disabled: item.isSubmit,
                                loading: item.isSubmit,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submit(item)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          )
        }),
        !_vm.isAdd && _vm.customMsgList.length < 10
          ? _c(
              "div",
              { staticClass: "add", on: { click: _vm.addTactics } },
              [
                _vm.customMsgList.length === 0
                  ? _c("span", [_vm._v(" 暂无话术 ")])
                  : _vm._e(),
                _c("a-icon", { attrs: { type: "plus-circle" } }),
              ],
              1
            )
          : _c("div", { staticStyle: { "flex-shrink": "0", width: "15px" } }),
        _vm.systemMsgList.length > 0
          ? _c(
              "div",
              { staticStyle: { "padding-right": "15px" } },
              [
                _c("a-divider", {
                  staticStyle: { height: "5em" },
                  attrs: { type: "vertical" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.systemMsgList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item system",
              class: { disabled: item.isEnabled === 2 },
            },
            [
              _c("a-textarea", {
                staticClass: "txt",
                attrs: {
                  placeholder: "请输入回复内容",
                  maxLength: 300,
                  disabled: !item.isEdit,
                },
                model: {
                  value: item.replyMsg,
                  callback: function ($$v) {
                    _vm.$set(item, "replyMsg", $$v)
                  },
                  expression: "item.replyMsg",
                },
              }),
              _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c("div", { staticClass: "system-script" }, [
                    _c("span", [
                      _vm._v("系统话术 "),
                      item.isEnabled === 2
                        ? _c("span", [_vm._v("(已禁用)")])
                        : _vm._e(),
                    ]),
                  ]),
                  item.isEnabled === 1
                    ? _c("a-tooltip", { attrs: { title: "禁用系统话术" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "enable-btn",
                            on: {
                              click: function ($event) {
                                return _vm.changeEnable(item)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "stop" } }),
                            _vm._v(" 禁用"),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.isEnabled === 2
                    ? _c("a-tooltip", { attrs: { title: "启用系统话术" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "enable-btn",
                            on: {
                              click: function ($event) {
                                return _vm.changeEnable(item)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "check-circle" } }),
                            _vm._v(" 启用"),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }