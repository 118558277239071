"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    configPayload: {
      type: Object,
      required: true
    },
    // 筛选条件
    selectValue: {
      type: Object,
      required: false,
      default: undefined
    },
    //初始化是否请求
    initData: {
      type: Boolean,
      default: true
    },
    // 是否设置分页
    paginationSetting: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      table_list: [],
      table_loading: false,
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  mounted: function mounted() {
    if (this.initData) {
      this.getTableList(1, 10);
    }
  },
  methods: {
    // 查询列表
    searchList: function searchList() {
      this.getTableList(1, this.pagination.page_size);
    },
    // 刷新列表
    refreshList: function refreshList() {
      this.getTableList(this.pagination.current, this.pagination.page_size);
    },
    //   获取table 列表信息
    getTableList: function getTableList(pageNum, pageSize) {
      var _this = this;
      var select_value = this.selectValue ? JSON.parse(JSON.stringify(this.selectValue)) : '';
      var payload = _objectSpread({
        pageNum: pageNum,
        pageSize: pageSize
      }, select_value);
      if (!this.paginationSetting) {
        delete payload.pageNum;
        delete payload.pageSize;
      }
      console.log('payloadpayloadpayload', payload);
      this.table_loading = true;
      for (var key in payload) {
        if (payload[key] == '') {
          delete payload[key];
        }
      } // 删除空属性
      return this.configPayload.http_api(payload).then(function (res) {
        if (res.code) {
          if (res.code == 200) {
            if (_this.paginationSetting) {
              var _res$data = res.data,
                list = _res$data.list,
                page_num = _res$data.page_num,
                page_size = _res$data.page_size,
                total = _res$data.total;
              _this.table_list = list;
              _this.pagination = {
                pageNum: page_num,
                pageSize: page_size,
                total: total
              };
              _this.$emit('table_list', _this.table_list);
            } else {
              _this.table_list = res.data;
              _this.$emit('table_list', _this.table_list);
            }
          } else {
            _this.$message.error(res.message);
          }
        } else {
          var _list = res.list,
            _page_num = res.page_num,
            _page_size = res.page_size,
            _total = res.total;
          _this.table_list = _list;
          _this.pagination = {
            pageNum: _page_num,
            pageSize: _page_size,
            total: _total
          };
        }
      }).finally(function () {
        _this.table_loading = false;
      });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange: function onChange(pageNum, pageSize) {
      this.getTableList(pageNum, pageSize);
    }
  }
};
exports.default = _default;