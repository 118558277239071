"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _account = _interopRequireDefault(require("@/api/account"));
var _getStoreInfo = _account.default.getStoreInfo,
  _getMediaList = _account.default.getMediaList,
  _getStoreInfoZ = _account.default.getStoreInfoZ,
  _getMediaListZ = _account.default.getMediaListZ,
  _getMediaCode = _account.default.getMediaCode,
  _getCity = _account.default.getCity,
  _updateInfo = _account.default.updateInfo,
  _addMediaCode = _account.default.addMediaCode,
  _updateRemake = _account.default.updateRemake;
var _default = {
  namespaced: true,
  state: {
    storeInfoData: null,
    mediaList: null,
    storeInfoDataZ: null,
    mediaListZ: null,
    mediaCodeData: null,
    cityList: [],
    updateInfoResult: null,
    addMediaResult: null,
    updateRemakeResult: null
  },
  actions: {
    getStoreInfo: function getStoreInfo(_ref) {
      var commit = _ref.commit;
      return _getStoreInfo().then(function (res) {
        commit('changeState', {
          name: 'storeInfoData',
          data: res.data
        });
      });
    },
    getMediaList: function getMediaList(_ref2) {
      var commit = _ref2.commit;
      return _getMediaList().then(function (res) {
        commit('changeState', {
          name: 'mediaList',
          data: res.data
        });
      });
    },
    getStoreInfoZ: function getStoreInfoZ(_ref3) {
      var commit = _ref3.commit;
      return _getStoreInfoZ().then(function (res) {
        commit('changeState', {
          name: 'storeInfoDataZ',
          data: res.data
        });
      });
    },
    getMediaListZ: function getMediaListZ(_ref4) {
      var commit = _ref4.commit;
      return _getMediaListZ().then(function (res) {
        commit('changeState', {
          name: 'mediaListZ',
          data: res.data
        });
      });
    },
    getCity: function getCity(_ref5) {
      var commit = _ref5.commit;
      return _getCity().then(function (res) {
        commit('changeState', {
          name: 'cityList',
          data: res.data
        });
      });
    },
    updateInfo: function updateInfo(_ref6, payload) {
      var commit = _ref6.commit;
      var company_name = payload.company_name,
        sellphone = payload.sellphone,
        city_name = payload.city_name,
        city_id = payload.city_id,
        address = payload.address,
        id = payload.id;
      commit('changeState', {
        name: 'updateInfoResult',
        data: null
      });
      return _updateInfo({
        company_name: company_name,
        sellphone: sellphone,
        city_name: city_name,
        city_id: city_id,
        address: address,
        id: id
      }).then(function (res) {
        commit('changeState', {
          name: 'updateInfoResult',
          data: res.data
        });
      });
    },
    getMediaCode: function getMediaCode(_ref7, payload) {
      var commit = _ref7.commit;
      var share_url = payload.share_url;
      commit('changeState', {
        name: 'mediaCodeData',
        data: 'loading'
      });
      return _getMediaCode({
        share_url: share_url
      }).then(function (res) {
        commit('changeState', {
          name: 'mediaCodeData',
          data: res.data
        });
      });
    },
    addMediaCode: function addMediaCode(_ref8, payload) {
      var commit = _ref8.commit;
      var user_id = payload.user_id,
        creator = payload.creator,
        modifier = payload.modifier,
        code = payload.code,
        type = payload.type,
        is_deleted = payload.is_deleted;
      commit('changeState', {
        name: 'addMediaResult',
        data: null
      });
      return _addMediaCode({
        user_id: user_id,
        creator: creator,
        modifier: modifier,
        code: code,
        type: type,
        is_deleted: is_deleted
      }).then(function (res) {
        commit('changeState', {
          name: 'addMediaResult',
          data: res.data
        });
      });
    },
    updateRemake: function updateRemake(_ref9, payload) {
      var commit = _ref9.commit;
      var remark = payload.remark,
        code = payload.code,
        id = payload.id;
      commit('changeState', {
        name: 'updateRemakeResult',
        data: null
      });
      return _updateRemake({
        remark: remark,
        code: code,
        id: id
      }).then(function (res) {
        commit('changeState', {
          name: 'updateRemakeResult',
          data: res.data
        });
      });
    }
  },
  mutations: {
    changeState: function changeState(state, payload) {
      var data = payload.data,
        name = payload.name;
      state[name] = data;
    }
  }
};
exports.default = _default;