"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'alertMarning',
  props: {
    description: {
      type: String,
      default: ' 因抖音私信能力变更，于6月1日起，高潜攀谈功能将暂停使用。直播价值潜客名单正常提供，可在「高潜攀谈-互动监测」页面查看，点击「私信」可主动私聊潜客'
    }
  }
};
exports.default = _default;