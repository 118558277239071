"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var openHost = process.env.VUE_APP_OPENDATA_API;
var url = "".concat(openHost, "/mirror-data");
var re = new _NetworldRequest.default({
  needMessage: false
}).getAxios();
var openNr = new _NetworldRequest.default({
  baseURL: url
}).getAxios();
function request(data) {
  return re(data).then(function (res) {
    if (res.data.errcode) {
      var msg = "网络不通畅，请重试";
      // Message({
      //   message: msg,
      //   type: 'error',
      //   duration: 6 * 1000
      // })
    }

    return res;
  });
}

/*
 *直播信息
 */
var liveMessage = 'douyin/live/info';

/*
 *  直播间直播时评论分析，车型跟品牌
 */
var commentAnalysis = '/douyin/live-polling/comment/analysis';

/* 
 *评论中热度前5的车型
 */
var commentHotCar = '/douyin/live-polling/series/hot';

/* 
 *直播观众性别分析
 */
var audience = '/douyin/live-polling/audience/analysis/gender';

/* 
*评论中热词
*/
var hotWord = '/douyin/live-polling/comment/hotwords';

/* 
*直播观众地区分布
*/
var audienceAdress = '/douyin/live-polling/audience/analysis/area';

/* 
*直播间直播时点赞数的总量，增量变化趋势
*/
// const diggTrend = '/douyin/live-polling/digg/trend'

/* 
*直播间直播时评论数的总量，增量变化趋势
*/
var commentTrend = '/douyin/live-polling/comment/trend';

/* 
*直播间直播时用户、礼物的变化趋势
*/
var liveRoomTrend = '/douyin/live/live-room-trend/';
var _default = {
  getLiveMessage: function getLiveMessage(params) {
    var roomId = params.roomId;
    return openNr.get("".concat(liveMessage, "/").concat(roomId));
  },
  getCommentAnalysis: function getCommentAnalysis(param) {
    return openNr.get(commentAnalysis, {
      params: {
        room_id: param
      }
    });
  },
  getHotCarType: function getHotCarType(param) {
    return openNr.get(commentHotCar, {
      params: {
        room_id: param
      }
    });
  },
  getAudience: function getAudience(param) {
    return openNr.get(audience, {
      params: {
        room_id: param
      }
    });
  },
  // 直播分析线条图
  getAnalysisChart: function getAnalysisChart(param) {
    return openNr.get("/douyin/live/statistical/chart/".concat(param));
  },
  getHotWord: function getHotWord(param) {
    return openNr.get(hotWord, {
      params: {
        room_id: param
      }
    });
  },
  getAudienceAdress: function getAudienceAdress(param) {
    return openNr.get(audienceAdress, {
      params: {
        room_id: param
      }
    });
  },
  // getDiggTrend(param){
  //     return openNr.get(diggTrend,{
  //         params:{
  //             room_id : param
  //         }
  //     })
  // },
  getCommentTrend: function getCommentTrend(param) {
    return openNr.get(commentTrend, {
      params: {
        room_id: param
      }
    });
  },
  getLiveRoomTrend: function getLiveRoomTrend(param) {
    return openNr.get("".concat(liveRoomTrend).concat(param));
  },
  // 获取mqtt配置
  getMqttConfig: function getMqttConfig(_ref) {
    var platformType = _ref.platformType,
      anchorId = _ref.anchorId,
      deviceId = _ref.deviceId;
    return request({
      url: "/mqtt/live/mqttClientSetting",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        platformType: platformType,
        anchorId: anchorId,
        deviceId: deviceId
      }
    });
  },
  // 获取禁言列表
  getForbiddenList: function getForbiddenList(_ref2) {
    var current = _ref2.current,
      page_size = _ref2.page_size,
      live_room_id = _ref2.live_room_id,
      platform = _ref2.platform,
      from_time = _ref2.from_time;
    return request({
      url: "/mqtt/live/forbidden/record/query",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        current: current,
        page_size: page_size,
        live_room_id: live_room_id,
        platform: platform,
        from_time: from_time
      }
    });
  },
  // 实时直播视频
  getLiveVideo: function getLiveVideo(params) {
    return openNr.get("/douyin/live/".concat(params));
  },
  //获取新增粉丝数与在线观看数
  getMqttFans: function getMqttFans(_ref3) {
    var platformType = _ref3.platformType,
      roomId = _ref3.roomId;
    return request({
      url: "/mqtt/live/increase-fans-watching-count",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        platformType: platformType,
        roomId: roomId
      }
    });
  }
};
exports.default = _default;