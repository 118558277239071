var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.mediaSettingVisible,
            closable: false,
            maskClosable: false,
            title:
              (_vm.isMediaSettingUpdate ? "修改" : "设置") + "客情主营媒体号",
          },
        },
        [
          !_vm.isMediaSettingUpdate
            ? _c("span", [_vm._v(" 请设置客情媒体号以使用功能。 ")])
            : _vm._e(),
          _vm._v(" 最多可选择 "),
          _c("span", { staticStyle: { color: "red", "font-weight": "bold" } }, [
            _vm._v(_vm._s(_vm.canBindMediaCount)),
          ]),
          _vm._v(" 个，如需更多请联系阿凡提工作人员。 "),
          _c(
            "div",
            { staticClass: "media-list" },
            [
              _c(
                "a-checkbox-group",
                {
                  staticStyle: { width: "100%" },
                  attrs: { value: _vm.bindMedia },
                  on: { change: _vm.handleMediaChange },
                },
                _vm._l(_vm.bindMediaList, function (item, index) {
                  return _c(
                    "label",
                    {
                      key: index,
                      staticClass: "media-info",
                      attrs: { for: "media" + index },
                    },
                    [
                      _c("label", { attrs: { for: "" } }),
                      _c(
                        "div",
                        { staticClass: "img" },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "avatar",
                              attrs: {
                                src: _vm.avatarFormat(item.avatar || ""),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      src: require("@/assets/dev/default_head_logo.png"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("img", {
                            staticClass: "dy-logo",
                            attrs: {
                              src: require("@/assets/icon/dy.png"),
                              alt: "",
                              srcset: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "name-info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.nickname)),
                        ]),
                        _c("span", [_vm._v(_vm._s(item.code))]),
                      ]),
                      _c("a-checkbox", {
                        key: "media" + index,
                        attrs: {
                          id: "media" + index,
                          value: item.authorId,
                          disabled:
                            !_vm.bindMedia.includes(item.authorId) &&
                            _vm.bindMedia.length === _vm.canBindMediaCount * 1,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c("div", [
            _vm._v(" 没有找到媒体号？查看 "),
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.setState({
                      label: "entryGuideVisible",
                      value: true,
                    })
                  },
                },
              },
              [_vm._v("审核中")]
            ),
            _vm._v(" 或 "),
            _c("a", { on: { click: _vm.getAddMediaAuthUrl } }, [
              _vm._v("点击添加"),
            ]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "mediaSettingVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      disabled: _vm.bindMedia.length === 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "mediaSettingCheckVisible",
                          value: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" 确定 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.mediaSettingCheckVisible,
            closable: false,
            maskClosable: false,
            title: "确认已选择的客情媒体号",
          },
        },
        [
          _c(
            "div",
            { staticClass: "media-list", staticStyle: { height: "465px" } },
            _vm._l(_vm.selectMediaList, function (item, index) {
              return _c("label", { key: index, staticClass: "media-info" }, [
                _c("label", { attrs: { for: "" } }),
                _c(
                  "div",
                  { staticClass: "img" },
                  [
                    _c(
                      "el-image",
                      {
                        staticClass: "avatar",
                        attrs: { src: _vm.avatarFormat(item.avatar || "") },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: require("@/assets/dev/default_head_logo.png"),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("img", {
                      staticClass: "dy-logo",
                      attrs: {
                        src: require("@/assets/icon/dy.png"),
                        alt: "",
                        srcset: "",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "name-info" }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.nickname)),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.code))]),
                ]),
              ])
            }),
            0
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "default",
                      disabled: _vm.isSettingMediaLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "mediaSettingCheckVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 重新选择 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.isSettingMediaLoading,
                    },
                    on: { click: _vm.setMedia },
                  },
                  [_vm._v(" 确认选择 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.addMediaVisible,
            closable: false,
            maskClosable: false,
            title: "设置客情主营媒体号",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("检测到未添加抖音媒体号，请在风火轮APP中添加"),
          ]),
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("a-icon", { attrs: { type: "exclamation-circle" } }),
              _vm._v(" 仅限抖音蓝V号和员工号 "),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "entryGuideVisible",
                          value: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" 查看添加入口 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "addMediaVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 我知道了 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.checkingVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v(" 添加的抖音号"),
            _c("span", { staticClass: "media-name" }, [
              _vm._v(_vm._s(_vm.checkMediaList.join("、"))),
            ]),
            _vm._v("正在审核中，请耐心等待.. "),
          ]),
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("a-icon", { attrs: { type: "exclamation-circle" } }),
              _vm._v(" 仅限抖音蓝V号和员工号 "),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "checkingVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 我知道了 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.authChangeTipsVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("检测到抖音授权项更新，请重新授权"),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "authChangeTipsVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkDouYinAuthorization(true)
                      },
                    },
                  },
                  [_vm._v(" 去授权 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            visible: _vm.authTipsVisible,
            closable: false,
            maskClosable: false,
            title: "温馨提示",
          },
        },
        [
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("风火轮将对您门店的抖音号私信、视频评论等信息进行管理"),
          ]),
          _c("div", { staticClass: "tips-text" }, [
            _vm._v("请先完成抖音号的授权"),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "default",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setState({
                          label: "authTipsVisible",
                          value: false,
                        })
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "30%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.isGetAuthUrlLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkDouYinAuthorization(true)
                      },
                    },
                  },
                  [_vm._v(" 去授权 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          staticClass: "auth-modal",
          attrs: {
            width: 540,
            visible: _vm.entryGuideVisible,
            maskClosable: false,
            footer: null,
            title: "查看审核中的媒体号",
          },
          on: {
            cancel: function ($event) {
              return _vm.setState({ label: "entryGuideVisible", value: false })
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { width: "300px" },
              attrs: { src: require("@/assets/dev/entry-guid.png"), alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }