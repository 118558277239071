"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "400px"
    },
    fansAmount: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    // this.initChart();
  },
  watch: {
    fansAmount: function fansAmount() {
      this.initChart();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: this.fansAmount.manufactors //["直接访问", "邮件营销", "联盟广告", "视频广告", "搜索引擎"]
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value"
        },
        yAxis: {
          type: "category",
          data: this.fansAmount.manufactors // ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },

        series: (0, _toConsumableArray2.default)(this.fansAmount.data.map(function (val) {
          val.type = "bar";
          val.barWidth = 20;
          val.stack = "总量";
          val.itemStyle = {
            color: 'rgba(54, 52, 142, 1)'
          };
          return val;
        }))
      });
    }
  }
};
exports.default = _default;