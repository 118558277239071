"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)();
var _default = {
  // 获取产品应答列表
  getProductReplyList: function getProductReplyList(params) {
    return request({
      url: "/productReply/list",
      method: 'GET',
      params: params
    });
  },
  // 新增产品
  addProduct: function addProduct(data) {
    return request({
      url: "/productReply/insertProduct",
      method: 'POST',
      data: data
    });
  },
  // 删除产品
  deleteProduct: function deleteProduct(params) {
    return request({
      url: "/productReply/deleteProduct",
      method: 'GET',
      params: params
    });
  },
  // 产品启用/停用
  switchProduct: function switchProduct(params) {
    return request({
      url: "/productReply/switchProduct",
      method: 'GET',
      params: params
    });
  },
  // 获取产品详情
  getProductDetail: function getProductDetail(productId) {
    return request({
      url: "/productReply/product/".concat(productId),
      method: 'GET'
    });
  },
  // 产品维护
  updateProduct: function updateProduct(data) {
    return request({
      url: "/productReply/updateProduct",
      method: 'POST',
      data: data
    });
  },
  // 产品排序
  sortProduct: function sortProduct(data) {
    return request({
      url: "/productReply/productSort",
      method: 'POST',
      data: data
    });
  },
  // 查询产品主播品牌启停详情
  getProductAuthorPrincipalSetting: function getProductAuthorPrincipalSetting(params) {
    return request({
      url: "/productReply/productAuthorPrincipalSetting",
      method: 'GET',
      params: params
    });
  },
  // 产品主播品牌启用/停用
  switchProductAuthorPrincipal: function switchProductAuthorPrincipal(params) {
    return request({
      url: "/productReply/switchProductAuthorPrincipal",
      method: 'GET',
      params: params
    });
  },
  // 获取产品策略组列表
  getProductStrategyGroupList: function getProductStrategyGroupList(params) {
    return request({
      url: "/productReply/productStrategyGroupList",
      method: 'GET',
      params: params
    });
  },
  // 获取产品策略组详情
  getProductStrategyGroupDetail: function getProductStrategyGroupDetail(strategyGroupId) {
    return request({
      url: "/productReply/strategyGroup/".concat(strategyGroupId),
      method: 'GET'
    });
  },
  // 新增产品策略组
  addProductStrategyGroup: function addProductStrategyGroup(data) {
    return request({
      url: "/productReply/insertStrategyGroup",
      method: 'POST',
      data: data
    });
  },
  // 编辑产品策略组
  updateProductStrategyGroup: function updateProductStrategyGroup(data) {
    return request({
      url: "/productReply/updateStrategyGroup",
      method: 'POST',
      data: data
    });
  },
  // 删除产品策略组
  deleteProductStrategyGroup: function deleteProductStrategyGroup(params) {
    return request({
      url: "/productReply/deleteStrategyGroup",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用产品策略组
  switchProductStrategyGroup: function switchProductStrategyGroup(params) {
    return request({
      url: "/productReply/switchStrategyGroup",
      method: 'GET',
      params: params
    });
  },
  // 产品排序
  sortProductStrategyGroup: function sortProductStrategyGroup(data) {
    return request({
      url: "/productReply/strategyGroupSort",
      method: 'POST',
      data: data
    });
  },
  // 查询策略组话术内容
  getStrategyGroupReplyMsg: function getStrategyGroupReplyMsg(params) {
    return request({
      url: "/productReply/strategyGroupReplyMsg",
      method: 'GET',
      params: params
    });
  },
  // 策略组话术维护
  updateStrategyGroupReplyMsg: function updateStrategyGroupReplyMsg(data) {
    return request({
      url: "/productReply/strategyGroupReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 获取全部产品列表
  getAllProductList: function getAllProductList(params) {
    return request({
      url: "/productReply/not/page/list",
      method: 'GET',
      params: params
    });
  },
  // 获取产品信息
  getProductInfo: function getProductInfo(params) {
    return request({
      url: "/productReply/product/info",
      method: 'GET',
      params: params
    });
  },
  // 获取关键词应答列表
  getKeywordResponseList: function getKeywordResponseList(params) {
    return request({
      url: "/chat-keyword-reply/web/list",
      method: 'GET',
      params: params
    });
  },
  // 新增关键词组
  addKeywordGroup: function addKeywordGroup(data) {
    return request({
      url: "/chat-keyword-reply/web/insert",
      method: 'POST',
      data: data
    });
  },
  // 维护关键词组
  updateKeywordGroup: function updateKeywordGroup(data) {
    return request({
      url: "/chat-keyword-reply/update",
      method: 'POST',
      data: data
    });
  },
  // 获取关键词组详情
  getKeywordGroupDetail: function getKeywordGroupDetail(id) {
    return request({
      url: "/chat-keyword-reply/".concat(id),
      method: 'GET'
    });
  },
  // 获取关键词应答话术列表
  getKeywordResponseScriptList: function getKeywordResponseScriptList(params) {
    return request({
      url: "/chat-keyword-reply/strategyReplyMsg",
      method: 'GET',
      params: params
    });
  },
  // 维护关键词应答话术
  updateKeywordResponseScriptList: function updateKeywordResponseScriptList(data) {
    return request({
      url: "/chat-keyword-reply/web/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 删除关键词组
  deleteKeywordResponse: function deleteKeywordResponse(groupId) {
    return request({
      url: "/chat-keyword-reply/delete/".concat(groupId),
      method: 'GET'
    });
  },
  // 排序关键词应答话术
  sortKeywordResponseList: function sortKeywordResponseList(data) {
    return request({
      url: "/chat-keyword-reply/sort",
      method: 'POST',
      data: data
    });
  },
  // 启用/禁用关键词应答策略
  switchKeywordResponseStrategy: function switchKeywordResponseStrategy(params) {
    return request({
      url: "/chat-keyword-reply/switch",
      method: 'GET',
      params: params
    });
  },
  // 查询关键词策略主播品牌启停详情
  getKeywordResponseAuthorPrincipalSetting: function getKeywordResponseAuthorPrincipalSetting(params) {
    return request({
      url: "/chat-keyword-reply/web/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 关键词策略主播品牌启用/停用
  switchKeywordResponseAuthorPrincipal: function switchKeywordResponseAuthorPrincipal(params) {
    return request({
      url: "/chat-keyword-reply/web/switch/author/principal",
      method: 'GET',
      params: params
    });
  },
  // 获取智能应答策略列表
  getSmartResponseList: function getSmartResponseList(data) {
    return request({
      url: "/intelligent/greeting/list",
      method: 'POST',
      data: data
    });
  },
  // 获取智能应答话术列表
  getSmartResponseScriptList: function getSmartResponseScriptList(params) {
    return request({
      url: "/intelligent/greeting/strategyReplyMsg",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用智能应答策略
  switchSmartResponseStrategy: function switchSmartResponseStrategy(params) {
    return request({
      url: "/intelligent/greeting/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护智能应答话术
  updateSmartResponseScriptList: function updateSmartResponseScriptList(data) {
    return request({
      url: "/intelligent/greeting/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 排序智能应答话术
  sortSmartResponseScriptList: function sortSmartResponseScriptList(data) {
    return request({
      url: "/intelligent/greeting/strategySort",
      method: 'POST',
      data: data
    });
  },
  // 查询策略主播品牌启停详情
  getSmartResponseAuthorPrincipalSetting: function getSmartResponseAuthorPrincipalSetting(params) {
    return request({
      url: "/intelligent/greeting/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 策略主播品牌启用/停用
  switchSmartResponseAuthorPrincipal: function switchSmartResponseAuthorPrincipal(params) {
    return request({
      url: "/intelligent/greeting/switch/author/principal",
      method: 'GET',
      params: params
    });
  },
  // 获取兜底应答策略列表
  getUndertakeResponseList: function getUndertakeResponseList(data) {
    return request({
      url: "/bottom-pocket/greeting/list",
      method: 'POST',
      data: data
    });
  },
  // 获取兜底应答话术列表
  getUndertakeResponseScriptList: function getUndertakeResponseScriptList(params) {
    return request({
      url: "/bottom-pocket/greeting/strategyReplyMsg",
      method: 'GET',
      params: params
    });
  },
  // 启用/禁用兜底应答策略
  switchUndertakeResponseStrategy: function switchUndertakeResponseStrategy(params) {
    return request({
      url: "/bottom-pocket/greeting/switchStrategy",
      method: 'GET',
      params: params
    });
  },
  // 维护兜底应答话术
  updateUndertakeResponseScriptList: function updateUndertakeResponseScriptList(data) {
    return request({
      url: "/bottom-pocket/greeting/strategyReplyMsg",
      method: 'POST',
      data: data
    });
  },
  // 排序兜底应答话术
  sortUndertakeResponseScriptList: function sortUndertakeResponseScriptList(data) {
    return request({
      url: "/bottom-pocket/greeting/strategySort",
      method: 'POST',
      data: data
    });
  },
  // 查询策略主播品牌启停详情
  getUndertakeResponseAuthorPrincipalSetting: function getUndertakeResponseAuthorPrincipalSetting(params) {
    return request({
      url: "/bottom-pocket/greeting/principal/setting",
      method: 'GET',
      params: params
    });
  },
  // 策略主播品牌启用/停用
  switchUndertakeResponseAuthorPrincipal: function switchUndertakeResponseAuthorPrincipal(params) {
    return request({
      url: "/bottom-pocket/greeting/switch/author/principal",
      method: 'GET',
      params: params
    });
  }
};
exports.default = _default;