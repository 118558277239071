"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    isAutoplay: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      videoUrl: ''
    };
  },
  methods: {
    openModal: function openModal(url) {
      this.visible = true;
      this.videoUrl = url;
    },
    onClose: function onClose() {
      this.visible = false;
      this.videoUrl = '';
    }
  }
};
exports.default = _default;