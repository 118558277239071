"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _home = _interopRequireDefault(require("@/api/home.js"));
var _attention = _interopRequireDefault(require("@/api/attention.js"));
var _vuex = require("vuex");
var _index = require("@/utils/index.js");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('auth'),
  mapState = _createNamespacedHelp.mapState,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  data: function data() {
    return {
      avatarFormat: _index.avatarFormat
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    entryGuideVisible: function entryGuideVisible(state) {
      return state.entryGuideVisible;
    },
    addMediaVisible: function addMediaVisible(state) {
      return state.addMediaVisible;
    },
    checkingVisible: function checkingVisible(state) {
      return state.checkingVisible;
    },
    mediaSettingVisible: function mediaSettingVisible(state) {
      return state.mediaSettingVisible;
    },
    mediaSettingCheckVisible: function mediaSettingCheckVisible(state) {
      return state.mediaSettingCheckVisible;
    },
    isMediaSettingUpdate: function isMediaSettingUpdate(state) {
      return state.isMediaSettingUpdate;
    },
    isSettingMediaLoading: function isSettingMediaLoading(state) {
      return state.isSettingMediaLoading;
    },
    authTipsVisible: function authTipsVisible(state) {
      return state.authTipsVisible;
    },
    authChangeTipsVisible: function authChangeTipsVisible(state) {
      return state.authChangeTipsVisible;
    },
    isGetAuthUrlLoading: function isGetAuthUrlLoading(state) {
      return state.isGetAuthUrlLoading;
    },
    bindMediaList: function bindMediaList(state) {
      return state.bindMediaList;
    },
    canBindMediaCount: function canBindMediaCount(state) {
      return state.canBindMediaCount;
    },
    bindMedia: function bindMedia(state) {
      return state.bindMedia;
    },
    checkMediaList: function checkMediaList(state) {
      return state.checkMediaList;
    },
    toRouteInfo: function toRouteInfo(state) {
      return state.toRouteInfo;
    },
    errorMessage: function errorMessage(state) {
      return state.errorMessage;
    }
  })), {}, {
    selectMediaList: function selectMediaList() {
      var _this = this;
      console.log(this.bindMediaList);
      return this.bindMediaList.filter(function (item) {
        return _this.bindMedia.includes(item.authorId);
      });
    }
  }),
  watch: {
    toRouteInfo: function toRouteInfo(val) {
      if (val) {
        this.$router.replace(val);
      }
    },
    errorMessage: function errorMessage(val) {
      if (val) {
        this.$message({
          customClass: 'z-index-10002',
          type: 'error',
          message: val.substring(0, val.indexOf('?'))
        });
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['setState', 'checkDouYinAuthorization', 'setMedia', 'getAuthUrl', 'getAddMediaAuthUrl'])), {}, {
    handleMediaChange: function handleMediaChange(value) {
      this.setState({
        label: 'bindMedia',
        value: value
      });
    }
  }),
  created: function created() {
    var _this2 = this;
    if (!window.location.href.includes('/login') && !window.location.href.includes('/auth_tips')) {
      localStorage.homeUrl = window.location.href;
    }
    if (!window.location.href.includes('/login')) {
      this.checkDouYinAuthorization();
    }
    this.$router.beforeEach(function (to, from, next) {
      if (to.path && to.name !== 'login' && to.name !== 'auth_tips') {
        localStorage.homeUrl = to.path;
        _this2.setState({
          label: 'nextFunction',
          value: next
        });
        _this2.checkDouYinAuthorization();
      } else {
        next();
        _this2.setState({
          label: 'nextFunction',
          value: null
        });
      }
    });
  }
};
exports.default = _default;