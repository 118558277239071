"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
// svg component
// register globally
_vue.default.component('svg-icon', _SvgIcon.default);
var req = require.context('./svg', false, /\.svg$/);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
var res = requireAll(req);
var _default = res;
exports.default = _default;