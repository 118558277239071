"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("../mixins/resize"));
//
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "line-chart"
    },
    id: {
      type: String,
      default: "line-chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "400px"
    },
    fansTrend: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    this.initChart();
  },
  watch: {
    fansTrend: function fansTrend() {
      this.initChart();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        // title: {
        //   text: "折线图堆叠"
        // },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: this.fansTrend.data.map(function (val) {
            return val.name;
          }) // ["邮件营销", "联盟广告", "视频广告", "直接访问", "搜索引擎"]
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.fansTrend.histogram //["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },

        yAxis: {
          type: "value"
        },
        series: (0, _toConsumableArray2.default)(this.fansTrend.data.map(function (val) {
          val.type = "line";
          val.stack = "总量";
          val.itemStyle = {
            color: "rgba(54, 52, 142, 1)"
          };
          return val;
        }))
      });
    }
  }
};
exports.default = _default;