"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)("live_stream"),
  mapState = _createNamespacedHelp.mapState,
  mapMutations = _createNamespacedHelp.mapMutations,
  mapActions = _createNamespacedHelp.mapActions;
var _default = {
  data: function data() {
    return {
      month: new Date(),
      days: "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var date = new Date();
          var curMonth = date.getMonth();
          var curYear = date.getFullYear();
          if (time.getFullYear() === curYear) {
            return time.getMonth() > curMonth || time.getMonth() <= time.getMonth() - 5;
          } else {
            return time.getFullYear();
          }
        }
      },
      week: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      date: [],
      dateWeek: [],
      active: true,
      activeNum: 1
    };
  },
  computed: _objectSpread({}, mapState({
    calenderArr: function calenderArr(state) {
      return state.calenderArr;
    }
  })),
  mounted: function mounted() {
    this.getMonth(this.month);
    this.getDateRange(this.month);
  },
  methods: _objectSpread(_objectSpread({
    // 获取某月1号星期几
    getMonth: function getMonth(date) {
      var strDate = date.getDate();
      var week = date.getDay();
      if (strDate !== 1) {
        var dateNow = new Date();
        dateNow = new Date(date.setDate(1));
        week = dateNow.getDay();
      }
      var weekNum = week ? week - 1 : 6;
      this.dateWeek = new Array(weekNum);
    },
    // 获取时间范围
    getDateRange: function getDateRange(date) {
      var d = new Date();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var years = date.getYear();
      var value = new Date(years, month, 0);
      var days = value.getDate();
      month = month >= 10 ? month : "0".concat(month);
      var beginTime = "".concat(year, "-").concat(month, "-01 00:00:00");
      var endTime = "";
      var h = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      if (d.getMonth() + 1 == month) {
        var dateN = d.getDate() < 10 ? "0".concat(d.getDate()) : d.getDate();
        endTime = "".concat(year, "-").concat(month, "-").concat(dateN, " ").concat(h, ":").concat(m, ":").concat(s);
      } else {
        endTime = "".concat(year, "-").concat(month, "-").concat(days, " 23:59:59");
      }
      this.changeState({
        data: beginTime,
        name: "begin_time"
      });
      this.changeState({
        data: endTime,
        name: "end_time"
      });
      this.changeState({
        data: date,
        name: 'selectDate'
      });
      this.getLiveCalender();
    },
    // 获取某天直播列表
    getDateLive: function getDateLive(index) {
      this.activeNum = index;
    },
    selectMonth: function selectMonth() {
      this.getMonth(this.month);
      this.getDateRange(this.month);
    },
    selectDateLive: function selectDateLive(index) {
      this.CHANGE_LIVE_LIST({
        filterDate: index
      });
    }
  }, mapActions(["getLiveCalender"])), mapMutations(["changeState", 'CHANGE_MONTH_DATE', 'CHANGE_LIVE_LIST']))
};
exports.default = _default;