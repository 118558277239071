"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var _elementUi = require("element-ui");
// import request from '@/utils/NetworldRequest'

var re = new _NetworldRequest.default({
  needMessage: false
}).getAxios();
function request(data) {
  return re(data).then(function (res) {
    if (res.data.errcode) {
      var msg = "网络不通畅，请重试";
      // Message({
      //   message: msg,
      //   type: 'error',
      //   duration: 6 * 1000
      // })
    }

    return res;
  });
}
var _default = {
  // 获取mqtt配置
  getMqttConfig: function getMqttConfig(_ref) {
    var platformType = _ref.platformType,
      anchorId = _ref.anchorId,
      deviceId = _ref.deviceId;
    return request({
      url: "/mqtt/live/mqttClientSetting",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        platformType: platformType,
        anchorId: anchorId,
        deviceId: deviceId
      }
    });
  },
  // 直播间粉丝跟主播的互动信息
  getPollingInfo: function getPollingInfo(_ref2) {
    var type = _ref2.type,
      fans_id = _ref2.fans_id,
      author_id = _ref2.author_id;
    return request({
      url: "douyin-live-polling/get-fans-info",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        type: type,
        fans_id: fans_id,
        author_id: author_id
      }
    });
  },
  // 查询价值互动
  getPollingV: function getPollingV(_ref3) {
    var type = _ref3.type,
      fans_id = _ref3.fans_id,
      author_id = _ref3.author_id,
      current = _ref3.current,
      page_size = _ref3.page_size,
      comment_validate = _ref3.comment_validate;
    return request({
      url: "/douyin-live-polling/get-fans-polling",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        type: type,
        fans_id: fans_id,
        author_id: author_id,
        current: current,
        page_size: page_size,
        comment_validate: comment_validate
      }
    });
  },
  // 查询全部互动
  getPollingAll: function getPollingAll(_ref4) {
    var type = _ref4.type,
      fans_id = _ref4.fans_id,
      author_id = _ref4.author_id,
      current = _ref4.current,
      page_size = _ref4.page_size,
      comment_validate = _ref4.comment_validate;
    return request({
      url: "/douyin-live-polling/get-fans-polling",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        type: type,
        fans_id: fans_id,
        author_id: author_id,
        current: current,
        page_size: page_size,
        comment_validate: comment_validate
      }
    });
  },
  // 直播间粉丝意向
  getIntention: function getIntention(_ref5) {
    var type = _ref5.type,
      comment_validate = _ref5.comment_validate,
      fans_id = _ref5.fans_id,
      author_id = _ref5.author_id;
    return request({
      url: "/douyin-live-polling/get-series-and-area",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        type: type,
        comment_validate: comment_validate,
        fans_id: fans_id,
        author_id: author_id
      }
    });
  },
  // 查询政策文件
  getFile: function getFile(_ref6) {
    var series_id = _ref6.series_id;
    return request({
      url: '/api/monitor/accurate/v1',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        series_id: series_id
      }
    });
  },
  // 获取车型配置分类列表
  getCartypeConfig: function getCartypeConfig(_ref7) {
    var series_id = _ref7.series_id;
    return request({
      url: '/api/monitor/cartype-config',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        series_id: series_id
      }
    });
  },
  // 获取车系竞品列表
  getCompetitor: function getCompetitor(_ref8) {
    var series_id = _ref8.series_id;
    return request({
      url: "/api/monitor/".concat(series_id, "/v1"),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 获取供应商关联的直播账号列表
  getUserList: function getUserList(_ref9) {
    var user_id = _ref9.user_id;
    return request({
      url: '/mirror-data/live/account/list',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        user_id: user_id
      }
    });
  },
  // 获取优惠信息
  getDiscount: function getDiscount(_ref10) {
    var cartypeId = _ref10.cartypeId,
      discount_price = _ref10.discount_price;
    return request({
      url: "/api/monitor/media/".concat(cartypeId),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        discount_price: discount_price
      }
    });
  },
  // 获取车型配置
  getCarConfig: function getCarConfig(_ref11) {
    var cartypeId = _ref11.cartypeId;
    return request({
      url: "/api/monitor/".concat(cartypeId, "/configs/v1"),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 根据车系ID厂商ID获取车系资料
  getImgById: function getImgById(_ref12) {
    var series_id = _ref12.series_id;
    return request({
      url: "/mirror-data/series/information/list",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        series_id: series_id
      }
    });
  },
  // 获取用户最近一场直播潜在客户
  getCommentUsers: function getCommentUsers(_ref13) {
    var author_id = _ref13.author_id,
      type = _ref13.type;
    return request({
      url: "/mirror-data/".concat(type, "/live-polling/comment-users"),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        author_id: author_id
      }
    });
  },
  // 主播个人信息
  getAuthorInfo: function getAuthorInfo(_ref14) {
    var user_id = _ref14.user_id;
    return request({
      url: "/account/author-list",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        user_id: user_id
      }
    });
  },
  // 查询车系下拉列表
  getSeriesList: function getSeriesList() {
    return request({
      url: "/mirror-data/customer_favorite_series/getSeries",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 查询车系下拉列表（潜客挖掘 -- 关联当前主播的）
  getSeriesListById: function getSeriesListById(_ref15) {
    var author_id = _ref15.author_id,
      origin = _ref15.origin;
    return request({
      url: "/mirror-data/customer_es/interested/series",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        author_id: author_id,
        origin: origin
      }
    });
  },
  // 省市查询
  getAreaList: function getAreaList(_ref16) {
    var parent_id = _ref16.parent_id,
      level = _ref16.level;
    return request({
      url: "/mirror-data/customer_favorite_series/areas",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        parent_id: parent_id,
        level: level
      }
    });
  },
  // 意向客户添加
  addUser: function addUser(_ref17) {
    var author_id = _ref17.author_id,
      platform_id = _ref17.platform_id,
      nickname = _ref17.nickname,
      gender = _ref17.gender,
      avatar_url = _ref17.avatar_url,
      favorite_level = _ref17.favorite_level,
      province_code = _ref17.province_code,
      city_code = _ref17.city_code,
      origin = _ref17.origin,
      seriesStr = _ref17.seriesStr,
      code = _ref17.code;
    var data = {
      author_id: author_id,
      platform_id: platform_id,
      nickname: nickname,
      gender: gender,
      avatar_url: avatar_url,
      favorite_level: favorite_level,
      province_code: province_code,
      city_code: city_code,
      origin: origin,
      seriesStr: seriesStr,
      code: code
    };
    // data = JSON.stringify(data)
    return request({
      url: "/mirror-data/customer_favorite_series/save",
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      data: data
    }).then(function (res) {
      if (res.errcode && res.errcode == 2001) {
        // throw new Error(res.errmsg || "接口报错啦")
        console.error(res.errmsg || "接口报错啦");
      }
      return res;
    });
  },
  // 意向客户编辑
  updateUser: function updateUser(_ref18) {
    var id = _ref18.id,
      favorite_level = _ref18.favorite_level,
      province_code = _ref18.province_code,
      city_code = _ref18.city_code,
      seriesStr = _ref18.seriesStr,
      origin = _ref18.origin;
    return request({
      url: "/mirror-data/customer_favorite_series/update",
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      data: {
        id: id,
        favorite_level: favorite_level,
        province_code: province_code,
        city_code: city_code,
        seriesStr: seriesStr,
        origin: origin
      }
    });
  },
  // 修改前查询单个用户
  getUserInfo: function getUserInfo(_ref19) {
    var id = _ref19.id;
    return request({
      url: "/mirror-data/customer_favorite_series/find/".concat(id),
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 购车城市下拉列表
  getBuyCarList: function getBuyCarList() {
    return request({
      url: "/mirror-data/customer_favorite_series/order/city",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 购车城市下拉列表(省市分开)
  getBuyCarListProps: function getBuyCarListProps(_ref20) {
    var parent_id = _ref20.parent_id,
      level = _ref20.level;
    return request({
      url: "/mirror-data/customer_favorite_series/areas",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        parent_id: parent_id,
        level: level
      }
    });
  },
  // 意向跟踪列表
  getTrackingList: function getTrackingList(_ref21) {
    var nickname = _ref21.nickname,
      code = _ref21.code,
      city_code = _ref21.city_code,
      days = _ref21.days,
      series_ids = _ref21.series_ids,
      page_size = _ref21.page_size,
      current = _ref21.current;
    return request({
      url: "/mirror-data/customer_favorite_series/list",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        nickname: nickname,
        code: code,
        city_code: city_code,
        days: days,
        series_ids: series_ids,
        page_size: page_size,
        current: current
      }
    });
  },
  // 意向统计
  getTrackingNumber: function getTrackingNumber(_ref22) {
    var days = _ref22.days;
    return request({
      url: "/mirror-data/customer_favorite_series/favorite_series_list",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        days: days
      }
    });
  },
  // 忽略
  deleteUser: function deleteUser(_ref23) {
    var id = _ref23.id;
    return request({
      url: "/mirror-data/customer_favorite_series/del/".concat(id),
      method: 'PUT',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 潜客列表 - new
  getExcavateList: function getExcavateList(_ref24) {
    var nickname = _ref24.nickname,
      code = _ref24.code,
      city_code = _ref24.city_code,
      days = _ref24.days,
      series_ids = _ref24.series_ids,
      page_size = _ref24.page_size,
      current = _ref24.current,
      author_id = _ref24.author_id,
      origin = _ref24.origin;
    return request({
      url: "/mirror-data/customer_es/list",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        nickname: nickname,
        code: code,
        city_code: city_code,
        days: days,
        series_ids: series_ids,
        page_size: page_size,
        current: current,
        author_id: author_id,
        origin: origin
      }
    });
  },
  // 留资记录列表
  getCustomerList: function getCustomerList(_ref25) {
    var author_id = _ref25.author_id,
      current = _ref25.current,
      page_size = _ref25.page_size,
      contact_begin_time = _ref25.contact_begin_time,
      contact_end_time = _ref25.contact_end_time,
      visit_begin_time = _ref25.visit_begin_time,
      visit_end_time = _ref25.visit_end_time;
    return request({
      url: "/revisit/customer-contact-list",
      method: 'GET',
      baseURL: process.env.VUE_APP_LIVE_CRM_API,
      params: {
        author_id: author_id,
        current: current,
        page_size: page_size,
        contact_begin_time: contact_begin_time,
        contact_end_time: contact_end_time,
        visit_begin_time: visit_begin_time,
        visit_end_time: visit_end_time
      }
    });
  },
  // 检查待更新
  getIsHaveNew: function getIsHaveNew(_ref26) {
    var origin = _ref26.origin,
      author_id = _ref26.author_id;
    return request({
      url: "/mirror-data/customer_es/crawl-data",
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        origin: origin,
        author_id: author_id
      }
    });
  }
};
exports.default = _default;