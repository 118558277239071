"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
var _blackUser = _interopRequireDefault(require("./blackUser.json"));
var openNr = new _NetworldRequest.default().getAxios();
console.log(_blackUser.default);
// export default {
//     getBlackList() {
//         return openNr.get(`//afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/json/blackUser.json`)
//     },
// }
var _default = _blackUser.default.data;
exports.default = _default;