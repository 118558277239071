"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var prefix = "AFT-OVERALL-ADMIN";
var _default = {
  setCookies: function setCookies(key, val, expires) {
    _jsCookie.default.set(prefix + key, val, {
      expires: expires
    });
  },
  getCookies: function getCookies(key) {
    return _jsCookie.default.get(prefix + key);
  },
  removeCookies: function removeCookies(key) {
    _jsCookie.default.remove(prefix + key);
  },
  getCasToken: function getCasToken() {
    return _jsCookie.default.get(prefix + 'token');
  }
};
exports.default = _default;