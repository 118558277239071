var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "short-management-container" }, [
    _c("div", { staticClass: "short-management-container-main" }, [
      _c("div", { staticClass: "short-management-container-main-left" }, [
        _c(
          "div",
          { staticClass: "refresh" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.refreshClick },
              },
              [_vm._v("刷新 ")]
            ),
          ],
          1
        ),
        _vm.videoData
          ? _c(
              "ul",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.loadVideoListData,
                    expression: "loadVideoListData",
                  },
                ],
                staticClass: "list",
                attrs: {
                  id: "videoList",
                  "infinite-scroll-distance": 30,
                  "infinite-scroll-disabled": "disabledVideo",
                },
              },
              [
                _vm._l(_vm.videoData.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        _vm.currentVideo.item_id == item.item_id
                          ? "left-item active"
                          : "left-item",
                      on: {
                        click: function ($event) {
                          return _vm.videoClick(item)
                        },
                      },
                    },
                    [
                      _c("CrmVideoItem", { attrs: { videoData: item } }),
                      _c("div", { staticClass: "count-div" }, [
                        _c("span", { staticClass: "f14" }, [
                          _vm._v("近7天 评论新增"),
                        ]),
                        _c("span", { staticClass: "f34 fw" }, [
                          _vm._v(_vm._s(item.nday_new_comment)),
                        ]),
                        _c("span", { staticClass: "f14" }, [
                          _vm._v("近7天 价值新增"),
                        ]),
                        _c("span", { staticClass: "f34 fw c-r" }, [
                          _vm._v(_vm._s(item.week_new_comment_validate)),
                        ]),
                      ]),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentVideo.item_id == item.item_id,
                            expression: "currentVideo.item_id == item.item_id",
                          },
                        ],
                        staticClass: "el-icon-caret-right right-icon",
                      }),
                    ],
                    1
                  )
                }),
                _vm.loadingVideo
                  ? _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("加载中..."),
                    ])
                  : _vm._e(),
                _vm.noMoreVideo
                  ? _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("没有更多了"),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "short-management-container-main-right" }, [
        _c("div", { staticClass: "fw f20 title" }, [
          _vm._v(" " + _vm._s(_vm.currentVideo.title) + " "),
        ]),
        _c(
          "div",
          { staticClass: "right-comment" },
          [
            _c(
              "el-tabs",
              {
                staticStyle: { height: "100%" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { label: "全部评论", name: "first" },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c("span", { staticClass: "f12" }, [_vm._v("只看近")]),
                        _c("el-input", {
                          staticClass: "mini-input",
                          staticStyle: { width: "70px", margin: "0 10px" },
                          model: {
                            value: _vm.searchNumber,
                            callback: function ($$v) {
                              _vm.searchNumber = _vm._n($$v)
                            },
                            expression: "searchNumber",
                          },
                        }),
                        _c("span", { staticClass: "f12" }, [_vm._v("天")]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "12px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.loadCommentListData(true)
                              },
                            },
                          },
                          [_vm._v("确定 ")]
                        ),
                      ],
                      1
                    ),
                    _vm.commentData
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.loadCommentListData,
                                expression: "loadCommentListData",
                              },
                            ],
                            staticClass: "list",
                            attrs: {
                              id: "commentList",
                              "infinite-scroll-distance": 30,
                              "infinite-scroll-disabled": "disabledComment",
                            },
                          },
                          [
                            _vm._l(
                              _vm.commentData.list,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.currentCommentItem.comment_id ==
                                        item.comment_id
                                          ? "right-comment-item active"
                                          : "right-comment-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.commentClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "c-g f12" }, [
                                        _vm._v(
                                          " " + _vm._s(item.create_time) + " "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "f16",
                                          staticStyle: { margin: "5px 0 0px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.content) + " "
                                          ),
                                          item.scene_name
                                            ? _c(
                                                "a-tag",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "4px",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { color: "#f59a23" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.gotoScenePage(
                                                        item.scene_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.scene_name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.currentCommentItem.comment_id ==
                                        item.comment_id &&
                                      _vm.replyData &&
                                      _vm.replyData.pagination
                                        ? _c(
                                            "div",
                                            { staticClass: "total-number c-r" },
                                            [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.replyData.pagination
                                                      .total
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.replyData &&
                                  _vm.openCommentId == item.comment_id
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(
                                            _vm.replyData.list,
                                            function (item2, replyIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index + "_" + replyIndex,
                                                  class:
                                                    _vm.currentCommentItem
                                                      .comment_id ==
                                                    item2.comment_id
                                                      ? "right-comment-reply-item active"
                                                      : "right-comment-reply-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.replyCommentClick(
                                                        item2
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "c-g f12" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item2.create_time
                                                          ) +
                                                          " "
                                                      ),
                                                      item2.is_mine == 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "c-r",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "16px",
                                                              },
                                                            },
                                                            [_vm._v("作者")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "f14",
                                                      staticStyle: {
                                                        margin: "5px 0 10px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item2.content
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          !_vm.noMoreReply
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "c-blue",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.loadReplyListData(
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 查看更多 ")]
                                              )
                                            : _vm._e(),
                                          _vm.loadingReply
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [_vm._v(" 加载中... ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            _vm.loadingComment
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(" 加载中... ")]
                                )
                              : _vm._e(),
                            !_vm.loadingComment && _vm.noMoreComment
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(" 没有更多了 ")]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: {
                      label:
                        "只看价值评论(" +
                        (_vm.commentDataV ? _vm.commentDataV.total || 0 : 0) +
                        ")",
                      name: "second",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c("span", { staticClass: "f12" }, [_vm._v("只看近")]),
                        _c("el-input", {
                          staticClass: "mini-input",
                          staticStyle: { width: "70px", margin: "0 10px" },
                          model: {
                            value: _vm.searchNumberV,
                            callback: function ($$v) {
                              _vm.searchNumberV = _vm._n($$v)
                            },
                            expression: "searchNumberV",
                          },
                        }),
                        _c("span", { staticClass: "f12" }, [_vm._v("天")]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "12px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.loadCommentListDataV(true)
                              },
                            },
                          },
                          [_vm._v("确定 ")]
                        ),
                      ],
                      1
                    ),
                    _vm.commentDataV
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.loadCommentListDataV,
                                expression: "loadCommentListDataV",
                              },
                            ],
                            staticClass: "list",
                            attrs: {
                              id: "commentListV",
                              "infinite-scroll-distance": 30,
                              "infinite-scroll-disabled": "disabledCommentV",
                            },
                          },
                          [
                            _vm._l(
                              _vm.commentDataV.list,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.currentCommentItem.comment_id ==
                                        item.comment_id
                                          ? "right-comment-item active"
                                          : "right-comment-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.commentClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "c-g f12" }, [
                                        _vm._v(
                                          " " + _vm._s(item.create_time) + " "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "f16",
                                          staticStyle: { margin: "5px 0 0px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.content) + " "
                                          ),
                                          item.scene_name
                                            ? _c(
                                                "a-tag",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "4px",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { color: "#f59a23" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.gotoScenePage(
                                                        item.scene_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.scene_name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.currentCommentItem.comment_id ==
                                        item.comment_id &&
                                      _vm.replyData &&
                                      _vm.replyData.pagination
                                        ? _c(
                                            "div",
                                            { staticClass: "total-number c-r" },
                                            [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.replyData.pagination
                                                      .total
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.replyData &&
                                  _vm.openCommentId == item.comment_id
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(
                                            _vm.replyData.list,
                                            function (item2, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  class:
                                                    _vm.currentCommentItem
                                                      .comment_id ==
                                                    item2.comment_id
                                                      ? "right-comment-reply-item active"
                                                      : "right-comment-reply-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.replyCommentClick(
                                                        item2
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "c-g f12" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item2.create_time
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "f14",
                                                      staticStyle: {
                                                        margin: "5px 0 10px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item2.content
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          !_vm.noMoreReply
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "c-blue",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.loadReplyListData(
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 查看更多 ")]
                                              )
                                            : _vm._e(),
                                          _vm.loadingReply
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [_vm._v(" 加载中... ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            _vm.loadingCommentV
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(" 加载中... ")]
                                )
                              : _vm._e(),
                            !_vm.loadingCommentV && _vm.noMoreCommentV
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(" 没有更多了 ")]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.currentCommentItem
          ? _c("div", { staticClass: "right-bottom" }, [
              _c(
                "div",
                { staticClass: "right-info" },
                [
                  _c("div", { staticClass: "top" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/dev/defult-avatar.png"),
                        width: "50",
                        alt: "",
                      },
                    }),
                    _c("div", [
                      _c("div", { staticClass: "f16 fw" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.currentCommentItem.comment_user_id) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "f12 c-g mt8" }, [
                        _vm._v(
                          " " + _vm._s(_vm.currentCommentItem.create_time) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "f16 fw c-blue mt8",
                      staticStyle: { width: "95%" },
                    },
                    [_vm._v(" " + _vm._s(_vm.currentCommentItem.content) + " ")]
                  ),
                  _c("el-input", {
                    staticClass: "resizeNone",
                    attrs: {
                      type: "textarea",
                      maxlength: "100",
                      rows: 3,
                      autosize: false,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.replyValue,
                      callback: function ($$v) {
                        _vm.replyValue =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "replyValue",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "bottom" },
                    [
                      _vm._m(0),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.replyBtnClick },
                        },
                        [_vm._v("回复 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "other-comment" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticStyle: { height: "100%" },
                      model: {
                        value: _vm.otherName,
                        callback: function ($$v) {
                          _vm.otherName = $$v
                        },
                        expression: "otherName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          staticStyle: { height: "100%" },
                          attrs: {
                            label:
                              "此用户其他评论(" +
                              (_vm.otherData ? _vm.otherData.total || 0 : 0) +
                              ")",
                            name: "other",
                          },
                        },
                        [
                          _vm.otherData
                            ? _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "infinite-scroll",
                                      rawName: "v-infinite-scroll",
                                      value: _vm.loadOtherListData,
                                      expression: "loadOtherListData",
                                    },
                                  ],
                                  staticClass: "list",
                                  attrs: {
                                    id: "otherList",
                                    "infinite-scroll-distance": 30,
                                    "infinite-scroll-disabled": "disabledLeft",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.otherData.list,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "left-list-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "c-g f12" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.create_time) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "f16",
                                              staticStyle: {
                                                margin: "5px 0 16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.content) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm.loadingOther
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" 加载中... ")]
                                      )
                                    : _vm._e(),
                                  _vm.noMoreOther
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" 没有更多了 ")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "f14 c-g tips" }, [
      _c("div", [_vm._v("· 评论回复成功将展示在抖音评论区，所有人可见")]),
      _c("div", [
        _vm._v(
          " · 回复内容请勿色情/暴力/政治等敏感词汇、抖音平台外部链接等内容，可能会触发抖音评论的风控规则，导致回复评论不可见等问题 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }