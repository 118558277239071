var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.visible, footer: null, width: "480px" },
          on: { cancel: _vm.handlePreviewCancel },
        },
        [
          !_vm.previewSrc
            ? _c("div", [_vm._v("没有可预览链接")])
            : [
                _vm.is_video
                  ? _c("div", [
                      _c(
                        "video",
                        {
                          staticStyle: { height: "480px", width: "100%" },
                          attrs: {
                            id: "video",
                            src: _vm.video_preview_src,
                            controls: "",
                            autoplay: _vm.isAutoplay,
                            controlslist: "nodownload",
                          },
                          on: {
                            contextmenu: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [_vm._v(" 你的浏览器不支持video ")]
                      ),
                    ])
                  : _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c("img", {
                        staticClass: "preview",
                        attrs: { src: _vm.previewSrc },
                      }),
                    ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }