var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_box" },
    [
      _c(
        "a-card",
        [
          _c(
            "p",
            [
              _c("a-icon", {
                staticClass: "icon_s",
                attrs: { type: "exclamation-circle" },
              }),
              _vm._v("仅展示近30天（含当天）内发布的短视频数据 "),
            ],
            1
          ),
          _c(
            "a-tabs",
            {
              on: { change: _vm.changeTabs },
              model: {
                value: _vm.tab_active,
                callback: function ($$v) {
                  _vm.tab_active = $$v
                },
                expression: "tab_active",
              },
            },
            [
              _c("a-tab-pane", { key: 0, attrs: { tab: "全部" } }),
              _c("a-tab-pane", { key: 6, attrs: { tab: "价值评论" } }),
              _c("a-tab-pane", { key: 2, attrs: { tab: "策略已响应" } }),
              _c("a-tab-pane", { key: 1, attrs: { tab: "策略未响应" } }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "a-form-model",
                _vm._b(
                  { ref: "searchFrom", attrs: { model: _vm.search_form } },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "命中规则/策略" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    "default-active-first-option": false,
                                    "filter-option": _vm.filterOption,
                                    placeholder: "请选择规则/策略",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.sceneId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search_form, "sceneId", $$v)
                                    },
                                    expression: "search_form.sceneId",
                                  },
                                },
                                _vm._l(_vm.attention_list, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.sceneId,
                                      attrs: { value: item.sceneId },
                                    },
                                    [_vm._v(" " + _vm._s(item.sceneName) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "评论内容" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入评论关键词",
                                  maxLength: 100,
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.search_form.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search_form, "content", $$v)
                                  },
                                  expression: "search_form.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "来源视频" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    "default-active-first-option": false,
                                    "filter-option": _vm.filterOption,
                                    placeholder: "请选择视频",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.search_form.videoId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search_form, "videoId", $$v)
                                    },
                                    expression: "search_form.videoId",
                                  },
                                },
                                _vm._l(_vm.video_list, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.videoId,
                                      attrs: { value: item.videoId },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.videoTitle) + " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "评论时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: ["开始时间", "结束时间"],
                                  format: "YYYY-MM-DD",
                                  inputReadOnly: "",
                                  "disabled-date": _vm.disabledDate,
                                  ranges: {
                                    近7天: [
                                      this.moment().subtract(6, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                    近15天: [
                                      this.moment().subtract(14, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                    近30天: [
                                      this.moment().subtract(29, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                  },
                                },
                                on: { change: _vm.onChangeDate },
                                model: {
                                  value: _vm.comment_time,
                                  callback: function ($$v) {
                                    _vm.comment_time = $$v
                                  },
                                  expression: "comment_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.tab_active === 2 || _vm.tab_active === 6,
                              expression:
                                "tab_active === 2 || tab_active === 6",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "响应时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: ["开始时间", "结束时间"],
                                  format: "YYYY-MM-DD",
                                  inputReadOnly: "",
                                  "disabled-date": _vm.disabledDate,
                                  ranges: {
                                    近7天: [
                                      this.moment().subtract(6, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                    近15天: [
                                      this.moment().subtract(14, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                    近30天: [
                                      this.moment().subtract(29, "days"),
                                      this.moment().endOf("day"),
                                    ],
                                  },
                                },
                                on: { change: _vm.onChangeResDate },
                                model: {
                                  value: _vm.response_time,
                                  callback: function ($$v) {
                                    _vm.response_time = $$v
                                  },
                                  expression: "response_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-wrap" },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10p",
                          attrs: { icon: "redo", type: "dashed" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10p",
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchClick },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("cn-table", {
            ref: "cntable",
            attrs: {
              configPayload: _vm.configPayload,
              selectValue: _vm.search_form,
              initData: false,
              rowKey: function (record, index) {
                return record.id
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("text-ellipsis", {
                        attrs: { value: text.content, limit: 2 },
                      }),
                    ],
                    1
                  )
                },
              },
              {
                key: "videoTitle",
                fn: function (text) {
                  return _c(
                    "a",
                    {
                      staticClass: "video-title",
                      on: {
                        click: function ($event) {
                          return _vm.goInteraction(
                            text.authorId,
                            text.videoId,
                            text.authorOpenId
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(text.videoTitle ? text.videoTitle : "未知") +
                          " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "sceneName",
                fn: function (text) {
                  return _c("div", {}, [
                    text.sceneName
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                key: text.id,
                                attrs: { type: "warning", effect: "dark" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "max-width": "150px" } },
                                  [
                                    _c("text-ellipsis", {
                                      attrs: { value: text.sceneName },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("div", [_vm._v("无")]),
                    text.sceneType === 1 && text.videoRuleIsOpened === 0
                      ? _c(
                          "a",
                          {
                            staticClass: "btn_style",
                            on: {
                              click: function ($event) {
                                return _vm.openAutoReplayDrawer(
                                  text.sceneId,
                                  text.sceneName,
                                  text.sceneType
                                )
                              },
                            },
                          },
                          [_vm._v(" 启用自动回复 ")]
                        )
                      : _vm._e(),
                    text.sceneType === 2 && text.keyWordRuleIsOpened === 0
                      ? _c(
                          "a",
                          {
                            staticClass: "btn_style",
                            on: {
                              click: function ($event) {
                                return _vm.openAutoReplayDrawer(
                                  text.sceneId,
                                  text.sceneName,
                                  text.sceneType
                                )
                              },
                            },
                          },
                          [_vm._v(" 启用自动回复 ")]
                        )
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "status",
                fn: function (text) {
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(text.statusDesc) + " "),
                  ])
                },
              },
              {
                key: "action",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "padding-left": "0" },
                          attrs: { type: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.openComment(text.id)
                            },
                          },
                        },
                        [_vm._v("回复评论")]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "replyContent",
                fn: function (text) {
                  return _c("div", {}, [
                    text.replyContent
                      ? _c(
                          "ul",
                          { staticClass: "contents_ul" },
                          [
                            _c("div", [_vm._v(_vm._s(text.replyTime))]),
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  title: text.replyContent,
                                },
                              },
                              [
                                _c("li", { staticClass: "contens_li" }, [
                                  _vm._v(_vm._s(text.replyContent)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("无")]),
                  ])
                },
              },
              {
                key: "userWaitTime",
                fn: function (text) {
                  return _c("div", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.disposeLongTime(text.userWaitTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "replyMsg",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-tooltip",
                        { attrs: { placement: "top", title: text.replyMsg } },
                        [
                          _c("div", { staticClass: "reply_msg" }, [
                            _vm._v(_vm._s(text.replyMsg)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "useComment",
                fn: function (text) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "div",
                        { staticStyle: { color: "rgba(0, 0, 0, 0.35)" } },
                        [_vm._v(_vm._s(text.commentTime))]
                      ),
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            placement: "bottomLeft",
                            title: text.content,
                          },
                        },
                        [
                          _c("div", { staticClass: "content-box" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(text.content) + " "),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("reply-strategy-drawer", {
            ref: "strategyDrawer",
            attrs: { visible: _vm.strategy_visible, tab_active: _vm.tab_str },
            on: {
              closeDrawer: function ($event) {
                _vm.strategy_visible = false
              },
              resetTable: _vm.resetTable,
              startUseing: _vm.startUseing,
            },
          }),
          _c("reply-rules-drawer", {
            ref: "rulesDrawer",
            attrs: { visible: _vm.rules_visible, tab_active: _vm.tab_str },
            on: {
              closeDrawer: function ($event) {
                _vm.rules_visible = false
              },
              resetTable: _vm.resetTable,
              startUseing: _vm.startUseing,
            },
          }),
          _c("reply-modal", {
            ref: "replyModal",
            attrs: { show_modal: _vm.show_modal, tab_active: _vm.tab_str },
            on: { closeModal: _vm.closeModal, resetTable: _vm.resetTable },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }