"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  columns_all: [{
    title: "评论内容",
    dataIndex: "content",
    scopedSlots: {
      customRender: "content"
    }
  }, {
    title: "评论时间",
    dataIndex: "commentTime"
  }, {
    title: "来源视频",
    dataIndex: "videoTitle",
    scopedSlots: {
      customRender: "videoTitle"
    }
  }, {
    title: "命中规则/策略",
    dataIndex: "sceneName",
    scopedSlots: {
      customRender: "sceneName"
    }
  }, {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status"
    }
  }, {
    title: "操作",
    dataIndex: "action",
    scopedSlots: {
      customRender: "action"
    }
  }],
  columns_value: [{
    title: "评论内容",
    dataIndex: "content",
    scopedSlots: {
      customRender: "content"
    }
  }, {
    title: "来源视频",
    dataIndex: "videoTitle",
    scopedSlots: {
      customRender: "videoTitle"
    }
  }, {
    title: "命中规则/策略",
    dataIndex: "sceneName",
    scopedSlots: {
      customRender: "sceneName"
    }
  }, {
    title: "策略响应内容",
    dataIndex: "replyContent",
    scopedSlots: {
      customRender: "replyContent"
    }
  }, {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status"
    }
  }, {
    title: "操作",
    dataIndex: "action",
    scopedSlots: {
      customRender: "action"
    }
  }],
  columns_success: [{
    title: "评论内容",
    dataIndex: "content",
    scopedSlots: {
      customRender: "content"
    }
  }, {
    title: "来源视频",
    dataIndex: "videoTitle",
    scopedSlots: {
      customRender: "videoTitle"
    }
  }, {
    title: "命中规则/策略",
    dataIndex: "sceneName",
    scopedSlots: {
      customRender: "sceneName"
    }
  }, {
    title: "策略响应内容",
    dataIndex: "replyContent",
    scopedSlots: {
      customRender: "replyContent"
    }
  }, {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status"
    }
  }, {
    title: "操作",
    dataIndex: "action",
    scopedSlots: {
      customRender: "action"
    }
  }],
  columns_fail: [{
    title: "命中规则/策略",
    dataIndex: "sceneName",
    scopedSlots: {
      customRender: "sceneName"
    }
  }, {
    title: "评论内容",
    dataIndex: "content",
    scopedSlots: {
      customRender: "content"
    }
  }, {
    title: "来源视频",
    dataIndex: "videoTitle",
    scopedSlots: {
      customRender: "videoTitle"
    }
  }, {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status"
    }
  }, {
    title: "操作",
    dataIndex: "action",
    scopedSlots: {
      customRender: "action"
    }
  }]
};
exports.default = _default;