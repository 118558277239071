"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 最视频监控列表
  getAwemeList: function getAwemeList(_ref) {
    var status = _ref.status,
      title = _ref.title,
      size = _ref.size,
      page = _ref.page;
    return request({
      url: '/mirror-data/aweme-monit/list',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        status: status,
        title: title,
        size: size,
        page: page
      }
    });
  },
  // 剩余未使用的监控数量
  getUnused: function getUnused() {
    return request({
      url: '/mirror-data/aweme-monit/unused-monit-count',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 查询视频连接是否存在
  queryAweme: function queryAweme(_ref2) {
    var awemePath = _ref2.awemePath;
    return request({
      url: '/mirror-data/aweme-monit/query-aweme-by-path',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        awemePath: awemePath
      }
    });
  },
  // 创建监控
  createAweme: function createAweme(_ref3) {
    var awemePath = _ref3.awemePath,
      hours = _ref3.hours;
    return request({
      url: '/mirror-data/aweme-monit/create',
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        awemePath: awemePath,
        hours: hours
      }
    });
  },
  // 监控视频详情的互动信息
  queryInteractInfo: function queryInteractInfo(_ref4) {
    var monitAwemeRecordId = _ref4.monitAwemeRecordId;
    return request({
      url: '/mirror-data/aweme-monit/interact-info',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId
      }
    });
  },
  // 详情--数据走势
  queryTrend: function queryTrend(_ref5) {
    var monitAwemeRecordId = _ref5.monitAwemeRecordId;
    return request({
      url: '/mirror-data/aweme-monit/interaction-trend',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId
      }
    });
  },
  // 详情--数据走势-导出
  exportTrend: function exportTrend(_ref6) {
    var monitAwemeRecordId = _ref6.monitAwemeRecordId;
    return request({
      url: '/mirror-data/aweme-monit/export',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId
      }
    });
  },
  // 详情--视频分析--热词
  queryHotAnalysis: function queryHotAnalysis(_ref7) {
    var monitAwemeRecordId = _ref7.monitAwemeRecordId;
    return request({
      url: '/mirror-data/aweme-monit/hot-analysis',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId
      }
    });
  },
  // 详情--视频分析--评论
  queryVideoComments: function queryVideoComments(_ref8) {
    var monitAwemeRecordId = _ref8.monitAwemeRecordId,
      page = _ref8.page,
      size = _ref8.size;
    return request({
      url: '/mirror-data/aweme-monit/comments',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId,
        page: page,
        size: size
      }
    });
  },
  // 详情--观众分析
  queryAudienceAnalysis: function queryAudienceAnalysis(_ref9) {
    var monitAwemeRecordId = _ref9.monitAwemeRecordId;
    return request({
      url: '/mirror-data/aweme-monit/audience-analysis',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        monitAwemeRecordId: monitAwemeRecordId
      }
    });
  }
};
exports.default = _default;