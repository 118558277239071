"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.match");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
//
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "pie-chart"
    },
    id: {
      type: String,
      default: "pie-chart"
    },
    boxStyle: {
      type: Object,
      default: function _default() {
        return {
          width: "874px",
          height: "300px"
        };
      }
    },
    pieData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    // this.initChart();
  },
  computed: {
    styleObject: function styleObject() {
      var obj = this.boxStyle;
      for (var attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";
        obj[attr] = unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    pieData: function pieData() {
      this.initChart();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      console.log('性别分析接口', this.pieData);
      var bgColor = "#fff";
      var color = ["#CB6162", "#00B9C5"];
      this.chart.setOption({
        color: color,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: ({d}%)"
        },
        legend: {
          show: false
        },
        series: [(0, _defineProperty2.default)({
          type: "pie",
          radius: ["40%", "70%"],
          // center: ["50%", "50%"],
          data: this.chartData,
          hoverAnimation: false,
          label: {
            normal: {
              position: "outside",
              alignTo: "none",
              bleedMargin: 1,
              formatter: '{b} {d}% '
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "12",
              fontWeight: "bold"
            }
          },
          itemStyle: {
            borderWidth: 10,
            borderColor: "#000710"
          }
        }, "data", [{
          value: this.pieData.gender.percentage[0],
          name: this.pieData.gender.desc[0]
        }, {
          value: this.pieData.gender.percentage[1],
          name: this.pieData.gender.desc[1]
        }])]
      });
    }
  }
};
exports.default = _default2;