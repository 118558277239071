var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-card",
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.handleSubDaysChange },
                  model: {
                    value: _vm.subDays,
                    callback: function ($$v) {
                      _vm.subDays = $$v
                    },
                    expression: "subDays",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: -1 } }, [
                    _vm._v(" 昨天 "),
                  ]),
                  _c("a-radio-button", { attrs: { value: 7 } }, [
                    _vm._v(" 近7天 "),
                  ]),
                ],
                1
              ),
              _c("a-range-picker", {
                staticClass: "ml-10",
                attrs: {
                  "disabled-date": _vm.disabledDate,
                  "allow-clear": false,
                },
                on: { change: _vm.handleDateChange },
                model: {
                  value: _vm.selectedDates,
                  callback: function ($$v) {
                    _vm.selectedDates = $$v
                  },
                  expression: "selectedDates",
                },
              }),
              _c("span", { staticClass: "ml-10 color-grey" }, [
                _vm._v("仅支持查询近90天"),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "statistics-time" }, [
            _vm._v("周期内抖音线索总数：" + _vm._s(_vm.clueCount)),
          ]),
          _c(
            "a-card",
            [
              _c("h2", { staticClass: "title" }, [_vm._v("邀约明细")]),
              _c("cn-table", {
                ref: "cntable",
                attrs: {
                  configPayload: _vm.configPayload,
                  initData: false,
                  selectValue: _vm.form,
                  paginationSetting: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "callDuration",
                    fn: function (text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm.convertToTime(text.callDuration))),
                      ])
                    },
                  },
                  {
                    key: "avgCallDuration",
                    fn: function (text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm.convertToTime(text.avgCallDuration))),
                      ])
                    },
                  },
                  {
                    key: "action",
                    fn: function (text) {
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "padding-left": "0" },
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCallDetailDrawer(text)
                                },
                              },
                            },
                            [_vm._v(" 通话详情 ")]
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("CallDetailDrawer", { ref: "CallDetailDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }