"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _video = _interopRequireDefault(require("video.js"));
require("videojs-contrib-hls");
var _hls = _interopRequireDefault(require("hls.js"));
var _xgplayer = _interopRequireDefault(require("xgplayer"));
var _xgplayerHls = _interopRequireDefault(require("xgplayer-hls.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      url: "https://pili-live-hls.afanticar.com/afanticar-live/201026141111563369720.m3u8"
    };
  },
  mounted: function mounted() {
    // hls
    // const hls = new Hls();
    // hls.loadSource(this.url);
    // hls.attachMedia(this.$refs.hlsVideo);

    // const videoPlayer = videojs("videoDom", {}, function onPlayerReady() {
    //   this.play();
    // });

    // 直播开始
    var url = this.url;
    var player = new _xgplayerHls.default({
      id: "live-player",
      // 播放器容器id
      url: url,
      // 视频源
      fluid: true,
      // 是否开启流式布局
      useHls: true,
      // 打开hls解析功能
      playsinline: true,
      // 开启ios和微信的内联模式
      poster: "http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/live/benchi.jpeg",
      // 封面图
      isLive: true,
      // autoplay: true, // 自动播放 */
      volume: 0.5,
      // 默认音量 0~1
      closeVideoClick: true,
      // 取消通过点击屏幕播放/暂停状态
      closeVideoTouch: true,
      // 取消移动端下通过touchend事件播放/暂停状态
      ignores: ["time", "definition", "pc", "play", "progress"],
      // 关闭对应内置控件
      "x5-video-player-type": "h5",
      // 微信同层播放
      "x5-video-player-fullscreen": false,
      // 微信全屏播放
      /* errorTips:
      '<div class="f-text-center" style="z-index: 9999"><p class="s-white f-font16">直播中断了, 尝试刷新下</p><button class="xgplayer-error-refresh f-default-btn" style="color: #fff;margin-top: 16px;">刷新</button></div>' // 视频源错误提示 */
      errorTips: '<div class="f-text-center" style="z-index: 9999"><p class="s-white f-font16">直播中断了，尝试刷新页面</p></div>' // 视频源错误提示
    });
  }
};
exports.default = _default;