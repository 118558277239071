var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_box" },
    [
      _c(
        "a-card",
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.activeKey,
                callback: function ($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey",
              },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "关键词回复" } },
                [
                  _c("reply-rules-pane", {
                    attrs: { "active-key": _vm.activeKey },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }