"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// videoData传参示例
// videoData: {
//   "comment_count":"3",
//   "share_count":"1",
//   "aweme_title":"SUV销量冠军哈弗H6的合金模型 1:18红标版",
//   "aweme_id":"6736329142041677060",
//   "aweme_topic":[],
//   "digg_count":"16",
//   "aweme_cover":"http://p9-dy.byteimg.com/large/tos-cn-p-0015/aa5835e2e9f74bf68c98c032a51beddf.jpeg?from=2563711402_large",
// }
var _default2 = {
  components: {},
  // props: ["videoData"],
  props: {
    videoData: {
      type: Object
    },
    parentStyle: {
      type: Object,
      default: function _default() {
        return {
          width: '155px',
          height: '226px',
          margin: '0'
        };
      }
    },
    imgWidth: {
      type: String,
      default: '168px'
    },
    mskPadding: {
      style: Object,
      default: function _default() {
        return {
          padding: '15px 8px 5px'
        };
      }
    }
  },
  data: function data() {
    return {
      defultImg: 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg'
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    editNumer: function editNumer(num) {
      if (parseInt(num) >= 10000) {
        return (num / 10000).toFixed(2) + 'W';
      } else {
        return num;
      }
    }
  }
};
exports.default = _default2;