"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var request = (0, _request.default)({
  baseURL: process.env.VUE_APP_CONTENT_CENTER_API
});
var _default = {
  // 上传文件
  uploadFile: function uploadFile(data) {
    return request({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: "/v1/file/upload-by-file",
      method: 'POST',
      data: data
    });
  }
};
exports.default = _default;