var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "启用自动回复规则",
        placement: "right",
        visible: _vm.visible,
        maskClosable: false,
        width: 580,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-spin",
        {
          attrs: { spinning: _vm.loading_dw, tip: "加载中...", size: "large" },
        },
        [
          _c(
            "div",
            { staticClass: "main_box" },
            [
              _c("p", [
                _vm._v(
                  "启用针对视频评论的回复规则，对之后命中的评论做自动回复。"
                ),
              ]),
              _c("div", { staticClass: "flex_btn" }, [
                _c("p", { staticClass: "tips_reply" }, [
                  _vm._v(" 对命中"),
                  _c("span", { staticClass: "blue-name" }, [
                    _vm._v(_vm._s(_vm.form.sceneName)),
                  ]),
                  _vm._v("规则的评论（已回复的除外），随机回复任意一条内容 "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "content_box" },
                [
                  _c(
                    "a-form-model",
                    { ref: "form", attrs: { model: _vm.form } },
                    _vm._l(_vm.form.wordResponseList, function (item, index) {
                      return _c(
                        "a-form-model-item",
                        { key: index },
                        [
                          _c("a-textarea", {
                            staticStyle: {
                              width: "90%",
                              "margin-right": "8px",
                            },
                            attrs: {
                              placeholder: "请输入回复内容",
                              rows: 4,
                              value: item.showMessage,
                              maxLength: 100,
                              "read-only": "",
                            },
                          }),
                          _c("div", { staticClass: "right_box" }, [
                            _c("span", { staticClass: "tips_count" }, [
                              _vm._v(_vm._s(item.showMessage.length) + "/100"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-checkbox",
                {
                  model: {
                    value: _vm.form.isHitPerform,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isHitPerform", $$v)
                    },
                    expression: "form.isHitPerform",
                  },
                },
                [
                  _vm._v(" 同时对近3天内已命中"),
                  _c("span", { staticClass: "blue-name" }, [
                    _vm._v(_vm._s(_vm.form.sceneName)),
                  ]),
                  _vm._v("规则的 "),
                  _c("span", { staticClass: "red-name" }, [
                    _vm._v(_vm._s(_vm.form.threeDayHitStrategyCommentNum)),
                  ]),
                  _vm._v(" 条评论执行该规则 "),
                ]
              ),
              _c(
                "p",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _vm._v(" 若需要维护规则详细信息，请进入 "),
                  _c(
                    "a-button",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { type: "link" },
                      on: { click: _vm.goRouter },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v("关键词回复规则页面")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "tips_box" }, [
        _vm._v(" 注意：回复的评论内容将发布到抖音视频评论区，所有人可见 "),
      ]),
      _c(
        "div",
        {
          style: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
          },
        },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "8px" },
              on: { click: _vm.onClose },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: { click: _vm.saveEditMessage },
            },
            [_vm._v(" 启用 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }