"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _default = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: ['本品车系', '竞品车系'],
    bottom: true
  },
  grid: {
    top: '3%',
    left: '2%',
    right: '5%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    axisTick: {
      inside: false
    },
    axisLine: {
      show: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed"
      }
    }
  },
  yAxis: {
    type: 'category',
    data: []
  },
  series: [{
    name: '本品车系',
    type: 'bar',
    data: [],
    itemStyle: {
      normal: {
        color: new _echarts.default.graphic.LinearGradient(0, 0, 1, 0, [{
          offset: 0,
          color: 'rgba(100,169,243,0.00)'
        }, {
          offset: 1,
          color: 'rgba(100,169,243,1.00)'
        }])
      }
    }
  }, {
    name: '竞品车系',
    type: 'bar',
    data: [],
    itemStyle: {
      normal: {
        color: new _echarts.default.graphic.LinearGradient(0, 0, 1, 0, [{
          offset: 0,
          color: 'rgba(39,199,125,0.00)'
        }, {
          offset: 1,
          color: 'rgba(39,199,125,1.00)'
        }])
      }
    }
  }]
};
exports.default = _default;