"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var request = (0, _request.default)();
function buildCancelToken() {
  return _axios.default.CancelToken;
}

// 缓存上一个请求的cancel，用于取消上一个尚未结束的请求
var cancel = null; // 一个请求对应一个cancel
var _module = '/live-crm';
var CancelToken = buildCancelToken();
var _default = {
  //  查询触达策略列表
  getAttentionList: function getAttentionList() {
    return request({
      url: "/mkms/liveAutoReplyModel/list",
      method: 'get'
    });
  },
  // 关闭触达策略
  changeAttention: function changeAttention(data) {
    return request({
      url: "/mkms/liveAutoReplyModel/switch",
      method: 'post',
      data: data
    });
  },
  // 查询触达策略配置
  liveAutoReplyModel: function liveAutoReplyModel(sceneId) {
    return request({
      url: "/mkms/liveAutoReplyModel/".concat(sceneId),
      method: 'get'
    });
  },
  // 编辑触达策略配置
  editLiveAutoReply: function editLiveAutoReply(data) {
    return request({
      url: "/mkms/liveAutoReplyModel/".concat(data.sceneId),
      method: 'post',
      data: data
    });
  },
  // 新关注监测查询全部
  attentionListAll: function attentionListAll(params) {
    return request({
      url: "/mkms/liveFollowDetection/list",
      method: 'get',
      params: params
    });
  },
  // 新关注监测未响应
  attentionListNores: function attentionListNores(params) {
    return request({
      url: "/mkms/liveFollowDetection/notResponse/list",
      method: 'get',
      params: params
    });
  },
  // 新关注监测策略已响应
  attentionListRespose: function attentionListRespose(params) {
    return request({
      url: "/mkms/liveFollowDetection/response/list",
      method: 'get',
      params: params
    });
  },
  // 新关注监测-查询目标城市配置
  getliveUserTargetCity: function getliveUserTargetCity() {
    return request({
      // url: `/mkms/liveUserTargetCity/get`,
      url: "/customer-chat/get/chat-city",
      method: 'get'
    });
  },
  // 新关注监测-关闭目标城市
  getUserTargetCitySwitch: function getUserTargetCitySwitch() {
    return request({
      url: "/mkms/liveUserTargetCity/switch",
      method: 'post'
    });
  },
  // 新关注监测-编辑目标城市配置
  getUserTargetCityUpdate: function getUserTargetCityUpdate(data) {
    return request({
      // url: `/mkms/liveUserTargetCity/update`,
      url: "/customer-chat/post/chat-city",
      method: 'post',
      data: data
    });
  },
  // 查询触达策略示例评论
  getCommentList: function getCommentList(params) {
    return request({
      url: "/mkms/liveAutoReplyModel/sampleComment/list",
      method: 'get',
      params: params
    });
  },
  // 获取用户抖音主页二维码
  getDyUserQrcode: function getDyUserQrcode(params) {
    if (cancel) {
      cancel('取消请求');
      cancel = null;
    }
    return request({
      url: "/mkms/liveFollowDetection/dyUserQrcode",
      method: 'get',
      params: params,
      cancelToken: new CancelToken(function (c) {
        // 记录cancel，用于在下一个请求之前，取消这个请求（如果这个请求那时还未执行完毕的话）
        cancel = c;
      })
    });
  },
  //   关键指标质量报告列表
  //   getAnalysisList(params) {
  //     return request({
  //       url: `${module}/attribution-analysis-info/list`,
  //       method: 'get',
  //       params,
  //       cancelToken: new CancelToken(c => {
  //         // 记录cancel，用于在下一个请求之前，取消这个请求（如果这个请求那时还未执行完毕的话）
  //         cancel = c
  //       }),
  //     })
  //   },
  // 跳转私信管理页面-保存关注信息到私信粉丝记录表
  chatFans: function chatFans(userId) {
    return request({
      url: "/mkms/liveFollowDetection/chatFans/".concat(userId),
      method: 'post'
    });
  },
  // 查询主动私信营销开关设置
  switchHeadStatus: function switchHeadStatus() {
    return request({
      url: "/mkms/liveSwitchSetting/setting",
      method: 'get'
    });
  },
  // 开启/停用主动私信营销
  changeHeadSwitch: function changeHeadSwitch(params) {
    return request({
      url: "/mkms/liveSwitchSetting/switch",
      method: 'get',
      params: params
    });
  },
  // 视频触达策略
  getVideoReply: function getVideoReply() {
    return request({
      url: '/mkms/videoComment/reply',
      method: 'get'
    });
  },
  // 获取互动监测查询条件
  getAttention: function getAttention() {
    return request({
      url: '/mkms/liveFollowDetection/queryParams',
      method: 'get'
    });
  },
  // 获取是否首次登录
  loginPcOrClickGuestSentiment: function loginPcOrClickGuestSentiment(params) {
    return request({
      url: '/mkms/first/loginPcOrClickGuestSentiment',
      method: 'get',
      params: params
    });
  },
  // 确认媒体号信息
  loginFirstConfirm: function loginFirstConfirm(params) {
    return request({
      url: '/mkms/first/confirm',
      method: 'get',
      params: params
    });
  },
  // 获取是否授权
  getAuthorizationStatus: function getAuthorizationStatus() {
    return request({
      url: '/mkms/first/isAuthorization',
      method: 'get'
    });
  },
  // 是否设置特别关注城市
  getIsSetCareCity: function getIsSetCareCity() {
    return request({
      url: '/mkms/first/isSetCareCity',
      method: 'get'
    });
  },
  // 是否显示头部警告
  getInitiativeMarketingAnnouncementInfo: function getInitiativeMarketingAnnouncementInfo(params) {
    return request({
      url: '/mkms/marketingDataCenter/initiativeMarketingAnnouncementInfo',
      method: 'get',
      params: params
    });
  }
};
exports.default = _default;