"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  getOverall: function getOverall() {
    return request({
      url: '/mirror/overall',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getFansTrend: function getFansTrend() {
    return request({
      url: '/mirror/fans/trend',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getFansAmount: function getFansAmount() {
    return request({
      url: '/mirror-data/douyin/author/fans/all-manufactor',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getDealerArea: function getDealerArea() {
    return request({
      url: '/mirror/dealers/area',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getDealerAweme: function getDealerAweme() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request({
      url: '/mirror/dealers/aweme',
      method: 'GET',
      params: {
        interval: data.interval
      },
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getDealerTop: function getDealerTop() {
    return request({
      url: '/mirror/dealers/top',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getBubbleChat: function getBubbleChat() {
    return request({
      url: '/mirror/aweme/douyin/topic',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getInterationSeries: function getInterationSeries() {
    return request({
      url: '/mirror/interation/series',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAnchorEvaluate: function getAnchorEvaluate() {
    return request({
      url: '/mirror/author/douyin/struct',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getAnchorRecommend: function getAnchorRecommend() {
    return request({
      url: '/mirror//recommend',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getTopic: function getTopic(_ref) {
    var topic = _ref.topic;
    return request({
      url: '/mirror/aweme/douyin/topic-aweme',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        topic: topic
      }
    });
  },
  getEnemies: function getEnemies(_ref2) {
    var id = _ref2.id;
    return request({
      url: "/mirror-data/series/".concat(id, "/enemies"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  getEnemySeries: function getEnemySeries(_ref3) {
    var id = _ref3.id;
    return request({
      url: "/mirror/series/".concat(id, "/comment-count"),
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  }
};
exports.default = _default;