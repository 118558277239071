var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.tipVisible,
        footer: null,
        closable: false,
        width: 360,
      },
    },
    [
      _c("p", { staticClass: "header_tips" }, [_vm._v("温馨提示")]),
      _c("p", { staticClass: "color1 mrg16" }, [
        _vm._v(
          " 主动私信营销功能可以针对有直播价值评论的关注用户自动发送私信。 "
        ),
      ]),
      _c("div", { staticClass: "color1 mrg8" }, [
        _vm._v("在使用之前，需要您的抖音账号设置："),
      ]),
      _c("div", { staticClass: "color2 ftw1" }, [
        _vm._v(" 关注和粉丝列表设置为公开可见 "),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c("a", { staticClass: "footer-btn", on: { click: _vm.goRouter } }, [
          _vm._v("如何设置"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }