"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _live_comment = _interopRequireDefault(require("@/api/live_comment"));
var _common = _interopRequireDefault(require("@/api/common"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      video_message: {},
      confirmLoading: false,
      form: {
        message_content: ""
      },
      rules: {
        message_content: [{
          required: true,
          message: "回复内容不能为空",
          trigger: "blur"
        }]
      },
      video_id: ""
    };
  },
  props: {
    show_modal: {
      type: Boolean,
      default: false
    },
    tab_active: {
      type: String,
      default: ""
    }
  },
  methods: {
    getVideoComment: function getVideoComment(id) {
      var _this = this;
      this.video_id = id;
      _live_comment.default.getVideoComment(id).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this.video_message = res.data;
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    handleOk: function handleOk(e) {
      var _this2 = this;
      this.filtrate.setTracking({
        en: "zxmk_yx_pljchfplan_click",
        kv: {
          openID: this.video_id,
          pageName: this.tab_active
        }
      });
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this2.confirmLoading = true;
          var payload = {
            id: _this2.video_id,
            content: _this2.form.message_content
          };
          _live_comment.default.replyComment(payload).then(function (res) {
            if (res.code == 200) {
              _this2.$emit("resetTable");
              _this2.$message.success("评论回复成功");
              _this2.handleCancel();
            } else {
              _this2.$message.error(res.message);
            }
          }).finally(function () {
            _this2.confirmLoading = false;
          });
        }
      });
    },
    handleCancel: function handleCancel(e) {
      console.log("Clicked cancel button");
      this.form.message_content = "";
      this.$emit("closeModal");
    },
    disposeTime: function disposeTime(value) {
      if (!value) {
        return;
      }
      var pub_time = this.moment(value).valueOf();
      var today = this.moment().startOf("day").valueOf();
      var yesterday_time = this.moment().subtract(1, "days").valueOf();
      var year_time = this.moment().startOf("year").valueOf();
      var week_time = this.moment().startOf("week").valueOf();
      var date_arrA = value.split("-");
      var date_arrB = value.split(" ");
      var now_time = Date.parse(new Date()); // 获取当前时间戳
      var diff_time = now_time - pub_time;
      var minute = 60 * 1000;
      var hour = minute * 60;
      var hourC = diff_time / hour;
      var minC = diff_time / minute;
      var res = "";
      var time = value.slice(11, 16);
      if (pub_time < year_time) {
        res = date_arrA[0] + "." + date_arrA[1] + "." + date_arrA[2] + " " + date_arrB[1];
        res = res.slice(0, 16);
      } else if (pub_time < week_time) {
        res = date_arrA[1] + "." + date_arrA[2] + " " + date_arrB[1];
        res = res.slice(0, 11);
      } else if (pub_time >= week_time && pub_time < yesterday_time) {
        var week = this.moment(pub_time).format("ddd");
        res = week + " " + date_arrB[1];
        res = res.slice(0, 8);
      } else if (pub_time < today && pub_time >= yesterday_time) {
        res = "昨天" + " " + time;
      } else if (pub_time >= today && hourC >= 1) {
        res = "今天" + " " + time;
      } else if (minC >= 1) {
        res = parseInt(minC) + "分钟前";
      } else {
        res = "刚刚";
      }
      return res;
    }
  }
};
exports.default = _default;