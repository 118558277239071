"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/dev-frontedn-aft-crm-dy/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NetworldRequest = _interopRequireDefault(require("@/utils/NetworldRequest"));
// import request from '@/utils/NetworldRequest'

var request = new _NetworldRequest.default().getAxios();
var _default = {
  // 获取门店详情-经销商
  getStoreInfo: function getStoreInfo() {
    return request({
      url: '/mirror-data/account/personal/information',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 获取门店详情-主机厂
  getStoreInfoZ: function getStoreInfoZ() {
    return request({
      url: '/mirror-data/account/personal/information_z',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 媒体账号列表-经销商
  getMediaList: function getMediaList() {
    return request({
      url: '/mirror-data/account/media/list',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 媒体账号列表-主机厂
  getMediaListZ: function getMediaListZ() {
    return request({
      url: '/mirror-data/account/media/list_z',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 查询媒体账号
  getMediaCode: function getMediaCode(_ref) {
    var share_url = _ref.share_url;
    return request({
      url: "/mirror-data/account/add/media?share_url=".concat(share_url),
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API
    });
  },
  // 添加媒体账号
  addMediaCode: function addMediaCode(_ref2) {
    var user_id = _ref2.user_id,
      creator = _ref2.creator,
      modifier = _ref2.modifier,
      code = _ref2.code,
      type = _ref2.type,
      is_deleted = _ref2.is_deleted;
    return request({
      url: "/mirror-data/live/account/create",
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      data: {
        user_id: user_id,
        creator: creator,
        modifier: modifier,
        code: code,
        type: type,
        is_deleted: is_deleted
      }
    });
  },
  // 获取全部城市
  getCity: function getCity() {
    return request({
      url: '/mirror-data/account/areas/v1',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        level: 2
      }
    });
  },
  // 更新个人经销商信息
  updateInfo: function updateInfo(_ref3) {
    var company_name = _ref3.company_name,
      sellphone = _ref3.sellphone,
      city_name = _ref3.city_name,
      city_id = _ref3.city_id,
      address = _ref3.address,
      id = _ref3.id;
    return request({
      url: '/mirror-data/account/update/dealer',
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      data: {
        company_name: company_name,
        sellphone: sellphone,
        city_name: city_name,
        city_id: city_id,
        address: address,
        id: id
      }
    });
  },
  // 更新备注
  updateRemake: function updateRemake(_ref4) {
    var remark = _ref4.remark,
      code = _ref4.code,
      id = _ref4.id;
    return request({
      url: '/mirror-data/live/account/update',
      method: 'PUT',
      baseURL: process.env.VUE_APP_OPENDATA_API,
      data: {
        remark: remark,
        code: code,
        id: id
      }
    });
  }
};
exports.default = _default;